<ion-fab *ngIf="showVitaliaIcon" class="fab-container" vertical="bottom" horizontal="end" slot="fixed">
  <ion-fab-button class="principal-button contact-button" [activated]="optionsOpened" (click)="toggleOptions()"
    title="{{ pageContent?.contactCenter | translate }}">
    <div class="i-customer-service"></div>
  </ion-fab-button>
  <div class="help-button-container" [class.open]="optionsOpened">
    <div class="tools-container" [class.open]="optionsOpened">
      <div class="row-container first-row">
        <div class="container-button" (click)="goToSocialNetwork('whatsapp')">
          <div class="help-button whatsapp" title="{{ pageContent?.whatsapp | translate }}">
            <div class="icon i-whatsapp"></div>
          </div>
        </div>
        <div class="help-description whatsapp" [ngClass]="font?.size">{{ pageContent?.whatsapp | translate }}</div>
      </div>
      <div *ngIf="!isApp" class="row-container">
        <div class="container-button" (click)="openVideocall()">
          <div class="help-button" title=" Videollamada ">
            <div class="icon i-laptop-videocall"></div>
          </div>
        </div>
        <div class="help-description" [ngClass]="font?.size"> Videollamada </div>
      </div>
      <div class="row-container">
        <div class="container-button" (click)="openCallCenterModal()">
          <div class="help-button" title="{{ pageContent?.contactCenter | translate }}">
            <div class="icon i-phone-contact"></div>
          </div>
        </div>
        <div class="help-description" [ngClass]="font?.size">{{ pageContent?.contactCenter | translate }}</div>
      </div>
      <div class="row-container">
        <div class="container-button" (click)="displayWebchat()">
          <div class="help-button" title="{{ pageContent?.webchat | translate }}">
            <div class="icon i-webchat"></div>
          </div>
        </div>
        <div class="help-description" [ngClass]="font?.size">{{ pageContent?.webchat | translate }}</div>
      </div>
    </div>

  </div>
</ion-fab>

<div *ngIf="optionsOpened" class="black-screen"></div>