<div class="modal-container">
  <div class="i-cross-gray" (click)="closeModal()"></div>

  <h1 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h1>
  <p class="description" [ngClass]="font?.size">{{ pageContent?.description | translate }}</p>

  <form [formGroup]="form">
    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-select [ngClass]="font?.size" [placeholder]="pageContent?.form.reasonId | translate" formControlName="reasonId">
          <mat-option [ngClass]="font?.size" *ngFor="let contactReason of contactReasons" [value]="contactReason.id">
            {{contactReason.description}}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size" [innerHTML]="pageContent?.form.errors.required | translate"></mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.form.detail | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="detail">
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" [innerHTML]="pageContent?.form.errors.required | translate"></mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-select [ngClass]="font?.size" [placeholder]="pageContent?.form.file | translate" formControlName="addedFile"
          (selectionChange)="updateFileValidator()">
          <mat-option [ngClass]="font?.size" [value]="false">{{ pageContent?.buttons.no | translate }}</mat-option>
          <mat-option [ngClass]="font?.size" [value]="true">{{ pageContent?.buttons.yes | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="file-input" *ngIf="addedFile.value">
      <mat-form-field class="file-name" [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.form.fileName | translate"></mat-label>
        <input [ngClass]="font?.size" matInput formControlName="fileName" readonly>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size">{{ pageContent?.form.errors.required | translate }}</mat-error>
      </mat-form-field>

      <label for="file-upload" class="btn primary small" [ngClass]="font?.size">
        {{ labelButtonFile | translate }}
      </label>
      <input id="file-upload" class="btn primary small" type="file" (change)="onFileChange($event)" accept="{{ validMimeTypes }}">
    </div>
  </form>

  <button class="btn large primary" [ngClass]="font?.size" [disabled]="form.invalid" (click)="sendFormData()">{{ pageContent?.buttons.send | translate }}</button>
</div>
