import { EMAIL_NOT_FOUND_ERROR_CODE } from '@constants/error-codes.constants';

const pageKey = 'PASSWORDLESS_ACCESS';

export const RESEND_CODE_TIMEOUT = 120;

export const PASSWORDLESS_ACCESS_CONTENT = {
  title: `${pageKey}.TITLE`,
  subtitle: `${pageKey}.SUBTITLE`,
  updateTitle: `${pageKey}.UPDATE_TITLE`,
  updateSubtitle: `${pageKey}.UPDATE_SUBTITLE`,
  successMessage: `${pageKey}.SUCCESS_MESSAGE`,
  nullEmailModal: {
    errorCode: EMAIL_NOT_FOUND_ERROR_CODE,
    title: `${pageKey}.ERROR_MODAL.NO_EMAIL.TITLE`,
    message: `${pageKey}.ERROR_MODAL.NO_EMAIL.MESSAGE`,
    button: `${pageKey}.ERROR_MODAL.NO_EMAIL.UPDATE_DATA`,
  },
  form: {
    controls: {
      rut: {
        label: `${pageKey}.FORM.CONTROLS.RUT.LABEL`,
        errors: {
          required: `${pageKey}.FORM.CONTROLS.RUT.ERRORS.REQUIRED`,
          invalid: `${pageKey}.FORM.CONTROLS.RUT.ERRORS.INVALID`
        }
      }
    },
  },
  recoveryOptions: {
    messages: {
      bothOptions: `${pageKey}.RECOVERY_OPTIONS.MESSAGES.BOTH_OPTIONS`,
      isForeigner: `${pageKey}.RECOVERY_OPTIONS.MESSAGES.IS_FOREIGNER`,
      changeOption: `${pageKey}.RECOVERY_OPTIONS.MESSAGES.CHANGE_OPTION`,
      timerMessage: `${pageKey}.RECOVERY_OPTIONS.MESSAGES.TIMER_MESSAGE`,
      retry: `${pageKey}.RECOVERY_OPTIONS.MESSAGES.RETRY`,
      disclaimer: `${pageKey}.RECOVERY_OPTIONS.MESSAGES.DISCLAIMER`
    },
    options: {
      sms: `${pageKey}.RECOVERY_OPTIONS.OPTIONS.SMS`,
      email: `${pageKey}.RECOVERY_OPTIONS.OPTIONS.EMAIL`,
    },
    buttons: {
      sendKey: `${pageKey}.RECOVERY_OPTIONS.BUTTONS.SEND_LINK`,
      updateData: `${pageKey}.RECOVERY_OPTIONS.BUTTONS.UPDATE_DATA`,
      back: `${pageKey}.RECOVERY_OPTIONS.BUTTONS.BACK`
    }
  },
  buttons: {
    continue: `${pageKey}.BUTTONS.CONTINUE`,
    back: `${pageKey}.BUTTONS.BACK`,
    updateData: `${pageKey}.BUTTONS.UPDATE_DATA`,
    submit: `${pageKey}.BUTTONS.SUBMIT`,
    backLogin: `${pageKey}.BUTTONS.BACK_LOGIN`,
  },
  recaptcha: `${pageKey}.RECAPTCHA`,
  languages: {
    spanish: `LOGIN.LANGUAGES.SPANISH`,
    creole: `LOGIN.LANGUAGES.CREOLE`,
  }
};
