export const EMPLOYER_MOCK = {
  success: [{
    response: {
      rut: '98.000.500-3',
      address: 'Tenderini 127',
      commune: 'Santiago',
      email: 'sitioweb.planvital@planvital.cl',
      economicActivity: 'ADMINISTRADORAS DE FONDOS DE PENSIONES (AFP)',
      phoneNumber: '555555555',
      city: 'Santiago',
      businessName: 'A.F.P. Planvital S.A.',
      region: 'Region Metropolitana',
    },
  },
  ],
  failures: [{
    response: {
      code: 50001,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
    },
  }, {
    response: {
      code: 40066,
      message: 'El RUT no se encuentra registrado como empresa.',
      messageDescription: '',
    },
  }],
};
