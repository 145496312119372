import { Environment } from '@interfaces/environment.interface';

const BASE_URL = 'http://35.186.243.77/';
// Confirmar rutas al iniciar proyecto o realizar pulls
const PJ_URL = 'http://35.186.243.77/amber/';
export const environment: Environment = {
  platformCompiledFor: 'web',
  baseUrl: BASE_URL,
  clientsUrl: `${BASE_URL}clients/`,
  authenticationUrl: `${BASE_URL}authentication/`,
  employersUrl: `${BASE_URL}employers/`,
  certificatesUrl: `${BASE_URL}certificates/`,
  publicProxyUrl: `${BASE_URL}public/`,
  affiliateApplicationsUrl: `${PJ_URL}applications/`,
  quotationVideoUrl: 'https://us-east4-plan-vital-dev-qa.cloudfunctions.net/statements-video-data',
  transferUrl: 'http://35.186.243.77/transfer/',
  name: 'dev',
  production: false,
  serviceWorker: false,
  expressVersion: false,
  mockedResponseSuccessRate: 1,
  mockHttpCalls: false,
  recaptchaSiteKey: '6LdMsLcZAAAAAILUOD0ObyYhJHeWDjUeMSaPWFbC',
  cmsApiUrl: `${BASE_URL}cms/sitio-privado/`,
  cmsApiUrlPublic: 'http://35.186.243.77/cms/sitio-publico/',
  cmsApiUrlV2: 'https://cms-dev.planvital.cl/',
  cmsContent: 'published',
  googleAnalyticsId: '',
  firebaseConfig: {
    apiKey: 'AIzaSyAGbRArJYj704Prbq8iEtcAx88qBQFH_wk',
    authDomain: 'plan-vital-dev-qa.firebaseapp.com',
    databaseURL: 'https://plan-vital-dev-qa.firebaseio.com',
    projectId: 'plan-vital-dev-qa',
    storageBucket: 'plan-vital-dev-qa.appspot.com',
    messagingSenderId: '381542100011',
    appId: '1:381542100011:web:da5eadd3e46fcd76'
  },
  planvitalUrl: 'https://shckltn.planvital.cl',
  publicSiteProxyApiUrl: `${BASE_URL}public/`,
  googleMapsAPIKey: 'AIzaSyASsg_8ad1MzbrYhqXg-8_68evuow-jh5c',
  publicFilesUrl: 'https://shckltn.planvital.cl/archivos/',
  filesApiUrl: 'https://files.apistg.planvital.cl/archivos/',
  INDIVIDEO_ENVIRONMENT: 'UAT',
  INDIVIDEO_CODE: '2sELxdTtu2FVw-948',
  INDIVIDEO_CODE_HT: 'WGA3ppaSFKAGWYDfJ7Vw-746',
  BANK_CALENDAR_PATH: 'calendarios/4',
  botmakerChatbotVideoCall: 'https://go.botmaker.com/rest/webchat/p/F6ONOJNNN2/init.js',
  botmakerChatbotInternationalCall: 'https://go.botmaker.com/rest/webchat/p/GAZ3AQU2EY/init.js',
  APIGEE_URL: 'https://planvital-qa.apigee.net/',
  facialValidationSiteUrl: 'https://biometriafacial-dev.planvital.cl/',
  facialAuthorizeTrolley: 'https://apiweb-dev.planvital.cl/transfer-facial-biometric/trolley-auth/authorize',
  facialValidationBaseUrl: 'https://apiweb-dev.planvital.cl/transfer-facial-biometric/',
};

export const FIREBASE_REMOTE_CONFIG: any = {
  minimumFetchIntervalMillis: 3600000,
  fetchTimeoutMillis: 60000
};

export const LOGIN_SHUTDOWN_MODAL: any = {
  title: 'REMOTE',
  description: 'REMOTE',
  titleText: 'REMOTE'
};
