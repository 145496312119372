
const pageKey = 'PENSION_STATEMENT';

export const PENSION_STATEMENT_CONTENT = {
  header: {
    breadcrumb: `${pageKey}.HEADER.BREADCRUMB`,
    title: `${pageKey}.HEADER.TITLE`,
    description: `${pageKey}.HEADER.DESCRIPTION`,
  },
  selectors: {
    summarized: `${pageKey}.SELECTORS.SUMMARIZED`,
    video: `${pageKey}.SELECTORS.VIDEO`
  },
  downloads: {
    summarized: `${pageKey}.DOWNLOADS.SUMMARIZED`,
    detailed: `${pageKey}.DOWNLOADS.DETAILED`
  },
  content: {
    title: `${pageKey}.CONTENT.TITLE`,
    description: `${pageKey}.CONTENT.DESCRIPTION`,
    tabs: {
      video: `${pageKey}.CONTENT.TABS.VIDEO`,
      summarized: `${pageKey}.CONTENT.TABS.SUMMARIZED`,
    },
    table: {
      headers: {
        periods: `${pageKey}.CONTENT.TABLE.HEADERS.PERIODS`,
        downloads: `${pageKey}.CONTENT.TABLE.HEADERS.DOWNLOADS`,
        send: `${pageKey}.CONTENT.TABLE.HEADERS.SEND`,
        downloadPdf: `${pageKey}.CONTENT.TABLE.HEADERS.DOWNLOAD_PDF`,
        downloadPdfDisclaimer: `${pageKey}.CONTENT.TABLE.HEADERS.DOWNLOAD_PDF_DISCLAIMER`,
        video: `${pageKey}.CONTENT.TABLE.HEADERS.VIDEO`,
      },
      noElements: `${pageKey}.CONTENT.TABLE.NO_ELEMENTS`
    }
  },
  modals: {
    successSentEmail: {
      title: `${pageKey}.MODALS.SUCCESS_SENT_EMAIL.TITLE`,
      message: `${pageKey}.MODALS.SUCCESS_SENT_EMAIL.MESSAGE`
    },
    notFoundElement: {
      title: `${pageKey}.MODALS.NOT_FOUND_STATEMENT.TITLE`,
      message: `${pageKey}.MODALS.NOT_FOUND_STATEMENT.MESSAGE`
    }
  }
};
