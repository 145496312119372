import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-alert-toast',
  templateUrl: './alert-toast.component.html',
  styleUrls: ['./alert-toast.component.scss'],
})
export class AlertToastComponent {
  @Input() public severity: string;
  @Input() public pageContent: {
    message: string,
    closeBtn: string,
  } = { message: '', closeBtn: '' };
  @Output() public hideAlert = new EventEmitter<boolean>();

  constructor(
    public font: FontService,
  ) { }

  public hideToast(): void {
    this.hideAlert.emit(true);
  }
}
