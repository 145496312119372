/* eslint-disable @typescript-eslint/ban-types */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@constants/constants';
import { ModalData } from '@interfaces/modal-data.interface';
import { FontService } from '@providers/font/font.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal-email-error.component.html',
    styleUrls: ['./modal-email-error.component.scss'],
})
export class ModalEmailErrorComponent {
    public title: string;
    public message: string;
    public firstBtnText: string;
    private primaryCallback: Function;
    private closeCallback: Function;
    public iconName: string;
    public closeBtn = true;
    public personalData: string;
    public isPersonalData = false;

    constructor(
        public dialogRef: MatDialogRef<ModalEmailErrorComponent>,
        public font: FontService,
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
        private readonly router: Router,
    ) {
        Object.keys(data).forEach(key => this[key] = data[key]);
        if (data.personalData) {
            this.personalData = data.personalData;
            this.isPersonalData = true;
        }
    }

    public goToPersonalData(): void {
        this.router.navigate([APP_ROUTES.client.personalInfo]);
        this.dialogClose();
      }

    public primaryAction(): void {
        this.dialogClose();
        this.dialogRef.afterClosed().subscribe(() => this.primaryCallback());
    }

    public dialogClose(): void {
        this.dialogRef.close();
        if (this.closeCallback) this.closeCallback();
    }
}
