import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_WITH_RECAPTCHA_VALIDATION } from '@constants/recaptcha.constants';
import { environment } from '@env';
import { Util } from '@util';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  constructor(private readonly util: Util) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const matches = ROUTES_WITH_RECAPTCHA_VALIDATION.filter(
      (route) => this.util.compareStringUsingWildcard(route.route, request.url) && route.method === request.method
    );
    if (matches.length === 1) {
      return from(this.getRecaptchaToken(matches[0].action))
        .pipe(
          switchMap((recaptchaToken: string) => {
            const requestClone = request.clone({
              headers: request.headers.append('recaptcha-token', recaptchaToken)
            });
            return next.handle(requestClone);
          })
        );
    }
    return next.handle(request);
  }

  private getRecaptchaToken(actionKey: string) {
    return (window as any).grecaptcha.enterprise.execute(environment.recaptchaSiteKey, { action: actionKey });
  }
}
