/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalComponent } from '@components/modal/modal.component';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-modal-bio-tutorial',
  templateUrl: './modal-bio-tutorial.component.html',
  styleUrls: ['./modal-bio-tutorial.component.scss']
})
export class ModalBioTutorialComponent implements OnInit {
  safeLinkVideo: SafeResourceUrl;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    public font: FontService,
    private sanitizer: DomSanitizer,
  ) {}

  public dialogClose(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.safeLinkVideo = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/GwSTImZNFHg?si=FYSKoIXoSiGHhHmT&controls=0');
  }
}
