export const TRACE_RESULT_OK = 'ok';
export const TRACE_RESULT_ERROR = 'error';

export const SEND_DOCUMENT_CAPTURES = 'sendDocumentCaptures';
export const SEND_CAPTURED_SELPHI = 'sendCapturedSelphi';
export const CAPTURING_DOCUMENT = 'capturingDocument';
export const CAPTURING_SELPHI = 'capturingSelphi';
export const BIOMETRIC_SPA_FLOW_ENDED = 'biometricSpaFlowEnded';

export const TRACEABILITY = {
    MODULE: {
        REQUESTS: {
            ID: '020',
            GENERAL_FLOW: {
                functionalityId: '010',
            },
            TRANSFER_FLOW: {
                functionalityId: '020',
            },
            STEP_ID: {
                facial_site_ingress: '010',
                ci_capturing: '020',
                ci_capture_validation_sending: '030',
                selphi_capturing: '040',
                selphi_validation_sending: '050',
                biometric_validation_ended: '060',
                previred_called: '070',
            },
        },
    },
};

export const FLOW_ENDING_TRACE_STATES = {
    success: { code: 'success', result: TRACE_RESULT_OK },
    failed: { code: 'failed', result: TRACE_RESULT_ERROR },
    unload: { code: 'unload', result: TRACE_RESULT_OK },
};

export const FACIAL_VALIDATION_TRACE_STATES = {
    extraction_finished: { code: 'widget_picture_extraction_operation_finished', result: TRACE_RESULT_OK },
    extraction_failure: { code: 'Operación de extracción de imagen desde widget fallida', result: TRACE_RESULT_ERROR },
    extraction_timeout: { code: 'Usuario supera tiempo de espera para captura', result: TRACE_RESULT_ERROR },
    sunglasses_exception: { code: 'Se ha detectado la presencia de lentes de sol', result: TRACE_RESULT_ERROR },
    user_canceled: { code: 'Usuario cancela captura de fotografía facial', result: TRACE_RESULT_ERROR },
    camera_not_found: { code: 'Cámara no encontrada', result: TRACE_RESULT_ERROR },
    permission_denied: { code: 'user_denied_permission', result: TRACE_RESULT_ERROR },
    capture_exception: { code: 'widget_unexpected_capture_exception', result: TRACE_RESULT_ERROR },
    retrying: { code: 'retrying', result: TRACE_RESULT_ERROR },
    accomplish: { code: TRACE_RESULT_OK, result: TRACE_RESULT_OK },
    process_cancelled: { code: 'process_cancelled', result: TRACE_RESULT_ERROR },
    finished: { code: TRACE_RESULT_OK, result: TRACE_RESULT_OK },
    error: { code: 'service_error', result: TRACE_RESULT_ERROR },
};

export const ID_VALIDATION_TRACE_ACTIONS = {
    start: { code: 'start', result: TRACE_RESULT_OK },
    capturing: { code: 'capturing', result: TRACE_RESULT_OK },
    user_canceled: { code: 'Usuario cancela captura', result: TRACE_RESULT_ERROR },
    capturing_exception: { code: 'Excepción en la captura desde Widget', result: TRACE_RESULT_ERROR },
    extraction_finished: { code: 'widget_picture_extraction_operation_finished', result: TRACE_RESULT_OK },
    extraction_failure: { code: 'Operación de extracción de imagen desde widget fallida', result: TRACE_RESULT_ERROR },
    retrying: { code: 'retrying', result: TRACE_RESULT_ERROR },
    camera_not_found: { code: 'Cámara no detectada', result: TRACE_RESULT_ERROR },
    permission_dismissed: { code: 'Usuario no otorga permisos necesarios del browser', result: TRACE_RESULT_ERROR },
    extraction_timeout: { code: 'Usuario supera tiempo de espera para captura', result: TRACE_RESULT_ERROR },
    finished: { code: TRACE_RESULT_OK, result: TRACE_RESULT_OK },
    error: { code: 'service_error', result: TRACE_RESULT_ERROR }
};

// eslint-disable  quote-props
export const ACTION_TRACEABILITY = {
    'sendDocumentCaptures': TRACEABILITY.MODULE.REQUESTS.STEP_ID.ci_capture_validation_sending,
    'sendCapturedSelphi': TRACEABILITY.MODULE.REQUESTS.STEP_ID.selphi_validation_sending,
    'capturingDocument': TRACEABILITY.MODULE.REQUESTS.STEP_ID.ci_capturing,
    'capturingSelphi': TRACEABILITY.MODULE.REQUESTS.STEP_ID.selphi_capturing,
    'biometricSpaFlowEnded': TRACEABILITY.MODULE.REQUESTS.STEP_ID.biometric_validation_ended
};
