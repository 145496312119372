import { Mock } from '@interfaces/mock.interface';

export const JOB_TITLES_MOCKUP: Mock = {
  success: [{
    response: [
      {
        id: '000',
        description: 'No informado'
      },
      {
        id: '010',
        description: 'Analista'
      },
      {
        id: '020',
        description: 'Asistente'
      },
      {
        id: '030',
        description: 'Consultor'
      },
      {
        id: '040',
        description: 'Asesor'
      },
      {
        id: '050',
        description: 'Ejecutivo Junior'
      },
      {
        id: '060',
        description: 'Ejecutivo Senior'
      },
      {
        id: '070',
        description: 'Supervisor'
      },
      {
        id: '080',
        description: 'Jefe Area'
      },
      {
        id: '090',
        description: 'Jefe Seccion'
      },
      {
        id: '100',
        description: 'Jefe Departamento'
      },
      {
        id: '110',
        description: 'Jefe de Local'
      },
      {
        id: '120',
        description: 'Jefe de Sucursal'
      },
      {
        id: '130',
        description: 'Subgerente de Area'
      },
      {
        id: '140',
        description: 'Gerente de Area'
      },
      {
        id: '150',
        description: 'Director de Area'
      },
      {
        id: '160',
        description: 'Gerente de Division'
      },
      {
        id: '170',
        description: 'Director de Division'
      },
      {
        id: '180',
        description: 'Subgerente'
      },
      {
        id: '190',
        description: 'SubDirector General'
      },
      {
        id: '200',
        description: 'Gerente'
      },
      {
        id: '210',
        description: 'Director General'
      },
      {
        id: '220',
        description: 'Presidente'
      },
      {
        id: '230',
        description: 'Director Ejecutivo'
      },
      {
        id: '240',
        description: 'Miembro de Directorio'
      },
      {
        id: '999',
        description: 'Otro Profesional'
      },
    ]
  }],
  failures: [{
    response: {}
  }]
};
