import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { OtpDynamicKey, OtpValidateKey } from '@interfaces/otp-dynamic-key.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OtpDynamicKeyService {

  constructor(private http: HttpInterceptor) { }

  public generateOtp(otpDynamicKey: OtpDynamicKey): Observable<any> {
    const url = `${environment.clientsUrl}otp/generate`;
    return this.http.post(url, otpDynamicKey, null);
  }

  public validaOtp(otpValidateKey: OtpValidateKey, customHeaders?): Observable<any> {
    const url = `${environment.clientsUrl}otp/validate`;
    return this.http.post(url, otpValidateKey, null, customHeaders);
  }

}
