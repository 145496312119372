import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from '@constants/constants';

const routes: Routes = [
  { path: '', redirectTo: APP_ROUTES.main, pathMatch: 'full' },
  {
    path: APP_ROUTES.main,
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule),
  },
  {
    path: APP_ROUTES.login,
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: APP_ROUTES.client.recoverAccessKey,
    loadChildren: () => import('./pages/recover-access-key/recover-access-key.module').then(m => m.RecoverAccessKeyPageModule)
  },
  {
    path: APP_ROUTES.beneficiary.recoverAccessKey,
    loadChildren: () => import('./pages/recover-access-key/recover-access-key.module').then(m => m.RecoverAccessKeyPageModule)
  },
  {
    path: APP_ROUTES.client.passwordlessAccess,
    loadChildren: () => import('./pages/passwordless-access/passwordless-access.module').then(m => m.PasswordLessPageModule)
  },
  {
    path: APP_ROUTES.client.passwordlessValidate,
    loadChildren: () => import('./pages/passwordless-validate/passwordless-validate.module').then(m => m.PasswordlessValidatePageModule)
  },
  {
    path: APP_ROUTES.externalWithdrawal,
    loadChildren: () => import('./pages/token-validation/token-validation.module').then(m => m.TokenValidationModule),
  },
  {
    path: APP_ROUTES.client.PensionerGuard,
    loadChildren: () => import('./pages/pensioner-information/pensioner-information.module').then(m => m.PensionerInformationPageModule),
  },
  { path: '**', redirectTo: APP_ROUTES.main },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
