// Security Key
export const INVALID_ID_ERROR_CODE = 40124;
export const EXPIRED_ID_ERROR_CODE = 40145;
export const INVALID_PIN_ERROR_CODE = 40130;
export const BLOCKED_ID_ERROR_CODE = 40137;
export const EQUAL_PASSWORD_ERROR_CODE = 40153;
export const INVALID_SECURITY_KEY_ERROR_CODE = 40014;
export const PREVIRED_QUESTION_NOT_FOUND = 40096;

// Recover access key
export const CONTACT_INFO_NOT_VALID = 40227;
export const USER_NOT_PLAN_VITAL = 40001;
export const USER_NOT_PLAN_VITAL_BENEFICIARY = 40002;
export const DATA_NOT_MATCH_ERROR_CODE = 40135;
export const EMAIL_NOT_MATCH_ERROR_CODE = 40159;
export const CELLPHONE_NOT_MATCH_ERROR_CODE = 40160;

// Passwordless access key
export const EMAIL_NOT_FOUND_ERROR_CODE = 40161;

// Fund change
export const BUSINESS_VALIDATION_ERROR_CODE = 40013;
