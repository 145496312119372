import { Mock } from '@interfaces/mock.interface';

export const BANKS_MOCKUP: Mock = {
  success: [{
    response: [
      {
        id: '12',
        description: 'BANCO ESTADO',
      },
      {
        id: '16',
        description: 'BANCO BCI',
      },
      {
        id: '28',
        description: 'BANCO BICE',
      },
      {
        id: '55',
        description: 'BANCO CONSORCIO',
      },
    ]
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }],
};
