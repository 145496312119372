<div class="modal-close">
  <div (click)="dialogClose()" class="i-cross-gray"></div>
</div>
<div class="modal-container">
  <div>
    <iframe width="100%" height="315" [src]="safeLinkVideo"
    title="YouTube video player"
    frameborder="0"
    referrerpolicy="strict-origin-when-cross-origin"></iframe>
  </div>
</div>
