import { Injectable } from '@angular/core';

import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { environment } from '@env';
import { Util } from '@util';
import * as fileSaver from 'file-saver';

@Injectable()
export class FilesUtil {

  constructor(
    private fileOpener: FileOpener,
    private util: Util
  ) { }

  public downloadBase64File(base64Data: string, name: string, type = 'application/pdf') {
    const blob = this.util.base64toBlob(base64Data, type);
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.style.display = 'none';
    anchor.href = blobUrl;
    anchor.download = name;
    anchor.click();
  }

  public async getPdfForm(url: string, title?: string) {
    return this.util.isNative ? await this.downloadFileApp(url, title) : this.downloadFileWeb(url, title);
  }

  private downloadFileWeb(url: string, title?: string) {
    return title ? fileSaver.saveAs(url, title) : fileSaver.saveAs(url);
  }

  public async downloadFile(url: string, title?: string) {
    return this.util.isNative ? await this.downloadFileApp(url, title) : this.downloadBase64File(url, title);
  }

  private async downloadFileApp(url: string, title?: string) {
    const filesUrl = url.replace(environment.publicFilesUrl, environment.filesApiUrl);
    const fileName = title ? title : url.substr(url.lastIndexOf('/') + 1);
    const result = await Filesystem.writeFile({ data: filesUrl, directory: Directory.Cache, path: fileName });
    this.fileOpener.showOpenWithDialog(result.uri, 'application/pdf');
  }
}
