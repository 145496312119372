import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalComponent } from '@components/modal/modal.component';
import { LANGUAGES, LOGIN_ERROR_CODES, LOGOUT_MODES, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@constants/constants';
import { environment } from '@env';
import { ErrorResponse } from '@interfaces/error-response.model';
import { UserStorage } from '@interfaces/user-data.interface';
import { LOGIN_CONTENT } from '@pages-content/login.constant';
import { RECOVER_PASSWORD_REDIRECT, RELOGIN_MODAL_CONTENT } from '@pages-content/relogin-modal.constant';
import { FontService } from '@providers/font/font.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Util } from '@util';
import { LoginUtils } from '@utils/login/login.utils';
import { finalize } from 'rxjs/operators';

type stepType = 'start' | 'login' | 'recover' | 'request' | 'parametrical';



@Component({
  selector: 'app-relogin-modal',
  templateUrl: './relogin-modal.component.html',
  styleUrls: ['./relogin-modal.component.scss'],
})
export class ReloginModalComponent extends ModalComponent {
  public pageContent = LOGIN_CONTENT;
  public modalContent = RELOGIN_MODAL_CONTENT;
  public loginForm: UntypedFormGroup;
  public minLength = PASSWORD_MIN_LENGTH;
  public maxLength = PASSWORD_MAX_LENGTH;
  public loginFormStep = false;
  public wrongPasswordMessage: string;
  public imagesNames = ['login1', 'login2', 'login3', 'login4'];
  public planvitalUrl = environment.planvitalUrl;
  public step: stepType = 'start';
  public goToEmployerRecover = false;
  public userData: UserStorage;
  public languageSelected = LANGUAGES.find((language) => language === this.util.language);
  public updateAvailable: boolean;
  public loading = false;


  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    public font: FontService,
    public util: Util,
    public loginUtils: LoginUtils,
  ) {
    super(dialogRef, font, data);
    this.createForm();
  }

  private createForm(): void {
    this.loginForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(this.minLength),
        Validators.maxLength(this.maxLength),
      ]],
    });
  }

  public get password() { return this.loginForm.controls.password; }

  public onReloginHot(): void {
    const rut = this.authService.getUserRut();
    const { password } = this.loginForm.value;

    const userType = this.authService.currentUserTypeValue;
    this.loading = true;

    this.authService.login({ rut, password }, userType)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (_) => {
          this.dialogRef.close();
          window.location.reload();
        },
        (error: ErrorResponse) => this.handleLoginError(error),
      );
  }

  private handleLoginError(error: ErrorResponse): void {
    const { statusCode, title, attemptsLeft } = error;
    const { wrongPassword, wrongTempPassword } = LOGIN_ERROR_CODES;
    if (wrongPassword.includes(statusCode)) {
      this.wrongPasswordMessage = this.loginUtils.getErrorAttemptsMessage(attemptsLeft);
      return this.cleanAndSetPasswordError();
    } else if (wrongTempPassword.includes(statusCode)) {
      this.wrongPasswordMessage = title;
      return this.cleanAndSetPasswordError();
    } else {
      this.authService.logout();
    }
  }

  private cleanAndSetPasswordError(): void {
    this.password.setValue('');
    this.password.setErrors({ wrongPassword: true });
  }

  public onRecoverPassword(): void {
    const userType = this.authService.currentUserTypeValue;
    this.authService.killSession(LOGOUT_MODES.normalFlow, false);
    this.router.navigate([ RECOVER_PASSWORD_REDIRECT[userType].url ], { queryParams: RECOVER_PASSWORD_REDIRECT[userType].query });
    this.dialogRef.close();
  }

  public onCloseDialog(): void {
    this.authService.logout(LOGOUT_MODES.normalFlow, false);
    this.dialogRef.close();
  }
}
