import { Mock } from '@interfaces/mock.interface';

export const GET_BANK_CALENDAR_MOCK: Mock = {
  success: [{
    response: {
      data: {
        id: 4,
        fechas: {
          january: [
            19,
            20,
          ],
          february: [
            17,
            18,
          ],
          march: [
            17,
            18,
          ],
          april: [
            18,
            19,
          ],
          may: [
            18,
            19,
          ],
          june: [
            16,
            17,
          ],
          july: [
            18,
            19,
          ],
          august: [
            17,
            18,
          ],
          september: [
            15,
            15,
          ],
          october: [
            14,
            17,
          ],
          november: [
            17,
            18,
          ],
          december: [
            19,
            20,
          ]
        },
        titulo: 'bankCalendar'
      },
      public: true
    },
  }],
  failures: [
    {
      response: {
        code: 50001,
        folioNumber: null,
        messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        serviceErrorMessage: '',
        message: 'Error',
      },
    },
  ]
};
