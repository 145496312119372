/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '@components/modal/modal.component';
import { ResumeModalComponent } from '@components/resume-modal/resume-modal.component';
import { MODAL_CONFIG } from '@constants/modal-data.constants';
import { ModalData } from '@interfaces/modal-data.interface';

import {
  ModalEmailErrorComponent
} from '../../components/modal-email-error/modal-email-error.component';

@Injectable()
export class ModalProvider {
  private modal: MatDialogRef<ModalComponent, any>;
  private resumenModal: MatDialogRef<ResumeModalComponent, any>;

  constructor(
    public dialog: MatDialog,
  ) { }

  public openModal(modalData: ModalData): void {
    this.openDialog(modalData);
  }

  public openModalCustom(component, modalData: any, customProps?: any): void {
    this.openDialogCustom(component, modalData, customProps);
  }

  public openModalCenterCustom(component, modalData: any, customProps?: any): void {
    this.openDialogCustomCenter(component, modalData, customProps);
  }

  public openModalCustomWithClass(component, modalData: any, panelClass?: string): void {
    this.openDialogCustomWithClass(component, modalData, panelClass);
  }

  public openModalWithClass(component, modalData: any, panelClass?: string): void {
    this.openDialogWithClass(component, modalData, panelClass);
  }

  public openModalUnclose(component, modalData: any, customProps?: any): void {
    this.openDialogUnclose(component, modalData, customProps);
  }

  public closeModals(): void {
    this.dialog.closeAll();
    this.modal = null;
  }

  private openDialog(data: ModalData): void {
    if (Boolean(this.modal)) { this.closeModals(); }
    if (!data.fromWithdrawl) {
      this.modal = data.personalData ? this.dialog.open(ModalEmailErrorComponent as any, { ...MODAL_CONFIG, data }) :
        this.dialog.open(ModalComponent, { ...MODAL_CONFIG, data });
      this.modal.afterClosed().subscribe(() => this.modal = undefined);
    } else {
      this.resumenModal = this.dialog.open(ResumeModalComponent, { ...MODAL_CONFIG, data });
      this.resumenModal.afterClosed().subscribe(() => this.modal = undefined);
    }
  }

  private openDialogCustomCenter(component, data: any, customProps?: any): void {
    if (Boolean(this.modal)) { this.closeModals(); }
    this.modal = this.dialog.open(component, { ...MODAL_CONFIG, data, disableClose: customProps?.disableClose ?? true });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);

  }

  private openDialogCustom(component, data: any, customProps?: any): void {
    if (Boolean(this.modal)) { this.closeModals(); }
    this.modal = this.dialog.open(component, { data, autoFocus: false, disableClose: customProps?.disableClose ?? true, panelClass: 'dialog-custom' });
    this.modal.updatePosition({ right: `0` });

    if (customProps?.width && customProps?.height) { this.modal.updateSize(customProps.width, customProps.height); }
    if (customProps?.right) { this.modal.updatePosition(customProps.right); }

    this.modal.afterClosed().subscribe(() => this.modal = undefined);
  }

  private openDialogCustomWithClass(component, data: any, panelClass?: string): void {
    if (Boolean(this.modal)) { this.closeModals(); }

    this.modal = this.dialog.open(component, { data, panelClass, autoFocus: false, disableClose: true });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
  }

  private openDialogWithClass(component, data: any, panelClass?: string): void {
    if (Boolean(this.modal)) { this.closeModals(); }

    this.modal = this.dialog.open(component, { data, panelClass, autoFocus: false, disableClose: true });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
  }
  private openDialogUnclose(component, data: any, customProps?: any): void {
    if (Boolean(this.modal)) { this.closeModals(); }
    this.modal = this.dialog.open(component, { data, autoFocus: false, disableClose: true, panelClass: 'dialog-custom' });

    if (customProps?.width && customProps?.height) { this.modal.updateSize(customProps.width, customProps.height); }
  }
}
