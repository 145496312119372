import { Injectable } from '@angular/core';

import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import {
  Auth, User, UserCredential, browserSessionPersistence, getAuth,
  initializeAuth, onAuthStateChanged, setPersistence, signInWithCustomToken, signOut
} from 'firebase/auth';
import { Database, DatabaseReference, getDatabase, ref, set } from 'firebase/database';
import { RemoteConfig, fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';

import { LOGIN_SHUTDOWN_MODAL_PROPS, REMOTE_CONFIG_TIMES } from '@constants/constants';
import { FIREBASE_REMOTE_CONFIG, LOGIN_SHUTDOWN_MODAL, environment } from '@env';
import { BeneficiaryOTPActivation } from '@interfaces/beneficiary-otp-activation.interface';
import { FacialBiometricBody } from '@interfaces/facial-biometric-codes.interface';
import { FacialValidationActivation } from '@interfaces/facial-validation-activation.interface';
import { LoginShutDownResponse, UpdateRequiredResponse } from '@interfaces/remote-config.interface';
import { FirebaseService } from '@services/firebase/firebase.abstract';

@Injectable()
export class FirebaseServiceImplemented implements FirebaseService {
  private app: FirebaseApp;
  private auth: Auth;
  private currentUser: User;
  private database: Database;
  private remoteConfig: RemoteConfig;

  public initializeFirebase(): void {
    if (this.isInitialized()) return;
    this.app = initializeApp(environment.firebaseConfig);
    if (Capacitor.isNativePlatform()) {
      this.auth = initializeAuth(this.app, {
        persistence: browserSessionPersistence
      });
    } else {
      this.auth = getAuth(this.app);
      setPersistence(this.auth, browserSessionPersistence);
    }
    this.database = getDatabase(this.app);
  }

  public async initializeRemoteConfig(): Promise<void> {
    if (this.remoteConfig) return;
    this.remoteConfig = getRemoteConfig(this.app);
    this.remoteConfig.settings = FIREBASE_REMOTE_CONFIG;
    await this.initializeDefaultConfig();
  }

  public async initializeDefaultConfig(): Promise<void> {
    this.remoteConfig.defaultConfig = {
      LoginShutdownModal: JSON.stringify(LOGIN_SHUTDOWN_MODAL_PROPS),
      PrivateSiteRoutesConfig: null
    };
    this.remoteConfig.settings = {
      minimumFetchIntervalMillis: 5 * REMOTE_CONFIG_TIMES.MINUTE,
      fetchTimeoutMillis: REMOTE_CONFIG_TIMES.MINUTE,
    };

    await fetchAndActivate(this.remoteConfig)
      .then(() => {
        this.setRoutesConfig();
        this.setLoginModalConfig();
      })
      .catch((error) => console.error('Error fetching the remote config', error));
  }

  public isInitialized(): boolean {
    try {
      const app = getApp();
      return !!app;
    } catch (error) {
      return false;
    }
  }

  public getCurrentUser(): Promise<User> {
    if (this.currentUser) return Promise.resolve(this.currentUser);
    return new Promise(resolve => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) this.currentUser = user;
        resolve(user);
      });
    });
  }

  public async signInWithCustomToken(token: string): Promise<UserCredential> {
    return await signInWithCustomToken(this.auth, token);
  }

  public async signOut(): Promise<void> {
    return await signOut(this.auth);
  }

  public async getRemoteConfigLoginValues(): Promise<LoginShutDownResponse> {
    await this.initializeRemoteConfig();
    const modalConfig: LoginShutDownResponse = JSON.parse(getValue(this.remoteConfig, 'LoginShutdownModal').asString());
    return modalConfig;
  }

  public async getRemoteConfigFacialValidationActivation(): Promise<FacialValidationActivation> {
    await this.initializeRemoteConfig();
    const facialValidationActivation: FacialValidationActivation = JSON.parse(
      getValue(this.remoteConfig, 'FacialBiometricValidation').asString()
    );
    return facialValidationActivation;
  }

  public async getRemoteConfigFacialValidationActivationApp(): Promise<FacialValidationActivation> {
    await this.initializeRemoteConfig();
    const facialValidationActivation: FacialValidationActivation = JSON.parse(
      getValue(this.remoteConfig, 'FacialBiometricValidationApp').asString()
    );
    return facialValidationActivation;
  }

  public async getRemoteConfigUpdateRequiredValues(): Promise<UpdateRequiredResponse> {
    await this.initializeRemoteConfig();
    const modalConfig: UpdateRequiredResponse = JSON.parse(getValue(this.remoteConfig, 'UpdateRequiredModal').asString());
    return modalConfig;
  }

  public pushNotificationsRegister(): void {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration',
      (token: Token) => {
        Preferences.set({
          key: 'pushNotToken',
          value: token.value
        });
      }
    );

    PushNotifications.addListener('registrationError',
      (error: any) => {
        // console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }

  private setLoginModalConfig(): void {
    const modalConfig = JSON.parse(getValue(this.remoteConfig, 'LoginShutdownModal').asString());

    LOGIN_SHUTDOWN_MODAL.title = modalConfig.title;
    LOGIN_SHUTDOWN_MODAL.description = modalConfig.description;
    LOGIN_SHUTDOWN_MODAL.titleText = modalConfig.titleText;
  }

  private setRoutesConfig(): void {
    const routesConfig = JSON.parse(getValue(this.remoteConfig, 'PrivateSiteRoutesConfig').asString());
    environment.routesConfig = routesConfig[environment.name];
  }

  public writeStatusData(data: FacialBiometricBody, uuid: string): void {
    const { rut, name, lastName, motherLastName, date, process, status, remoteAction } = data;
    this.database = getDatabase(this.app);
    set(ref(this.database, `facial_biometric_codes/${uuid}`), {
      rut,
      name,
      lastName,
      motherLastName,
      date,
      process,
      status,
      remoteAction
    });
  }

  public readStatusData(uuid: string): DatabaseReference {
    this.database = getDatabase(this.app);
    return ref(this.database, `facial_biometric_codes/${uuid}`);
  }

  public async getRemoteConfigOTPBeneficiaary(): Promise<BeneficiaryOTPActivation> {
    await this.initializeRemoteConfig();
    const otpActivation: BeneficiaryOTPActivation = JSON.parse(getValue(this.remoteConfig, 'EnableOTPForBeneficiary').asString());
    return otpActivation;
  }
}
