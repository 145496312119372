import { Observable, from, throwError } from 'rxjs';

import {
  HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP_ERROR_CODES, SERVER_ERROR_MESSAGE } from '@constants/constants';
import { environment } from '@env';
import { ErrorResponse } from '@interfaces/error-response.model';
import { CommunicationProvider } from '@providers/communication/communication';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Injectable()
export class AuthInterceptorProvider implements HttpInterceptor {
  private authTokenBlacklist = [
    environment.filesApiUrl,
  ];

  constructor(
    public http: HttpClient,
    private communicationProvider: CommunicationProvider,
    private authService: AuthenticationService,
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next)
      .catch((error) => {
        if (error instanceof HttpErrorResponse && error.status === HTTP_ERROR_CODES.unauthorized.code) {
          this.communicationProvider.getInterceptedSource().next(error.status);
        }
        return throwError(this.handleError(error)).toPromise();
      }));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = this.authService.token;
    if (token && !this.urlInAuthBlacklist(request.url)) {
      request = request.clone({ setHeaders: { Authorization: String(token) } });
    }
    return next.handle(request).toPromise();
  }

  private handleError(error: HttpErrorResponse): ErrorResponse {
    const errorResponse = new ErrorResponse();
    errorResponse.statusCode = error.status;
    if (error.error) {
      const { code, message, messageDescription, messageDescriptionHt, securityKeyAttempts,
        extraData, stack, messageError, errorMessage } = error.error;
      if (code) {
        errorResponse.statusCode = code;
      }
      if (message) {
        const internalServerError = error.status === HTTP_ERROR_CODES.internalServerError.code;
        errorResponse.title = internalServerError ? SERVER_ERROR_MESSAGE : message;
      }
      if (messageDescription) {
        errorResponse.message = messageDescription;
      }
      if (stack) {
        errorResponse.errorMessage = stack.errorMessage;
      }
      if (messageError) {
        errorResponse.messageError = messageError;
      }
      if (messageDescriptionHt) {
        errorResponse.messageHt = messageDescriptionHt;
      }
      if (extraData && extraData.attemptsLeft) {
        errorResponse.attemptsLeft = extraData.attemptsLeft;
      }
      // Added for the security key validation flow for beneficiaries
      if (extraData && extraData.securityKeyAttempts) {
        errorResponse.securityKeyAttempts = extraData.securityKeyAttempts;
      }

      if (errorMessage) {
        errorResponse.errorMessage = errorMessage;
      }
      if (securityKeyAttempts) {
        errorResponse.securityKeyAttempts = securityKeyAttempts;
      }
    }
    return errorResponse;
  }

  private urlInAuthBlacklist(url: string): boolean {
    return this.authTokenBlacklist.some((blacklistUrl) => url.includes(blacklistUrl));
  }
}
