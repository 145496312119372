import { Mock } from '@interfaces/mock.interface';

export const LOGIN_MOCK: Mock = {
  success: [{
    response: {
      code: 20001,
      message: 'OK',
      token: '1a2b3c',
      attemptsLeft: 1000,
      securityAnswers: 0,
      temporalToken: false,
      messageDescription: 'description',
    },
  }],
  failures: [{
    response: {
      code: 40001,
      message: 'Al parecer no eres parte de la familia Planvital.',
      token: '',
      attemptsLeft: 1000,
      securityAnswers: 0,
      temporalToken: false,
      messageDescription: '¿Quieres integrarte a AFP PlanVital? ¡Estás a un click!',
    },
  }],
};
