import { Injectable } from '@angular/core';

import { User, UserCredential } from 'firebase/auth';
import { DatabaseReference } from 'firebase/database';

import { BeneficiaryOTPActivation } from '@interfaces/beneficiary-otp-activation.interface';
import { FacialBiometricBody } from '@interfaces/facial-biometric-codes.interface';
import { FacialValidationActivation } from '@interfaces/facial-validation-activation.interface';
import { LoginShutDownResponse, UpdateRequiredResponse } from '@interfaces/remote-config.interface';

@Injectable()
export abstract class FirebaseService {
  public abstract initializeFirebase(): void;
  public abstract initializeRemoteConfig(): void;
  public abstract initializeDefaultConfig(): void;
  public abstract isInitialized(): boolean;
  public abstract getCurrentUser(): Promise<User>;
  public abstract signInWithCustomToken(token: string): Promise<UserCredential>;
  public abstract signOut(): Promise<void>;
  public abstract getRemoteConfigLoginValues(): Promise<LoginShutDownResponse>;
  public abstract getRemoteConfigUpdateRequiredValues(): Promise<UpdateRequiredResponse>;
  public abstract pushNotificationsRegister(): void;
  public abstract writeStatusData(data: FacialBiometricBody, uuid: string): void;
  public abstract readStatusData(uuid: string): DatabaseReference;
  public abstract getRemoteConfigFacialValidationActivation(): Promise<FacialValidationActivation>;
  public abstract getRemoteConfigFacialValidationActivationApp(): Promise<FacialValidationActivation>;
  public abstract getRemoteConfigOTPBeneficiaary(): Promise<BeneficiaryOTPActivation>;
}
