
import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalFacialValidationComponent } from '@components/modal-facial-validation/modal-facial-validation.component';
import { ValidationStatus } from '@custom-types/validation-status.type';
import {
  APPROVED_PROCESS, CANCELED_PROCESS,
  MODAL_FACIAL_VALIDATION_CONTENT, REJECTED_PROCESS
} from '@pages-content/modal-facial-validation.constant';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-process-validation',
  templateUrl: './process-validation.component.html',
  styleUrls: ['./process-validation.component.scss'],
})
export class ProcessValidationComponent {
  @Input() status: ValidationStatus;

  public pageContent = MODAL_FACIAL_VALIDATION_CONTENT;

  constructor(
    public font: FontService,
    public dialogRef: MatDialogRef<ModalFacialValidationComponent>,
  ) { }

  public closeModal(): void {
    this.dialogRef.close(CANCELED_PROCESS);
  }

  public success(): void {
    this.dialogRef.close(APPROVED_PROCESS);
  }

  public rejected(): void {
    this.dialogRef.close(REJECTED_PROCESS);
  }

}
