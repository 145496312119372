import { Injectable } from '@angular/core';
import { DetailedAccount } from '@interfaces/accounts.interface';
import { EmployerUserData } from '@interfaces/employer-user-data.interface';
import { GraphicSummaryData } from '@interfaces/graphic-sumary-data.interface';
import { UserData } from '@interfaces/user-data.interface';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Injectable()
export class CommunicationProvider {
  private accounts: Array<DetailedAccount>;

  private requestInterceptedSource: Subject<number> = new Subject<number>();
  public requestIntercepted: Observable<number> = this.requestInterceptedSource.asObservable();

  private accountsSource: Subject<Array<DetailedAccount>> = new Subject<Array<DetailedAccount>>();
  public accounts$: Observable<Array<DetailedAccount>> = this.accountsSource.asObservable();

  private userDataSubject = new Subject<UserData | EmployerUserData>();
  public userData$: Observable<UserData | EmployerUserData> = this.userDataSubject.asObservable();

  private graphicSummarySubject = new BehaviorSubject<GraphicSummaryData>({} as GraphicSummaryData);
  public graphicSummary$: Observable<GraphicSummaryData> = this.graphicSummarySubject.asObservable();

  public getGraphicSummary() {
    return this.graphicSummarySubject;
  }

  public getInterceptedSource(): Subject<number> {
    return this.requestInterceptedSource;
  }

  public getAccountsSource(): Subject<Array<DetailedAccount>> {
    return this.accountsSource;
  }

  public getUserData(): Observable<UserData | EmployerUserData> {
    return this.userDataSubject.asObservable();
  }

  public getAccountsData(): Observable<Array<DetailedAccount>> {
    return this.accounts ? of(this.accounts) : this.accountsSource.asObservable();
  }

  public setUserData(userData: UserData | EmployerUserData, error?: any) {
    if (error) { this.userDataSubject.error(error); }
    this.userDataSubject.next(userData);
  }

  public setAccountData(accounts: Array<DetailedAccount>, error?: any) {
    if (error) { this.accountsSource.error(error); }
    this.accounts = accounts;
    this.accountsSource.next(accounts);
  }

  public setGraphicSummaryData(graphicSummary: GraphicSummaryData, error?: any) {
    if (error) { this.graphicSummarySubject.error(error); }
    this.graphicSummarySubject.next(graphicSummary);
  }
}
