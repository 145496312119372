import { Injectable } from '@angular/core';
import { registerPlugin } from '@capacitor/core';
import { SelphiFaceLivenessMode, SelphiFacePlugin, SelphiFaceResult } from 'capacitor-selphi-plugin';
import { SELPHI_RESOURCES_PATH } from './selphi.service.constants';

const SelphiFace = registerPlugin<SelphiFacePlugin>('SelphiFace');

@Injectable({
  providedIn: 'root'
})
export class SelphiService {

  constructor(
  ) { }

  /**
   * Method that launches the plugin using the authentication with liveness passive mode.
   * @param debug  Comment for parameter ´debug´.
   * @param livenessMode  Comment for parameter ´livenessMode´.
   * @param resourcesPath  Comment for parameter ´resourcesPath´.
   * @returns Promise with a JSON string.
   */
  async launchSelphiAuthentication(): Promise<SelphiFaceResult> {
    // SelphiFaceConfiguration
    return await SelphiFace.startExtraction({
      debug: false,
      crop: true,
      tutorial: false,
      resourcesPath: SELPHI_RESOURCES_PATH,
      frontalCameraPreferred: true,
      showResultAfterCapture: true,
      enableImages: false,
      enableGenerateTemplateRaw: true,
      jpgQuality: 0.95,
      livenessMode: SelphiFaceLivenessMode.Passive,
    });
  }

  /**
   * Method that launches the plugin using the authentication with liveness passive mode.
   * @param imageBase64  Comment for parameter ´imageBase64´.
   * @returns Promise with a String.
   */
  async generateTemplateRaw(imageBase64: any): Promise<string> {
    // console.log('Launching selphi widget...  generateTemplateRaw');
    // console.log('imageBase64: ', imageBase64);
    return await SelphiFace.generateTemplateRaw(imageBase64);
  }

  async getDeviceCameras(): Promise<any> {
    // console.log('Launching getDeviceCameras widget...');
    return await SelphiFace.getDeviceCameras();
  }
}
