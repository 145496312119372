const pageKey = 'REQUESTS.FOREIGN_WITHDRAW_FORM';

const personalBackgroundKey = `${pageKey}.STEPS.PERSONAL_BACKGROUND`;
const employerBackgroundKey = `${pageKey}.STEPS.LAST_EMPLOYER`;
const delegateKey = `${pageKey}.STEPS.DELEGATE`;
const employmentDocumentsKey = `${pageKey}.STEPS.EMPLOYMENT_DOCUMENTS`;
const employerContractModalKey = `${employmentDocumentsKey}.EMPLOYER_CONTRACT_MODAL`;
const paymentMethodsKey = `${pageKey}.STEPS.PAYMENT_METHODS`;
const toastKey = `${pageKey}.TOAST`;
const voucherKey = `${pageKey}.STEPS.VOUCHER`;
const disclaimerKey = 'GENERIC_DISCLAIMER.PERSONAL_BACKGROUND';
const fileSizeDisclaimer = 'GENERIC_DISCLAIMER.FILE_SIZE';

export const FOREIGN_WITHDRAW_FORM_CONTENT = {
  breadcrumb: `${pageKey}.BREADCRUMB`,
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`
  },
  steps: {
    personalBackground: {
      label: `${personalBackgroundKey}.LABEL`,
      title: `${personalBackgroundKey}.TITLE`,
      buttons: {
        next: `${personalBackgroundKey}.BUTTONS.NEXT`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      disclaimer: {
        message: `${disclaimerKey}.MESSAGE`,
        redirectionText: `${disclaimerKey}.REDIRECTION_TEXT`
      },
      form: {
        names: `${personalBackgroundKey}.FORM.NAMES`,
        lastNames: `${personalBackgroundKey}.FORM.LAST_NAMES`,
        rut: `${personalBackgroundKey}.FORM.RUT`,
        gender: {
          label: `${personalBackgroundKey}.FORM.GENDER.LABEL`,
          male: `${personalBackgroundKey}.FORM.GENDER.MALE`,
          female: `${personalBackgroundKey}.FORM.GENDER.FEMALE`
        },
        maritalStatus: `${personalBackgroundKey}.FORM.MARITAL_STATUS.TITLE`,
        address: `${personalBackgroundKey}.FORM.ADDRESS`,
        birthdate: `${personalBackgroundKey}.FORM.BIRTHDATE`,
        email: `${personalBackgroundKey}.FORM.EMAIL`,
        phone: `${personalBackgroundKey}.FORM.PHONE`,
        nationality: `${personalBackgroundKey}.FORM.NATIONALITY`,
        errors: {
          required: `${personalBackgroundKey}.FORM.ERRORS.REQUIRED`,
          invalidEmail: `${personalBackgroundKey}.FORM.ERRORS.INVALID_EMAIL`,
          invalidDomain: `${personalBackgroundKey}.FORM.ERRORS.INVALID_DOMAIN`,
          invalidRut: `${personalBackgroundKey}.FORM.ERRORS.INVALID_RUT`,
          invalidAddress: `${personalBackgroundKey}.FORM.ERRORS.INVALID_ADDRESS`
        }
      }
    },
    lastEmployer: {
      label: `${employerBackgroundKey}.LABEL`,
      title: `${employerBackgroundKey}.TITLE`,
      description: `${employerBackgroundKey}.DESCRIPTION`,
      buttons: {
        next: `${personalBackgroundKey}.BUTTONS.NEXT`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      form: {
        rut: {
          label: `${employerBackgroundKey}.FORM.RUT.LABEL`,
          errors: {
            invalid: `${employerBackgroundKey}.FORM.RUT.ERRORS.INVALID`
          }
        },
        buttons: {
          confirmation: `${employerBackgroundKey}.FORM.BUTTONS.CONFIRMATION`,
          searchEmployer: `${employerBackgroundKey}.FORM.BUTTONS.SEARCH_EMPLOYER`,
          back: `${employerBackgroundKey}.FORM.BUTTONS.BACK`,
          saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
          deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
        }
      },
      dataSection: {
        name: `${employerBackgroundKey}.DATA_SECTION.NAME`,
        address: `${employerBackgroundKey}.DATA_SECTION.ADDRESS`,
        commune: `${employerBackgroundKey}.DATA_SECTION.COMMUNE`,
        city: `${employerBackgroundKey}.DATA_SECTION.CITY`,
        region: `${employerBackgroundKey}.DATA_SECTION.REGION`,
        phone: `${employerBackgroundKey}.DATA_SECTION.PHONE`,
        email: `${employerBackgroundKey}.DATA_SECTION.EMAIL`,
        economicActivity: `${employerBackgroundKey}.DATA_SECTION.ECONOMIC_ACTIVITY`
      }
    },
    delegate: {
      label: `${delegateKey}.LABEL`,
      title: `${delegateKey}.TITLE`,
      description: `${delegateKey}.DESCRIPTION`,
      buttons: {
        next: `${delegateKey}.BUTTONS.NEXT`,
        back: `${employerBackgroundKey}.FORM.BUTTONS.BACK`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      form: {
        enable: `${delegateKey}.FORM.ENABLE`,
        disable: `${delegateKey}.FORM.DISABLE`,
        names: `${delegateKey}.FORM.NAMES`,
        lastNames: `${delegateKey}.FORM.LAST_NAMES`,
        rut: `${delegateKey}.FORM.RUT`,
        address: `${delegateKey}.FORM.ADDRESS`,
        email: `${delegateKey}.FORM.EMAIL`,
        phone: `${delegateKey}.FORM.PHONE`,
        errors: {
          required: `${delegateKey}.FORM.ERRORS.REQUIRED`,
          invalidEmail: `${delegateKey}.FORM.ERRORS.INVALID_EMAIL`,
          invalidDomain: `${delegateKey}.FORM.ERRORS.INVALID_DOMAIN`,
          invalidRut: `${delegateKey}.FORM.ERRORS.INVALID_RUT`,
          invalidAddress: `${delegateKey}.FORM.ERRORS.INVALID_ADDRESS`,
          invalidPhone: `${delegateKey}.FORM.ERRORS.INVALID_PHONE`
        }
      }
    },
    employmentDocuments: {
      label: `${employmentDocumentsKey}.LABEL`,
      section: {
        title: `${employmentDocumentsKey}.SECTION.TITLE`,
        description: `${employmentDocumentsKey}.SECTION.DESCRIPTION`
      },
      disclaimer: `${employmentDocumentsKey}.DISCLAIMER`,
      buttons: {
        addContract: `${employmentDocumentsKey}.BUTTONS.ADD_CONTRACT`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      errors: {
        required: `${employmentDocumentsKey}.ERRORS.REQUIRED`
      },
      employerContractModal: {
        title: `${employerContractModalKey}.TITLE`,
        contractData: {
          title: `${employerContractModalKey}.CONTRACT_DATA.TITLE`,
          form: {
            type: {
              label: `${employerContractModalKey}.CONTRACT_DATA.FORM.TYPE.LABEL`
            },
            dateStart: `${employerContractModalKey}.CONTRACT_DATA.FORM.DATE_START`,
            dateEnd: `${employerContractModalKey}.CONTRACT_DATA.FORM.DATE_END`,
            actualContract: `${employerContractModalKey}.CONTRACT_DATA.FORM.ACTUAL_CONTRACT`,
            info: `${employerContractModalKey}.CONTRACT_DATA.FORM.INFO`
          }
        },
        employerData: {
          title: `${employerContractModalKey}.EMPLOYER_DATA.TITLE`,
          form: {
            name: `${employerContractModalKey}.EMPLOYER_DATA.FORM.NAME`,
            rut: `${employerContractModalKey}.EMPLOYER_DATA.FORM.RUT`
          }
        },
        errors: {
          required: `${employerContractModalKey}.ERRORS.REQUIRED`,
          invalidRut: `${employerContractModalKey}.ERRORS.INVALID_RUT`
        },
        buttons: {
          updateContract: `${employerContractModalKey}.BUTTONS.MODIFY`,
          addContract: `${employerContractModalKey}.BUTTONS.ADD`,
          uploadFile: `${employerContractModalKey}.BUTTONS.UPLOAD_FILE`,
          updateFile: `${employerContractModalKey}.BUTTONS.UPDATE_FILE`,
          degreeFile: `${employerContractModalKey}.BUTTONS.DEGREE_FILE`
        }
      },
      statement: `${employmentDocumentsKey}.STATEMENT`,
      form: {
        title: `${employmentDocumentsKey}.FORM.TITLE`,
        attachIdFile: `${employmentDocumentsKey}.FORM.ATTACH_ID_FILE`,
        attachDegreeFile: `${employmentDocumentsKey}.FORM.ATTACH_DEGREE_FILE`,
        attachCertificateFile: `${employmentDocumentsKey}.FORM.ATTACH_CERTIFICATE_FILE`,
        attachFormat: `${employmentDocumentsKey}.FORM.ATTACH_FORMAT`,
        attachExample: `${employmentDocumentsKey}.FORM.ATTACH_EXAMPLE`,
        attachMaxSize: `${employmentDocumentsKey}.FORM.ATTACH_MAX_SIZE`,
        attachDifferentFiles: `${employmentDocumentsKey}.FORM.DIFFERENT_FILES`,
        submitButton: `${employmentDocumentsKey}.FORM.SUBMIT_BUTTON`,
        goBack: `${employmentDocumentsKey}.FORM.GO_BACK`
      },
      fileSizeDisclaimer: {
        message: `${fileSizeDisclaimer}.MESSAGE`
      }
    },
    paymentMethods: {
      label: `${paymentMethodsKey}.LABEL`,
      formSection: {
        title: `${paymentMethodsKey}.FORM_SECTION.TITLE`,
        transfer: `${paymentMethodsKey}.FORM_SECTION.TRANSFER`,
        cash: `${paymentMethodsKey}.FORM_SECTION.CASH`,
        bank: {
          field: `${paymentMethodsKey}.FORM_SECTION.BANK.FIELD`,
          options: `${paymentMethodsKey}.FORM_SECTION.BANK.OPTIONS`
        },
        account: {
          field: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.FIELD`,
          options: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.OPTIONS`
        },
        other: {
          options: `${paymentMethodsKey}.FORM_SECTION.OTHER.OPTIONS`
        }
      },
      disclaimer: `${paymentMethodsKey}.DISCLAIMER`,
      digitalAccountDisclaimer: `${paymentMethodsKey}.DIGITAL_ACCOUNT_DISCLAIMER`,
      errors: {
        required: `${paymentMethodsKey}.ERRORS.REQUIRED`,
        digitalAccount: `${paymentMethodsKey}.ERRORS.DIGITAL_ACCOUNT`
      },
      buttons: {
        submit: `${paymentMethodsKey}.BUTTONS.SUBMIT`,
        back: `${paymentMethodsKey}.BUTTONS.BACK`,
        addBeneficiary: `${paymentMethodsKey}.BUTTONS.ADD_BENEFICIARY`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      }
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
      content: {
        title: `${voucherKey}.CONTENT.TITLE`,
        description: `${voucherKey}.CONTENT.DESCRIPTION`,
        homeButton: `${voucherKey}.CONTENT.HOME_BUTTON`,
        download: `${voucherKey}.CONTENT.DOWNLOAD_PDF`,
        customRouteButton: `${voucherKey}.CONTENT.CUSTOM_ROUTE_BUTTON`
      }
    }
  }
};

export const FOREIGN_WITHDRAW_FORM_FILE_TYPE = 'retiro-fondos-tecnicos-extranjeros';

export const HOLDER_TYPES = {
  affiliate: 'A',
  delegate: 'M'
};

const content = FOREIGN_WITHDRAW_FORM_CONTENT.steps.employmentDocuments.form;
export const DOCUMENT_ATTACHMENTS = {
  files: [
    {
      order: 0,
      buttonLabel: content.attachIdFile,
      fileIndex: 'identificationFile',
      required: true
    },
    {
      order: 1,
      buttonLabel: content.attachDegreeFile,
      fileIndex: 'degreeFile',
      required: true
    },
    {
      order: 2,
      buttonLabel: content.attachCertificateFile,
      fileIndex: 'certificateFile',
      required: true
    },
  ]
};
