import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalContactCenterComponent } from '@components/modal-contact-center/modal-contact-center.component';
import { ModalContactComponent } from '@components/modal-contact/modal-contact.component';
import { CONTACT_CENTER } from '@constants/constants';
import { DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS, MODAL_CONFIG } from '@constants/modal-data.constants';
import { SOCIAL_URL, WHATSAPP } from '@constants/static-link.constant';
import { environment } from '@env';
import { SocialNetworkType } from '@interfaces/help-button.interface';
import { HELP_BUTTON_CONTENT } from '@pages-content/help-button.constant';
import { FontService } from '@providers/font/font.service';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { WebChatService } from '@providers/webchat/webchat.service';
import { CmsService } from '@services/cms/cms.service';
import { ContactService } from '@services/contact/contact.service';
import { VitaliaService } from '@services/vitalia/vitalia.service';
import { Util } from '@util';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
})
export class HelpButtonComponent implements OnInit {
  public optionsOpened = false;
  public pageContent = HELP_BUTTON_CONTENT;
  public socialNetworks: Array<string>;
  public whatsapp = WHATSAPP as SocialNetworkType;
  public contactCenterNumber = CONTACT_CENTER.NUMBER;
  public callCenterHours: string;
  public showVitaliaIcon: boolean;

  constructor(
    public dialog: MatDialog,
    private cmsService: CmsService,
    private loadingProvider: LoadingProvider,
    private contactService: ContactService,
    private modalProvider: ModalProvider,
    private util: Util,
    public font: FontService,
    private vitaliaService: VitaliaService,
    public webchatService: WebChatService
  ) {
    const socialNetworks = Object.keys(SOCIAL_URL);
    this.socialNetworks = socialNetworks.filter(
      (socialNetwork) => (socialNetwork !== 'whatsapp' && socialNetwork !== 'email')
    );
  }

  public get publicSiteFaqsRoute(): string { return `${environment.planvitalUrl}/preguntas-frecuentes`; }
  public get publicSiteBranchOfficesRoute(): string { return `${environment.planvitalUrl}/sucursales`; }
  public get isApp(): boolean { return this.util.isNative; }

  ngOnInit(): void {
    this.vitaliaService.vitaliaStatusObs().subscribe((response) => {
      this.showVitaliaIcon = response;
    });
  }

  public iconSocialNetwork(socialNetwork: string): string {
    return `i-${socialNetwork}`;
  }

  public toggleOptions(): void {
    this.optionsOpened = !this.optionsOpened;
    this.webchatService.initializeChat();
    // this.webchatService.showUserData();
  }

  public openContactModal(): void {
    this.toggleOptions();
    this.loadingProvider.showLoading();
    this.contactService.getReasons()
      .pipe(finalize(() => this.loadingProvider.hideLoading()))
      .subscribe(
        (response) => this.dialog.open(ModalContactComponent, {
          disableClose: false, ...MODAL_CONFIG, data: { contactReasons: response },
        }),
        () => this.handleError(false)
      );
  }

  public openCallCenterModal(): void {
    this.toggleOptions();
    this.loadingProvider.showLoading();
    this.cmsService.getGeneralInfo()
      .pipe(finalize(() => this.loadingProvider.hideLoading()))
      .subscribe(
        (response) => {
          this.callCenterHours = response.callCenterHours;
          this.dialog.open(ModalContactCenterComponent);
        },
        () => this.handleError()
      );
  }

  private handleError(callCenterModal = true): void {
    const params = this.util.setModalTexts(DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS);
    params.primaryCallback = () => callCenterModal ? this.openCallCenterModal() : this.openContactModal();
    this.modalProvider.openModal(params);
  }

  public goToUrl(url: string): void {
    this.util.goTo(url);
    this.toggleOptions();
  }

  public goToSocialNetwork(type: SocialNetworkType): void {
    this.goToUrl(SOCIAL_URL[type]);
  }

  public goToEmail(): void {
    this.util.goTo(SOCIAL_URL['email'], '_self');
  }

  public displayWebchat(): void {
    this.webchatService.toggleWebChatWindow();
    this.toggleOptions();
  }

  public openVideocall(): void {
    this.webchatService.displayVideocall();
    this.toggleOptions();
  }


}
