import { Mock } from '@interfaces/mock.interface';

export const GET_INSTITUTIONS_MOCK: Mock = {
  success: [{
    response: {
      data: {
        instituciones_salud: {
          contenido: [
            'Fonasa',
            'Banmedica S.A.',
            'Chuquicamata LTDA.',
            'Colmena Golden Cross S.A.',
            'Consalud S.A.',
            'Cruz Blanca S.A.',
            'Cruz Del Norte LTDA.',
            'FerroSalud S.A.',
            'Fundacion LTDA.',
            'Fusat S.A.',
            'Normedica S.A.',
            'Nueva MasVida S.A.',
            'Rio Blanco LTDA.',
            'San Lorenzo LTDA.',
            'Vida Tres S.A.',
          ]
        },
      },
      public: true
    },
    output: {
      healthcareInstitutions: [
        'Fonasa',
        'Banmedica S.A.',
        'Chuquicamata LTDA.',
        'Colmena Golden Cross S.A.',
        'Consalud S.A.',
        'Cruz Blanca S.A.',
        'Cruz Del Norte LTDA.',
        'FerroSalud S.A.',
        'Fundacion LTDA.',
        'Fusat S.A.',
        'Normedica S.A.',
        'Nueva MasVida S.A.',
        'Rio Blanco LTDA.',
        'San Lorenzo LTDA.',
        'Vida Tres S.A.',
      ],
    }
  }],
  failures: [
    {
      response: {
        code: 50001,
        folioNumber: null,
        messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        serviceErrorMessage: '',
        message: 'Error',
      },
    },
  ]
};
