export interface SelphIDPlugin {
  startCapture(configuration: SelphIDConfiguration): Promise<SelphIDResult>;
  startTest(configuration: SelphIDConfiguration): Promise<SelphIDResult>;
  startTestImageBase64(configuration: SelphIDConfiguration): Promise<SelphIDResult>;
  getDeviceCameras(): Promise<any>;
  initPluginSelphID(): Promise<String>;
  tokenize(data: String): Promise<String>;
  getVersion(): Promise<String>;
}

export interface SelphIDConfiguration {
   /**
   * Whether to save the photo to the gallery.
   * If the photo was picked from the gallery, it will only be saved if edited.
   * @default: false
   *
   * @since 1.0.0
   */
   operation: SelphIDOperation;
   resourcesPath: string;
   license: string,
   debug?: boolean;
   showResultAfterCapture?: boolean;
   showTutorial?: boolean;
   scanMode?: SelphIDScanMode;
   specificData?: string;
   fullscreen?: boolean;
   locale?: string;
   tokenImageQuality?: number;
   documentType?: SelphIDDocumentType;
   timeout?: SelphIDTimeout;
   tokenPreviousData?: string;
   enableWidgetEventListener?: boolean;
   testImage?: string;
   generateRawImages?: boolean;
   imageQuality?: number;
   compressFormat?: SelphIDCompressFormat;
   translationsContent?: string;
   viewsContent?: string;
   documentModels?: string;
   cameraId?: number;
   params?: any;
   showTutorialOnly?: boolean;
}

export interface SelphIDResult {
  /**
   * The path to the compressed file with all the plugin resources.
   *
   */
   finishStatus: SelphIDFinishStatus;
   finishStatusDescription?: string;
   errorType: SelphIDErrorType;
   errorMessage?: string;
   frontDocumentImage?: string;
   backDocumentImage?: string;
   faceImage?: string;
   signatureImage?: string;
   fingerprintImage?: string;
   documentData?: string;
   tokenFrontDocumentImage?: string;
   tokenBackDocumentImage?: string;
   tokenFaceImage?: string;
   tokenOCR?: string;
   documentCaptured?: string;
   captureProgress?: number;
   timeoutStatus?: number;
   lastActionBeforeClose?: string;
   matchingSidesScore: number;
   rawFrontDocument?: string;
   rawBackDocument?: string;
   tokenRawFrontDocument?: string;
   tokenRawBackDocument?: string;
   lastActionBeforeCapture?: string;
   timeoutDiagnostic?: string;
}

export enum SelphIDCompressFormat {
    JPEG = 'jpeg',
    PNG = 'png',
}

export enum SelphIDOperation {
  Front = 'Front',
  Back = 'Back',
  Wizard = 'Wizard',
}

export enum SelphIDDocumentType {
  IDCard = 'IDCard',
  Passport = 'Passport',
  DriverLicense = 'DriverLicense',
  ForeignCard = 'ForeignCard',
  CreditCard = 'CreditCard',
  Custom = 'Custom',
}

export enum SelphIDScanMode {
  Generic = 'Generic',
  Specific = 'Specific',
  Search = 'Search',
}

export enum SelphIDTimeout {
  Short = 'Short',
  Medium = 'Medium',
  Long = 'Long',
}

export enum SelphIDErrorType {
  UnknownError = 1,
  NoError = 2,
  CameraPermissionError = 3,
  SettingsPermissionError = 4,
  HardwareError = 5,
  ExtractionLicenseError = 6,
  UnexpectedCaptureError = 7,
  ControlNotInitializedError = 8,
  BadExtractorConfigurationError = 9,
}

export enum SelphIDFinishStatus {
  Ok = 1,
  Error = 2,
  CancelByUser = 3,
  StatusTimeout = 4,
}