import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_WITH_IP_VALIDATION } from '@constants/ip-validation.constant';
import * as publicIp from 'public-ip';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable()
export class IpInterceptor implements HttpInterceptor {

  constructor() { }

  private async getIpClient() {
    return await publicIp.v4();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const matches = ROUTES_WITH_IP_VALIDATION.filter(
      // IMPORTANT: Just applicable for certificates progressives holidays !!!!
      (routes) => request.url.includes(routes.route) && routes.method === request.method
    );
    if (matches.length === 1) {
      return from(this.getIpClient())
        .pipe(
          switchMap((ipClient: string) => {
            let requestClone;
            if (request.method === 'POST') {
              if (request.body instanceof FormData) {
                requestClone = request.clone();
                requestClone.body.append('ip', ipClient);
              } else {
                requestClone = request.clone({ body: { ...request.body, ipClient } });
              }
            } else if (request.method === 'GET') {
              requestClone = request.clone({ params: request.params.append('ip', ipClient) });
            }
            return next.handle(requestClone);
          })
        );
    }
    return next.handle(request);
  }
}
