import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HtmlSanPipe } from './domsanitizer/html-san.pipe';
import { ResourceUrlSanPipe } from './domsanitizer/resource-url-san.pipe';
import { ScriptSanPipe } from './domsanitizer/script-san.pipe';
import { StyleSanPipe } from './domsanitizer/style-san.pipe';
import { UrlSanPipe } from './domsanitizer/url-san.pipe';

@NgModule({
  declarations: [
    HtmlSanPipe,
    ResourceUrlSanPipe,
    ScriptSanPipe,
    StyleSanPipe,
    UrlSanPipe,
  ],
  imports: [CommonModule],
  exports: [
    HtmlSanPipe,
    ResourceUrlSanPipe,
    ScriptSanPipe,
    StyleSanPipe,
    UrlSanPipe,
  ],
})
export class PipesModule { }
