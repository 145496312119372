import { AlertBanner } from '@interfaces/components.interface';
import { Mock } from '@interfaces/mock.interface';


export const GET_ALERT_BANNER_MOCKUP: Mock = {
  success: [{
    response: {
      data: [
        {
          activo: true,
          modal: {
            title: 'Title',
            description: 'description'
          },
          traducciones: [
            {
              id: 1,
              titulo: 'Sucursales cerradas por contingencia Covid-19',
              mensaje: 'San Carlos, Temuco, Illapel y Osorno. En la Región Metropolitana; Maipú, Tenderini, Providencia y Teatinos.',
              idioma: 'es',
              alerta_cinta: 1,
            },
          ]
        },
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const ALERT_BANNER_MOCKUP: AlertBanner = {
  title: 'Sucursales cerradas por contingencia Covid-19',
  message: 'San Carlos, Temuco, Illapel y Osorno. En la Región Metropolitana; Maipú, Tenderini, Providencia y Teatinos.',
  active: true,
  modal: {
    title: 'Title',
    description: 'description',
  },
};

export const ALERT_BANNER_NULL_MOCKUP: AlertBanner = {
  title: null,
  message: null,
  active: true,
};

export const GET_HEADERS_MOCKUP: Mock = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          imagen: '16d3caad-dd9a-4a4b-93da-f17fc546c41a.png',
          icono: 'handshake',
          traducciones: [
            {
              id: 1,
              titulo: '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital</strong></p>',
              languages_code: 'es',
              header: 1,
              alt_imagen: 'alt'
            },
          ]
        },
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};
