import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { CmsUtils } from '@app/shared/utils/cms/cms.utils';
import { CMS_REQUESTS } from '@constants/constants';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { BankCalendar, Institutions } from '@interfaces/cms.interface';
import { AlertBanner, GeneralInfo } from '@interfaces/components.interface';

import * as cmsConstants from '@constants/cms.constant';
import { Banner } from '@interfaces/banner.interface';
import { FinancialInformation } from '@interfaces/financial-information.interface';
import { ProfitabilityPercentages } from '@interfaces/profitability-percentages.interface';
import { PERIODS_PROFITABILITY_MOCK, PROFITABILITY_DATES_MOCK } from '@mocks/periods-profitability.mock';
import { GET_BALANCE_EVOLUTION_INFO_MOCK } from './mocks/cms-balance-evolution.mock';
import { GET_BANK_CALENDAR_MOCK } from './mocks/cms-bank-calendar.mock';
import { GET_FINANCIAL_INFORMATION_MOCKUP, GET_GENERAL_INFO_MOCKUP } from './mocks/cms-general-info.mock';
import { GET_HOME_COLLECTION_MOCK } from './mocks/cms-home-collection.mock';
import { GET_INSTITUTIONS_MOCK } from './mocks/cms-institutions.mock';
import * as mocks from './mocks/components.mock';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  constructor(
    private cmsUtils: CmsUtils,
    private http: HttpInterceptor,
  ) {
  }

  public homeCollection(gender: string = 'F'): Observable<any> {
    const url = `${environment.cmsApiUrl}items/${CMS_REQUESTS.home.path}?fields=${CMS_REQUESTS.home.fields}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_HOME_COLLECTION_MOCK)
      .pipe(
        map(response => this.cmsUtils.mapHome(response.data, gender)),
        catchError(error => 'Bienvenido a tu Nueva Sucursal en línea')
      );
  }

  public getGeneralInfo(language = 'es'): Observable<GeneralInfo | any> {
    const url = `${environment.cmsApiUrl}items/informacion_general/1?fields=${cmsConstants.GENERAL_INFO_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_GENERAL_INFO_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapGeneralInfo(response.data, language)),
        catchError(error => of({ callCenterHours: '', callCenterAvailable: true }))
      );
  }

  public getInstitutions(fields: string | Array<string>): Observable<Institutions> {
    if (Array.isArray(fields)) fields = fields.join(',');
    const url = `${environment.cmsApiUrl}items/${CMS_REQUESTS.institutions.path}?fields=${fields}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_INSTITUTIONS_MOCK)
      .pipe(map(response => this.cmsUtils.mapInstitutions(response.data)));
  }

  public loadFinancialInformation(): Observable<FinancialInformation> {
    const lang = this.cmsUtils.getLanguage();
    const url = `${environment.cmsApiUrl}items/${cmsConstants.FINANCIAL_INFORMATION_PATH}?fields=${cmsConstants.FINANCIAL_INFORMATION_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    const financialInformationRequest = this.http.get(url, GET_FINANCIAL_INFORMATION_MOCKUP);
    const headersRequest = this.getHeaders(lang);
    return forkJoin([financialInformationRequest, headersRequest])
      .pipe(
        map(response => this.cmsUtils.mapFinancialInformation(response[0].data, response[1])),
      );
  }

  public getBankCalendar(fields: string | Array<string>): Observable<BankCalendar> {
    if (Array.isArray(fields)) fields = fields.join(',');
    const filter = {
      _and: [
        CMS_REQUESTS.filter.published,
        CMS_REQUESTS.bankCalendar.filter,
      ]
    };
    const url = `${environment.cmsApiUrl}items/${CMS_REQUESTS.bankCalendar.path}?fields=${fields}&filter=${JSON.stringify(filter)}`;

    return this.http.get(url, GET_BANK_CALENDAR_MOCK)
      .pipe(map(response => this.cmsUtils.mapBankCalendar(response.data)));
  }

  public getProfitabilityPercentages(): Observable<ProfitabilityPercentages> {
    const url = `${environment.cmsApiUrl}items/${CMS_REQUESTS.profitability.percentages.path}?filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, PERIODS_PROFITABILITY_MOCK)
      .pipe(map(response => this.cmsUtils.mapProfitabilityPercentage(response.data)));
  }

  public getProfitabilityDates(): Observable<any> {
    const url = `${environment.cmsApiUrl}items/${CMS_REQUESTS.profitability.dates.path}?filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, PROFITABILITY_DATES_MOCK)
      .pipe(map(response => this.cmsUtils.mapProfitabilityDates(response)));
  }

  public getBalanceEvolutionInformation(): Observable<any> {
    const fields = Object.values(CMS_REQUESTS.homeMobile.balanceEvolution.fields).join(',');
    const url = `${environment.cmsApiUrl}items/${CMS_REQUESTS.homeMobile.balanceEvolution.path}?fields=${fields}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_BALANCE_EVOLUTION_INFO_MOCK)
      .pipe(
        map(response => this.cmsUtils.mapBalanceEvolutionInformation(response.data)),
        catchError(error => of(GET_BALANCE_EVOLUTION_INFO_MOCK))
      );
  }

  public getHeaders(language = 'es'): Observable<Array<Banner>> {
    const url = `${environment.cmsApiUrl}items/headers?fields=${cmsConstants.HEADERS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_HEADERS_MOCKUP)
      .pipe(map(response => this.cmsUtils.mapHeaders(response.data, language)));
  }

  public getAlertBanner(): Observable<AlertBanner> {
    const language = this.cmsUtils.getLanguage();
    const filter = {
      _and: [
        CMS_REQUESTS.filter.published,
        CMS_REQUESTS.alertBanner.filter,
      ]
    };
    const url = `${environment.cmsApiUrl}items/alerta_cinta?fields=${cmsConstants.ALERT_BANNER_FIELDS}&filter=${JSON.stringify(filter)}&limit=1`;
    return this.http.get(url, mocks.GET_ALERT_BANNER_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapAlertBanner(response.data, language),
          catchError(error => error)
        ));
  }
}
