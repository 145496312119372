import { Mock } from '@interfaces/mock.interface';

export const REQUEST_EMPLOYER_ACCESS_KEY: Mock = {
  success: [{
    response: {
      folio: '1234',
    },
  }],
  failures: [{
    response: {
      code: 50001,
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      message: 'Error',
    },
  }, {
    response: {
      code: 40163,
      messageDescription: 'La clave ya fue activada',
      message: 'Error',
    },
  },
  {
    response: {
      code: 40066,
      messageDescription: 'El RUT del empleador no fue encontrado',
      message: 'Error',
    },
  },
],
};
