import { Mock } from '@interfaces/mock.interface';

export const GET_HOME_COLLECTION_MOCK: Mock = {
  success: [{
    response: {
      data: {
        id: 1,
        banner_lateral: '26a22781-2f2c-478d-9608-2d4a859e9c3e',
        mensaje_bienvenida_masculino: 'Bienvenido a tu Nueva Sucursal en línea',
        mensaje_bienvenida_femenino: 'Bienvenida a tu Nueva Sucursal en línea'
      },
      public: true
    },
    output: {
      lateralBanner: 'http://35.186.243.77/v2/cms/assets/26a22781-2f2c-478d-9608-2d4a859e9c3e',
      welcomeMessage: 'Bienvenida a tu Nueva Sucursal en línea',
      imageLink: 'Bienvenida a tu Nueva Sucursal en línea',
    }
  }],
  failures: [{
    response: {}
  }]
};
