const pageKey = 'HELP_BUTTON';
const callCenterKey = `${pageKey}.MODALS.CALL_CENTER`;
const contactKey = `${pageKey}.MODALS.CONTACT`;

export const HELP_BUTTON_CONTENT = {
  contactCenter: `${pageKey}.CONTACT_CENTER`,
  branchOffices: `${pageKey}.BRANCH_OFFICES`,
  contact: `${pageKey}.CONTACT`,
  faqs: `${pageKey}.FAQS`,
  facebook: `${pageKey}.FACEBOOK`,
  twitter: `${pageKey}.TWITTER`,
  instagram: `${pageKey}.INSTAGRAM`,
  linkedin: `${pageKey}.LINKEDIN`,
  youtube: `${pageKey}.YOUTUBE`,
  whatsapp: `${pageKey}.WHATSAPP`,
  webchat: `${pageKey}.WEBCHAT`,
  email: `${pageKey}.EMAIL`,
  modals: {
    callCenter: {
      title: `${callCenterKey}.TITLE`,
      buttons: {
        normalCall: `${callCenterKey}.BUTTONS.NORMAL_CALL`,
        click2Call: `${callCenterKey}.BUTTONS.CLICK_2_CALL`,
        cobrowsing: `${callCenterKey}.BUTTONS.COBROWSING`,
        send: `${callCenterKey}.BUTTONS.SEND`,
        back: `${callCenterKey}.BUTTONS.BACK`,
      },
      click2Call: {
        title: `${callCenterKey}.CLICK_2_CALL.TITLE`,
        description: `${callCenterKey}.CLICK_2_CALL.DESCRIPTION`,
        form: {
          phone: `${callCenterKey}.CLICK_2_CALL.FORM.PHONE`,
          reason: `${callCenterKey}.CLICK_2_CALL.FORM.REASON`,
        },
        errors: {
          required: `${callCenterKey}.CLICK_2_CALL.ERRORS.REQUIRED`,
          invalid: `${callCenterKey}.CLICK_2_CALL.ERRORS.INVALID`
        },
        disclaimer: `${callCenterKey}.CLICK_2_CALL.DISCLAIMER`,
        successMessage: `${callCenterKey}.CLICK_2_CALL.SUCCESS`,
      },
      cobrowsing: {
        title: `${callCenterKey}.COBROWSING.TITLE`,
        description: `${callCenterKey}.COBROWSING.DESCRIPTION`,
        enterCodeInstruction: `${callCenterKey}.COBROWSING.ENTER_CODE_INSTRUCTION`,
        screenshareButton: `${callCenterKey}.COBROWSING.SCREENSHARE_BUTTON`,
        disabledScreenshareButton: `${callCenterKey}.COBROWSING.DISABLED_SCREENSHARE_BUTTON`,
        stopScreenshareButton: `${callCenterKey}.COBROWSING.STOP_SCREENSHARE_BUTTON`,
        recomendation: `${callCenterKey}.COBROWSING.RECOMENDATION`,
        errors: {
          invalid: `${callCenterKey}.COBROWSING.ERRORS.INVALID`
        },
      }
    },
    contact: {
      title: `${contactKey}.TITLE`,
      description: `${contactKey}.DESCRIPTION`,
      form: {
        reasonId: `${contactKey}.FORM.REASON`,
        detail: `${contactKey}.FORM.DETAIL`,
        file: `${contactKey}.FORM.FILE`,
        fileName: `${contactKey}.FORM.FILE_NAME`,
        errors: {
          required: `${contactKey}.FORM.ERRORS.REQUIRED`,
        },
      },
      buttons: {
        upload: `${contactKey}.BUTTONS.UPLOAD`,
        update: `${contactKey}.BUTTONS.UPDATE`,
        yes: `${contactKey}.BUTTONS.YES`,
        no: `${contactKey}.BUTTONS.NO`,
        send: `${contactKey}.BUTTONS.SEND`,
      },
      success: {
        title: `${contactKey}.SUCCESS.TITLE`,
        description: `${contactKey}.SUCCESS.DESCRIPTION`,
      },
    }
  }
};
