import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOGIN_CONTENT } from '@pages-content/login.constant';

@Injectable({
  providedIn: 'root'
})
export class LoginUtils {

  private loginErrorContent = LOGIN_CONTENT.form.controls.password.errors;

  constructor(
    private translateService: TranslateService,
  ) { }

  public getErrorAttemptsMessage(attemptsLeft: number): string {
    return this.incorrectPassword() + this.attemptsMessage(attemptsLeft);
  }

  private incorrectPassword(): string {
    return this.translateService.instant(this.loginErrorContent.wrong.incorrect);
  }

  private attemptsMessage(attemptsLeft: number): string {
    if (!attemptsLeft || !this.loginErrorContent) return '';
    const quantityKey = attemptsLeft > 1 ? 'plural' : 'singular';
    const { firstSegment, secondSegment } = this.loginErrorContent.wrong.attempts[quantityKey];
    return `. ${this.translateService.instant(firstSegment)} ${attemptsLeft} ${this.translateService.instant(secondSegment)}.`;
  }
}
