export const NOT_FOUND_SECURITY_KEY = 'not_found';

export const RESEND_CODE_TIMEOUT = 60;
export const CODE_LENGTH = 4;

export const SERIAL_NUMBER_MAX_LENGTH = 10;

export const MIN_LENGTH_SECURITY_KEY = 6;
export const MAX_LENGTH_SECURITY_KEY = 10;
export const MIN_LENGTH_ANSWER = 3;
export const CONSECUTIVE_LENGTH_SECURITY_KEY = 4;
export const REPETITIVE_LENGTH_SECURITY_KEY = 4;
export const MIN_DIGITS_SECURITY_KEY = 1;
export const MIN_LETTERS_SECURITY_KEY = 1;
export const MIN_SECURITY_QUESTION_LENGTH = 1;
export const MAX_SECURITY_QUESTION_LENGTH = 20;

export const VALID_SECURITY_KEY_STATES_FOR_BENEFICIARY = {
  revoked: '75',
  notFound: '70',
};

export const SECURITY_KEY_LOWER_CHAR = 1;
export const SECURITY_KEY_UPPER_CHAR = 1;
export const SECURITY_KEY_UPPER_CHAR_NONE = 0;
