import moment from 'moment';
import { finalize } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Util } from '@app/shared/utils/utils';
import { ModalComponent } from '@components/modal/modal.component';
import { FILE_VALID_MIME_TYPES } from '@constants/constants';
import {
  DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS, MODAL_CONTACT_DATA
} from '@constants/modal-data.constants';
import { DefaultCreateResponse, DefaultResponse } from '@interfaces/default-response.interface';
import { TranslateService } from '@ngx-translate/core';
import { HELP_BUTTON_CONTENT } from '@pages-content/help-button.constant';
import { FontService } from '@providers/font/font.service';
import { ModalProvider } from '@providers/modal/modal';
import { ContactService } from '@services/contact/contact.service';

@Component({
  selector: 'app-modal-contact',
  templateUrl: './modal-contact.component.html',
  styleUrls: ['./modal-contact.component.scss'],
})
export class ModalContactComponent {
  public pageContent = HELP_BUTTON_CONTENT.modals.contact;
  public contactReasons: Array<DefaultResponse> = [];
  public form: UntypedFormGroup;
  public validMimeTypes = FILE_VALID_MIME_TYPES;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      contactReasons: Array<DefaultResponse>,
    },
    private util: Util,
    private contactService: ContactService,
    private changeDetector: ChangeDetectorRef,
    private modalProvider: ModalProvider,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModalComponent>,
    public font: FontService,
  ) {
    this.contactReasons = data.contactReasons;
    this.form = this.formBuilder.group({
      reasonId: ['', Validators.required],
      detail: ['', Validators.required],
      addedFile: [false],
      fileName: [{ value: null, disabled: true }],
      file: [{ value: null }],
    });
  }

  public get fileName(): UntypedFormControl { return this.form.get('fileName') as UntypedFormControl; }
  public get file(): UntypedFormControl { return this.form.get('file') as UntypedFormControl; }
  public get addedFile(): UntypedFormControl { return this.form.get('addedFile') as UntypedFormControl; }
  public get reasonId(): UntypedFormControl { return this.form.get('reasonId') as UntypedFormControl; }
  public get labelButtonFile(): string { return !this.fileName.value ? this.pageContent.buttons.upload : this.pageContent.buttons.update; }

  public closeModal(): void {
    this.dialogRef.close();
  }

  private handleError(): void {
    const params = this.util.setModalTexts(DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS);
    params.primaryCallback = () => this.sendFormData();
    this.modalProvider.openModal(params);
  }

  public sendFormData(): void {
    if (this.form.invalid) { return; }

    const data = { ...this.form.getRawValue() };
    if (!data.addedFile) delete data.file;
    delete data.addedFile;
    delete data.fileName;

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    this.contactService.contact(formData)
      .pipe(finalize(() => this.dialogRef.close()))
      .subscribe(
        (response: DefaultCreateResponse) => this.handleSuccess(response),
        () => this.handleError(),
      );
  }

  public resultParams(data: DefaultCreateResponse): string {
    const params = { folio: data.folio, date: moment().format('yyyy-MM-DD') };
    return this.translateService.instant(this.pageContent.success.description, params);
  }

  public updateFileValidator(): void {
    this.addedFile.value ? this.file.setValidators(Validators.required) : this.file.clearValidators();
    this.file.setValue(null);
    this.fileName.setValue(null);
  }

  private handleSuccess(data: DefaultCreateResponse): void {
    const modalData = MODAL_CONTACT_DATA;
    modalData.message = this.resultParams(data);
    this.modalProvider.openModal(modalData);
  }

  public onFileChange(event): void {
    this.form.patchValue({ file: null, fileName: null });
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.patchValue({ file, fileName: file.name });
        this.changeDetector.markForCheck();
      };
    }
  }
}
