export interface SelphiFacePlugin {
  /**
   * Generates a `templateRaw` from a URI image (with header) or an image in ***stringBase64*** format. The plugin transforms it to native image (***Bitmap*** in **Android** and ***UIImage*** in **IOS**) and sends it to the widget to generate the `templateRaw`. The widget will return the `templateRaw` in ***stringBase64*** format.
   * 
   * @since 1.0.0
   */
  generateTemplateRaw(imageBase64: string): Promise<string>;
  /**
   * Run the plugin in selfie mode.
   * 
   * @since 1.0.0
   */
  startExtraction(configuration: SelphiFaceConfiguration): Promise<SelphiFaceResult>;
  /**
   * Returns a list of available cameras with their characteristics
   *  
   * @since 1.0.0
   */
  getDeviceCameras(): Promise<any>;
  /**
   * Returns the native version of the widget
   *  
   * @since 1.1.10
   */
  getVersion(): Promise<string>;
}

export interface SelphiFaceConfiguration {
  /**
   * The path to the compressed file with all the plugin resources.
   * 
   * @since 1.0.0
   */
  resourcesPath: string;
  /**
   * Property to select the front camera as the preferred camera
   *
   * @since 1.0.0
   */
  frontalCameraPreferred?: boolean;
  /**
   * Sets the debug mode of the widget.
   * 
   * @default: false
   *
   * @since 1.0.0
   */
  debug?: boolean;
  /**
   * Indicates whether the images returned in the completion event contain only the area of the detected face, with a magnification given by "CropPercent" or the entire image is returned.
   * 
   * @default: false
   *
   * @since 1.0.0
   */
  crop?: boolean;
  /**
   * Specifies the percentage that the detected face area is enlarged to compose the returned image.
   * 
   * @since 1.0.0
   */
  cropPercent?: boolean;
  /**
   * Property that allows the stabilization mode to be enabled or disabled prior to the face detection process. If enabled it will give some guidelines to know if it is correctly located or not.
   * 
   * @since 1.0.0
   */
  stabilizationMode?: boolean;
  /**
   * Indicates if the template raw must be optimized.
   * 
   * @since 1.0.0
   */
  templateRawOptimized?: boolean;
  /**
   * Property to set a percentage of quality to the return image (bestImage). The value must be between 0 and 1 (float).
   * 
   * @since 1.0.0
   */
  jpgQuality?: number;
  /**
   * Indicates whether the widget returns to the application the images used during extraction or not. It should be noted that returning images may result in a considerable increase in the use of device resources.
   * 
   * @since 1.0.0
   */
  enableImages?: boolean;
  /**
   * Indicates the camera width during the capture process.
   * 
   * @since 1.0.0
   */
  desiredCameraWidth?: number;
  /**
   * Indicates the camera height during the capture process.
   * 
   * @since 1.0.0
   */
  desiredCameraHeight?: number;
  /**
   * Sets the liveness mode of the widget. .
   * 
   * @since 1.0.0
   */
  livenessMode?: SelphiFaceLivenessMode;
  /**
   * Sets the enableWidgetEventListener mode of the widget. .
   * 
   * @since 1.1.1
   */
  enableGenerateTemplateRaw?: boolean;
  /**
   * Sets the enableWidgetEventListener mode of the widget. .
   * 
   * @since 1.1.2
   */
  enableWidgetEventListener?: boolean;
  /**
  * Sets the enableWidgetEventListener mode of the widget. .
  * 
  * @since 1.1.3
  */
  qrMode?: boolean;
  /* 
  Sets a specific camera recovered in the method getDeviceCameras.
  */
  cameraId?: number;
  /**
   * Enable or no to show the tutorial video of the widget.
   * 
   * @default: false
   *
   * @since 1.0.0
  */
  tutorial?: boolean;
  /**
   * Enable or no to show the result of the widget after the capture.
   * 
   * @default: false
   *
   * @since 1.0.0
  */
  showResultAfterCapture?: boolean;
}

export interface SelphiFaceResult {
  /**
   * Returns the global diagnosis of the operation
   * 
   * @since 1.0.0
   */
   finishStatus: string;
   /**
   * Returns the description of the finishStatus
   * ation
   * 
   * @since 1.0.0
   */
   finishStatusDescription?: string;
   /**
   * Returns the type of error that has occurred (if there has been one, which is indicated in the `finishStatus` parameter with the value `error`)
   * 
   * @since 1.0.0
   */
   errorType: string;
   /**
   * Indicates an additional error message if needed. It is an optional value.
   * 
   * @since 1.0.0
   */
   errorMessage?: string;
   /**
   * Returns the user template that is generated after the extraction process.
   * 
   * @since 1.0.0
   */
   template?: string;
   /**
   * Returns the raw template that is generated after the extraction process.
   * 
   * @since 1.0.0
   */
   templateRaw?: string;
   /**
   * Returns the score of the probability that the user wears glasses.
   * 
   * @since 1.0.0
   */
   eyeGlassesScore?: number;
   /**
   * Returns the quality score of the template.
   * 
   * @since 1.0.0
   */
   templateScore?: number;
   /**
   * Devuelve los datos del código QR capturado.
   * 
   * @since 1.0.0
   */
   qrData?: string;
   /**
   * If the `enableImages` flag was activated in the configuration, it returns the images that are obtained during the extraction process. The images are returned sorted by the time instant in which they were obtained.
   * 
   * @since 1.0.0
   */
   images?: [string];
   /**
   * Returns the best image extracted from the registration or authentication process. This image is the original size image extracted from the camera.
   * 
   * @since 1.0.0
   */
   bestImage?: string;
   /**
   * Returns a cropped image centered on the user's face. This image is obtained from the "bestImage". This is the image that should be used as the characteristic image of the user who performed the registration or authentication process as 'avatar'.
   * 
   * @since 1.0.0
   */
   bestImageCropped?: string;
}

export enum SelphiFaceLivenessMode {
  None = 'NONE',
  Passive = 'PASSIVE',
}

export enum SelphiFaceErrorType {
  UnknownError = 1,
  NoError = 2,
  CameraPermissionError = 3,
  SettingsPermissionError = 4,
  HardwareError = 5,
  ExtractionLicenseError = 6,
  UnexpectedCaptureError = 7,
  ControlNotInitializedError = 8,
  BadExtractorConfigurationError = 9,
}

export enum SelphiFaceFinishStatus {
  Ok = 1,
  Error = 2,
  CancelByUser = 3,
  StatusTimeout = 4,
}