/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DEFAULT_ERROR_MODAL_PROPS } from '@constants/modal-data.constants';
import { ErrorResponse } from '@interfaces/error-response.model';
import { ModalData } from '@interfaces/modal-data.interface';
import { OtpDynamicData, OtpDynamicKey } from '@interfaces/otp-dynamic-key.interface';
import { MODALS_CONTENT } from '@pages-content/modals.constant';
import { FontService } from '@providers/font/font.service';
import { ModalProvider } from '@providers/modal/modal';
import { OtpDynamicKeyService } from '@services/otp-dynamic-key/otp-dynamic-key.service';
import { Util } from '@util';

@Component({
    selector: 'app-modal-dynamic-key-channel',
    templateUrl: './modal-dynamic-key-channel.component.html',
    styleUrls: ['./modal-dynamic-key-channel.component.scss'],
})
export class ModalDynamicKeyChannelComponent {
    public firstBtnText: string;
    public secondBtnText: string;

    private primaryCallback: (res?: any) => {};
    private secondaryCallback: () => {};
    closeCallback: () => {};
    public closeBtn = true;

    public cardData: OtpDynamicData;

    selectedOption: string | null = null;
    public isLoading = false;

    constructor(
        public dialogRef: MatDialogRef<ModalDynamicKeyChannelComponent>,
        public font: FontService,
        @Inject(MAT_DIALOG_DATA) data: ModalData,
        private otpDynamicKeyService: OtpDynamicKeyService,
        private readonly modalProvider: ModalProvider,
        public util: Util,
    ) {
        //Agregar métooo para identificar modulo -> 
        //en template agregar condicion para quitar mensaje o subtitulo cuando sea creacion o recuperacion sacu
        Object.keys(data).forEach(key => this[key] = data[key]);
        if (data) {
            this.cardData = data as any;
        }
    }

    public primaryAction(): void {
        this.isLoading = true;
        const otpDynamicKey = {
            site: 'privateSite',
            module: this.cardData.data?.options?.module,
            rut: this.cardData.data?.options?.rut,
            method: this.selectedOption,
            template: ''
        } as OtpDynamicKey;
        this.otpDynamicKeyService.generateOtp(otpDynamicKey).subscribe(res => {
            this.isLoading = false;
            if (res.success) {
                this.dialogClose(res.id);
                this.dialogRef.afterClosed().subscribe(result => {
                    this.primaryCallback(result);
                });
            } else {
                this.handleError();
            }
        }, error => {
            this.isLoading = false;
            this.handleError(error);
        });

    }

    handleError(error?: ErrorResponse): void {
        const modal = DEFAULT_ERROR_MODAL_PROPS;
        if (!error) {
            modal.message = MODALS_CONTENT.otpError.message;
        }
        this.modalProvider.openModal(modal);
    }

    public secondaryAction(): void {
        this.dialogClose();
        this.dialogRef.afterClosed().subscribe(() => this.secondaryCallback());
    }

    public dialogClose(data?: any): void {
        this.dialogRef.close(data);
        if (this.closeCallback) this.closeCallback();
    }

    selectOptionChannel(option: string) {
        this.selectedOption = option;
        this.primaryAction();
    }
}
