import { Injectable } from '@angular/core';
import { registerPlugin } from '@capacitor/core';
import { SelphIDOperation,
         SelphIDPlugin,
         SelphIDResult,
         SelphIDScanMode,
         SelphIDTimeout,
         SelphIDDocumentType } from 'capacitor-selphid-plugin';
import { LICENSE_SELPHID_ANDROID, LICENSE_SELPHID_IOS, SELPHID_RESOURCES_PATH } from './selphid.service.constants';
import { Util } from '@util';
import { environment } from '@env';
import { PACKAGE_NAME_DEV, SELPHID_ANDROID_LICENSE_DEV, SELPHID_ANDROID_LICENSE_PROD,
         SELPHID_IOS_LICENSE_DEV, SELPHID_IOS_LICENSE_PROD } from '@constants/constants';
import { PACKAGE_NAME_PROD } from '../../constants/constants';


const SelphID = registerPlugin<SelphIDPlugin>('SelphID');

@Injectable({
  providedIn: 'root',
})
export class SelphidService {

  constructor(
    // private platform: Platform,
    private util: Util
  ) {
  }

  /**
   * Method that launches the SelphID plugin with Search Mode.
   * @returns Promise with a JSON string.
   */
  async launchSelphidCapture(): Promise<SelphIDResult> {
    // console.log('Launching selphID widget...', this.settings);
    LICENSE_SELPHID_ANDROID.license.extraData = environment.production ? SELPHID_ANDROID_LICENSE_PROD : SELPHID_ANDROID_LICENSE_DEV;
    LICENSE_SELPHID_ANDROID.license.packageName = environment.production ? PACKAGE_NAME_PROD : PACKAGE_NAME_DEV;
    LICENSE_SELPHID_IOS.license.extraData = environment.production ? SELPHID_IOS_LICENSE_PROD : SELPHID_IOS_LICENSE_DEV;
    LICENSE_SELPHID_IOS.license.packageName = environment.production ? PACKAGE_NAME_PROD : PACKAGE_NAME_DEV;
    return await SelphID.startCapture({
      debug: false,
      showResultAfterCapture: true,
      showTutorial: false,
      specificData: 'CL|<ALL>',
      operation: SelphIDOperation.Wizard,
      scanMode: SelphIDScanMode.Search,
      timeout: SelphIDTimeout.Medium,
      documentType: SelphIDDocumentType.IDCard,
      resourcesPath: SELPHID_RESOURCES_PATH,
      locale: 'es_ES',
      license: this.util.isIosPlatform ? JSON.stringify(LICENSE_SELPHID_IOS) : JSON.stringify(LICENSE_SELPHID_ANDROID)
    });
  }

  async getDeviceCameras(): Promise<any> {
    // console.log('Launching getDeviceCameras widget...');
    return await SelphID.getDeviceCameras();
  }

  async tokenize(data: string): Promise<string> {
    // console.log('Launching tokenize widget...');
    return (await SelphID.tokenize(data)).toString();
  }

  async getVersion(): Promise<string> {
    // console.log('Launching getVersion widget...');
    return (await SelphID.getVersion()).toString();
  }
}
