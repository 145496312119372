export const MONTHS_ES = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
export const MONTHS_EN = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export const MONTHS_HT = ['Jan', 'Fev', 'Mas', 'Avr', 'Me', 'Jen', 'Jiy', 'Out', 'Sept', 'Okt', 'Nov', 'Des'];

/* eslint-disable max-len */
export const MONTHS_EXTENDED = [
  'MAIN.GENERAL.MONTHS.JANUARY',
  'MAIN.GENERAL.MONTHS.FEBRUARY',
  'MAIN.GENERAL.MONTHS.MARCH',
  'MAIN.GENERAL.MONTHS.APRIL',
  'MAIN.GENERAL.MONTHS.MAY',
  'MAIN.GENERAL.MONTHS.JUNE',
  'MAIN.GENERAL.MONTHS.JULY',
  'MAIN.GENERAL.MONTHS.AUGUST',
  'MAIN.GENERAL.MONTHS.SEPTEMBER',
  'MAIN.GENERAL.MONTHS.OCTOBER',
  'MAIN.GENERAL.MONTHS.NOVEMBER',
  'MAIN.GENERAL.MONTHS.DECEMBER',
];
