import { Mock } from '@interfaces/mock.interface';

export const PERIODS_PROFITABILITY_MOCK: Mock = {
  success: [{
    response: {
      data: {
        fondo_a_1_month: '-0,6',
        fondo_a_1_year: '3,1',
        fondo_a_3_year: '29,0',
        fondo_a_5_year: '41,9',
        fondo_b_1_month: '-0,5',
        fondo_b_1_year: '1,7',
        fondo_b_3_year: '25,4',
        fondo_b_5_year: '37,6',
        fondo_c_1_month: '-0,5',
        fondo_c_1_year: '1,6',
        fondo_c_3_year: '17,4',
        fondo_c_5_year: '29,6',
        fondo_d_1_month: '0,7',
        fondo_d_1_year: '1,2',
        fondo_d_3_year: '13,1',
        fondo_d_5_year: '24,1',
        fondo_e_1_month: '1,3',
        fondo_e_1_year: '0,0',
        fondo_e_3_year: '10,7',
        fondo_e_5_year: '21,4',
      }
    },
  }],
  failures: [{
    response: {
      code: '50001',
      message: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      statusCode: '500',
    },
  }, {
    response: {
      code: '40000',
      message: 'Error',
      statusCode: '400',
    },
  }, {
    response: {
      code: '40001',
      message: 'Error',
      statusCode: '401',
    },
  }],
};

export const PROFITABILITY_DATES_MOCK: Mock = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            modified_on: '2022-07-15T14:57:18+00:00',
            fechas_mes: '31 de mayo 2022 y el 30 de junio 2022',
            fechas_year: '30 de junio 2021 y el 30 de junio 2022',
            fechas_three_years: '30 de junio 2019 y el 30 de junio 2022',
            fechas_five_years: '30 de junio 2017 y el 30 de junio 2022',
            idioma: 'es'
          },
          {
            modified_on: '2022-07-15T14:57:18+00:00',
            fechas_mes: 'ht',
            fechas_year: 'ht',
            fechas_three_years: 'ht',
            fechas_five_years: 'ht',
            idioma: 'ht'
          },
        ]
      },
    },
  }],
  failures: [{
    response: {
      code: '50001',
      message: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      statusCode: '500',
    },
  }, {
    response: {
      code: '40000',
      message: 'Error',
      statusCode: '400',
    },
  }, {
    response: {
      code: '40001',
      message: 'Error',
      statusCode: '401',
    },
  }],
};

export const PROFITABILITY_DATES_FAILURE_DATA = {
  es: [
    'Sin Datos: Existe un error con el acceso al servicio o al servidor',
    'Sin Datos: Existe un error con el acceso al servicio o al servidor',
    'Sin Datos: Existe un error con el acceso al servicio o al servidor',
    'Sin Datos: Existe un error con el acceso al servicio o al servidor',
  ],
  ht: [
    'Pa gen Done: Gen yon erè ak aksè nan sèvis la oswa sèvè',
    'Pa gen Done: Gen yon erè ak aksè nan sèvis la oswa sèvè',
    'Pa gen Done: Gen yon erè ak aksè nan sèvis la oswa sèvè',
    'Pa gen Done: Gen yon erè ak aksè nan sèvis la oswa sèvè',
  ],
};
