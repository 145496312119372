import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class InvestorProfileProvider {
  private stepObs = new Subject<number>();
  private step: number;

  private resetSimulationObs = new Subject<number>();

  constructor() { }

  public setStep(step: number): void {
    this.step = step;
    this.stepObs.next(this.step);
  }

  public getStateObs(): Subject<number> {
    return this.stepObs;
  }

  public setResetSimulationValue(value: number): void {
    this.resetSimulationObs.next(value);
  }

  public getResetSimulationObs(): Subject<number> {
    return this.resetSimulationObs;
  }
}
