import { Mock } from '@interfaces/mock.interface';

export const USER_DATA_MOCK: Mock = {
  success: [{
    response: {
      address: 'Av. Apoquindo 4767',
      cellphoneNumber: '942729012',
      deceased: false,
      name: 'Valentina',
      region: 'NOVENA REGION',
      city: 'Santiago',
      lastName: 'Torres',
      commune: 'Las Condes',
      clientId: '17.475.077-5',
      keyStatus: 'not_found',
      motherLastName: 'Ramirez',
      birthdate: '1944-01-06T00:00:00',
      email: 'tereha@gmail.com',
      typeClass: 'A',
      phoneNumber: '223154786',
      alternativePhoneNumber: '55555555',
      sex: 'F',
      communeCode: '1107',
      regionCode: '1',
      regions: [
        {
          code: '1',
          description: 'PRIMERA REGION',
          communes: [
            {
              regionCode: '1',
              city: 'IQUIQUE',
              description: 'ALTO HOSPICIO',
              code: '1107'
            },
          ]
        },
      ],
      investmentOptions: {
        entertainment: true,
        family: false,
        healthyLife: true,
        other: false,
        professionalDevelopment: false,
        social: false,
        travel: false
      },
      generaliAuthorized: true,
      maritalStatus: 'C',
      hasSecurityKey: true,
      validSecurityKey: true,
      dataset: '',
      addressType: 'P',
      nationality: 'Chile'
    },
    output: {
      address: 'Av. Apoquindo 4767',
      cellphoneNumber: '942729012',
      name: 'Valentina',
      lastName: 'Torres',
      clientId: '17.475.077-5',
      securityKeyStatus: 'not_found',
      motherLastName: 'Ramirez',
      birthdate: '1944-01-06T00:00:00',
      gender: 'F',
      email: 'tereha@gmail.com',
      type: 'A',
      phoneNumber: '223154786',
      nationality: 'Chile',
      assignedExecutive: '',
      communeCode: '1107',
      regionCode: '1',
      regions: [
        {
          code: '1',
          description: 'PRIMERA REGION',
          communes: [
            {
              regionCode: '1',
              city: 'IQUIQUE',
              description: 'ALTO HOSPICIO',
              code: '1107'
            },
          ]
        },
      ],
      investmentOptions: {
        entertainment: true,
        family: false,
        healthyLife: true,
        other: false,
        professionalDevelopment: false,
        social: false,
        travel: false
      },
      generaliAuthorized: true,
      maritalStatus: 'C',
    }
  }],
  failures: [{
    response: {
      code: 50001,
      folioNumber: null,
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      serviceErrorMessage: '',
      message: 'Error',
    },
  },
  ],
};

export const USER_DATA_UPDATED_MOCK: Mock = {
  success: [{
    response: {
      address: 'Av. Apoquindo 4767',
      cellphoneNumber: '957811244',
      email: 'test@gmail.com',
      communeCode: '1107',
    },
  }],
  failures: [{
    response: {
      code: 404,
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      message: 'Error',
    },
  },
  ],
};

export const EMPLOYER_USER_DATA_MOCK: Mock = {
  success: [{
    response: {
      rut: '1-9',
      businessName: 'Municipalidad De Conchali',
      type: 'Persona Natural',
      typeId: 'PN',
      economicActivity: 'Actividades de la administración pública en general',
      economicActivityId: '841100',
      state: 'Vigente',
      modificationDate: '2020-07-07 22:09:53',
      branchOffice: 'Municipalidad De Conchali',
      branchOfficeId: '0',
      address: 'AV. INDEPENDENCIA 123',
      commune: 'Conchali',
      communeId: '13104',
      city: 'Santiago',
      region: 'Region Metropolitana',
      regionId: '13',
      phone: '123456789',
      cellphone: '123456789',
      email: 'employer@gmail.cl',
      legalRepresentative: {
        name: 'Jane Doe',
        rut: '90304372',
      },
      user: {
        name: 'John Doe',
        cellphone: '123456789',
        email: 'personal@gmail.com',
        jobTitle: 'Programador'
      }
    },
    output: {
      rut: '1-9',
      businessName: 'Municipalidad De Conchali',
      type: 'Persona Natural',
      typeId: 'PN',
      economicActivity: 'Actividades De La Administración Pública En General',
      economicActivityId: '841100',
      state: 'Vigente',
      modificationDate: '2020-07-07 22:09:53',
      branchOffice: 'Municipalidad De Conchali',
      branchOfficeId: '0',
      address: 'Av. Independencia 123',
      commune: 'Conchali',
      communeId: '13104',
      city: 'Santiago',
      region: 'Region Metropolitana',
      regionId: '13',
      phone: '123456789',
      cellphone: '123456789',
      email: 'employer@gmail.cl',
      legalRepresentative: {
        name: 'Jane Doe',
        rut: '90304372',
      },
      user: {
        name: 'John Doe',
        cellphone: '123456789',
        email: 'personal@gmail.com',
        jobTitle: 'Programador'
      }
    }
  }],
  failures: [{
    response: {
      code: 50001,
      folioNumber: null,
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      serviceErrorMessage: '',
      message: 'Error',
    },
  }],
};

export const USER_TYPE_DATA_MOCK = {
  client: USER_DATA_MOCK,
  employer: EMPLOYER_USER_DATA_MOCK,
  beneficiary: USER_DATA_MOCK,
};

export const PERSONAL_INFO_UPDATED_MOCK: Mock = {
  success: [{
    response: {
      address: 'Av. Apoquindo 4767',
      communeId: '1107',
    },
  }],
  failures: [{
    response: {
      code: 404,
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      message: 'Error',
    },
  },
  ],
};

export const USER_DATA_UPDATE_DATE_MOCK: Mock = {
  success: [{
    response: {
      updatedAt: '2021-03-30T19:10:36.695Z',
    },
  }],
  failures: [{
    response: {
      code: 404,
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      message: 'Error',
    },
  },
  ],
};
