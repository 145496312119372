import { Directive, HostListener, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[blockPaste]'
})
export class BlockPasteDirective {

  @Input('blockPaste') formControl: UntypedFormControl;

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
}
