export const PAYMENT_METHODS = {
    currentAccount: '3',
    checkView: '4',
    savings_account: '5',
    vistaAccountRut: '8'
};

export const BANK_ACCOUNTS = {
    stateBank: '12'
};

export const PAYMENT_FORM = {
    inmediate: 'I',
    a24hrs: 'A',
    deferred: 'D',
};
