/* eslint-disable @typescript-eslint/ban-types */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Image } from '@interfaces/components.interface';
import { ModalData } from '@interfaces/modal-data.interface';
import { FontService } from '@providers/font/font.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
    public title: string;
    public message: string;
    public subtitle?: string;
    public firstBtnText: string;
    public secondBtnText: string;
    private primaryCallback: Function;
    private secondaryCallback: Function;
    private closeCallback: Function;
    public showSecondaryButton = false;
    public iconName: string;
    public images: boolean;
    public closeBtn = true;
    public alertImage: Image;

    constructor(
        public dialogRef: MatDialogRef<ModalComponent>,
        public font: FontService,
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
    ) {
        Object.keys(data).forEach(key => this[key] = data[key]);
        if (data.secondaryCallback) { this.showSecondaryButton = true; }
        if (data.images) { this.images = data.images; }
    }

    public primaryAction(): void {
        this.dialogClose();
        this.dialogRef.afterClosed().subscribe(() => this.primaryCallback());
    }

    public secondaryAction(): void {
        this.dialogClose();
        this.dialogRef.afterClosed().subscribe(() => this.secondaryCallback());
    }

    public dialogClose(): void {
        this.dialogRef.close();
        if (this.closeCallback) this.closeCallback();
    }
}
