import { Validators } from '@angular/forms';
import { CAI_WITHDRAWAL_FORM_FILE_TYPE } from '@pages-content/cai-withdrawal.constant';

import { APP_ROUTES, PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN, RUT_MAX_LENGTH } from './constants';
import { FOREIGN_WITHDRAW_FORM_FILE_TYPE } from './foreign-withdraw-form.constant';
import { OVERPAYMENT_REFUND_FORM_FILE_TYPE } from './overpayment-refund.constants';
import { ALPHABETIC_PATTERN, PHONE_PATTERN } from './regex.constants';
import { FREELY_DISPOSABLE_SURPLUS_PAYMENT_FORM_FILE_TYPE } from './surplus-payment-form.constant';

export const FILE_MAX_SIZE = 3145728;

export const FILE_EXTENSIONS = ['pdf', 'jpg', 'png', 'docx', 'doc', 'bmp', 'vnd.openxmlformats-officedocument.wordprocessingml.document'];

export const DISABILITY_PENSION_TYPE = 'pension-invalidez';
export const ELDERLY_PENSION_TYPE = 'pension-vejez-normal';
export const ANTICIPATED_PENSION_TYPE = 'pension-anticipada';
export const DISABILITY_REEVALUATION_TYPE = 'reevaluacion-invalidez';
export const OFFER_REQUEST_FORM_FILE_TYPE = 'pension-solicitud-oferta';
export const MODALITY_ACCEPTANCE_OFFER_TYPE = 'modalidad-aceptacion-oferta';
export const TRANSFER_FUNDS_UNEMPLOYMENT_TYPE = 'transferencia-fondos-cuenta-cesantia';
export const SURPLUS_FUNDS_SIMULATOR_TYPE = 'simulador-fondos-excedente';
export const PENSION_ADDITIONAL_FORM_CONTENT_TYPE = 'pension-adicional';

export const WITHDRAWAL_AGREED_DEPOSITS_IPS_TYPE = 'retiros-depositos-convenidos-ips';
export const MANDATES_FORM_FILE_TYPE = 'mandatos';
export const LIFE_ANNUTY_APPLICATION_FORM_FILE_TYPE = 'solicitud-renta-vitalicia';
export const LIFE_ANNUTY_DECISION_FORM_FILE_TYPE = 'decision-renta-vitalicia';
export const CALCUATION_AND_BOND_RECOGNITION_FORM_FILE_TYPE = 'calculo-y-bono-de-reconocimiento';
export const SELECT_MODALITY_BOND_RECOGNITION_FORM_FILE_TYPE = 'seleccion-modalidad-de-bono-de-reconocimiento';

export const PENSION_FORM_TYPE = {
  disability: DISABILITY_PENSION_TYPE,
  anticipated: ANTICIPATED_PENSION_TYPE,
  elderly: ELDERLY_PENSION_TYPE,
  reevaluation: DISABILITY_REEVALUATION_TYPE,
  offerRequest: OFFER_REQUEST_FORM_FILE_TYPE,
  modalityAcceptance: MODALITY_ACCEPTANCE_OFFER_TYPE,
  transferFundsUnemployment: TRANSFER_FUNDS_UNEMPLOYMENT_TYPE,
  surplusFoundsSimulator: SURPLUS_FUNDS_SIMULATOR_TYPE,
  withdrawalAgreedDepositsIps: WITHDRAWAL_AGREED_DEPOSITS_IPS_TYPE,
  pensionAdditional: PENSION_ADDITIONAL_FORM_CONTENT_TYPE,
  pensionAnticipatedDeferredFormRequest: LIFE_ANNUTY_APPLICATION_FORM_FILE_TYPE,
  pensionAnticipatedDeferredDecisionFormRequest: LIFE_ANNUTY_DECISION_FORM_FILE_TYPE,
  bonusCalculateRequest: CALCUATION_AND_BOND_RECOGNITION_FORM_FILE_TYPE,
  bonusTransactModeRequest: SELECT_MODALITY_BOND_RECOGNITION_FORM_FILE_TYPE,
};

export const PUBLIC_SITE_FORMS_URL = {
  [MANDATES_FORM_FILE_TYPE]: 'mandatos',
};

export const PARTIAL_FORMS_WEB_CHANNEL = 'W';
export const PARTIAL_FORMS_BRANCH_OFFICE_CHANNEL = 'S';

export const CHANNEL_LABELS = {
  [PARTIAL_FORMS_BRANCH_OFFICE_CHANNEL]: 'Sucursal',
  [PARTIAL_FORMS_WEB_CHANNEL]: 'Sitio web'
};

export const FORMS_PATH = {
  [FREELY_DISPOSABLE_SURPLUS_PAYMENT_FORM_FILE_TYPE]: APP_ROUTES.client.surplusPaymentForm,
  [FOREIGN_WITHDRAW_FORM_FILE_TYPE]: APP_ROUTES.client.foreignWithdrawRequest,
  [CAI_WITHDRAWAL_FORM_FILE_TYPE]: APP_ROUTES.client.caiWithdrawal,
  [OVERPAYMENT_REFUND_FORM_FILE_TYPE]: APP_ROUTES.overpaymentRefundRequest,
};

export enum filesActionType {
  insert,
  update,
  delete,
  none,
}

export const MEDICAL_REPORT_FILE_KEY = 'medicalReport';

export const MAX_LENGTH = 50;
export const SPAN_LENGTH = 10;


export const NAMES_MAX_LENGTH = 30;

/************** VALIDATORS **************/
export const VALIDATORS = {
  NAME: [
    Validators.required,
    Validators.maxLength(NAMES_MAX_LENGTH),
    Validators.pattern(ALPHABETIC_PATTERN),
  ],
  RUT: [
    Validators.required,
    Validators.maxLength(RUT_MAX_LENGTH),
  ],
  PHONE: [
    Validators.required,
    Validators.minLength(PHONE_NUMBER_LENGTH),
    Validators.maxLength(PHONE_NUMBER_LENGTH),
    Validators.min(PHONE_NUMBER_MIN),
    Validators.max(PHONE_NUMBER_MAX),
    Validators.pattern(PHONE_PATTERN),
  ],
};
