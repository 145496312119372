import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { TraceabilityRequestDto } from '@interfaces/traceability.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TraceabiliyService {

  constructor(private http: HttpInterceptor) { }

  public start(request: TraceabilityRequestDto): Observable<any> {
    const url = `${environment.clientsUrl}traceability/start`;
    return this.http.post(url, request, null);
  }

  public save(request: TraceabilityRequestDto): Observable<any> {
    const url = `${environment.clientsUrl}traceability/save`;
    return this.http.post(url, request, null);
  }

}
