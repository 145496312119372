import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { FacialBiometricValidationData } from '@interfaces/facialvalidation.interface';
import { IdValidationRequest, ScanReference } from '@interfaces/idvalidation.interface';
import { Util } from '@util';
import { Observable } from 'rxjs';
import { DOCUMENT_VALIDATION_MOCKUP, FACIAL_SUCCESS_CONFIRMATION_MOCKUP, FACIAL_VALIDATION_MOCKUP, TROLLEY_AUTHORIZE_MOCKUP } from './mocks/facial-biometric.mock';

@Injectable({
  providedIn: 'root'
})
export class FacialBiometricService {

  private baseUrl = `${environment.facialValidationBaseUrl}`;
  constructor(
    private http: HttpInterceptor,
    private utils: Util
  ) { }

  public documentValidation(rut: string, data: IdValidationRequest): Observable<ScanReference> {
    const cleanRut = this.utils.rutClean(rut);
    const url = `${this.baseUrl}identities/customers/${cleanRut}/facial-biometric/documents/validation`;
    return this.http.post(url, data, DOCUMENT_VALIDATION_MOCKUP);
  }

  public facialValidation(rut: string, bioData: FacialBiometricValidationData): Observable<any> {
    const cleanRut = this.utils.rutClean(rut);
    const url = `${this.baseUrl}identities/customers/${cleanRut}/facial-biometric/validation`;
    return this.http.post(url, bioData, FACIAL_VALIDATION_MOCKUP);
  }

  public successConfirmation(ticketId: string): Observable<{ success: boolean; }> {
    const url = `${environment.clientsUrl}facial-biometric/confirm-success/${ticketId}`;
    return this.http.get(url, FACIAL_SUCCESS_CONFIRMATION_MOCKUP);
  }

  public trolleyAuthorize(data: any): Observable<string> {
    const url = environment.facialAuthorizeTrolley;
    return this.http.post(url, data, TROLLEY_AUTHORIZE_MOCKUP);
  }

}
