import { RadioButtonInputListElement } from '@interfaces/radio-button-input-list-element.interface';

export type PensionType =
  | 'anticipated'
  | 'elderly'
  | 'disability'
  | 'anticipated-deferred';

export const OTHER_PAYMENT_METHODS_IDS = ['40', '41', '4'];
export const OTHER_PAYMENT_METHODS_IDS_EMPLOYER = ['4'];

export const FORM_PDF_KEY = 'formPdf';
export const FORM_PDF_NAME = 'Certificado.pdf';

const pageKey = 'REQUESTS.PENSION_FORM';
const toastKey = `${pageKey}.TOAST`;
const personalBackgroundKey = `${pageKey}.STEPS.PERSONAL_BACKGROUND`;
const employerBackgroundKey = `${pageKey}.STEPS.EMPLOYER_BACKGROUND`;
const othersKey = `${pageKey}.STEPS.OTHERS`;
const declarationsKey = `${pageKey}.STEPS.DECLARATIONS`;
const quotesKey = `${pageKey}.STEPS.QUOTES`;
const transferKey = `${pageKey}.STEPS.TRANSFER`;
const employmentBackgroundKey = `${pageKey}.STEPS.EMPLOYMENT_BACKGROUND`;
const medicalBackgroundKey = `${pageKey}.STEPS.MEDICAL_BACKGROUND`;
const medicalBackgroundModalKey = `${medicalBackgroundKey}.MEDICAL_REPORT_MODAL`;

const voucherKey = `${pageKey}.STEPS.VOUCHER`;
const savedModal = 'REQUESTS.MODALS.SAVED';
const savedAnticipatedDeferredModal = 'REQUESTS.MODALS.ANTICIPATED_DEFERRED_SAVED';
const disabilityKey = `${othersKey}.DISABILITY`;
const workerTypeKey = `${othersKey}.WORKER_TYPE`;
const disclaimerKey = 'GENERIC_DISCLAIMER.PERSONAL_BACKGROUND';

export const PENSION_TYPES = [
  {
    id: 'normal',
    description: `${pageKey}.TYPES.ELDERLY.TEXT`,
  },
  {
    id: 'anticipated',
    description: `${pageKey}.TYPES.ANTICIPATED.TEXT`,
  },
  {
    id: 'disability',
    description: `${pageKey}.TYPES.DISABILITY.TEXT`,
  },
];

export const DISABILITY_PENSION_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved',
};

export const ANTICIPATED_DEFERRED_SAVED = {
  title: `${savedAnticipatedDeferredModal}.TITLE`,
  description: `${savedAnticipatedDeferredModal}.DESCRIPTION`,
  firstBtnText: `${savedAnticipatedDeferredModal}.BUTTON`,
  iconName: 'i-saved',
};

export const STATEMENT_PENSIONARY_DECLARATIONS_HEADLINES = [
  {
    id: '1',
    description: `${declarationsKey}.FORM_SECTION.HEADLINES.AFFILIATE`,
  },
  {
    id: '2',
    description: `${declarationsKey}.FORM_SECTION.HEADLINES.MANDATARY`,
  },
];

export const WITHOUT_EDUCATION_CODE = 'A';

export const MALE_GENDER = 'M';

export const PENSION_AGE_MONTHS = {
  men: 64 * 12 + 6,
  women: 59 * 12 + 6,
};

export const GENERIC_CONTENT = {
  title: `${othersKey}.NEEDS.TITLE`,
  description: `${othersKey}.NEEDS.DESCRIPTION`,
  options: {
    yes: `${othersKey}.OPTIONS.YES`,
    no: `${othersKey}.OPTIONS.NO`,
  },
  errors: {
    required: `${othersKey}.ERRORS.REQUIRED`,
  },
};

export const MARITAL_STATUS_DESCRIPTION = {
  A: `${personalBackgroundKey}.FORM.MARITAL_STATUS.OPTIONS.NOT_DEFINED`,
  S: `${personalBackgroundKey}.FORM.MARITAL_STATUS.OPTIONS.SINGLE`,
  C: `${personalBackgroundKey}.FORM.MARITAL_STATUS.OPTIONS.MARRIED`,
  V: `${personalBackgroundKey}.FORM.MARITAL_STATUS.OPTIONS.WIDOWED`,
  D: `${personalBackgroundKey}.FORM.MARITAL_STATUS.OPTIONS.DIVORCED`,
  P: `${personalBackgroundKey}.FORM.MARITAL_STATUS.OPTIONS.CIVIL_UNION`,
};

export const PENSION_FORM_CONTENT = {
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`,
  },
  types: {
    elderly: {
      breadcrumb: `${pageKey}.TYPES.ELDERLY.BREADCRUMB`,
      title: `${pageKey}.TYPES.ELDERLY.TITLE`,
      description: `${pageKey}.TYPES.ELDERLY.DESCRIPTION`,
    },
    anticipated: {
      breadcrumb: `${pageKey}.TYPES.ANTICIPATED.BREADCRUMB`,
      title: `${pageKey}.TYPES.ANTICIPATED.TITLE`,
      description: `${pageKey}.TYPES.ANTICIPATED.DESCRIPTION`,
    },
    disability: {
      breadcrumb: `${pageKey}.TYPES.DISABILITY.BREADCRUMB`,
      title: `${pageKey}.TYPES.DISABILITY.TITLE`,
      description: `${pageKey}.TYPES.DISABILITY.DESCRIPTION`,
    },
    'anticipated-deferred': {
      breadcrumb: `${pageKey}.TYPES.ANTICIPATED_DEFERRED.BREADCRUMB`,
      title: `${pageKey}.TYPES.ANTICIPATED_DEFERRED.TITLE`,
      description: `${pageKey}.TYPES.ANTICIPATED_DEFERRED.DESCRIPTION`,
    },
    'anticipated-deferred-decision': {
      breadcrumb: `${pageKey}.TYPES.ANTICIPATED_DEFERRED_DECISION.BREADCRUMB`,
      title: `${pageKey}.TYPES.ANTICIPATED_DEFERRED_DECISION.TITLE`,
      description: `${pageKey}.TYPES.ANTICIPATED_DEFERRED_DECISION.DESCRIPTION`,
    },
  },

  steps: {
    anticipatedDeferredBacgrkound: {
      label: `${personalBackgroundKey}.LABEL`,
      title: `${personalBackgroundKey}.TITLE`,
      disclaimer: {
        message: `${disclaimerKey}.MESSAGE`,
        redirectionText: `${disclaimerKey}.REDIRECTION_TEXT`,
      },
      buttons: {
        next: `${personalBackgroundKey}.BUTTONS.NEXT`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
        addBeneficiary: `${declarationsKey}.BUTTONS.ADD_BENEFICIARY`,
      },
      statementSection: {
        title: `${declarationsKey}.STATEMENT_SECTION.TITLE`,
        description: `${declarationsKey}.STATEMENT_SECTION.DESCRIPTION`,
      },
      form: {
        names: `${personalBackgroundKey}.FORM.NAMES`,
        lastName: `${personalBackgroundKey}.FORM.LAST_NAME`,
        motherLastName: `${personalBackgroundKey}.FORM.MOTHER_LAST_NAME`,
        pensionType: {
          label: `${personalBackgroundKey}.FORM.PENSION_TYPE`,
          options: PENSION_TYPES,
        },
        rut: `${personalBackgroundKey}.FORM.RUT`,
        maritalStatus: `${personalBackgroundKey}.FORM.MARITAL_STATUS.TITLE`,
        address: `${personalBackgroundKey}.FORM.ADDRESS`,
        birthdate: `${personalBackgroundKey}.FORM.BIRTHDATE`,
        email: `${personalBackgroundKey}.FORM.EMAIL`,
        phone: `${personalBackgroundKey}.FORM.PHONE`,
        commune: `${employerBackgroundKey}.DATA_SECTION.COMMUNE`,
        region: `${employerBackgroundKey}.DATA_SECTION.REGION`,
        jobTitle: `${personalBackgroundKey}.FORM.JOB_TITLE`,
        errors: {
          required: `${personalBackgroundKey}.FORM.ERRORS.REQUIRED`,
          invalidEmail: `${personalBackgroundKey}.FORM.ERRORS.INVALID_EMAIL`,
          invalidDomain: `${personalBackgroundKey}.FORM.ERRORS.INVALID_DOMAIN`,
          invalidRut: `${personalBackgroundKey}.FORM.ERRORS.INVALID_RUT`,
          invalidAddress: `${personalBackgroundKey}.FORM.ERRORS.INVALID_ADDRESS`,
        },
      },
    },
    anticipatedDeferredDecisionBacgrkound: {
      label: `${personalBackgroundKey}.LABEL`,
      title: `${personalBackgroundKey}.TITLE`,
      disclaimer: {
        message: `${disclaimerKey}.MESSAGE`,
        redirectionText: `${disclaimerKey}.REDIRECTION_TEXT`,
      },
      buttons: {
        next: `${personalBackgroundKey}.BUTTONS.NEXT`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
        addBeneficiary: `${declarationsKey}.BUTTONS.ADD_BENEFICIARY`,
      },
      statementSection: {
        title: `${quotesKey}.TITLE`,
        description: `${quotesKey}.DESCRIPTION`,
        disclaimer: `${quotesKey}.DISCLAIMER`,
        attachmentFiles: [
          {
            order: 0,
            buttonLabel: `${quotesKey}.ATTACH_FILES_CONTENT.TITLE`,
            fileIndex: 'formFile',
            required: true,
            keyList: {
              formFile: '',
              idFile: ''
            }
          },
        ]
      },
      form: {
        names: `${personalBackgroundKey}.FORM.NAMES`,
        lastName: `${personalBackgroundKey}.FORM.LAST_NAME`,
        motherLastName: `${personalBackgroundKey}.FORM.MOTHER_LAST_NAME`,
        pensionType: {
          label: `${personalBackgroundKey}.FORM.PENSION_TYPE`,
          options: PENSION_TYPES,
        },
        rut: `${personalBackgroundKey}.FORM.RUT`,
        maritalStatus: `${personalBackgroundKey}.FORM.MARITAL_STATUS.TITLE`,
        address: `${personalBackgroundKey}.FORM.ADDRESS`,
        birthdate: `${personalBackgroundKey}.FORM.BIRTHDATE`,
        email: `${personalBackgroundKey}.FORM.EMAIL`,
        phone: `${personalBackgroundKey}.FORM.PHONE`,
        commune: `${employerBackgroundKey}.DATA_SECTION.COMMUNE`,
        region: `${employerBackgroundKey}.DATA_SECTION.REGION`,
        jobTitle: `${personalBackgroundKey}.FORM.JOB_TITLE`,
        errors: {
          required: `${personalBackgroundKey}.FORM.ERRORS.REQUIRED`,
          invalidEmail: `${personalBackgroundKey}.FORM.ERRORS.INVALID_EMAIL`,
          invalidDomain: `${personalBackgroundKey}.FORM.ERRORS.INVALID_DOMAIN`,
          invalidRut: `${personalBackgroundKey}.FORM.ERRORS.INVALID_RUT`,
          invalidAddress: `${personalBackgroundKey}.FORM.ERRORS.INVALID_ADDRESS`,
        },
      },
    },
    personalBackground: {
      label: `${personalBackgroundKey}.LABEL`,
      title: `${personalBackgroundKey}.TITLE`,
      disclaimer: {
        message: `${disclaimerKey}.MESSAGE`,
        redirectionText: `${disclaimerKey}.REDIRECTION_TEXT`,
      },
      buttons: {
        next: `${personalBackgroundKey}.BUTTONS.NEXT`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      form: {
        names: `${personalBackgroundKey}.FORM.NAMES`,
        lastNames: `${personalBackgroundKey}.FORM.LAST_NAMES`,
        rut: `${personalBackgroundKey}.FORM.RUT`,
        gender: {
          label: `${personalBackgroundKey}.FORM.GENDER.LABEL`,
          male: `${personalBackgroundKey}.FORM.GENDER.MALE`,
          female: `${personalBackgroundKey}.FORM.GENDER.FEMALE`,
        },
        maritalStatus: `${personalBackgroundKey}.FORM.MARITAL_STATUS.TITLE`,
        address: `${personalBackgroundKey}.FORM.ADDRESS`,
        birthdate: `${personalBackgroundKey}.FORM.BIRTHDATE`,
        email: `${personalBackgroundKey}.FORM.EMAIL`,
        phone: `${personalBackgroundKey}.FORM.PHONE`,
        jobTitle: `${personalBackgroundKey}.FORM.JOB_TITLE`,
        healthInstitution: `${personalBackgroundKey}.FORM.HEALTH_INSTITUTION`,
        errors: {
          required: `${personalBackgroundKey}.FORM.ERRORS.REQUIRED`,
          invalidEmail: `${personalBackgroundKey}.FORM.ERRORS.INVALID_EMAIL`,
          invalidDomain: `${personalBackgroundKey}.FORM.ERRORS.INVALID_DOMAIN`,
          invalidRut: `${personalBackgroundKey}.FORM.ERRORS.INVALID_RUT`,
          invalidAddress: `${personalBackgroundKey}.FORM.ERRORS.INVALID_ADDRESS`,
        },
      },
    },
    employerBackground: {
      label: `${employerBackgroundKey}.LABEL`,
      title: `${employerBackgroundKey}.TITLE`,
      description: `${employerBackgroundKey}.DESCRIPTION`,
      buttons: {
        next: `${personalBackgroundKey}.BUTTONS.NEXT`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      form: {
        rut: {
          label: `${employerBackgroundKey}.FORM.RUT.LABEL`,
          errors: {
            invalid: `${employerBackgroundKey}.FORM.RUT.ERRORS.INVALID`,
          },
        },
        buttons: {
          confirmation: `${employerBackgroundKey}.FORM.BUTTONS.CONFIRMATION`,
          searchEmployer: `${employerBackgroundKey}.FORM.BUTTONS.SEARCH_EMPLOYER`,
          back: `${employerBackgroundKey}.FORM.BUTTONS.BACK`,
        },
      },
      dataSection: {
        name: `${employerBackgroundKey}.DATA_SECTION.NAME`,
        address: `${employerBackgroundKey}.DATA_SECTION.ADDRESS`,
        commune: `${employerBackgroundKey}.DATA_SECTION.COMMUNE`,
        city: `${employerBackgroundKey}.DATA_SECTION.CITY`,
        region: `${employerBackgroundKey}.DATA_SECTION.REGION`,
        phone: `${employerBackgroundKey}.DATA_SECTION.PHONE`,
        email: `${employerBackgroundKey}.DATA_SECTION.EMAIL`,
        economicActivity: `${employerBackgroundKey}.DATA_SECTION.ECONOMIC_ACTIVITY`,
      },
    },
    others: {
      label: `${othersKey}.LABEL`,
      buttons: {
        next: `${othersKey}.BUTTONS.NEXT`,
        back: `${othersKey}.BUTTONS.BACK`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      disability: {
        title: `${disabilityKey}.TITLE`,
        disabilities: [
          { code: '1', text: `${disabilityKey}.DISABILITIES.1` },
          { code: '2', text: `${disabilityKey}.DISABILITIES.2` },
          { code: '3', text: `${disabilityKey}.DISABILITIES.3` },
          { code: '4', text: `${disabilityKey}.DISABILITIES.4` },
        ],
        form: {
          disabilityCauseReport: {
            claimNumber: `${disabilityKey}.FORM.DISABILITY_CAUSE_REPORT.CLAIM_NUMBER`,
            date: `${disabilityKey}.FORM.DISABILITY_CAUSE_REPORT.DATE`,
            policeUnit: `${disabilityKey}.FORM.DISABILITY_CAUSE_REPORT.POLICE_UNIT`,
          },
        },
        validations: {
          required: `${disabilityKey}.VALIDATIONS.REQUIRED`,
        },
        disclaimer: `${disabilityKey}.DISCLAIMER`,
      },
      workerType: {
        title: `${workerTypeKey}.TITLE`,
        description: `${workerTypeKey}.DESCRIPTION`,
        label: `${workerTypeKey}.LABEL`,
        employeeTypes: [
          { id: 'A', description: `${workerTypeKey}.EMPLOYEE_TYPES.A` },
          { id: 'B', description: `${workerTypeKey}.EMPLOYEE_TYPES.B` },
          { id: 'C', description: `${workerTypeKey}.EMPLOYEE_TYPES.C` },
          { id: 'D', description: `${workerTypeKey}.EMPLOYEE_TYPES.D` },
        ],
      },
      needs: {
        content: GENERIC_CONTENT,
        options: [
          {
            code: 0,
            text: `${othersKey}.NEEDS.OPTIONS.0.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.5.TITLE`,
                definition: `FORM_DEFINITIONS.5.TEXT`,
              },
            ],
          },
          {
            code: 1,
            text: `${othersKey}.NEEDS.OPTIONS.1.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.14.TITLE`,
                definition: `FORM_DEFINITIONS.14.TEXT`,
              },
            ],
          },
          {
            code: 2,
            text: `${othersKey}.NEEDS.OPTIONS.2.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.15.TITLE`,
                definition: `FORM_DEFINITIONS.15.TEXT`,
              },
            ],
          },
          {
            code: 3,
            text: `${othersKey}.NEEDS.OPTIONS.3.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.10.TITLE`,
                definition: `FORM_DEFINITIONS.10.TEXT`,
              },
            ],
          },
          {
            code: 4,
            text: `${othersKey}.NEEDS.OPTIONS.4.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.16.TITLE`,
                definition: `FORM_DEFINITIONS.16.TEXT`,
              },
            ],
          },
          {
            code: 5,
            text: `${othersKey}.NEEDS.OPTIONS.5.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.17.TITLE`,
                definition: `FORM_DEFINITIONS.17.TEXT`,
              },
            ],
          },
          {
            code: 6,
            text: `${othersKey}.NEEDS.OPTIONS.6.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.18.TITLE`,
                definition: `FORM_DEFINITIONS.18.TEXT`,
              },
            ],
          },
          {
            code: 7,
            text: `${othersKey}.NEEDS.OPTIONS.7.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.19.TITLE`,
                definition: `FORM_DEFINITIONS.19.TEXT`,
              },
            ],
          },
          {
            code: 8,
            text: `${othersKey}.NEEDS.OPTIONS.8.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.23.TITLE`,
                definition: `FORM_DEFINITIONS.23.TEXT`,
              },
              {
                title: `FORM_DEFINITIONS.22.TITLE`,
                definition: `FORM_DEFINITIONS.22.TEXT`,
              },
            ],
          },
          {
            code: 9,
            text: `${othersKey}.NEEDS.OPTIONS.9.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.24.TITLE`,
                definition: `FORM_DEFINITIONS.24.TEXT`,
              },
              {
                title: `FORM_DEFINITIONS.23.TITLE`,
                definition: `FORM_DEFINITIONS.23.TEXT`,
              },
            ],
          },
          {
            code: 10,
            text: `${othersKey}.NEEDS.OPTIONS.10.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.25.TITLE`,
                definition: `FORM_DEFINITIONS.25.TEXT`,
              },
            ],
          },
          { code: 11, text: `${othersKey}.NEEDS.OPTIONS.11.TEXT` },
          {
            code: 12,
            text: `${othersKey}.NEEDS.OPTIONS.12.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.20.TITLE`,
                definition: `FORM_DEFINITIONS.20.TEXT`,
              },
            ],
          },
          {
            code: 13,
            text: `${othersKey}.NEEDS.OPTIONS.13.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.21.TITLE`,
                definition: `FORM_DEFINITIONS.21.TEXT`,
              },
            ],
          },
        ] as Array<RadioButtonInputListElement>,
      },
      anticipatedNeeds: {
        content: GENERIC_CONTENT,
        options: [
          {
            code: 0,
            text: `${othersKey}.NEEDS.OPTIONS.0.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.5.TITLE`,
                definition: `FORM_DEFINITIONS.5.TEXT`,
              },
            ],
          },
          {
            code: 1,
            text: `${othersKey}.NEEDS.OPTIONS.1.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.14.TITLE`,
                definition: `FORM_DEFINITIONS.14.TEXT`,
              },
            ],
          },
          {
            code: 2,
            text: `${othersKey}.NEEDS.OPTIONS.3.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.10.TITLE`,
                definition: `FORM_DEFINITIONS.10.TEXT`,
              },
            ],
          },
          {
            code: 3,
            text: `${othersKey}.NEEDS.OPTIONS.4.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.16.TITLE`,
                definition: `FORM_DEFINITIONS.16.TEXT`,
              },
            ],
          },
          {
            code: 4,
            text: `${othersKey}.NEEDS.OPTIONS.5.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.17.TITLE`,
                definition: `FORM_DEFINITIONS.17.TEXT`,
              },
            ],
          },
          {
            code: 5,
            text: `${othersKey}.NEEDS.OPTIONS.7.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.19.TITLE`,
                definition: `FORM_DEFINITIONS.19.TEXT`,
              },
            ],
          },
          {
            code: 6,
            text: `${othersKey}.NEEDS.OPTIONS.8.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.23.TITLE`,
                definition: `FORM_DEFINITIONS.23.TEXT`,
              },
              {
                title: `FORM_DEFINITIONS.22.TITLE`,
                definition: `FORM_DEFINITIONS.22.TEXT`,
              },
            ],
          },
          {
            code: 7,
            text: `${othersKey}.NEEDS.OPTIONS.9.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.24.TITLE`,
                definition: `FORM_DEFINITIONS.24.TEXT`,
              },
              {
                title: `FORM_DEFINITIONS.23.TITLE`,
                definition: `FORM_DEFINITIONS.23.TEXT`,
              },
            ],
          },
          {
            code: 8,
            text: `${othersKey}.NEEDS.OPTIONS.10.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.25.TITLE`,
                definition: `FORM_DEFINITIONS.25.TEXT`,
              },
            ],
          },
          { code: 9, text: `${othersKey}.NEEDS.OPTIONS.11.TEXT` },
          {
            code: 10,
            text: `${othersKey}.NEEDS.OPTIONS.12.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.20.TITLE`,
                definition: `FORM_DEFINITIONS.20.TEXT`,
              },
            ],
          },
          {
            code: 11,
            text: `${othersKey}.NEEDS.OPTIONS.13.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.21.TITLE`,
                definition: `FORM_DEFINITIONS.21.TEXT`,
              },
            ],
          },
        ] as Array<RadioButtonInputListElement>,
      },
      needsDisability: {
        content: GENERIC_CONTENT,
        options: [
          {
            code: 0,
            text: `${othersKey}.NEEDS.OPTIONS.0.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.5.TITLE`,
                definition: `FORM_DEFINITIONS.5.TEXT`,
              },
            ],
          },
          {
            code: 1,
            text: `${othersKey}.NEEDS.OPTIONS.1.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.14.TITLE`,
                definition: `FORM_DEFINITIONS.14.TEXT`,
              },
            ],
          },
          {
            code: 2,
            text: `${othersKey}.NEEDS.OPTIONS.3.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.10.TITLE`,
                definition: `FORM_DEFINITIONS.10.TEXT`,
              },
            ],
          },
          {
            code: 3,
            text: `${othersKey}.NEEDS.OPTIONS.5.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.17.TITLE`,
                definition: `FORM_DEFINITIONS.17.TEXT`,
              },
            ],
          },
          {
            code: 4,
            text: `${othersKey}.NEEDS.OPTIONS.6.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.18.TITLE`,
                definition: `FORM_DEFINITIONS.18.TEXT`,
              },
            ],
          },
          {
            code: 5,
            text: `${othersKey}.NEEDS.OPTIONS.7.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.19.TITLE`,
                definition: `FORM_DEFINITIONS.19.TEXT`,
              },
            ],
          },
          {
            code: 6,
            text: `${othersKey}.NEEDS.OPTIONS.8.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.23.TITLE`,
                definition: `FORM_DEFINITIONS.23.TEXT`,
              },
              {
                title: `FORM_DEFINITIONS.22.TITLE`,
                definition: `FORM_DEFINITIONS.22.TEXT`,
              },
            ],
          },
          {
            code: 7,
            text: `${othersKey}.NEEDS.OPTIONS.9.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.24.TITLE`,
                definition: `FORM_DEFINITIONS.24.TEXT`,
              },
              {
                title: `FORM_DEFINITIONS.23.TITLE`,
                definition: `FORM_DEFINITIONS.23.TEXT`,
              },
            ],
          },
          {
            code: 8,
            text: `${othersKey}.NEEDS.OPTIONS.10.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.25.TITLE`,
                definition: `FORM_DEFINITIONS.25.TEXT`,
              },
            ],
          },
          { code: 9, text: `${othersKey}.NEEDS.OPTIONS.11.TEXT` },
          {
            code: 10,
            text: `${othersKey}.NEEDS.OPTIONS.12.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.20.TITLE`,
                definition: `FORM_DEFINITIONS.20.TEXT`,
              },
            ],
          },
          {
            code: 11,
            text: `${othersKey}.NEEDS.OPTIONS.13.TEXT`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.21.TITLE`,
                definition: `FORM_DEFINITIONS.21.TEXT`,
              },
            ],
          },
        ] as Array<RadioButtonInputListElement>,
      },
      declares: {
        content: {
          title: `${othersKey}.DECLARES.TITLE`,
          description: `${othersKey}.DECLARES.DESCRIPTION`,
          options: {
            yes: `${othersKey}.OPTIONS.YES`,
            no: `${othersKey}.OPTIONS.NO`,
          },
          errors: {
            required: `${othersKey}.ERRORS.REQUIRED`,
          },
        },
        options: [
          {
            code: 0,
            text: `${othersKey}.DECLARES.OPTIONS.0.TEXT`,
            detail: `${othersKey}.DECLARES.OPTIONS.0.DETAIL`,
            definitions: [
              {
                title: `FORM_DEFINITIONS.22.TITLE`,
                definition: `FORM_DEFINITIONS.22.TEXT`,
              },
            ],
          },
          {
            code: 1,
            text: `${othersKey}.DECLARES.OPTIONS.1.TEXT`,
            detail: `${othersKey}.DECLARES.OPTIONS.1.DETAIL`,
            inputType: `select`,
          },
          {
            code: 2,
            text: `${othersKey}.DECLARES.OPTIONS.2.TEXT`,
            detail: `${othersKey}.DECLARES.OPTIONS.2.DETAIL`,
            inputType: `select`,
          },
        ] as Array<RadioButtonInputListElement>,
      },
    },
    declarations: {
      label: `${declarationsKey}.LABEL`,
      statementSection: {
        title: `${declarationsKey}.STATEMENT_SECTION.TITLE`,
        description: `${declarationsKey}.STATEMENT_SECTION.DESCRIPTION`,
      },
      paymentMethods: {
        formSection: {
          title: `${declarationsKey}.FORM_SECTION.TITLE`,
          transfer: `${declarationsKey}.FORM_SECTION.TRANSFER`,
          cash: `${declarationsKey}.FORM_SECTION.CASH`,
          bank: {
            field: `${declarationsKey}.FORM_SECTION.BANK.FIELD`,
            options: `${declarationsKey}.FORM_SECTION.BANK.OPTIONS`,
          },
          account: {
            field: `${declarationsKey}.FORM_SECTION.ACCOUNT.FIELD`,
            options: `${declarationsKey}.FORM_SECTION.ACCOUNT.OPTIONS`,
          },
          other: {
            options: `${declarationsKey}.FORM_SECTION.OTHER.OPTIONS`,
          },
        },
        buttons: {
          submit: `${declarationsKey}.BUTTONS.SUBMIT`,
          back: `${declarationsKey}.BUTTONS.BACK`,
        },
        errors: {
          required: `${declarationsKey}.ERRORS.REQUIRED`,
          digitalAccount: `${declarationsKey}.ERRORS.DIGITAL_ACCOUNT`,
        },
        digitalAccountDisclaimer: `${declarationsKey}.DIGITAL_ACCOUNT_DISCLAIMER`,
      },
      buttons: {
        addBeneficiary: `${declarationsKey}.BUTTONS.ADD_BENEFICIARY`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
    },
    transfer: {
      label: `${transferKey}.LABEL`,
      title: `${transferKey}.TITLE`,
      question: `${transferKey}.QUESTION`,
      options: {
        yes: `${transferKey}.OPTIONS.YES`,
        no: `${transferKey}.OPTIONS.NO`,
      },
      disclaimerTitle: `${transferKey}.DISCLAIMER_TITLE`,
      disclaimerList: {
        saving: `${transferKey}.DISCLAIMER_LIST.SAVING`,
        padlock: `${transferKey}.DISCLAIMER_LIST.PADLOCK`,
        profitability: `${transferKey}.DISCLAIMER_LIST.PROFITABILITY`,
        waitTime: `${transferKey}.DISCLAIMER_LIST.WAIT_TIME`,
      },
      declaration: `${transferKey}.DECLARATION`,
      buttons: {
        submit: `${transferKey}.BUTTONS.SUBMIT`,
        back: `${transferKey}.BUTTONS.BACK`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      table: {
        headers: {
          account: `${transferKey}.TABLE.HEADERS.ACCOUNT`,
          fund: `${transferKey}.TABLE.HEADERS.FUND`,
          quotations: `${transferKey}.TABLE.HEADERS.QUOTATIONS`,
          amount: `${transferKey}.TABLE.HEADERS.AMOUNT`,
        },
        fields: {
          fundsPrefix: `${transferKey}.TABLE.FIELDS.FUNDS_PREFIX`,
        },
      },
    },
    employmentBackground: {
      label: `${employmentBackgroundKey}.LABEL`,
      form: {
        education: {
          label: `${employmentBackgroundKey}.FORM.EDUCATION.LABEL`,
          options: [
            {
              code: WITHOUT_EDUCATION_CODE,
              text: `${employmentBackgroundKey}.FORM.EDUCATION.OPTIONS.A.TEXT`,
            },
            {
              code: 'B',
              text: `${employmentBackgroundKey}.FORM.EDUCATION.OPTIONS.B.TEXT`,
            },
            {
              code: 'M',
              text: `${employmentBackgroundKey}.FORM.EDUCATION.OPTIONS.M.TEXT`,
            },
            {
              code: 'S',
              text: `${employmentBackgroundKey}.FORM.EDUCATION.OPTIONS.S.TEXT`,
            },
          ] as Array<RadioButtonInputListElement>,
        },
        educationDetail: {
          label: `${employmentBackgroundKey}.FORM.EDUCATION_DETAIL.LABEL`,
          options: {
            complete: `${employmentBackgroundKey}.FORM.EDUCATION_DETAIL.OPTIONS.COMPLETE`,
            incomplete: `${employmentBackgroundKey}.FORM.EDUCATION_DETAIL.OPTIONS.INCOMPLETE`,
          },
        },
        job: `${employmentBackgroundKey}.FORM.JOB`,
        sector: {
          label: `${employmentBackgroundKey}.FORM.SECTOR.LABEL`,
          options: [
            {
              code: 'A',
              text: `${employmentBackgroundKey}.FORM.SECTOR.OPTIONS.A.TEXT`,
            },
            {
              code: 'B',
              text: `${employmentBackgroundKey}.FORM.SECTOR.OPTIONS.B.TEXT`,
            },
            {
              code: 'C',
              text: `${employmentBackgroundKey}.FORM.SECTOR.OPTIONS.C.TEXT`,
            },
            {
              code: 'D',
              text: `${employmentBackgroundKey}.FORM.SECTOR.OPTIONS.D.TEXT`,
            },
            {
              code: 'E',
              text: `${employmentBackgroundKey}.FORM.SECTOR.OPTIONS.E.TEXT`,
            },
            {
              code: 'F',
              text: `${employmentBackgroundKey}.FORM.SECTOR.OPTIONS.F.TEXT`,
            },
          ] as Array<RadioButtonInputListElement>,
        },
        currentLaborSituation: {
          label: `${employmentBackgroundKey}.FORM.CURRENT_LABOR_SITUATION.LABEL`,
          options: [
            {
              code: 'E',
              text: `${employmentBackgroundKey}.FORM.CURRENT_LABOR_SITUATION.OPTIONS.E.TEXT`,
            },
            {
              code: 'U',
              text: `${employmentBackgroundKey}.FORM.CURRENT_LABOR_SITUATION.OPTIONS.U.TEXT`,
            },
            {
              code: 'M',
              text: `${employmentBackgroundKey}.FORM.CURRENT_LABOR_SITUATION.OPTIONS.M.TEXT`,
            },
          ] as Array<RadioButtonInputListElement>,
        },
        currentLaborStartDate: `${employmentBackgroundKey}.FORM.CURRENT_LABOR_START_DATE`,
        currentLaborEndDate: `${employmentBackgroundKey}.FORM.CURRENT_LABOR_END_DATE`,
        subsidyPayingEntity: `${employmentBackgroundKey}.FORM.SUBSIDY_PAYING_ENTITY`,
        providentBox: `${employmentBackgroundKey}.FORM.PROVIDENT_BOX`,
        mutual: `${employmentBackgroundKey}.FORM.MUTUAL`,
        accidentOrDisease: `${employmentBackgroundKey}.FORM.ACCIDENT_OR_DISEASE`,
        accidentDate: `${employmentBackgroundKey}.FORM.ACCIDENT_DATE`,
        anyCompensation: `${employmentBackgroundKey}.FORM.ANY_COMPENSATION`,
        anyPension: `${employmentBackgroundKey}.FORM.ANY_PENSION`,
        pensionPayingEntity: `${employmentBackgroundKey}.FORM.PENSION_PAYING_ENTITY`,
        errors: {
          required: `${employmentBackgroundKey}.FORM.ERRORS.REQUIRED`,
        },
      },
      sections: {
        education: `${employmentBackgroundKey}.SECTIONS.EDUCATION`,
        employmentBackground: `${employmentBackgroundKey}.SECTIONS.EMPLOYMENT_BACKGROUND`,
        employmentSituation: `${employmentBackgroundKey}.SECTIONS.EMPLOYMENT_SITUATION`,
        employmentInvalidity: `${employmentBackgroundKey}.SECTIONS.EMPLOYMENT_INVALIDITY`,
      },
      employmentInvalidityOptions: {
        content: {
          options: {
            yes: `${employmentBackgroundKey}.OPTIONS.YES`,
            no: `${employmentBackgroundKey}.OPTIONS.NO`,
          },
          errors: {
            required: `${employmentBackgroundKey}.FORM.ERRORS.REQUIRED`,
          },
        },
        options: [
          {
            code: 0,
            text: `${employmentBackgroundKey}.FORM.ACCIDENT_OR_DISEASE`,
            detail: `${employmentBackgroundKey}.FORM.ACCIDENT_DATE`,
            inputType: 'date',
          },
          {
            code: 1,
            text: `${employmentBackgroundKey}.FORM.ANY_COMPENSATION`,
          },
          {
            code: 2,
            text: `${employmentBackgroundKey}.FORM.ANY_PENSION`,
            detail: `${employmentBackgroundKey}.FORM.PENSION_PAYING_ENTITY`,
            inputType: 'select',
            index: 0,
          },
        ] as Array<RadioButtonInputListElement>,
      },
      buttons: {
        next: `${employmentBackgroundKey}.BUTTONS.NEXT`,
        back: `${employmentBackgroundKey}.BUTTONS.BACK`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      placeholders: {
        select: `${employmentBackgroundKey}.PLACEHOLDERS.SELECT`,
        selectDate: `${employmentBackgroundKey}.PLACEHOLDERS.SELECT_DATE`,
        enterField: `${employmentBackgroundKey}.PLACEHOLDERS.ENTER_FIELD`,
      },
    },
    medicalBackground: {
      label: `${medicalBackgroundKey}.LABEL`,
      section: {
        title: `${medicalBackgroundKey}.SECTION.TITLE`,
        description: `${medicalBackgroundKey}.SECTION.DESCRIPTION`,
      },
      disclaimer: `${medicalBackgroundKey}.DISCLAIMER`,
      buttons: {
        submit: `${medicalBackgroundKey}.BUTTONS.SUBMIT`,
        back: `${medicalBackgroundKey}.BUTTONS.BACK`,
        addMedicalReport: `${medicalBackgroundKey}.BUTTONS.ADD_MEDICAL_REPORT`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      medicalReportModal: {
        title: `${medicalBackgroundModalKey}.TITLE`,
        medicalExamData: {
          title: `${medicalBackgroundModalKey}.MEDICAL_EXAM_DATA.TITLE`,
          form: {
            type: {
              label: `${medicalBackgroundModalKey}.MEDICAL_EXAM_DATA.FORM.TYPE.LABEL`,
              options: [
                {
                  code: 0,
                  text: `${medicalBackgroundModalKey}.MEDICAL_EXAM_DATA.FORM.TYPE.OPTIONS.0.TEXT`,
                },
                {
                  code: 1,
                  text: `${medicalBackgroundModalKey}.MEDICAL_EXAM_DATA.FORM.TYPE.OPTIONS.1.TEXT`,
                },
                {
                  code: 2,
                  text: `${medicalBackgroundModalKey}.MEDICAL_EXAM_DATA.FORM.TYPE.OPTIONS.2.TEXT`,
                },
                {
                  code: 3,
                  text: `${medicalBackgroundModalKey}.MEDICAL_EXAM_DATA.FORM.TYPE.OPTIONS.3.TEXT`,
                },
              ] as Array<RadioButtonInputListElement>,
            },
            date: `${medicalBackgroundModalKey}.MEDICAL_EXAM_DATA.FORM.DATE`,
          },
        },
        doctorData: {
          title: `${medicalBackgroundModalKey}.DOCTOR_DATA.TITLE`,
          form: {
            name: `${medicalBackgroundModalKey}.DOCTOR_DATA.FORM.NAME`,
            rut: `${medicalBackgroundModalKey}.DOCTOR_DATA.FORM.RUT`,
          },
        },
        errors: {
          required: `${medicalBackgroundModalKey}.ERRORS.REQUIRED`,
          invalidRut: `${medicalBackgroundModalKey}.ERRORS.INVALID_RUT`,
        },
        buttons: {
          updateMedicalReport: `${medicalBackgroundModalKey}.BUTTONS.MODIFY`,
          addMedicalReport: `${medicalBackgroundModalKey}.BUTTONS.ADD`,
          uploadFile: `${medicalBackgroundModalKey}.BUTTONS.UPLOAD_FILE`,
          updateFile: `${medicalBackgroundModalKey}.BUTTONS.UPDATE_FILE`,
        },
      },
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
      content: {
        title: `${voucherKey}.CONTENT.TITLE`,
        subtitle: `${voucherKey}.CONTENT.SUBTITLE`,
        description: `${voucherKey}.CONTENT.DESCRIPTION`,
        homeButton: `${voucherKey}.CONTENT.HOME_BUTTON`,
        download: `${voucherKey}.CONTENT.DOWNLOAD_PDF`,
        customRouteButton: `${voucherKey}.CONTENT.CUSTOM_ROUTE_BUTTON`,
      },
    },
    documents: {
      label: `${pageKey}.STEPS.DOCUMENTS.LABEL`,
      title: `${pageKey}.STEPS.DOCUMENTS.SECTION.TITLE`,
      description: `${pageKey}.STEPS.DOCUMENTS.SECTION.DESCRIPTION`,
      disclaimer: `${pageKey}.STEPS.DOCUMENTS.DISCLAIMER`,
      fileSizeWarning: `${pageKey}.STEPS.DOCUMENTS.FILE_SIZE_WARNING`,
      buttons: {
        addDocumentClient: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.ADD_DOCUMENT_CLIENT`,
        addDocumentEmployer: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.ADD_DOCUMENT_EMPLOYER`,
        next: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.NEXT`,
        back: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.BACK`,
        saveContinueLater: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.BUTTONS.DELETE_REQUEST`,
      },
      errors: {
        required: `${pageKey}.STEPS.DOCUMENTS.ERRORS.REQUIRED`
      },
      otherFiles: {
        title: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.TITLE`,
        attachIdFile: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.ATTACH_ID_FILE`,
        settlement: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.SETTLEMENT`,
        laborManagmentAct: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.LABOR_MANAGEMENT_ACT`,
        conciliationAct: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.CONCILIATION_ACT`,
        courtJudgment: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.COURT_JUDGEMENT`,
        other: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.OTHER`,
        attachFormat: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.ATTACH_FORMAT`,
        attachExample: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.ATTACH_EXAMPLE`,
        attachMaxSize: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.ATTACH_MAX_SIZE`,
        attachDifferentFiles: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.DIFFERENT_FILES`,
        otherFileDescription: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.OTHER_FILE_DESCRIPTION`
      },
      statement: `${pageKey}.STEPS.DOCUMENTS.STATEMENT`
    },
  },
};

export const DISABILITY_OCCUPATIONAL_ACCIDENT_CODE = '3';
