const WILDCARD = '*';

export const ROUTES_WITH_RECAPTCHA_VALIDATION = [
  {
    route: `${WILDCARD}authentication/clients/login${WILDCARD}`,
    method: 'POST',
    action: 'clientLogin'
  },
  {
    route: `${WILDCARD}employers/authentication/login${WILDCARD}`,
    method: 'POST',
    action: 'employerLogin'
  },
  {
    route: `${WILDCARD}authentication/beneficiary/login${WILDCARD}`,
    method: 'POST',
    action: 'beneficiaryLogin'
  },
  {
    route: `${WILDCARD}authentication/beneficiary/change-affiliate${WILDCARD}`,
    method: 'POST',
    action: 'changeAffiliate'
  },
  {
    route: `${WILDCARD}clients/authentication/password/recover${WILDCARD}`,
    method: 'POST',
    action: 'recoverClientPassword'
  },
  {
    route: `${WILDCARD}clients/authentication/password/beneficiary/recover${WILDCARD}`,
    method: 'POST',
    action: 'recoverBeneficiaryPassword'
  },
  {
    route: `${WILDCARD}clients/authentication/password/beneficiary${WILDCARD}`,
    method: 'PATCH',
    action: 'changeBeneficiaryPassword'
  },
  {
    route: `${WILDCARD}clients/authentication/passwordless-generate${WILDCARD}`,
    method: 'POST',
    action: 'passwordlessGenerate'
  },
  {
    route: `${WILDCARD}clients/authentication/passwordless-login${WILDCARD}`,
    method: 'POST',
    action: 'passwordlessLogin'
  },
  {
    route: `${WILDCARD}clients/authentication/questions${WILDCARD}`,
    method: 'GET',
    action: 'getQuestions'
  },
  {
    route: `${WILDCARD}clients/clients/${WILDCARD}/contact${WILDCARD}`,
    method: 'PATCH',
    action: 'updateContactInformation'
  },
  {
    route: `${WILDCARD}clients/clients/${WILDCARD}/contact/validate${WILDCARD}`,
    method: 'POST',
    action: 'validateContactInformation'
  },
  {
    route: `${WILDCARD}clients/clients/${WILDCARD}/beneficiary/contact/validate${WILDCARD}`,
    method: 'POST',
    action: 'validateBeneficiaryUserContactInfo'
  },
  {
    route: `${WILDCARD}security-keys${WILDCARD}`,
    method: 'PUT',
    action: 'changeSecurityKey'
  },
  {
    route: `${WILDCARD}security-keys/${WILDCARD}/questions${WILDCARD}`,
    method: 'PATCH',
    action: 'changeSecurityKeyQuestions'
  },
  {
    route: `${WILDCARD}employers/authentication/password/recover${WILDCARD}`,
    method: 'POST',
    action: 'recoverEmployerPassword'
  },
  {
    route: `${WILDCARD}employers/authentication/password`,
    method: 'POST',
    action: 'createEmployerPassword'
  },
  {
    route: `${WILDCARD}clients/clients/${WILDCARD}/contact/data${WILDCARD}`,
    method: 'GET',
    action: 'validateUserRut'
  },
  {
    route: `${WILDCARD}clients/beneficiary/${WILDCARD}/masked-contact-data${WILDCARD}`,
    method: 'GET',
    action: 'beneficiaryContactData'
  },
  {
    route: `${WILDCARD}clients/beneficiary/${WILDCARD}/deceased-affiliates${WILDCARD}`,
    method: 'GET',
    action: 'beneficiaryAffiliates'
  },
  {
    route: `${WILDCARD}clients/beneficiary/${WILDCARD}/beneficiary-data${WILDCARD}`,
    method: 'GET',
    action: 'beneficiaryData'
  },
  {
    route: `${WILDCARD}public/contact/register-bank-account`,
    method: 'POST',
    action: 'registerBankAccount'
  },
  {
    route: `${WILDCARD}security-keys/beneficiary${WILDCARD}`,
    method: 'GET',
    action: 'getBeneficiarySecurityKeyStatus'
  },
  {
    route: `${WILDCARD}security-keys/beneficiary${WILDCARD}`,
    method: 'PUT',
    action: 'changeBeneficiarySecurityKey'
  },
  {
    route: `${WILDCARD}security-keys/beneficiary${WILDCARD}`,
    method: 'PUT',
    action: 'recoverBeneficiarySecurityKey'
  },
  {
    route: `${WILDCARD}security-keys/beneficiary${WILDCARD}`,
    method: 'PUT',
    action: 'changeBeneficiarySecurityKeyQuestions'
  },
  {
    route: `${WILDCARD}beneficiary/change-fund${WILDCARD}`,
    method: 'POST',
    action: 'beneficiaryFundChange'
  },
  {
    route: `${WILDCARD}beneficiary/distribute-fund${WILDCARD}`,
    method: 'POST',
    action: 'beneficiaryFundDistribution'
  },
  {
    route: `${WILDCARD}transfer-facial-biometric/identities/customers/${WILDCARD}/facial-biometric/documents/validation`,
    method: 'POST',
    action: 'facialBiometricDocumentValidation'
  },
  {
    route: `${WILDCARD}transfer-facial-biometric/identities/customers/${WILDCARD}/facial-biometric/validation`,
    method: 'POST',
    action: 'facialBiometricFaceValidation'
  },
  {
    route: `${WILDCARD}transfer-facial-biometric/traceability/start`,
    method: 'POST',
    action: 'start'
  },
  {
    route: `${WILDCARD}transfer-facial-biometric/traceability/save`,
    method: 'POST',
    action: 'save'
  },
];
