import { LANGUAGE_KEY } from '@constants/constants';
import { environment } from '@env';
import { Banner, HeaderItem, HeaderTranslation } from '@interfaces/banner.interface';
import {
  AlertBannerItem, AlertBannerTranslation, BankCalendar, BankCalendarResponse, GeneralInfoItem, Institutions, InstitutionsResponse
} from '@interfaces/cms.interface';
import { AlertBanner, GeneralInfo } from '@interfaces/components.interface';
import { FinancialInformation, FinancialInformationItem } from '@interfaces/financial-information.interface';
import { BalanceEvolutionCmsData, BalanceEvolutionInformationItem } from '@interfaces/home-mobile.interface';
import { Home, HomeItem } from '@interfaces/home.interface';
import { Modal } from '@interfaces/info-card.interface';
import {
  ProfitabilityDates,
  ProfitabilityDatesArray,
  ProfitabilityDatesObjectTranslation,
  ProfitabilityDatesResponse,
  ProfitabilityDatesTranslation,
  ProfitabilityPercentages,
  ProfitabilityPercentagesResponse
} from '@interfaces/profitability-percentages.interface';

export class CmsUtils {

  private getBannerDetail(alertBannerItem: AlertBannerItem, language: string) {
    const bannerInformation = alertBannerItem.traducciones.find(traduccion => traduccion.languages_code === language);
    const { activo: active } = alertBannerItem;
    const { titulo: title, mensaje: message } = bannerInformation;
    const modal = alertBannerItem.modal ? this.getModalDetail(bannerInformation, alertBannerItem.imagen_modal) : null;
    return { title, message, active, modal } as AlertBanner;
  }


  private getModalDetail(alertBannerTranslation: AlertBannerTranslation, imageItem: string) {
    const { modal_titulo: title, modal_descripcion: description, alt_imagen: alt } = alertBannerTranslation;
    const image = { url: this.getImageUrl(imageItem), alt };
    return { title, description, image } as Modal<any>;
  }

  private getImageUrl(id: string): string {
    const url = id.length ? `${environment.cmsApiUrl}assets/${id}` : null;
    return url;
  }

  public mapHome(homeItem: HomeItem, gender: string): Home {
    return {
      lateralBanner: this.getImageUrl(homeItem.banner_lateral),
      welcomeMessage: gender === 'F' ? homeItem.mensaje_bienvenida_femenino : homeItem.mensaje_bienvenida_masculino,
      imageLink: homeItem.mensaje_bienvenida_femenino,
    };
  }

  public mapGeneralInfo(generalInfoItem: GeneralInfoItem, language: string): GeneralInfo {
    const { contact_center_habilitado = false } = generalInfoItem;
    const { horarios_call_center = null } = generalInfoItem.traducciones.find(item => item.languages_code === language) || {};
    const { titulo = null, bajada = null, boton = null } = generalInfoItem.flotante.find(item => item.languages_code === language) || {};
    return {
      callCenterHours: horarios_call_center,
      callCenterAvailable: contact_center_habilitado,
      floatingButton: {
        show: generalInfoItem.mostrar_flotante,
        title: titulo,
        description: bajada,
        button: {
          name: boton,
          url: generalInfoItem.flotante_url,
        },
      },
    };
  }

  public mapInstitutions(institutions: InstitutionsResponse): Institutions {
    const { instituciones_salud } = institutions;
    return {
      healthcareInstitutions: instituciones_salud.contenido || []
    };
  }

  public mapAlertBanner(alertBannerItems: Array<AlertBannerItem>, language: string): AlertBanner {
    if (!alertBannerItems.length) { return { title: '', message: '', active: false }; }
    return this.getBannerDetail(alertBannerItems[0], language);
  }

  public getLanguage() {
    if (localStorage.getItem(LANGUAGE_KEY) !== null) { return localStorage.getItem(LANGUAGE_KEY); }
    return 'es';
  }

  public mapHeaders(headerItems: Array<HeaderItem<HeaderTranslation>>, language: string): Array<Banner> {
    return headerItems.map(bannerItem => {
      const bannerTranslation = bannerItem.traducciones.find(item => item.languages_code === language) || null;

      return {
        id: bannerItem.id,
        image: {
          url: bannerItem.imagen ? this.getImageUrl(bannerItem.imagen) : null,
          alt: bannerTranslation?.alt_imagen || null,
        },
        icon: bannerItem.icono || null,
        firstLine: bannerTranslation?.titulo || null,
      };
    });
  }

  public mapFinancialInformation(financialInformationItem: FinancialInformationItem, headers: Array<Banner>): FinancialInformation {
    const language = this.getLanguage();
    const header = headers.find(item => item.id === financialInformationItem.header.id);
    const {
      titulo: title, cuerpo: description, titulo_tabla: titleTable, tabla: table, fecha_actualizacion: informationUpdateDate
    } = financialInformationItem.traducciones.find(item => item.languages_code === language);
    return {
      header,
      profitabilityVariation: { title, description, titleTable, table, informationUpdateDate },
    };
  }

  public mapBankCalendar(bankCalendar: Array<BankCalendarResponse>): BankCalendar {
    const { fechas } = bankCalendar[0];
    return { dates: fechas || null };
  }

  public mapProfitabilityPercentage(profitabilityResponse: ProfitabilityPercentagesResponse): ProfitabilityPercentages {
    return {
      fund_a: {
        month_1: profitabilityResponse.fondo_a_1_month,
        year_1: profitabilityResponse.fondo_a_1_year,
        year_3: profitabilityResponse.fondo_a_3_year,
        year_5: profitabilityResponse.fondo_a_5_year,
      },
      fund_b: {
        month_1: profitabilityResponse.fondo_b_1_month,
        year_1: profitabilityResponse.fondo_b_1_year,
        year_3: profitabilityResponse.fondo_b_3_year,
        year_5: profitabilityResponse.fondo_b_5_year,
      },
      fund_c: {
        month_1: profitabilityResponse.fondo_c_1_month,
        year_1: profitabilityResponse.fondo_c_1_year,
        year_3: profitabilityResponse.fondo_c_3_year,
        year_5: profitabilityResponse.fondo_c_5_year,
      },
      fund_d: {
        month_1: profitabilityResponse.fondo_d_1_month,
        year_1: profitabilityResponse.fondo_d_1_year,
        year_3: profitabilityResponse.fondo_d_3_year,
        year_5: profitabilityResponse.fondo_d_5_year,
      },
      fund_e: {
        month_1: profitabilityResponse.fondo_e_1_month,
        year_1: profitabilityResponse.fondo_e_1_year,
        year_3: profitabilityResponse.fondo_e_3_year,
        year_5: profitabilityResponse.fondo_e_5_year,
      },
    };
  }

  public mapProfitabilityDates(profitabilityDatesResponse: ProfitabilityDatesResponse): ProfitabilityDates {
    const [dataEs, dataHt] = profitabilityDatesResponse.data;

    const mapData = (data: ProfitabilityDatesTranslation): string[] => [
      data.fechas_mes,
      data.fechas_year,
      data.fechas_three_years,
      data.fechas_five_years,
    ];

    const dataArray: ProfitabilityDatesArray = {
      es: mapData(dataEs),
      ht: mapData(dataHt),
    };

    const mapDataObject = (data: ProfitabilityDatesTranslation): ProfitabilityDatesObjectTranslation => ({
      fechasMes: data.fechas_mes,
      fechasYear: data.fechas_year,
      fechasThreeYears: data.fechas_three_years,
      fechasFiveYears: data.fechas_five_years,
    });

    const dataObject: { es: ProfitabilityDatesObjectTranslation, ht: ProfitabilityDatesObjectTranslation; } = {
      es: mapDataObject(dataEs),
      ht: mapDataObject(dataHt),
    };

    return { dataArray, dataObject };
  }

  public mapBalanceEvolutionInformation(balanceEvolutionCmsData: BalanceEvolutionCmsData): object {
    const balanceInformation = {};
    balanceEvolutionCmsData.traducciones.forEach((item) => {
      balanceInformation[item.languages_code] = {
        headerTitle: item.titulo_modal,
        bodyTitle: item.titulo_contenido,
        bodyText: item.contenido,
      } as BalanceEvolutionInformationItem;
    });
    return balanceInformation;
  }
}
