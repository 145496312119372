/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { APP_ROUTES, TRACEABILITY } from '@constants/constants';
import { TraceabilityRequestDto } from '@interfaces/traceability.interface';
import { TraceabiliyService } from '@services/traceability/traceability.service';
import { Util } from '@util';

@Injectable()
export class TraceabilityBgProvider {

  constructor(
    private readonly traceabiliyService: TraceabiliyService,
    private readonly util: Util
  ) { }

  startTraceabilityBg(route) {
    this.saveLocalStorageWithdrawalType(route);
    const requestTraceability = this.mapDataTraceability(
      TRACEABILITY.MODULE.REQUESTS.ID,
      this.getFunctionalityId(),
      TRACEABILITY.MODULE.REQUESTS.STEP_ID.start
    );
    this.traceabiliyService.start(requestTraceability).subscribe(result => {
      this.util.setLocalStorage('trace_processid', result.processId);
    }, error => { });
  }

  saveTraceabilityBgToWithdrawals(stepId: string, result?: string) {
    const requestTraceability = this.mapDataTraceability(
      TRACEABILITY.MODULE.REQUESTS.ID,
      this.getFunctionalityId(),
      stepId,
      this.util.getLocalStorage('trace_processid'),
      result ? result : 'ok'
    );
    this.traceabiliyService.save(requestTraceability).subscribe();
  }

  getFunctionalityId(): string {
    const typeWithdrawal = this.util.getLocalStorage('trace_flow');

    const functionality = typeWithdrawal === 'apv' ? TRACEABILITY.MODULE.REQUESTS.APV.functionalityId
      : typeWithdrawal === 'cav' ? TRACEABILITY.MODULE.REQUESTS.CAV.functionalityId
        : TRACEABILITY.MODULE.REQUESTS.CAV10.functionalityId;

    return functionality;
  }

  saveLocalStorageWithdrawalType(route: string) {
    const valueRoute = this.util.extractAfterSlash(route);
    const typeWithdrawal = valueRoute === APP_ROUTES.client.cavCreation ? 'cav'
      : valueRoute === APP_ROUTES.client.withdrawalCav10 ? 'withdrawal-10'
        : valueRoute;
    this.util.setLocalStorage('trace_flow', typeWithdrawal);
  }

  mapDataTraceability(
    moduleId: string,
    functionalityId: string,
    stepId: string,
    processId?: string,
    result?: string,
    stage?: string,
  ): TraceabilityRequestDto {
    const requestTraceability = {
      moduleId,
      functionalityId,
      stepId,
      processId,
      result,
      stage: stage ? stage : 'Ingreso solicitud',
      channel: this.util?.isMediumMobile ? 'app' : 'web'
    } as TraceabilityRequestDto;
    return requestTraceability;
  }


  setTraceHeaderToBg(module, functionality): any {
    return {
      'Options-Processid': localStorage.getItem('trace_processid'),
      'Options-Module': module,
      'Options-Functionality': functionality,
      'Options-channel': this.util?.isMediumMobile ? 'app' : 'web'
    } as any;
  }

  isflowWithdrawals(route: string): boolean {
    const typeWithdrawal = this.util.extractAfterSlash(route);

    return typeWithdrawal === APP_ROUTES.client.apvWithdrawal ||
      typeWithdrawal === APP_ROUTES.client.cavWithdrawal ||
      typeWithdrawal === APP_ROUTES.client.withdrawalCav10;
  }

}
