import { APP_ROUTES } from '@constants/constants';

const pageKey = 'RELOGIN_MODAL';

export const RECOVER_PASSWORD_DUE_RELOGIN = 'recover-password-due-relogin';

export const RECOVER_PASSWORD_REDIRECT = {
  employer: {
    url: APP_ROUTES.login,
    query: { use: RECOVER_PASSWORD_DUE_RELOGIN },
  },
  client: {
    url: APP_ROUTES.client.recoverAccessKey,
    query: { use: RECOVER_PASSWORD_DUE_RELOGIN },
  },
  beneficiary: {
    url: APP_ROUTES.beneficiary.recoverAccessKey,
    query: { use: RECOVER_PASSWORD_DUE_RELOGIN },
  }
};


export const RELOGIN_MODAL_CONTENT = {
  title: `${pageKey}.TITLE`,
  subtitle: `${pageKey}.SUBTITLE`,
  buttonRelogin: `${pageKey}.BUTTON_RELOGIN`,
  buttonRecover: `${pageKey}.BUTTON_RECOVER`,
};
