import { GeneralInfo } from '@interfaces/components.interface';
import { FinancialInformation } from '@interfaces/financial-information.interface';
import { Mock } from '@interfaces/mock.interface';

export const GET_GENERAL_INFO_MOCKUP: Mock = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            id: 1,
            horarios_call_center: '<p>S&aacute;bado 18 de julio, 9:00 a 14:00 hrs</p>',
            idioma: 'es',
            informacion_general: 1
          },
        ],
        flotante_url: 'url',
        mostrar_flotante: true,
        flotante: [
          {
            id: 1,
            idioma: 'es',
            informacion_general: 1,
            titulo: 'Conoce tu saldo actual',
            bajada: 'Puedes consultar tu saldo actual solo ingresando tu rut y clave de acceso.',
            boton: 'Consultar saldo'
          },
        ],
      },
      public: true
    }
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }],
};

export const GENERAL_INFO_MOCKUP: GeneralInfo = {
  callCenterAvailable: true,
  callCenterHours: '<p>S&aacute;bado 18 de julio, 9:00 a 14:00 hrs</p>',
  floatingButton: {
    show: true,
    title: 'Conoce tu saldo actual',
    description: 'Puedes consultar tu saldo actual solo ingresando tu rut y clave de acceso.',
    button: {
      name: 'Consultar saldo',
      url: 'url',
    },
  },
};

export const GENERAL_INFO_NULL_MOCKUP: GeneralInfo = {
  callCenterAvailable: true,
  callCenterHours: null,
  floatingButton: {
    show: true,
    title: null,
    description: null,
    button: {
      name: null,
      url: 'url',
    },
  },
};

export const GET_FINANCIAL_INFORMATION_MOCKUP: Mock = {
  success: [{
    response: {
      data: {
        header: {
          id: 1,
          pagina: 'Información financiera',
          imagen: 19,
          icono: 'analysis'
        },
        traducciones: [
          {
            languages_code: 'es',
            id: 1,
            titulo: 'Rentabilidad Agosto 2020',
            cuerpo: '<p>En agosto todos los fondos administrados por PlanVital obtuvieron rentabilidades positivas.<br />Estos resultados se explican principalmente por los retornos positivos de las Inversiones en Renta Variable Extranjera para los Fondos A, B y C, debido a un mejor &aacute;nimo en los mercados burs&aacute;tiles tras la reapertura en algunas de las grandes econom&iacute;as globales. Tambi&eacute;n hubo retornos positivos de las Inversiones en Renta Fija Nacional para los Fondos D y E.<br />&nbsp;<br />En el siguiente cuadro te mostramos como aportaron las inversiones de los distintos tipos de instrumentos, en la rentabilidad de los fondos en agosto.&nbsp;</p>',
            titulo_tabla: 'Contribución a la rentabilidad nominal del mes de agosto',
            tabla: {
              'Tipo de Instrumento': {
                A: 'Fondo A',
                B: 'Fondo B',
                C: 'Fondo C',
                D: 'Fondo D',
                E: 'Fondo E'
              },
              'Renta Variable Extranjera': {
                A: '5.0%',
                B: '3.8%',
                C: '2.3%',
                D: '1.0%',
                E: '0.3%'
              },
              'Renta Variable Nacional': {
                A: '-0.2%',
                B: '-0.2%',
                C: '-0.1%',
                D: '-0.1%',
                E: '0.0%'
              },
              'Renta Fija Exranjera': {
                A: '0.9%',
                B: '1.0%',
                C: '0.0%',
                D: '0.7%',
                E: '0.3%'
              },
              'Renta Fija Nacional': {
                A: '0.0%',
                B: '0.3%',
                C: '1.1%',
                D: '1.9%',
                E: '2.0%'
              },
              'Activos Alternativos': {
                A: '0.2%',
                B: '0.1%',
                C: '0.1%',
                D: '0.1%',
                E: '0.0%'
              },
              'Derivados y otros': {
                A: '-0.6%',
                B: '-0.7%',
                C: '-0.5%',
                D: '-0.5%',
                E: '-0.2%'
              },
              Total: {
                A: '5.31%',
                B: '4.29%',
                C: '3.84%',
                D: '3.11%',
                E: '2.33%'
              }
            },
            fecha_actualizacion: 'Actualizado al 17 de marzo del 2021'
          },
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const FINANCIAL_INFORMATION_MOCKUP: FinancialInformation = {
  header: {
    id: 1,
    image: {
      url: 'http://35.186.243.77/v2/cms/assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png',
      alt: 'alt',
    },
    icon: 'handshake',
    firstLine: '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital</strong></p>',
  },
  profitabilityVariation: {
    title: 'Rentabilidad Agosto 2020',
    description: '<p>En agosto todos los fondos administrados por PlanVital obtuvieron rentabilidades positivas.<br />Estos resultados se explican principalmente por los retornos positivos de las Inversiones en Renta Variable Extranjera para los Fondos A, B y C, debido a un mejor &aacute;nimo en los mercados burs&aacute;tiles tras la reapertura en algunas de las grandes econom&iacute;as globales. Tambi&eacute;n hubo retornos positivos de las Inversiones en Renta Fija Nacional para los Fondos D y E.<br />&nbsp;<br />En el siguiente cuadro te mostramos como aportaron las inversiones de los distintos tipos de instrumentos, en la rentabilidad de los fondos en agosto.&nbsp;</p>',
    titleTable: 'Contribución a la rentabilidad nominal del mes de agosto',
    table: {
      'Tipo de Instrumento': {
        A: 'Fondo A',
        B: 'Fondo B',
        C: 'Fondo C',
        D: 'Fondo D',
        E: 'Fondo E'
      },
      'Renta Variable Extranjera': {
        A: '5.0%',
        B: '3.8%',
        C: '2.3%',
        D: '1.0%',
        E: '0.3%'
      },
      'Renta Variable Nacional': {
        A: '-0.2%',
        B: '-0.2%',
        C: '-0.1%',
        D: '-0.1%',
        E: '0.0%'
      },
      'Renta Fija Exranjera': {
        A: '0.9%',
        B: '1.0%',
        C: '0.0%',
        D: '0.7%',
        E: '0.3%'
      },
      'Renta Fija Nacional': {
        A: '0.0%',
        B: '0.3%',
        C: '1.1%',
        D: '1.9%',
        E: '2.0%'
      },
      'Activos Alternativos': {
        A: '0.2%',
        B: '0.1%',
        C: '0.1%',
        D: '0.1%',
        E: '0.0%'
      },
      'Derivados y otros': {
        A: '-0.6%',
        B: '-0.7%',
        C: '-0.5%',
        D: '-0.5%',
        E: '-0.2%'
      },
      Total: {
        A: '5.31%',
        B: '4.29%',
        C: '3.84%',
        D: '3.11%',
        E: '2.33%'
      }
    },
    informationUpdateDate: 'Actualizado al 17 de marzo del 2021'
  }
};
