const pageKey = 'REQUESTS.OVERPAYMENT_REFUND';
const personalBackgroundKey = `${pageKey}.STEPS.PERSONAL_BACKGROUND`;
const employerBackgroundKey = `${pageKey}.STEPS.EMPLOYER_BACKGROUND`;
const descriptionKey = `${pageKey}.STEPS.DESCRIPTION`;
const documentsKey = `${pageKey}.STEPS.DOCUMENTS`;
const documentModalKey = `${documentsKey}.DOCUMENT_MODAL`;
const paymentMethodsKey = `${pageKey}.STEPS.PAYMENT_METHODS`;
const voucherKey = `${pageKey}.STEPS.VOUCHER`;
const disclaimerKey = 'GENERIC_DISCLAIMER.PERSONAL_BACKGROUND';
const savedModal = 'REQUESTS.MODALS.SAVED';
const toastKey = `${pageKey}.TOAST`;

export const OVERPAYMENT_REFUND_FORM_FILE_TYPE = 'devolucion-pago-exceso';

const BACKGROUND_ERRORS = {
  required: `${personalBackgroundKey}.FORM.ERRORS.REQUIRED`,
  invalidEmail: `${personalBackgroundKey}.FORM.ERRORS.INVALID_EMAIL`,
  invalidDomain: `${personalBackgroundKey}.FORM.ERRORS.INVALID_DOMAIN`,
  invalidRut: `${personalBackgroundKey}.FORM.ERRORS.INVALID_RUT`,
  invalidAddress: `${personalBackgroundKey}.FORM.ERRORS.INVALID_ADDRESS`,
};

export const PERSONAL_BACKGROUND_LABEL = `${personalBackgroundKey}.LABEL`;
export const EMPLOYER_BACKGROUND_LABEL = `${employerBackgroundKey}.LABEL`;
export const OVERPAYMENT_MAXLENGTH = 255;

export const OVERPAYMENT_REFUND_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved',
};

export const OVERPAYMENT_REFUND_CONTENT = {
  breadcrumb: `${pageKey}.BREADCRUMB`,
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  folio: `${pageKey}.FOLIO`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`
  },
  steps: {
    personalBackground: {
      label: PERSONAL_BACKGROUND_LABEL,
      title: `${personalBackgroundKey}.TITLE`,
      buttons: {
        next: `${personalBackgroundKey}.BUTTONS.NEXT`
      },
      disclaimer: {
        message: `${disclaimerKey}.MESSAGE`,
        redirectionText: `${disclaimerKey}.REDIRECTION_TEXT`
      },
      form: {
        names: `${personalBackgroundKey}.FORM.NAMES`,
        lastNames: `${personalBackgroundKey}.FORM.LAST_NAMES`,
        rut: `${personalBackgroundKey}.FORM.RUT`,
        gender: {
          label: `${personalBackgroundKey}.FORM.GENDER.LABEL`,
          male: `${personalBackgroundKey}.FORM.GENDER.MALE`,
          female: `${personalBackgroundKey}.FORM.GENDER.FEMALE`
        },
        address: `${personalBackgroundKey}.FORM.ADDRESS`,
        birthdate: `${personalBackgroundKey}.FORM.BIRTHDATE`,
        email: `${personalBackgroundKey}.FORM.EMAIL`,
        phone: `${personalBackgroundKey}.FORM.PHONE`,
        errors: BACKGROUND_ERRORS
      }
    },
    employerBackground: {
      label: EMPLOYER_BACKGROUND_LABEL,
      title: `${employerBackgroundKey}.TITLE`,
      buttons: {
        next: `${employerBackgroundKey}.BUTTONS.NEXT`
      },
      form: {
        name: `${employerBackgroundKey}.FORM.NAME`,
        rut: `${employerBackgroundKey}.FORM.RUT`,
        address: `${employerBackgroundKey}.FORM.ADDRESS`,
        email: `${employerBackgroundKey}.FORM.EMAIL`,
        phone: `${employerBackgroundKey}.FORM.PHONE`,
        errors: BACKGROUND_ERRORS
      }
    },
    description: {
      label: `${descriptionKey}.LABEL`,
      form: {
        title: `${descriptionKey}.FORM.TITLE`,
        description: `${descriptionKey}.FORM.DESCRIPTION`,
        reason: `${descriptionKey}.FORM.REASON`,
        detail: `${descriptionKey}.FORM.DETAIL`,
        startDate: `${descriptionKey}.FORM.START_DATE`,
        endDate: `${descriptionKey}.FORM.END_DATE`,
        reasons: {
          client: {
            pension: `${descriptionKey}.FORM.REASONS.CLIENT.PENSION`,
            discount: `${descriptionKey}.FORM.REASONS.CLIENT.DISCOUNT`,
            improper: `${descriptionKey}.FORM.REASONS.CLIENT.IMPROPER`,
            taxableCeiling: `${descriptionKey}.FORM.REASONS.CLIENT.TAXABLE_CEILING`,
            employers: `${descriptionKey}.FORM.REASONS.CLIENT.EMPLOYERS`
          },
          employer: {
            medicalLicenses: `${descriptionKey}.FORM.REASONS.EMPLOYER.MEDICAL_LICENSES`,
            improper: `${descriptionKey}.FORM.REASONS.EMPLOYER.IMPROPER`
          },
          other: `${descriptionKey}.FORM.REASONS.OTHER`
        }
      },
      buttons: {
        next: `${descriptionKey}.BUTTONS.NEXT`,
        back: `${descriptionKey}.BUTTONS.BACK`,
        saveContinueLater: `${descriptionKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${descriptionKey}.BUTTONS.DELETE_REQUEST`,
      }
    },
    documents: {
      label: `${documentsKey}.LABEL`,
      title: `${documentsKey}.SECTION.TITLE`,
      description: `${documentsKey}.SECTION.DESCRIPTION`,
      disclaimer: `${documentsKey}.DISCLAIMER`,
      fileSizeWarning: `${documentsKey}.FILE_SIZE_WARNING`,
      buttons: {
        addDocumentClient: `${documentsKey}.BUTTONS.ADD_DOCUMENT_CLIENT`,
        addDocumentEmployer: `${documentsKey}.BUTTONS.ADD_DOCUMENT_EMPLOYER`,
        next: `${documentsKey}.BUTTONS.NEXT`,
        back: `${documentsKey}.BUTTONS.BACK`,
        saveContinueLater: `${documentsKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${documentsKey}.BUTTONS.DELETE_REQUEST`,
      },
      errors: {
        required: `${documentsKey}.ERRORS.REQUIRED`
      },
      documentModal: {
        title: `${documentModalKey}.TITLE`,
        description: `${documentModalKey}.DESCRIPTION`,
        attachFormat: `${documentModalKey}.ATTACH_FORMAT`,
        attachments: {
          title: `${documentModalKey}.ATTACHMENTS.TITLE`,
          form: {
            docType: {
              label: `${documentModalKey}.ATTACHMENTS.FORM.TYPE.LABEL`,
              doc1: `${documentModalKey}.ATTACHMENTS.FORM.TYPE.DOC1`,
              doc2: `${documentModalKey}.ATTACHMENTS.FORM.TYPE.DOC2`,
              doc3: `${documentModalKey}.ATTACHMENTS.FORM.TYPE.DOC3`,
              doc4: `${documentModalKey}.ATTACHMENTS.FORM.TYPE.DOC4`
            },
            period: `${documentModalKey}.ATTACHMENTS.FORM.PERIOD`
          }
        },
        errors: {
          required: `${documentModalKey}.ERRORS.REQUIRED`,
          fileFormat: `${documentModalKey}.ERRORS.FILE_FORMAT`
        },
        buttons: {
          updateDocument: `${documentModalKey}.BUTTONS.MODIFY`,
          addDocument: `${documentModalKey}.BUTTONS.ADD`,
          uploadFile: `${documentModalKey}.BUTTONS.UPLOAD_FILE`,
          updateFile: `${documentModalKey}.BUTTONS.UPDATE_FILE`
        }
      },
      otherFiles: {
        title: `${documentsKey}.OTHER_FILES.TITLE`,
        attachIdFile: `${documentsKey}.OTHER_FILES.ATTACH_ID_FILE`,
        pensionCertificate: `${documentsKey}.OTHER_FILES.PENSION_CERTIFICATE`,
        rentCertificate: `${documentsKey}.OTHER_FILES.RENT_CERTIFICATE`,
        payroll: `${documentsKey}.OTHER_FILES.PAYROLL`,
        settlement: `${documentsKey}.OTHER_FILES.SETTLEMENT`,
        resolution: `${documentsKey}.OTHER_FILES.RESOLUTION`,
        other: `${documentsKey}.OTHER_FILES.OTHER`,
        attachFormat: `${documentsKey}.OTHER_FILES.ATTACH_FORMAT`,
        attachExample: `${documentsKey}.OTHER_FILES.ATTACH_EXAMPLE`,
        attachMaxSize: `${documentsKey}.OTHER_FILES.ATTACH_MAX_SIZE`,
        attachDifferentFiles: `${documentsKey}.OTHER_FILES.DIFFERENT_FILES`,
        otherFileDescription: `${documentsKey}.OTHER_FILES.OTHER_FILE_DESCRIPTION`
      },
      statement: `${documentsKey}.STATEMENT`
    },
    paymentMethods: {
      label: `${paymentMethodsKey}.LABEL`,
      formSection: {
        title: `${paymentMethodsKey}.FORM_SECTION.TITLE`,
        transfer: `${paymentMethodsKey}.FORM_SECTION.TRANSFER`,
        cash: `${paymentMethodsKey}.FORM_SECTION.CASH`,
        bank: {
          field: `${paymentMethodsKey}.FORM_SECTION.BANK.FIELD`,
          options: `${paymentMethodsKey}.FORM_SECTION.BANK.OPTIONS`
        },
        account: {
          field: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.FIELD`,
          options: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.OPTIONS`
        },
        other: {
          options: `${paymentMethodsKey}.FORM_SECTION.OTHER.OPTIONS`
        }
      },
      disclaimer: `${paymentMethodsKey}.DISCLAIMER`,
      digitalAccountDisclaimer: `${paymentMethodsKey}.DIGITAL_ACCOUNT_DISCLAIMER`,
      errors: {
        required: `${paymentMethodsKey}.ERRORS.REQUIRED`,
        digitalAccount: `${paymentMethodsKey}.ERRORS.DIGITAL_ACCOUNT`
      },
      buttons: {
        submit: `${paymentMethodsKey}.BUTTONS.SUBMIT`,
        back: `${paymentMethodsKey}.BUTTONS.BACK`,
        addBeneficiary: `${paymentMethodsKey}.BUTTONS.ADD_BENEFICIARY`
      }
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
      content: {
        title: `${voucherKey}.CONTENT.TITLE`,
        subtitle: `${voucherKey}.CONTENT.SUBTITLE`,
        description: `${voucherKey}.CONTENT.DESCRIPTION`,
        homeButton: `${voucherKey}.CONTENT.HOME_BUTTON`,
        download: `${voucherKey}.CONTENT.DOWNLOAD_PDF`,
        customRouteButton: `${voucherKey}.CONTENT.CUSTOM_ROUTE_BUTTON`
      }
    }
  }
};

export const NON_ARRAY_CLIENT_FILES = ['pensionCertificate', 'rentCertificate'];
export const NON_ARRAY_EMPLOYER_FILES = ['payroll', 'settlement', 'resolution'];

export const ARRAY_FILES = {
  salaries: `${documentsKey}.FILES.SALARY`,
  quotes: `${documentsKey}.FILES.QUOTE`,
  employeeAfcCertificate: `${documentsKey}.FILES.EMPLOYEE_CERTIFICATE`,
  settlement: `${documentsKey}.FILES.SETTLEMENT`,
  laborManagementAct: `${documentsKey}.FILES.LABOR_MANAGEMENT_ACT`,
  conciliationAct: `${documentsKey}.FILES.CONCILIATION_ACT`,
  courtJudgment: `${documentsKey}.FILES.COURT_JUDGEMENT`,
};

const attachmentContent = OVERPAYMENT_REFUND_CONTENT.steps.documents.otherFiles;
export const OTHER_FILES = [
  {
    order: 0,
    buttonLabel: attachmentContent.pensionCertificate,
    fileIndex: 'pensionCertificate',
    required: false
  },
  {
    order: 0,
    buttonLabel: attachmentContent.rentCertificate,
    fileIndex: 'rentCertificate',
    required: false
  },
  {
    order: 0,
    buttonLabel: attachmentContent.payroll,
    fileIndex: 'payroll',
    required: false
  },
  {
    order: 0,
    buttonLabel: attachmentContent.settlement,
    fileIndex: 'settlement',
    required: false
  },
  {
    order: 1,
    buttonLabel: attachmentContent.resolution,
    fileIndex: 'resolution',
    required: false
  },
  {
    order: 1,
    buttonLabel: attachmentContent.other,
    fileIndex: 'other',
    required: false
  },
];

export const REQUIRED_FILES = {
  clientPension: {
    fileArrayTypes: ['salaries'],
    otherTypes: ['pensionCertificate', 'other']
  },
  clientImproper: {
    fileArrayTypes: ['salaries', 'quotes'],
    otherTypes: ['other']
  },
  clientTaxableCeiling: {
    fileArrayTypes: ['salaries'],
    otherTypes: ['rentCertificate', 'other']
  },
  clientDiscount: {
    fileArrayTypes: ['salaries'],
    otherTypes: ['other']
  },
  clientEmployers: {
    fileArrayTypes: ['salaries'],
    otherTypes: ['rentCertificate', 'other']
  },
  employerMedicalLicenses: {
    fileArrayTypes: ['salaries'],
    otherTypes: ['payroll', 'other']
  },
  employerImproper: {
    fileArrayTypes: ['salaries', 'quotes', 'employeeAfcCertificate'],
    otherTypes: ['settlement', 'resolution', 'other']
  },
  other: {
    fileArrayTypes: ['salaries', 'quotes', 'employeeAfcCertificate'],
    otherTypes: []
  }
};

const reasonsContent = OVERPAYMENT_REFUND_CONTENT.steps.description.form.reasons;
export const OVERPAYMENT_REASONS = {
  client: { ...reasonsContent.client, other: reasonsContent.other },
  employer: { ...reasonsContent.employer, other: reasonsContent.other }
};
