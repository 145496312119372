import {
  ACCOUNT_WITHDRAWAL_CAF_URL, ACCOUNT_WITHDRAWAL_CAI_URL, ACCOUNT_WITHDRAWAL_CAV_URL,
  ACCOUNT_WITHDRAWAL_CCO_URL, ACCOUNT_WITHDRAWAL_CCV_URL, CAI_WITHDRAWAL_REQUEST_URL,
  CHANGE_FUND_SERVICE_URL, DISABILITIY_PENSION_REQUEST_URL, DISTRIBUTE_FUND_SERVICE_URL,
  ELDERLY_PENSION_REQUEST_URL, MANAGER_DISCOUNT_APV_SERVICE_URL, MANAGER_DISCOUNT_CAV_SERVICE_URL,
  SEND_CLAIMS_SERVICE_URL, SURPLUS_CALCULATION_REQUEST_URL, SURPLUS_PAYMENT_REQUEST_URL
} from './url.constants';

export const SEND_NOEMAIL_NOTIFICATION_MESSAGE = {
    [CHANGE_FUND_SERVICE_URL]: 'Acabas de realizar un cambio de fondo',
    [DISTRIBUTE_FUND_SERVICE_URL]: 'Acabas de realizar una distribución de fondos',
    [SEND_CLAIMS_SERVICE_URL]: 'Ingresaste un nuevo reclamo en nuestro sitio',
    [ACCOUNT_WITHDRAWAL_CAV_URL]: 'Acabas de realizar una solicitud de retiro de tu cuenta CAV',
    [ACCOUNT_WITHDRAWAL_CCO_URL]: 'Acabas de realizar una solicitud de retiro de tu cuenta CCO',
    [ACCOUNT_WITHDRAWAL_CCV_URL]: 'Acabas de realizar una solicitud de retiro de tu cuenta CCV',
    [ACCOUNT_WITHDRAWAL_CAF_URL]: 'Acabas de realizar una solicitud de retiro de tu cuenta CAF',
    [ACCOUNT_WITHDRAWAL_CAI_URL]: 'Acabas de realizar una solicitud de retiro de tu cuenta CAI',
    [ELDERLY_PENSION_REQUEST_URL]: 'Acabas de ingresar una solicitud de pensión',
    [DISABILITIY_PENSION_REQUEST_URL]: 'Acabas de ingresar una solicitud de pensión de invalidez',
    [SURPLUS_CALCULATION_REQUEST_URL]: 'Acabas de ingresar una solicitud de cálculo de excedentes',
    [SURPLUS_PAYMENT_REQUEST_URL]: 'Acabas de ingresar una solicitud de pago de excedentes',
    [CAI_WITHDRAWAL_REQUEST_URL]: 'Acabas de ingresar una solicitud de retiro CAI'
};

export const SEND_NOEMAIL_NOTIFICATION_MESSAGE_MULTI_METHOD = {
  [MANAGER_DISCOUNT_APV_SERVICE_URL]: {
    POST: 'Acabas de solicitar una apertura de cuenta APV',
    DELETE: 'Acabas de solicitar una revocación de tu cuenta APV',
    PUT: 'Acabas de realizar una modificación de tu cuenta APV',
  },
  [MANAGER_DISCOUNT_CAV_SERVICE_URL]: {
    POST: 'Acabas de solicitar una apertura de cuenta Cuenta 2',
    DELETE: 'Acabas de solicitar una revocación de tu cuenta Cuenta 2',
    PUT: 'Acabas de realizar una modificación de tu cuenta Cuenta 2',
  }
};
