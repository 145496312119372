
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalFacialValidationComponent } from '@components/modal-facial-validation/modal-facial-validation.component';
import { CAPTURING_DOCUMENT, ID_VALIDATION_TRACE_ACTIONS, SEND_DOCUMENT_CAPTURES } from '@constants/traceability.constants';
import { IdValidationImages, IdValidationRequest } from '@interfaces/idvalidation.interface';
import { Trolley } from '@interfaces/trolley.interface';
import { CANCELED_PROCESS, MODAL_FACIAL_VALIDATION_CONTENT } from '@pages-content/modal-facial-validation.constant';
import { FontService } from '@providers/font/font.service';
import { FacialBiometricService } from '@services/facial-biometric/facial-biometric.service';
import { SelphidService } from '@services/selphid/selphid.service';
import { TraceabilityBioService } from '@services/traceability-bio/traceability-bio.service';
import { Util } from '@util';
import { SelphIDFinishStatus, SelphIDResult } from 'capacitor-selphid-plugin';

@Component({
  selector: 'app-id-capture',
  templateUrl: './id-capture.component.html',
  styleUrls: ['./id-capture.component.scss'],
})
export class IdCaptureComponent {
  @Output() idCaptureProcess: EventEmitter<any> = new EventEmitter();
  @Input() trolley: Trolley;

  public pageContent = MODAL_FACIAL_VALIDATION_CONTENT;

  // URI_JPEG_HEADER = 'data:image/jpeg;base64,';

  selphidService: SelphidService;

  isListExpanded = false;
  message = '';
  showError = false;
  frontDocumentImage: string = null;
  backDocumentImage: string = null;
  faceImage: string = null;
  ocrData = null;
  processSucceeded = false;
  serviceError = false;
  cancelByUser = false;
  loading = false;
  errorMessage = [];
  captureStarted = false;
  timeOut = false;

  get isTipStep(): boolean {
    return (!this.captureStarted && !this.processSucceeded && !this.cancelByUser && !this.timeOut) && !this.serviceError;
  }
  get isCaptureProcess(): boolean { return (this.captureStarted && !this.processSucceeded) && !this.serviceError; }
  get isProcessSucceeded(): boolean { return this.processSucceeded && !this.serviceError; }
  get isServiceError(): boolean { return this.serviceError && !this.processSucceeded; }
  get isCancelByUser(): boolean { return this.cancelByUser && !this.processSucceeded; }
  get isTimeOut(): boolean { return this.timeOut; }
  get isLoading(): boolean { return this.loading; }

  private idImages: IdValidationImages = {
    frontImage: '',
    backImage: '',
    faceImage: '',
    scanReference: ''
  };

  constructor(
    selphidService: SelphidService,
    public font: FontService,
    public dialogRef: MatDialogRef<ModalFacialValidationComponent>,
    private utils: Util,
    private biometricService: FacialBiometricService,
    private traceabilityBioService: TraceabilityBioService
  ) {
    this.selphidService = selphidService;
  }

  public cancel(): void {
    this.dialogRef.close(CANCELED_PROCESS);
  }

  public continue(): void {
    this.idCaptureProcess.emit(this.idImages);
    this.captureStarted = false;
    this.serviceError = false;
    this.processSucceeded = false;
  }

  async onLaunchSelphIDProcess() {
    this.message = '';
    this.captureStarted = true;
    this.cancelByUser = false;
    this.serviceError = false;
    this.loading = false;
    this.timeOut = false;
    await this.selphidService.launchSelphidCapture()
      .then(
        (result: SelphIDResult) => this.onSuccessSelphIDCapture(result),
        (err: string) => this.onErrorSelphIDCapture(err)
      );
    this.frontDocumentImage = null;
    this.backDocumentImage = null;
    this.faceImage = null;
    this.isListExpanded = false;
  }

  //  Formatting output
  onSuccessSelphIDCapture(result: any) {
    // console.log('Receiving selphID success event...');
    if (result !== null && result) {
      switch (result.finishStatus) {
        case SelphIDFinishStatus.Ok:
          this.frontDocumentImage = result.frontDocumentImage;
          this.backDocumentImage = result.backDocumentImage;
          this.faceImage =
            (typeof result.faceImage === 'undefined' || result.faceImage === '') ?
              './assets/images/image_no_available.png' :
              result.faceImage;
          this.idImages.frontImage = this.frontDocumentImage;
          this.idImages.backImage = this.backDocumentImage;
          this.idImages.faceImage = this.faceImage;
          this.ocrData = result.documentData;
          this.showError = false;
          this.message = 'Preview selfie';
          this.captureStarted = false;
          this.loading = true;
          this.traceabilityBioService.traceProcess(CAPTURING_DOCUMENT, { status: ID_VALIDATION_TRACE_ACTIONS.finished });
          this.validateIDCard(this.idImages);
          break;
        case SelphIDFinishStatus.CancelByUser: // CancelByUser
          this.captureStarted = false;
          this.processSucceeded = false;
          this.cancelByUser = true;
          this.serviceError = false;
          this.traceabilityBioService.traceProcess(CAPTURING_DOCUMENT, { status: ID_VALIDATION_TRACE_ACTIONS.user_canceled });
          break;
        case SelphIDFinishStatus.StatusTimeout: // Timeout
          this.timeOut = true;
          this.processSucceeded = false;
          this.captureStarted = false;
          this.serviceError = false;
          this.cancelByUser = false;
          this.traceabilityBioService.traceProcess(CAPTURING_DOCUMENT, { status: ID_VALIDATION_TRACE_ACTIONS.extraction_timeout });
          break;
        default: // Timeout
          this.showError = true;
          this.message = result.errorMessage ?? 'Undefined error';
          this.traceabilityBioService.traceProcess(CAPTURING_DOCUMENT, { status: ID_VALIDATION_TRACE_ACTIONS.capturing_exception });
          break;
      }
    }
  }

  private validateIDCard(images: IdValidationImages): void {
    const { rut, transferId, site, module, process } = this.trolley;

    const data: IdValidationRequest = {
      backDNI: images.backImage,
      frontDNI: images.frontImage,
      date: this.utils.getISODate(),
      transferId,
      site,
      module,
      process
    };

    this.biometricService.documentValidation(rut, data).subscribe(
      (response) => {
        this.idImages.scanReference = response.scanReference;
        this.loading = false;
        this.processSucceeded = true;
        this.traceabilityBioService.traceProcess(SEND_DOCUMENT_CAPTURES, { status: ID_VALIDATION_TRACE_ACTIONS.finished });
        this.continue();
      },
      (error) => {
        // console.log(error);
        this.loading = false;
        this.serviceError = true;
        this.processSucceeded = false;
        this.traceabilityBioService.traceProcess(SEND_DOCUMENT_CAPTURES, { status: ID_VALIDATION_TRACE_ACTIONS.error });
      });

  }

  onErrorSelphIDCapture(result: any) {
    // console.log('Receiving selphID plugin error event...');
    this.showError = true;
    this.message = 'An error occurs with the widget to capture the credential. Please retry or try another validation method.';
    this.traceabilityBioService.traceProcess(CAPTURING_DOCUMENT, { status: ID_VALIDATION_TRACE_ACTIONS.capturing_exception });
    this.loading = false;
    this.captureStarted = false;
    this.processSucceeded = false;
    this.timeOut = false;
    this.serviceError = true;
  }
}
