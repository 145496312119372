/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { ModalDynamicKeyChannelComponent } from '@components/modal-dynamic-key-channel/modal-dynamic-key-channel.component';
import { ModalDynamicKeyValidateComponent } from '@components/modal-dynamic-key-validate/modal-dynamic-key-validate.component';
import { CLIENT_USER_TYPE, OTP_CHANGE_FUND, OTP_CREATION_SACU, OTP_DISTRIBUTION_FUND, OTP_RECOVERY_SACU } from '@constants/constants';
import { MODAL_OTP_CHANNEL, MODAL_OTP_VALIDATE } from '@constants/modal-data.constants';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { UserManagerService } from '@services/user-manager/user-manager.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OtpSecurityProvider {

  public messageSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  module: string; rut: string; email: string; phone: string; ammount: number;

  constructor(
    private readonly userManagerService: UserManagerService,
    private readonly modalProvider: ModalProvider,
    public loadingProvider: LoadingProvider,

  ) { }

  async openModalKeyChannel(module: string, rut: string, email: string, phone: string, amount?: number) {
    if (!email || !phone) {
      this.loadingProvider.showLoading();
      const dataUser = await this.userManagerService.getUserData(CLIENT_USER_TYPE)
        .toPromise().finally(() => this.loadingProvider.hideLoading()) as any;
      email = dataUser?.email;
      phone = dataUser?.cellphoneNumber;
    }

    const params = this.getOtpChannelData(module);

    this.modalProvider.openModalCenterCustom(ModalDynamicKeyChannelComponent, {
      title: params.title,
      subtitle: params.subtitle,
      message: params.message,
      opcionalMessage: amount ? `${amount}` : '',
      firstBtnText: params.firstBtnText,
      primaryCallback: (res) => {
        const idOtp = res;
        this.openModalKeyValidate(idOtp);
      },
      secondBtnText: params.secondBtnText,
      secondaryCallback: () => { },
      data: {
        mailTitle: params.mailTitle,
        mailValue: this.obscureMail(email),
        smsTitle: params.smsTitle,
        smsValue: this.obscurePhone(phone),
        options: {
          rut,
          module,
        }
      }
    });
    this.module = module;
    this.rut = rut;
    this.email = email;
    this.phone = phone;
    this.ammount = amount;
  }

  openModalKeyValidate(idOtp: string) {
    const params = MODAL_OTP_VALIDATE;

    this.modalProvider.openModalCenterCustom(ModalDynamicKeyValidateComponent, {
      title: params.title,
      subtitle: params.subtitle,
      message: params.message,
      description: params.description,
      opcionalMessage: params.messageValidate,
      firstBtnText: params.firstBtnText,
      primaryCallback: (res) => {
        const result = { token: res };
        this.messageSubject.next(result);
      },
      secondBtnText: params.secondBtnText,
      secondaryCallback: (res) => {
        if (res === 'retry') {
          this.openModalKeyChannel(this.module, this.rut, this.email, this.phone, this.ammount);
        }
      },
      data: {
        rut: this.rut,
        module: this.module,
        id: idOtp
      }
    });
  }

  getOtpSubject() {
    return this.messageSubject.asObservable();
  }

  clearOtpSubject() {
    this.messageSubject.next('');
  }

  obscureMail(email: string) {
    const [name, domain] = email.split('@');
    const obscuredName = `${name[0]}***${name.slice(-1)}`;
    const topLevelDomain = domain.split('.').pop();
    return `${obscuredName}@***.${topLevelDomain}`;
  }

  obscurePhone(phone: string) {
    const strValue = phone.toString();
    return phone ? '*'.repeat(strValue.length - 4) + strValue.slice(-4) : '';
  }

  private getOtpChannelData(module: string) {
    switch (module) {
      case OTP_CHANGE_FUND:
        return MODAL_OTP_CHANNEL('CHANGE_FUND');
      case OTP_DISTRIBUTION_FUND:
        return MODAL_OTP_CHANNEL('DISTRIBUTION_FUND');
      case OTP_CREATION_SACU:
        return MODAL_OTP_CHANNEL('CREATION_SACU');
      case OTP_RECOVERY_SACU:
        return MODAL_OTP_CHANNEL('RECOVERY_SACU');
      default:
        return MODAL_OTP_CHANNEL('WITHDRAW');
    }
  }
}
