export class ErrorResponse {
  statusCode: number;
  title: string;
  message: string;
  messageHt?: string;
  attemptsLeft?: number;
  securityKeyAttempts?: number;
  code?: number;
  errorMessage?: string;
  messageError?: string;
}
