import { environment } from '@env';

export const TRANSFER_APP_URL = 'https://traspaso.planvital.cl/';
export const PREVIRED_URL = 'https://www.previred.com/web/previred/';
export const PENSIONS_URL = 'https://www.spensiones.cl';
export const PUBLIC_SITE_URL = 'https://planvital.cl/';
export const BRANCH_OFFICES_URL = `${PUBLIC_SITE_URL}branch-offices`;
export const QUOTA_VALUE_URL = `${PUBLIC_SITE_URL}affiliate/assessment/financial-information/quota-value`;
export const PAYMENT_SCHEDULE_AND_PENSION_URL = `${PUBLIC_SITE_URL}pensioner/pension-payment/calendar`;
export const GO_BACK_TO_REQUEST = `https://sucursalenlinea.planvital.cl/solicitudes/ingreso`;

// URL BACK SERVICES

// // FUND BASE SERVICE
const FUNDS_CHANGES_FUTURE_BASE_URL = `${environment.baseUrl}clients/funds-changes`;
export const CUSTOM_CHANGE = `${FUNDS_CHANGES_FUTURE_BASE_URL}/future/`;
export const MATERIALIZATION_DATE_URL = `${FUNDS_CHANGES_FUTURE_BASE_URL}/future-materialization-dates/`;

export const CHANGE_FUND_SERVICE_URL = `${environment.clientsUrl}affiliates/change-fund`;
export const BENEFICIARY_CHANGE_FUND_SERVICE_URL = `${environment.clientsUrl}beneficiary/change-fund/`;
export const DISTRIBUTE_FUND_SERVICE_URL = `${environment.clientsUrl}affiliates/distribute-funds`;
export const BENEFICIARY_DISTRIBUTE_FUND_SERVICE_URL = `${environment.clientsUrl}beneficiary/distribute-funds/`;

// // ACCOUNT MANAGET SERVICE
const ACCOUNT_MANAGER_BASE_URL = `${environment.baseUrl}accounts`;

export const ACCOUNT_WITHDRAWAL_CAV_URL = `${ACCOUNT_MANAGER_BASE_URL}/CAV/withdraw/`;
export const ACCOUNT_WITHDRAWAL_CCO_URL = `${ACCOUNT_MANAGER_BASE_URL}/CCO/withdraw/`;
export const ACCOUNT_WITHDRAWAL_CCV_URL = `${ACCOUNT_MANAGER_BASE_URL}/CCV/withdraw/`;
export const ACCOUNT_WITHDRAWAL_CAF_URL = `${ACCOUNT_MANAGER_BASE_URL}/CAF/withdraw/`;
export const ACCOUNT_WITHDRAWAL_CAI_URL = `${ACCOUNT_MANAGER_BASE_URL}/CAI/withdraw/`;
export const ACCOUNT_WITHDRAWAL_CDC_URL = `${ACCOUNT_MANAGER_BASE_URL}/CDC/withdraw/`;

export const MANAGER_DISCOUNT_APV_SERVICE_URL = `${ACCOUNT_MANAGER_BASE_URL}/apv/opening_discount/`;
export const MANAGER_DISCOUNT_CAV_SERVICE_URL = `${ACCOUNT_MANAGER_BASE_URL}/cav/opening_discount/`;

// // CLIENT FORMS SERVICE
export const CLIENT_FORMS_BASE_URL = `${environment.affiliateApplicationsUrl}forms`;

export const ELDERLY_PENSION_REQUEST_URL = `${CLIENT_FORMS_BASE_URL}/pensionaries`;
export const DISABILITIY_PENSION_REQUEST_URL = `${CLIENT_FORMS_BASE_URL}/pensionaries/disabilities`;
export const SURPLUS_CALCULATION_REQUEST_URL = `${CLIENT_FORMS_BASE_URL}/pensionaries/freely-disposable-surplus-calculation`;
export const SURPLUS_PAYMENT_REQUEST_URL = `${CLIENT_FORMS_BASE_URL}/pensionaries/freely-disposable-surplus-payment`;
export const CAI_WITHDRAWAL_REQUEST_URL = `${CLIENT_FORMS_BASE_URL}/cai/withdrawals`;

// // OTHER SERVICE
export const SEND_CLAIMS_SERVICE_URL = `${environment.clientsUrl}claims`;
