const pageKey = 'HOME';

export const HOME_CONTENT = {
  earnedProfitability: {
    contributions: `${pageKey}.EARNED_PROFITABILITY.CONTRUBUTIONS`,
    profitability: `${pageKey}.EARNED_PROFITABILITY.PROFITABILITY`,
    withdrawals: `${pageKey}.EARNED_PROFITABILITY.WITHDRAWALS`,
    captions: {
      contributionQuotes: `${pageKey}.EARNED_PROFITABILITY.CAPTIONS.CONTRIBUTION_QUOTES`,
      earnedProfitabilities: `${pageKey}.EARNED_PROFITABILITY.CAPTIONS.EARNED_PROFITABILITY`,
      withdrawal10: `${pageKey}.EARNED_PROFITABILITY.CAPTIONS.WITHDRAWAL_10`,
      currentBalance: `${pageKey}.EARNED_PROFITABILITY.CAPTIONS.CURRENT_BALANCE`,
      suggestion: `${pageKey}.EARNED_PROFITABILITY.CAPTIONS.SUGGESTION`
    }
  },
  welcomePanel: {
    title: `${pageKey}.WELCOME_PANEL.TITLE`,
    executive: `${pageKey}.WELCOME_PANEL.EXECUTIVE`,
    missingDataMessage: `${pageKey}.WELCOME_PANEL.MISSING_DATA_MESSAGE`,
    goToUpdateData: `${pageKey}.WELCOME_PANEL.GO_TO_UPDATE_DATA`
  },
  videoStatement: {
    title: `${pageKey}.VIDEO_STATEMENT.TITLE`,
    subtitle: `${pageKey}.VIDEO_STATEMENT.SUBTITLE`,
    button: `${pageKey}.VIDEO_STATEMENT.BUTTON`,
    welcome: `${pageKey}.VIDEO_STATEMENT.WELCOME`,
  },
  videoStatementMobile: {
    title: `${pageKey}.VIDEO_STATEMENT_MOBILE.TITLE`,
    button: `${pageKey}.VIDEO_STATEMENT_MOBILE.BUTTON`,
  },
  evolutionBalanceChartMobile: {
    title: `${pageKey}.EVOLUTION_BALANCE_CHART_MOBILE.TITLE`,
    period: `${pageKey}.EVOLUTION_BALANCE_CHART_MOBILE.PERIOD`
  },
  myExecutive: {
    title: `${pageKey}.MY_EXECUTIVE.TITLE`,
    contactPhone: `${pageKey}.MY_EXECUTIVE.CONTACT_PHONE`,
    contactEmail: `${pageKey}.MY_EXECUTIVE.CONTACT_EMAIL`,
    clipboardText: `${pageKey}.MY_EXECUTIVE.CLIPBOARD_TEXT`,
    clipboardSnackbarCloseText: `${pageKey}.MY_EXECUTIVE.CLIPBOARD_SNACKBAR_CLOSE_TEXT`,
  },
  accountsOffer: {
    title: `${pageKey}.ACCOUNTS_OFFER.TITLE`,
  },
  downloadMarket: {
    title: `${pageKey}.DOWNLOAD_MARKET.TITLE`,
    button: `${pageKey}.DOWNLOAD_MARKET.BUTTON`,
    subtitle: `${pageKey}.DOWNLOAD_MARKET.SUBTITLE`
  },
  shortcuts: {
    title: `${pageKey}.SHORTCUTS.TITLE`,
    fundChange: `${pageKey}.SHORTCUTS.FUND_CHANGE`,
    affiliateCertificate: `${pageKey}.SHORTCUTS.AFFILIATE_CERTIFICATE`,
    quotationCertificate: `${pageKey}.SHORTCUTS.QUOTATION_CERTIFICATE`,
    apvRegimeCertificate: `${pageKey}.SHORTCUTS.APV_REGIME_CERTIFICATE`,
    generalRegimeCertificate: `${pageKey}.SHORTCUTS.GENERAL_REGIME_CERTIFICATE`,
    webMailbox: `${pageKey}.SHORTCUTS.WEB_MAILBOX`,
    complaints: `${pageKey}.SHORTCUTS.COMPLAINTS`,
    help: `${pageKey}.SHORTCUTS.HELP`,
    requests: `${pageKey}.SHORTCUTS.REQUESTS`,
    transfer: `${pageKey}.SHORTCUTS.TRANSFER`,
    updateData: `${pageKey}.SHORTCUTS.UPDATE_DATA`,
    paymentScheduleAndPension: `${pageKey}.SHORTCUTS.PAYMENT_SCHEDULE_AND_PENSION`,
    pensionSettlement: `${pageKey}.SHORTCUTS.PENSION_SETTLEMENT`,
    pensionPayment: `${pageKey}.SHORTCUTS.PENSION_PAYMENT`,
    mobileButton: `${pageKey}.SHORTCUTS.MOBILE_BUTTON`,
    securityKey: `${pageKey}.SHORTCUTS.SECURITY_KEY`,
    pensionaryCertificates: `${pageKey}.SHORTCUTS.PENSIONARY_CERTIFICATES`,
    pensionaryRequests: `${pageKey}.SHORTCUTS.PENSIONARY_REQUESTS`,
    affiliateRequests: `${pageKey}.SHORTCUTS.AFFILIATE_REQUESTS`,
    button: {
      seeMore: `${pageKey}.SHORTCUTS.BUTTON.SEE_MORE`,
      close: `${pageKey}.SHORTCUTS.BUTTON.CLOSE`
    }
  },
  accountsSummary: {
    summaryTitle: `${pageKey}.ACCOUNTS_SUMMARY.SUMMARY_TITLE`,
    generalAccount: {
      totalDescription: `${pageKey}.ACCOUNTS_SUMMARY.GENERAL_ACCOUNT.TOTAL_DESCRIPTION`,
      totalEarned: `${pageKey}.ACCOUNTS_SUMMARY.GENERAL_ACCOUNT.TOTAL_EARNED`,
    },
    accountSelected: {
      totalDescription: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_SELECTED.TOTAL_DESCRIPTION`,
    },
    accountToOffer: {
      stillNot: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_TO_OFFER.STILL_NOT`,
      invitation: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_TO_OFFER.INVITATION`,
      openApv: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_TO_OFFER.OPEN_APV`,
      openCav: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_TO_OFFER.OPEN_CAV`
    },
    chart: {
      totalLegendDescription: {
        firstSegment: `${pageKey}.ACCOUNTS_SUMMARY.CHART.TOTAL_LEGEND_DESCRIPTION.FIRST_SEGMENT`,
        secondSegment: `${pageKey}.ACCOUNTS_SUMMARY.CHART.TOTAL_LEGEND_DESCRIPTION.SECOND_SEGMENT`,
      }
    },
    buttons: {
      primary: `${pageKey}.ACCOUNTS_SUMMARY.BUTTONS.PRIMARY`,
      moreDetails: `${pageKey}.ACCOUNTS_SUMMARY.BUTTONS.MORE_DETAILS`,
      lessDetails: `${pageKey}.ACCOUNTS_SUMMARY.BUTTONS.LESS_DETAILS`,
      accountIncreaseBalanceOf: `${pageKey}.ACCOUNTS_SUMMARY.BUTTONS.INCREASE_BALANCE_OF`,
      accountIncreaseBalance: `${pageKey}.ACCOUNTS_SUMMARY.BUTTONS.INCREASE_BALANCE`,
    },
    disclaimer: `${pageKey}.ACCOUNTS_SUMMARY.DISCLAIMER`,
    detailsInstruction: `${pageKey}.ACCOUNTS_SUMMARY.DETAILS_INSTRUCTION`,
    tooltips: {
      regimeA: `${pageKey}.ACCOUNTS_SUMMARY.TOOLTIPS.REGIME_A`,
      regimeB: `${pageKey}.ACCOUNTS_SUMMARY.TOOLTIPS.REGIME_B`,
      generalRegime: `${pageKey}.ACCOUNTS_SUMMARY.TOOLTIPS.GENERAL_REGIME`,
      opcionalRegime: `${pageKey}.ACCOUNTS_SUMMARY.TOOLTIPS.OPCIONAL_REGIME`,
      Bis54Regime: `${pageKey}.ACCOUNTS_SUMMARY.TOOLTIPS.54BIS_REGIME`,
      transitorioRegime: `${pageKey}.ACCOUNTS_SUMMARY.TOOLTIPS.TRANSITORIO_REGIME`,
      otro54bisRegime: `${pageKey}.ACCOUNTS_SUMMARY.TOOLTIPS.OTRO_54BIS_REGIME`,
    },
    accountDetails: {
      title: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.TITLE`,
      fundsDistribution: {
        title: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.FUNDS_DISTRIBUTION.TITLE`,
        fund: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.FUNDS_DISTRIBUTION.FUND`,
        regime: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.FUNDS_DISTRIBUTION.REGIME`,
      },
      lastQuotation: {
        title: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.LAST_QUOTATION.TITLE`,
      },
      yearsListed: {
        title: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.YEARS_LISTED.TITLE`,
        years: {
          singular: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.YEARS_LISTED.YEARS.SINGULAR`,
          plural: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.YEARS_LISTED.YEARS.PLURAL`
        },
        secondSegment: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.YEARS_LISTED.SECOND_SEGMENT`,
        months: {
          singular: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.YEARS_LISTED.MONTHS.SINGULAR`,
          plural: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.YEARS_LISTED.MONTHS.PLURAL`
        },
      },
      moreDetails: {
        description: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.MORE_DETAILS.DESCRIPTION`,
        button: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_DETAILS.MORE_DETAILS.BUTTON`,
      }
    },
    accountCCOFrozen: {
      title: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_CCO_FROZEN.TITLE`,
      description: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_CCO_FROZEN.DESCRIPTION`,
      value: `${pageKey}.ACCOUNTS_SUMMARY.ACCOUNT_CCO_FROZEN.VALUE`,
    }
  },
  accountsSummaryMobile: {
    summaryTitle: `${pageKey}.ACCOUNTS_SUMMARY_MOBILE.SUMMARY_TITLE`,
    summaryShowMore: `${pageKey}.ACCOUNTS_SUMMARY_MOBILE.SUMMARY_SHOW_MORE`,
    summaryHideMore: `${pageKey}.ACCOUNTS_SUMMARY_MOBILE.SUMMARY_HIDE_MORE`,
    summarySaving: `${pageKey}.ACCOUNTS_SUMMARY_MOBILE.SUMMARY_SAVING`,
    summaryDetail: `${pageKey}.ACCOUNTS_SUMMARY_MOBILE.SUMMARY_DETAIL`,
    summaryFund: `${pageKey}.ACCOUNTS_SUMMARY_MOBILE.SUMMARY_FUND`,
    summaryIncreaseBalance: `${pageKey}.ACCOUNTS_SUMMARY_MOBILE.SUMMARY_INCREASE_BALANCE`,
    summaryEarned: `${pageKey}.ACCOUNTS_SUMMARY_MOBILE.SUMMARY_EARNED`,
  },
  accountsVariaton: {
    title: `${pageKey}.ACCOUNTS_VARIATON.TITLE`,
    subtitle: `${pageKey}.ACCOUNTS_VARIATON.SUBTITLE`,
  },
  biometricModal: {
    title: `${pageKey}.BIOMETRIC_MODAL.TITLE`,
    firstButton: `${pageKey}.BIOMETRIC_MODAL.FIRST_BUTTON`,
    secondButton: `${pageKey}.BIOMETRIC_MODAL.SECOND_BUTTON`,
  },
  myPensionPayment: {
    title: `${pageKey}.MY_PENSION_PAYMENT.TITLE`,
    nextPayment: `${pageKey}.MY_PENSION_PAYMENT.NEXT_PAYMENT`,
    subsequentPayment: `${pageKey}.MY_PENSION_PAYMENT.SUBSEQUENT_PAYMENT`,
    paymentWay: `${pageKey}.MY_PENSION_PAYMENT.PAYMENT_WAY`,
    notFount: `${pageKey}.MY_PENSION_PAYMENT.NOT_FOUNT`,
    buttons: {
      morePayments: `${pageKey}.MY_PENSION_PAYMENT.BUTTONS.MORE_PAYMENTS`,
      addReminder: `${pageKey}.MY_PENSION_PAYMENT.BUTTONS.ADD_REMINDER`,
      registerAccount: `${pageKey}.MY_PENSION_PAYMENT.BUTTONS.REGISTER_ACCOUNT`,
      reactivatePayment: `${pageKey}.MY_PENSION_PAYMENT.BUTTONS.REACTIVATE_PAYMENT`,
      pensionSettlement: `${pageKey}.MY_PENSION_PAYMENT.BUTTONS.PENSION_SETTLEMENT`,
    },
    datePayments: {
      title: `${pageKey}.MY_PENSION_PAYMENT.DATE_PAYMENTS.TITLE`,
      nextPayment: `${pageKey}.MY_PENSION_PAYMENT.DATE_PAYMENTS.NEXT_PAYMENT`,
      subsequentPayment: `${pageKey}.MY_PENSION_PAYMENT.DATE_PAYMENTS.SUBSEQUENT_PAYMENT`,
      buttons: {
        addReminder: `${pageKey}.MY_PENSION_PAYMENT.DATE_PAYMENTS.BUTTONS.ADD_REMINDER`
      },
    },
    modalPaymentWay: {
      title: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.TITLE`,
      bankNameList: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.BANK_NAME_LIST`,
      bankAccountNumber: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.BANK_ACCOUNT_NUMBER`,
      bankAccountType: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.BANK_ACCOUNT_TYPE`,
      bankCurrentAccount: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.BANK_CURRENT_ACCOUNT`,
      bankSavingAccount: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.BANK_SAVING_ACCOUNT`,
      bankDemandAccount: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.BANK_DEMAND_ACCOUNT`,
      disclaimerText: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.DISCLAIMER_TEXT`,
      authorizeDataCheck: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.AUTHORIZE_DATA_CHECK`,
      personalAccountConfirmation: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.PERSONAL_ACCOUNT_CONFIRMATION`,
      buttonSendData: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.BUTTON_SEND_DATA`,
      errors: {
        required: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.ERRORS.REQUIRED`,
        noBankSelected: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.ERRORS.NO_BANK_SELECTED`,
        noAccountSelected: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.ERRORS.NO_ACCOUNT_SELECTED`,
      },
      success: {
        title: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.SUCCESS.TITLE`,
        content: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.SUCCESS.CONTENT`,
        acceptButton: `${pageKey}.MY_PENSION_PAYMENT.MODAL_PAYMENT_WAY.SUCCESS.ACCEPT_BUTTON`,
      }
    }
  }
};
