const pageKey = 'CAI_WITHDRAWAL';
const savedModal = 'REQUESTS.MODALS.SAVED';
const toastKey = `${pageKey}.TOAST`;
const paymentMethodsKey = `${pageKey}.STEPS.PAYMENT_METHOD.PAYMENT.FORM`;

export const CAI_WITHDRAWAL_FORM_FILE_TYPE = 'retiro-cai';

export const CAI_WITHDRAWAL_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved'
};

export const CAI_WITHDRAWAL_CONTENT = {
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`
  },
  breadcrumb: `${pageKey}.BREADCRUMB`,
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  securityKeyButtons: {
    main: `${pageKey}.SECURITY_KEY_BUTTONS.MAIN`,
    back: `${pageKey}.SECURITY_KEY_BUTTONS.BACK`
  },
  stepsNames: [
    `${pageKey}.STEPS_NAMES.BEFORE_BEGIN`,
    `${pageKey}.STEPS_NAMES.BALANCES_BY_EMPLOYER`,
    `${pageKey}.STEPS_NAMES.WITHDRAWAL_DETAIL`,
    `${pageKey}.STEPS_NAMES.DOCUMENTS`,
    `${pageKey}.STEPS_NAMES.PAYMENT`,
    `${pageKey}.STEPS_NAMES.VOUCHER`,
  ],
  steps: {
    balancesByEmployer: {
      title: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.TITLE`,
      description: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.DESCRIPTION`,
      quotaDate: {
        success: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.QUOTA_DATE.SUCCESS`,
        failure: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.QUOTA_DATE.FAILURE`
      },
      headers: {
        rut: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.RUT`,
        businessName: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.BUSINESS_NAME`,
        startDate: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.START_DATE`,
        firstFund: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.FIRST_FUND`,
        firstFundQuotes: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.FIRST_FUND_QUOTES`,
        firstFundBalance: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.FIRST_FUND_BALANCE`,
        secondFund: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.SECOND_FUND`,
        secondFundQuotes: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.SECOND_FUND_QUOTES`,
        secondFundBalances: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.SECOND_FUND_BALANCES`,
        selection: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.HEADERS.SELECTION`
      },
      buttons: {
        withdraw: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.BUTTONS.WITHDRAW`,
        next: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.BUTTONS.NEXT`,
        previous: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.BUTTONS.PREVIOUS`,
        saveContinueLater: `${pageKey}.STEPS.BALANCES_BY_EMPLOYER.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.BUTTONS.DELETE_REQUEST`,
      }
    },
    withdrawalDetail: {
      title: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.TITLE`,
      headers: {
        rut: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.HEADERS.RUT`,
        businessName: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.HEADERS.BUSINESS_NAME`
      },
      cause: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.CAUSE`,
      form: {
        cause: {
          label: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.FORM.CAUSE.LABEL`,
          disclaimer: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.FORM.CAUSE.DISCLAIMER`,
          error: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.FORM.CAUSE.ERROR`
        },
        datePlaceholder: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.FORM.DATE_PLACEHOLDER`,
        documentsTitle: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.FORM.DOCUMENTS_TITLE`
      },
      disclaimer: {
        success: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.DISCLAIMER.SUCCESS`,
        failure: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.DISCLAIMER.FAILURE`
      },
      information: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.INFORMATION`,
      buttons: {
        next: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.BUTTONS.NEXT`,
        previous: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.BUTTONS.PREVIOUS`,
        saveContinueLater: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.BUTTONS.DELETE_REQUEST`,
      }
    },
    documents: {
      label: `${pageKey}.STEPS.DOCUMENTS.LABEL`,
      title: `${pageKey}.STEPS.DOCUMENTS.SECTION.TITLE`,
      description: `${pageKey}.STEPS.DOCUMENTS.SECTION.DESCRIPTION`,
      disclaimer: `${pageKey}.STEPS.DOCUMENTS.DISCLAIMER`,
      fileSizeWarning: `${pageKey}.STEPS.DOCUMENTS.FILE_SIZE_WARNING`,
      buttons: {
        addDocumentClient: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.ADD_DOCUMENT_CLIENT`,
        addDocumentEmployer: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.ADD_DOCUMENT_EMPLOYER`,
        next: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.NEXT`,
        back: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.BACK`,
        saveContinueLater: `${pageKey}.STEPS.DOCUMENTS.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${pageKey}.STEPS.WITHDRAWAL_DETAIL.BUTTONS.DELETE_REQUEST`,
      },
      errors: {
        required: `${pageKey}.STEPS.DOCUMENTS.ERRORS.REQUIRED`
      },
      otherFiles: {
        title: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.TITLE`,
        attachIdFile: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.ATTACH_ID_FILE`,
        settlement: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.SETTLEMENT`,
        laborManagmentAct: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.LABOR_MANAGEMENT_ACT`,
        conciliationAct: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.CONCILIATION_ACT`,
        courtJudgment: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.COURT_JUDGEMENT`,
        other: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.OTHER`,
        attachFormat: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.ATTACH_FORMAT`,
        attachExample: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.ATTACH_EXAMPLE`,
        attachMaxSize: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.ATTACH_MAX_SIZE`,
        attachDifferentFiles: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.DIFFERENT_FILES`,
        otherFileDescription: `${pageKey}.STEPS.DOCUMENTS.OTHER_FILES.OTHER_FILE_DESCRIPTION`
      },
      statement: `${pageKey}.STEPS.DOCUMENTS.STATEMENT`
    },
    paymentMethod: {
      title: `${pageKey}.STEPS.PAYMENT_METHOD.TITLE`,
      headers: {
        fund: `${pageKey}.STEPS.PAYMENT_METHOD.HEADERS.FUND`,
        quota: `${pageKey}.STEPS.PAYMENT_METHOD.HEADERS.QUOTA`,
        amount: `${pageKey}.STEPS.PAYMENT_METHOD.HEADERS.AMOUNT`,
        totalAmount: `${pageKey}.STEPS.PAYMENT_METHOD.HEADERS.TOTAL_AMOUNT`
      },
      payment: {
        title: `${pageKey}.STEPS.PAYMENT_METHOD.PAYMENT.TITLE`,
        description: `${pageKey}.STEPS.PAYMENT_METHOD.PAYMENT.DESCRIPTION`,
        form: {
          label: `${paymentMethodsKey}.LABEL`,
          formSection: {
            title: `${paymentMethodsKey}.FORM_SECTION.TITLE`,
            transfer: `${paymentMethodsKey}.FORM_SECTION.TRANSFER`,
            cash: `${paymentMethodsKey}.FORM_SECTION.CASH`,
            bank: {
              field: `${paymentMethodsKey}.FORM_SECTION.BANK.FIELD`,
              options: `${paymentMethodsKey}.FORM_SECTION.BANK.OPTIONS`
            },
            account: {
              field: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.FIELD`,
              options: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.OPTIONS`
            },
            other: {
              options: `${paymentMethodsKey}.FORM_SECTION.OTHER.OPTIONS`
            }
          },
          disclaimer: `${paymentMethodsKey}.DISCLAIMER`,
          digitalAccountDisclaimer: `${paymentMethodsKey}.DIGITAL_ACCOUNT_DISCLAIMER`,
          errors: {
            required: `${paymentMethodsKey}.ERRORS.REQUIRED`,
            digitalAccount: `${paymentMethodsKey}.ERRORS.DIGITAL_ACCOUNT`
          },
          buttons: {
            submit: `${paymentMethodsKey}.BUTTONS.SUBMIT`,
            back: `${paymentMethodsKey}.BUTTONS.BACK`
          }
        }
      },
      buttons: {
        previous: `${pageKey}.STEPS.PAYMENT_METHOD.PAYMENT.BUTTONS.PREVIOUS`
      }
    },
    voucher: {
      title: `${pageKey}.STEPS.VOUCHER.TITLE`,
      folio: `${pageKey}.STEPS.VOUCHER.FOLIO`,
      description: `${pageKey}.STEPS.VOUCHER.DESCRIPTION`,
      homeButton: `${pageKey}.STEPS.VOUCHER.HOME_BUTTON`,
      download: `${pageKey}.STEPS.VOUCHER.DOWNLOAD_PDF`
    }
  },
  error: {
    generic: `${pageKey}.ERROR.GENERIC`,
    custom: `${pageKey}.ERROR.CUSTOM`,
    button: `${pageKey}.ERROR.BUTTON`
  }
};

const attachmentContent = CAI_WITHDRAWAL_CONTENT.steps.documents.otherFiles;
export const CAI_FILES = [
  {
    order: 0,
    buttonLabel: attachmentContent.settlement,
    fileIndex: 'settlement',
    required: false
  },
  {
    order: 1,
    buttonLabel: attachmentContent.laborManagmentAct,
    fileIndex: 'laborManagementAct',
    required: false
  },
  {
    order: 2,
    buttonLabel: attachmentContent.conciliationAct,
    fileIndex: 'conciliationAct',
    required: false
  },
  {
    order: 3,
    buttonLabel: attachmentContent.courtJudgment,
    fileIndex: 'courtJudgment',
    required: false
  },
];
