import { Mock } from '@interfaces/mock.interface';

export const EMPTY_MOCK: Mock = {
  success: [{
    response: {
      folio: '1',
      isFirstSave: true
    }
  }],
  failures: [{
    response: {}
  }]
};
