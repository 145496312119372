import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class UpdateAppService {
  private updateAppSource: Subject<boolean> = new Subject<boolean>();
  public updateAppIntercepted: Observable<boolean> = this.updateAppSource.asObservable();

  constructor(private updates: SwUpdate) { }

  public getUpdateAppSubscription(): Observable<boolean> {
    return this.updateAppIntercepted;
  }

  public checkForUpdates(): void {
    this.updates.available
      .subscribe(() => this.updates.activateUpdate().then(() => this.updateAppSource.next(true)));
  }
}
