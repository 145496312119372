import { Mock } from '@interfaces/mock.interface';

export const ECONOMIC_ACTIVITIES_MOCKUP: Mock = {
  success: [{
    response: [
      {
        id: '000',
        description: 'No informado'
      },
      {
        id: '001',
        description: 'Pensionado'
      },
      {
        id: '011',
        description: 'Fuerzas Armadas'
      },
      {
        id: '111',
        description: 'Miembros del poder ejecutivo y de los cuerpos legislativos.'
      },
      {
        id: '112',
        description: 'Personal directivo de la administracion publica'
      },
      {
        id: '114',
        description: 'Dirigentes y administradores de organizaciones especializadas'
      },
      {
        id: '121',
        description: 'Directores generales y gerentes generales de empresa'
      },
      {
        id: '122',
        description: 'Directores de departamentos de produccion y operaciones'
      },
      {
        id: '123',
        description: 'Otros directores de departamentos'
      },
      {
        id: '131',
        description: 'Gerentes de empresa'
      },
      {
        id: '211',
        description: 'Fisicos, quimicos y afines'
      },
      {
        id: '212',
        description: 'Matematicos, estadisticos y afines'
      },
      {
        id: '213',
        description: 'Profesionales de Informatica'
      },
      {
        id: '214',
        description: 'Arquitectos, ingenieros y afines'
      },
      {
        id: '221',
        description: 'Profesionales en ciencias biologicas y otras disciplinas relativas a los seres organicos'
      },
      {
        id: '222',
        description: 'Medicos y profesionales afines (excepto el personal de enfermeria y parteria)'
      },
      {
        id: '223',
        description: 'Personal de enfermeria y parteria de nivel superior'
      },
      {
        id: '231',
        description: 'Profesores de universidades y otros establecimientos de la enseñanza superior.'
      },
      {
        id: '232',
        description: 'Profesores de la enseñanza secundaria'
      },
      {
        id: '233',
        description: 'Maestros de nivel superior de la enseñanza primaria y preescolar'
      },
      {
        id: '234',
        description: 'Maestros e instructores de nivel superior de la enseñanza especial'
      },
      {
        id: '235',
        description: 'Otros profesionales de la enseñanza'
      },
      {
        id: '241',
        description: 'Especialistas en organizacion y administracion de empresas y afines'
      },
      {
        id: '242',
        description: 'Profesionales del derecho'
      },
      {
        id: '243',
        description: 'Archiveros, bibliotecarios, documentalistas y afines'
      },
      {
        id: '244',
        description: 'Especialistas en ciencias sociales y humanas'
      },
      {
        id: '245',
        description: 'Escritores, artistas creativos y ejecutantes'
      },
      {
        id: '246',
        description: 'Sacerdotes de distintas religiones'
      },
      {
        id: '311',
        description: 'Tecnicos en ciencias fisicas y quimicas y en ingenieria'
      },
      {
        id: '312',
        description: 'Tecnicos en programacion y control informaticos'
      },
      {
        id: '313',
        description: 'Operadores de equipos opticos y electronicos'
      },
      {
        id: '314',
        description: 'Tecnicos en navegacion maritima y aeronautica'
      },
      {
        id: '315',
        description: 'Inspectores de obras, seguridad y salud y control de calidad'
      },
      {
        id: '321',
        description: 'Tecnicos de nivel medio en ciencias biologicas, agronomia, zootecnia y afines'
      },
      {
        id: '322',
        description: 'Profesionales de nivel medio de la medicina moderna y la salud (excepto el personal de enfermeria y parteria)'
      },
      {
        id: '323',
        description: 'Personal de enfermeria y parteria de nivel medio'
      },
      {
        id: '324',
        description: 'Practicantes de la medicina tradicional y curanderos'
      },
      {
        id: '331',
        description: 'Maestros de nivel medio de la enseñanza primaria'
      },
      {
        id: '332',
        description: 'Maestros de nivel medio de la enseñanza preescolar'
      },
      {
        id: '333',
        description: 'Maestros de nivel medio de la enseñanza especial'
      },
      {
        id: '334',
        description: 'Otros maestros e instructores de nivel medio'
      },
      {
        id: '341',
        description: 'Profesionales de nivel medio en operaciones financieras y comerciales'
      },
      {
        id: '342',
        description: 'Agentes comerciales y corredores'
      },
      {
        id: '343',
        description: 'Profesionales de nivel medio de servicios de administracion'
      },
      {
        id: '344',
        description: 'Agentes de las administraciones publicas de aduanas, impuestos y afines'
      },
      {
        id: '345',
        description: 'Inspectores de policia y detectives'
      },
      {
        id: '346',
        description: 'Trabajadores y asistentes sociales de nivel medio'
      },
      {
        id: '347',
        description: 'Profesionales de nivel medio de actividades artisticas, espectaculos y deportes'
      },
      {
        id: '348',
        description: 'Auxiliares laicos de los cultos'
      },
      {
        id: '411',
        description: 'Secretarios y operadores de maquinas de oficina.'
      },
      {
        id: '412',
        description: 'Auxiliares contables y financieros.'
      },
      {
        id: '413',
        description: 'Empleados encargados del registro de materiales y de transportes.'
      },
      {
        id: '414',
        description: 'Empleados de bibliotecas y servicios de correos y afines.'
      },
      {
        id: '419',
        description: 'Otros oficinistas.'
      },
      {
        id: '421',
        description: 'Cajeros, taquilleros y afines.'
      },
      {
        id: '422',
        description: 'Empleados de servicios de informacion a la clientela.'
      },
      {
        id: '511',
        description: 'Personal al servicio directo de los pasajeros.'
      },
      {
        id: '512',
        description: 'Personal de intendencia y de restauracion.'
      },
      {
        id: '513',
        description: 'Trabajadores de los cuidados personales y afines.'
      },
      {
        id: '514',
        description: 'Otros trabajadores de servicios personales a particulares.'
      },
      {
        id: '515',
        description: 'Astrologos, adivinadores y afines.'
      },
      {
        id: '516',
        description: 'Personal de los servicios de proteccion y seguridad.'
      },
      {
        id: '521',
        description: 'Modelos de modas, arte y publicidad.'
      },
      {
        id: '522',
        description: 'Vendedores y demostradores de tiendas y almacenes.'
      },
      {
        id: '523',
        description: 'Vendedores de quioscos y de puestos de mercado.'
      },
      {
        id: '611',
        description: 'Agricultores y trabajadores calificados de cultivos para el mercado.'
      },
      {
        id: '612',
        description: 'Criadores y trabajadores pecuarios calificados de la cria de animales para el mercado y afines.'
      },
      {
        id: '613',
        description: 'Productores y trabajadores agropecuarios calificados cuya produccion se destina al mercado.'
      },
      {
        id: '614',
        description: 'Trabajadores forestales calificados y afines.'
      },
      {
        id: '615',
        description: 'Pescadores, cazadores y tramperos.'
      },
      {
        id: '621',
        description: 'Trabajadores agropecuarios y pesqueros de subsistencia.'
      },
      {
        id: '711',
        description: 'Mineros, canteros, pegadores y labrantes de piedra.'
      },
      {
        id: '712',
        description: 'Oficiales y operarios de la construccion (obra gruesa) y afines.'
      },
      {
        id: '713',
        description: 'Oficiales y operarios de la construccion (trabajos de acabado) y afines.'
      },
      {
        id: '714',
        description: 'Pintores, limpiadores de fachadas y afines.'
      },
      {
        id: '721',
        description: 'Moldeadores, soldadores, chapistas, caldereros, montadores de estructuras metalicas y afines'
      },
      {
        id: '722',
        description: 'Herreros, herramentistas y afines.'
      },
      {
        id: '723',
        description: 'Mecanicos y ajustadores de maquinas.'
      },
      {
        id: '724',
        description: 'Mecanicos y ajustadores de equipos electricos y electronicos.'
      },
      {
        id: '731',
        description: 'Mecanicos de precision en metales y materiales similares.'
      },
      {
        id: '732',
        description: 'Alfareros, operarios de cristalerias y afines.'
      },
      {
        id: '733',
        description: 'Artesanos de la madera, tejidos, cuero y materiales similares.'
      },
      {
        id: '734',
        description: 'Oficiales y operarios de las artes graficas y afines.'
      },
      {
        id: '741',
        description: 'Oficiales y operarios del procesamiento de alimentos y afines.'
      },
      {
        id: '742',
        description: 'Oficiales y operarios del tratamiento de la madera, ebanistas y afines.'
      },
      {
        id: '743',
        description: 'Oficiales y operarios de los textiles y de la confeccion y afines'
      },
      {
        id: '744',
        description: 'Oficiales y operarios de las pieles, cuero y calzado.'
      },
      {
        id: '811',
        description: 'Operadores de instalaciones mineras y de extraccion y procesamiento de minerales.'
      },
      {
        id: '812',
        description: 'Operadores de instalaciones de procesamiento de metales.'
      },
      {
        id: '813',
        description: 'Operadores de instalaciones de vidrieria, ceramica y afines.'
      },
      {
        id: '814',
        description: 'Operadores de instalaciones de procesamiento de la madera y de la fabricacion de papel.'
      },
      {
        id: '815',
        description: 'Operadores de instalaciones de tratamientos quimicos.'
      },
      {
        id: '816',
        description: 'Operadores de cadenas de montaje automatizadas y de robots industriales.'
      },
      {
        id: '821',
        description: 'Operadores de maquinas para trabajar metales y productos minerales.'
      },
      {
        id: '822',
        description: 'Operadores de maquinas para fabricar productos quimicos.'
      },
      {
        id: '823',
        description: 'Operadores de maquinas para fabricar productos de caucho y de material plastico.'
      },
      {
        id: '824',
        description: 'Operadores de maquinas para fabricar productos de madera.'
      },
      {
        id: '825',
        description: 'Operadores de maquinas de imprenta, encuadernacion y fabricacion de productos de papel.'
      },
      {
        id: '826',
        description: 'Operadores de maquinas para fabricar productos textiles y articulos de piel y cuero.'
      },
      {
        id: '827',
        description: 'Operadores de maquinas para elaborar alimentos y productos afines.'
      },
      {
        id: '828',
        description: 'Montadores.'
      },
      {
        id: '829',
        description: 'Otros operadores de maquinas y montadores.'
      },
      {
        id: '831',
        description: 'Maquinistas de locomotoras y afines.'
      },
      {
        id: '832',
        description: 'Conductores de vehiculos de motor.'
      },
      {
        id: '833',
        description: 'Operadores de maquinaria agricola movil y de otras maquinas moviles.'
      },
      {
        id: '834',
        description: 'Marineros de cubierta y afines.'
      },
      {
        id: '911',
        description: 'Vendedores ambulantes y afines.'
      },
      {
        id: '912',
        description: 'Limpiabotas y otros trabajadores callejeros.'
      },
      {
        id: '913',
        description: 'Personal domestico y afines, limpiadores, lavanderos y planchadores.'
      },
      {
        id: '914',
        description: 'Conserjes, lavadores de ventanas y afines.'
      },
      {
        id: '915',
        description: 'Mensajeros, porteadores, porteros y afines.'
      },
      {
        id: '916',
        description: 'Recolectores de basura y afines.'
      },
      {
        id: '921',
        description: 'Peones agropecuarios, forestales, pesqueros y afines.'
      },
      {
        id: '931',
        description: 'Peones de la mineria y la construccion.'
      },
      {
        id: '932',
        description: 'Peones de la industria manufacturera.'
      },
      {
        id: '933',
        description: 'Peones del transporte.'
      },
    ]
  }],
  failures: [{
    response: {}
  }]
};
