import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { MapComponent } from '@components/map/map.component';
import { DirectivesModule } from '@directives/directives.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CodeStepComponent } from '@pages/create-security-key/code-step/code-step.component';
import {
  CreationStepComponent
} from '@pages/create-security-key/creation-step/creation-step.component';
import { ModalDoneStepComponent } from '@pages/create-security-key/modal-done-step/modal-done-step.component';
import { QuestionStepComponent } from '@pages/create-security-key/question-step/question-step.component';
import {
  RequestStepComponent
} from '@pages/create-security-key/request-step/request-step.component';
import {
  ValidationStepComponent
} from '@pages/create-security-key/validation-step/validation-step.component';
import { PensionPaymentMethodComponent } from '@pages/pension-payment-method/pension-payment-method.component';
import { PdfViewerProvider } from '@providers/pdf-viewer/pdf-viewer';
import {
  DownloadMarketComponent
} from 'app/shared/components/download-market/download-market.component';
import { ChartistModule } from 'ng-chartist';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { AccessibilityComponent } from './accessibility/accessibility.component';
import { AccountSummaryPaginationComponent } from './account-summary-pagination/account-summary-pagination.component';
import { AccountsOfferComponent } from './accounts-offer/accounts-offer.component';
import { AccountsSummaryMobileComponent } from './accounts-summary-mobile/accounts-summary-mobile.component';
import { AccountsVariationProcessedComponent } from './accounts-variation-processed/accounts-variation-processed.component';
import {
  AdvisorBackgroundFormComponent
} from './advisor-background-form/advisor-background-form.component';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { AlertNewsBannerMobileComponent } from './alert-news-banner-mobile/alert-news-banner-mobile.component';
import { AlertNewsStripBannerComponent } from './alert-news-strip-banner/alert-news-strip-banner.component';
import { AlertToastComponent } from './alert-toast/alert-toast.component';
import { AssistanceButtonComponent } from './assistance-button/assistance-button.component';
import { AssistanceContainerComponent } from './assistance-container/assistance-container.component';
import { AssistanceInHoursComponent } from './assistance-in-hours/assistance-in-hours.component';
import { AssistanceOutHoursComponent } from './assistance-out-hours/assistance-out-hours.component';
import { AttachFilesComponent } from './attach-files/attach-files.component';
import { AvatarComponent } from './avatar/avatar.component';
import {
  BackgroundFormPensionAdditionalComponent
} from './background-form-pension-additional/background-form-pension-additional.component';
import { BackgroundFormComponent } from './background-form/background-form.component';
import { BeneficiaryAffiliateSelectorComponent } from './beneficiary-affiliate-selector/beneficiary-affiliate-selector.component';
import { IdCaptureGuidelinesComponent } from './biometric-steps/01-id-capture-guidelines/id-capture-guidelines.component';
import { IdCaptureComponent } from './biometric-steps/01-id-capture/id-capture.component';
import { FacialCaptureGuidelinesComponent } from './biometric-steps/02-facial-capture-guidelines/facial-capture-guidelines.component';
import { FacialCaptureComponent } from './biometric-steps/02-facial-capture/facial-capture.component';
import { ProcessValidationComponent } from './biometric-steps/03-process-validation/process-validation.component';
import { BtnSeeTutorialComponent } from './biometric-steps/btn-see-tutorial/btn-see-tutorial.component';
import { BiometricAccessComponent } from './biometry-access/biometry-acces.component';
import { BottomSheetTemplateComponent } from './bottom-sheet-template/bottom-sheet-template.component';
import {
  CardAttachedDocumentsComponent
} from './card-attached-documents/card-attached-documents.component';
import { CardCommentsComponent } from './card-comments/card-comments.component';
import { CardComponent } from './card/card.component';
import { CertificateFormComponent } from './certificate-form/certificate-form.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ChangeSecurityKeyFormComponent } from './change-security-key-form/change-security-key-form.component';
import {
  ChartInvestorProfileComponent
} from './chart-investor-profile/chart-investor-profile.component';
import { ChartQuotaValuesComponent } from './chart-quota-values/chart-quota-values.component';
import { CircularChartComponent } from './circular-chart/circular-chart.component';
import { CommentComponent } from './comment/comment.component';
import { ComplainTicketCardComponent } from './complain-ticket-card/complain-ticket-card.component';
import { ComplainsComponent } from './complains/complains.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { DelegateFormComponent } from './delegate-form/delegate-form.component';
import { DeleteButtonRequestComponent } from './delete-button-request/delete-button-request.component';
import { DirectMenuComponent } from './direct-menu/direct-menu.component';
import { DisabilityFormComponent } from './disability-form/disability-form.component';
import { DisclaimerBoxComponent } from './disclaimer-box/disclaimer-box.component';
import { EarnedProfitabilityGraphicMobileComponent } from './earned-profitability-graphic-mobile/earned-profitability-graphic-mobile.component';
import { EarnedProfitabilityGraphicComponent } from './earned-profitability-graphic/earned-profitability-graphic.component';
import {
  EmployerBackgroundFormComponent
} from './employer-background-form/employer-background-form.component';
import {
  EmployerCertificateFormComponent
} from './employer-certificate-form/employer-certificate-form.component';
import { EmployerDataFormComponent } from './employer-data-form/employer-data-form.component';
import { EmployerInfoComponent } from './employer-info/employer-info.component';
import {
  EmploymentBackgroundFormComponent
} from './employment-background-form/employment-background-form.component';
import {
  EmploymentDocumentsFormComponent
} from './employment-documents-form/employment-documents-form.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { FilterChipsComponent } from './filter-chips/filter-chips.component';
import { FullDatePickerComponent } from './full-date-picker/full-date-picker.component';
import { FundBoxComponent } from './fund-box/fund-box.component';
import { FundSelectorComponent } from './fund-selector/fund-selector.component';
import { FundsChangeVoucherComponent } from './funds-change-voucher/funds-change-voucher.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { ImpositionFormComponent } from './impositions-form/imposition-form.component';
import { IndiVideoComponent } from './indi-video/indi-video.component';
import { InfoTextPopupComponent } from './info-text-popup/info-text-popup.component';
import { InvestorProfileBoxComponent } from './investor-profile/investor-profile.component';
import {
  InvestorResultPopoverComponent
} from './investor-result-popover/investor-result-popover.component';
import {
  InvestorSimulationAccionsComponent
} from './investor-simulation-accions/investor-simulation-accions.component';
import { LoadingComponent } from './loading/loading.component';
import {
  MedicalBackgroundFormComponent
} from './medical-background-form/medical-background-form.component';
import {
  ModalAcceptApplicationComponent
} from './modal-accept-application/modal-accept-application.component';
import { ModalAccountValidationComponent } from './modal-account-validation/modal-account-validation.component';
import { ModalBeneficiaryAdmComponent } from './modal-beneficiary-adm/modal-beneficiary-adm.component';
import { ModalBeneficiaryComponent } from './modal-beneficiary/modal-beneficiary.component';
import { ModalBioTutorialComponent } from './modal-bio-tutorial/modal-bio-tutorial.component';
import { ModalBiometricConfirmationComponent } from './modal-biometric-confirmation/modal-biometric-confirmation.component';
import {
  ModalCertificateFormComponent
} from './modal-certificate-form/modal-certificate-form.component';
import { ModalCESComponent } from './modal-ces/modal-ces.component';
import { ModalComplainsComponent } from './modal-complains/modal-complains.component';
import { ModalConfirmCesComponent } from './modal-confirm-ces/modal-confirm-ces.component';
import { ModalConfirmDeleteRequestComponent } from './modal-confirm-delete-request/modal-confirm-delete-request.component';
import {
  ModalConfirmExitRequestComponent
} from './modal-confirm-exit-request/modal-confirm-exit-request.component';
import { ModalContactCenterComponent } from './modal-contact-center/modal-contact-center.component';
import {
  ModalContactInfoUpdateComponent
} from './modal-contact-info-update/modal-contact-info-update.component';
import { ModalContactMeComponent } from './modal-contact-me/modal-contact-me.component';
import { ModalContactStepZeroComponent } from './modal-contact-step-zero/modal-contact-step-zero.component';
import { ModalContactComponent } from './modal-contact/modal-contact.component';
import { ModalDefinitionsComponent } from './modal-definitions/modal-definitions.component';
import { ModalDeleteSuccessComponent } from './modal-delete-success/modal-delete-success.component';
import { ModalDynamicKeyChannelComponent } from './modal-dynamic-key-channel/modal-dynamic-key-channel.component';
import { ModalDynamicKeyValidateComponent } from './modal-dynamic-key-validate/modal-dynamic-key-validate.component';
import { ModalEmailErrorComponent } from './modal-email-error/modal-email-error.component';
import {
  ModalEmployerCertificateFormComponent
} from './modal-employer-certificate-form/modal-employer-certificate-form.component';
import {
  ModalEmployerContractComponent
} from './modal-employer-contract/modal-employer-contract.component';
import { ModalEmployerInfoRequestComponent } from './modal-employer-info-request/modal-employer-info-request.component';
import { ModalFacialValidationComponent } from './modal-facial-validation/modal-facial-validation.component';
import { ModalForceUpdateComponent } from './modal-force-update/modal-force-update.component';
import { ModalImpositionComponent } from './modal-impositions/modal-imposition.component';
import {
  ModalIncreaseBalanceComponent
} from './modal-increase-balance/modal-increase-balance.component';
import { ModalInfoPensionAdditionalComponent } from './modal-info-pension-additional/modal-info-pension-additional.component';
import { ModalInformationalComponent } from './modal-informational/modal-informational.component';
import { ModalMedicalReportComponent } from './modal-medical-report/modal-medical-report.component';
import { ModalMoreDatePaymentsComponent } from './modal-more-date-payments/modal-more-date-payments.component';
import { ModalOfferSchedulingComponent } from './modal-offer-scheduling/modal-offer-scheduling.component';
import {
  ModalOverpaymentDocumentComponent
} from './modal-overpayment-document/modal-overpayment-document.component';
import { ModalPartnerAddComponent } from './modal-partner-add/modal-partner-add.component';
import { ModalPensionerPaymentWayComponent } from './modal-pensioner-payment-way/modal-pensioner-payment-way.component';
import { ModalPreviredComponent } from './modal-previred/modal-previred.component';
import { ModalProfitabilityFundsComponent } from './modal-profitability-funds/modal-profitability-funds.component';
import { ModalQrComponent } from './modal-qr/modal-qr.component';
import {
  ModalQuotationVideoComponent
} from './modal-quotation-video/modal-quotation-video.component';
import { ModalReactivatePaymentComponent } from './modal-reactivate-payment/modal-reactivate-payment.component';
import {
  ModalStopScreenshareComponent
} from './modal-stop-screenshare/modal-stop-screenshare.component';
import { ModalSuccessfulyAnticipatedDeferredRequestComponent } from './modal-successfuly-anticipated-deferred-request/modal-successfuly-anticipated-deferred-request.component';
import {
  ModalSuccessfullySavedPartialRequestComponent
} from './modal-successfuly-saved-partial-request/modal-successfuly-saved-partial-request.component';
import { ModalSurplusSimulationRequestComponent } from './modal-surplus-simulation-request/modal-surplus-simulation-request.component';
import {
  ModalTransferAccountComponent
} from './modal-transfer-account/modal-transfer-account.component';
import { ModalChangeSecurityError } from './modals-security-key/error-modal/modal-change-security-error.component';
import { ModalChangeSecuritySuccess } from './modals-security-key/success-modal/modal-change-security-success.component';
import {
  MonthYearDatePickerComponent
} from './month-year-date-picker/month-year-date-picker.component';
import { MufflerComponent } from './muffler/muffler.component';
import { MyExecutiveMobileComponent } from './my-executive-mobile/my-executive-mobile.component';
import { MyExecutiveComponent } from './my-executive/my-executive.component';
import { MyPensionPaymentMobileComponent } from './my-pension-payment-mobile/my-pension-payment-mobile.component';
import { MyPensionPaymentComponent } from './my-pension-payment/my-pension-payment.component';
import { MyVideoStatementMobileComponent } from './my-video-statement-mobile/my-video-statement-mobile.component';
import { MyVideoStatementComponent } from './my-video-statement/my-video-statement.component';
import {
  OverpaymentDescriptionComponent
} from './overpayment-description/overpayment-description.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { PaymentMethodFormComponent } from './payment-method-form/payment-method-form.component';
import { PaymentReactivationComponent } from './payment-reactivation/payment-reactivation.component';
import { PensionBeneficiaryComponent } from './pension-beneficiary/pension-beneficiary.component';
import {
  PensionModalityFormComponent
} from './pension-modality-form/pension-modality-form.component';
import {
  PensionOtherBackgroundFormComponent
} from './pension-other-background-form/pension-other-background-form.component';
import { PensionOtherHistoryComponent } from './pension-other-history/pension-other-history.component';
import {
  PensionStatementTableComponent
} from './pension-statement-table/pension-statement-table.component';
import { PopoverComponent } from './popover/popover.component';
import { ProfitabilityFundsComponent } from './profitability-funds/profitability-funds.component';
import { QuotaValueSearcherComponent } from './quota-value-searcher/quota-value-searcher.component';
import {
  RadioButtonListFormComponent
} from './radio-button-input-list-form/radio-button-list-form.component';
import { ReloginModalComponent } from './relogin-modal/relogin-modal.component';
import { RequestDocumentsComponent } from './request-documents/request-documents.component';
import {
  SecurityKeyValidationComponent
} from './security-key-validation/security-key-validation.component';
import { SecurityComponent } from './security/security.component';
import {
  SelectionAmountWithdrawComponent
} from './selection-amount-withdraw/selection-amount-withdraw.component';
import {
  SelectionBankWithdrawComponent
} from './selection-bank-withdraw/selection-bank-withdraw.component';
import { SerialNumberModalComponent } from './serial-number-modal/serial-number-modal.component';
import { ShortcutsPensionerComponent } from './shortcuts-pensioner/shortcuts-pensioner.component';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import {
  SpecialConditionsFormComponent
} from './special-conditions-form/special-conditions-form.component';
import { StatementFormComponent } from './statement-pensionary-form/statement-form.component';
import { StepperBiometricComponent } from './stepper-biometric/stepper-biometric.component';
import { StepperOverflowComponent } from './stepper-overflow/stepper-overflow.component';
import { StepperComponent } from './stepper/stepper.component';
import { SuccessWithdrawComponent } from './success-withdraw/success-withdraw.component';
import { SwitchButtonSecurityComponent } from './switch-button-security/switch-button-security.component';
import { SwitchButtonComponent } from './switch-button/switch-button.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import {
  TransferPensionaryFormComponent
} from './transfer-pensionary-form/transfer-pensionary-form.component';
import { UnemployedFundTransferComponent } from './unemployed-fund-transfer/unemployed-fund-transfer.component';
import { VideoComponent } from './video/video.component';
import { VoucherComponent } from './voucher/voucher.component';
import {
  WithdrawConditionsFormComponent
} from './withdraw-conditions-form/withdraw-conditions-form.component';
import { WorkerTypeFormComponent } from './worker-type-form/worker-type-form.component';

@NgModule({
  declarations: [
    LoadingComponent,
    AlertToastComponent,
    AvatarComponent,
    IndiVideoComponent,
    VideoComponent,
    CircularChartComponent,
    CardCommentsComponent,
    CommentComponent,
    ComplainsComponent,
    ComplainTicketCardComponent,
    SecurityComponent,
    SecurityKeyValidationComponent,
    SelectionAmountWithdrawComponent,
    SelectionBankWithdrawComponent,
    SerialNumberModalComponent,
    ShortcutsComponent,
    ShortcutsPensionerComponent,
    StepperComponent,
    StepperBiometricComponent,
    SuccessWithdrawComponent,
    SwitchButtonComponent,
    SwitchButtonSecurityComponent,
    StepperOverflowComponent,
    ErrorMessageComponent,
    FundBoxComponent,
    TimePickerComponent,
    FundSelectorComponent,
    VoucherComponent,
    FundsChangeVoucherComponent,
    RequestStepComponent,
    ValidationStepComponent,
    CreationStepComponent,
    QuestionStepComponent,
    CodeStepComponent,
    ChangePasswordFormComponent,
    MonthYearDatePickerComponent,
    FullDatePickerComponent,
    EmployerInfoComponent,
    MapComponent,
    PaginatorComponent,
    HelpButtonComponent,
    ModalComplainsComponent,
    ModalContactComponent,
    ModalContactCenterComponent,
    ModalAccountValidationComponent,
    ModalContactMeComponent,
    ModalPreviredComponent,
    ModalPartnerAddComponent,
    ModalQuotationVideoComponent,
    ModalBeneficiaryComponent,
    ModalImpositionComponent,
    ModalCESComponent,
    ModalBeneficiaryAdmComponent,
    ModalFacialValidationComponent,
    ModalTransferAccountComponent,
    ModalInformationalComponent,
    ModalIncreaseBalanceComponent,
    ModalSuccessfullySavedPartialRequestComponent,
    ModalConfirmExitRequestComponent,
    ModalConfirmDeleteRequestComponent,
    ModalEmailErrorComponent,
    CertificateFormComponent,
    EmployerCertificateFormComponent,
    ModalCertificateFormComponent,
    ModalEmployerCertificateFormComponent,
    ModalForceUpdateComponent,
    PensionStatementTableComponent,
    AttachFilesComponent,
    BackgroundFormComponent,
    BackgroundFormPensionAdditionalComponent,
    EmployerBackgroundFormComponent,
    RadioButtonListFormComponent,
    PensionOtherBackgroundFormComponent,
    AdvisorBackgroundFormComponent,
    PensionModalityFormComponent,
    SpecialConditionsFormComponent,
    TransferPensionaryFormComponent,
    StatementFormComponent,
    ImpositionFormComponent,
    EmploymentBackgroundFormComponent,
    MedicalBackgroundFormComponent,
    ModalMedicalReportComponent,
    ModalEmployerContractComponent,
    ModalOverpaymentDocumentComponent,
    DisabilityFormComponent,
    WorkerTypeFormComponent,
    EmploymentDocumentsFormComponent,
    DelegateFormComponent,
    PaymentMethodFormComponent,
    BreadcrumbComponent,
    CustomInputComponent,
    WithdrawConditionsFormComponent,
    OverpaymentDescriptionComponent,
    AccessibilityComponent,
    EmployerDataFormComponent,
    SideNavComponent,
    ModalContactInfoUpdateComponent,
    DisclaimerBoxComponent,
    ModalDefinitionsComponent,
    InfoTextPopupComponent,
    InvestorSimulationAccionsComponent,
    MyExecutiveComponent,
    MyExecutiveMobileComponent,
    MyVideoStatementComponent,
    MyVideoStatementMobileComponent,
    AccountsOfferComponent,
    DownloadMarketComponent,
    ModalQrComponent,
    BeneficiaryAffiliateSelectorComponent,
    AlertBannerComponent,
    QuotaValueSearcherComponent,
    ChartQuotaValuesComponent,
    FilterChipsComponent,
    CardAttachedDocumentsComponent,
    CardComponent,
    RequestDocumentsComponent,
    InvestorProfileBoxComponent,
    ChartInvestorProfileComponent,
    PopoverComponent,
    InvestorResultPopoverComponent,
    ModalStopScreenshareComponent,
    ModalOfferSchedulingComponent,
    ModalAcceptApplicationComponent,
    ReloginModalComponent,
    ModalDoneStepComponent,
    MyPensionPaymentComponent,
    MyPensionPaymentMobileComponent,
    ModalMoreDatePaymentsComponent,
    ModalPensionerPaymentWayComponent,
    DeleteButtonRequestComponent,
    ModalDeleteSuccessComponent,
    ModalProfitabilityFundsComponent,
    AccountsSummaryMobileComponent,
    AccountSummaryPaginationComponent,
    ProfitabilityFundsComponent,
    BottomSheetTemplateComponent,
    DirectMenuComponent,
    AccountsVariationProcessedComponent,
    AssistanceButtonComponent,
    AssistanceInHoursComponent,
    AssistanceOutHoursComponent,
    MufflerComponent,
    ChangeSecurityKeyFormComponent,
    EarnedProfitabilityGraphicComponent,
    EarnedProfitabilityGraphicMobileComponent,
    PaymentReactivationComponent,
    ModalReactivatePaymentComponent,
    ModalConfirmCesComponent,
    AlertNewsStripBannerComponent,
    AlertNewsBannerMobileComponent,
    IdCaptureGuidelinesComponent,
    FacialCaptureGuidelinesComponent,
    IdCaptureComponent,
    FacialCaptureComponent,
    ProcessValidationComponent,
    UnemployedFundTransferComponent,
    ModalDynamicKeyChannelComponent,
    ModalDynamicKeyValidateComponent,
    BiometricAccessComponent,
    ModalSuccessfulyAnticipatedDeferredRequestComponent,
    ModalSurplusSimulationRequestComponent,
    PensionOtherHistoryComponent,
    PensionBeneficiaryComponent,
    ModalInfoPensionAdditionalComponent,
    PensionPaymentMethodComponent,
    ModalBiometricConfirmationComponent,
    ModalBioTutorialComponent,
    BtnSeeTutorialComponent,
    AssistanceContainerComponent,
    ModalContactStepZeroComponent,
    ModalEmployerInfoRequestComponent,
    ModalChangeSecuritySuccess,
    ModalChangeSecurityError,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ClipboardModule,
    ChartistModule,
    DirectivesModule,
    GoogleMapsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatDividerModule,
    MatTabsModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    AlertToastComponent,
    AvatarComponent,
    CircularChartComponent,
    CardAttachedDocumentsComponent,
    CardCommentsComponent,
    CommentComponent,
    ComplainsComponent,
    ComplainTicketCardComponent,
    SecurityComponent,
    SecurityKeyValidationComponent,
    SelectionAmountWithdrawComponent,
    SelectionBankWithdrawComponent,
    StepperComponent,
    StepperBiometricComponent,
    ShortcutsComponent,
    ShortcutsPensionerComponent,
    SuccessWithdrawComponent,
    SwitchButtonComponent,
    SwitchButtonSecurityComponent,
    StepperOverflowComponent,
    ErrorMessageComponent,
    FundBoxComponent,
    FundSelectorComponent,
    VoucherComponent,
    FundsChangeVoucherComponent,
    RequestStepComponent,
    ValidationStepComponent,
    CreationStepComponent,
    QuestionStepComponent,
    CodeStepComponent,
    ChangePasswordFormComponent,
    MonthYearDatePickerComponent,
    FullDatePickerComponent,
    TimePickerComponent,
    EmployerInfoComponent,
    MapComponent,
    PaginatorComponent,
    HelpButtonComponent,
    CertificateFormComponent,
    EmployerCertificateFormComponent,
    ModalComplainsComponent,
    ModalCertificateFormComponent,
    ModalEmployerCertificateFormComponent,
    ModalForceUpdateComponent,
    MyExecutiveComponent,
    MyExecutiveMobileComponent,
    DownloadMarketComponent,
    AccountsOfferComponent,
    PensionStatementTableComponent,
    AttachFilesComponent,
    BackgroundFormComponent,
    BackgroundFormPensionAdditionalComponent,
    EmployerBackgroundFormComponent,
    RadioButtonListFormComponent,
    PensionOtherBackgroundFormComponent,
    AdvisorBackgroundFormComponent,
    PensionModalityFormComponent,
    SpecialConditionsFormComponent,
    TransferPensionaryFormComponent,
    StatementFormComponent,
    ImpositionFormComponent,
    EmploymentBackgroundFormComponent,
    MedicalBackgroundFormComponent,
    DisabilityFormComponent,
    WorkerTypeFormComponent,
    EmploymentDocumentsFormComponent,
    DelegateFormComponent,
    PaymentMethodFormComponent,
    BreadcrumbComponent,
    CustomInputComponent,
    WithdrawConditionsFormComponent,
    OverpaymentDescriptionComponent,
    AccessibilityComponent,
    EmployerDataFormComponent,
    SideNavComponent,
    ModalContactInfoUpdateComponent,
    LoadingComponent,
    DisclaimerBoxComponent,
    InfoTextPopupComponent,
    InvestorSimulationAccionsComponent,
    MyVideoStatementComponent,
    MyVideoStatementMobileComponent,
    BeneficiaryAffiliateSelectorComponent,
    AlertBannerComponent,
    QuotaValueSearcherComponent,
    ChartQuotaValuesComponent,
    FilterChipsComponent,
    CardComponent,
    RequestDocumentsComponent,
    InvestorProfileBoxComponent,
    ChartInvestorProfileComponent,
    PopoverComponent,
    InvestorResultPopoverComponent,
    MyPensionPaymentComponent,
    MyPensionPaymentMobileComponent,
    ModalMoreDatePaymentsComponent,
    ModalPensionerPaymentWayComponent,
    DeleteButtonRequestComponent,
    AccountsSummaryMobileComponent,
    AccountSummaryPaginationComponent,
    ProfitabilityFundsComponent,
    BottomSheetTemplateComponent,
    DirectMenuComponent,
    AccountsVariationProcessedComponent,
    AssistanceButtonComponent,
    AssistanceInHoursComponent,
    AssistanceOutHoursComponent,
    ModalConfirmDeleteRequestComponent,
    ModalConfirmExitRequestComponent,
    ModalDeleteSuccessComponent,
    TranslateModule,
    MufflerComponent,
    ChangeSecurityKeyFormComponent,
    EarnedProfitabilityGraphicComponent,
    EarnedProfitabilityGraphicMobileComponent,
    AlertNewsStripBannerComponent,
    AlertNewsBannerMobileComponent,
    PaymentReactivationComponent,
    ModalReactivatePaymentComponent,
    ModalFacialValidationComponent,
    IdCaptureGuidelinesComponent,
    FacialCaptureGuidelinesComponent,
    IdCaptureComponent,
    FacialCaptureComponent,
    ProcessValidationComponent,
    BiometricAccessComponent,
    UnemployedFundTransferComponent,
    ModalSurplusSimulationRequestComponent,
    PensionOtherHistoryComponent,
    PensionBeneficiaryComponent,
    ModalInfoPensionAdditionalComponent,
    PensionPaymentMethodComponent,
    ModalBioTutorialComponent,
    BtnSeeTutorialComponent,
    AssistanceContainerComponent,
    ModalChangeSecuritySuccess,
    ModalChangeSecurityError,
  ],
  providers: [
    CurrencyPipe,
    PdfViewerProvider,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
