import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { PieChartOptions } from 'chartist';
import { Configuration } from 'ng-chartist';

import { STEPPER } from '@pages-content/stepper.constant';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-stepper-biometric',
  templateUrl: './stepper-biometric.component.html',
  styleUrls: ['./stepper-biometric.component.scss'],
})
export class StepperBiometricComponent implements OnChanges {
  @Input() public isLinear = false;
  @Input() public canGoBackwards = false;
  @Input() public steps: Array<string> = [];
  @Input() public currentIndex = 0;
  @Input() public progress: number;
  @Input() public title;
  @Output() public changedStep: EventEmitter<number> = new EventEmitter();
  public options: PieChartOptions = {
    donut: true,
    showLabel: false,
    chartPadding: 0,
    height: '58px',
    width: '58px',
    donutWidth: '22px',
  };
  public pageContent = STEPPER;
  public progressChartConfiguration: Configuration = {
    options: this.options,
    data: { series: [] },
    type: 'Pie'
  };

  constructor(public font: FontService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.progress.currentValue) {
      this.progressChartConfiguration = {
        options: this.options,
        data: { series: [
          { value: changes.progress.currentValue, className: 'stepper complete' },
          { value: 100 - changes.progress.currentValue, className: 'stepper incomplete' },
        ] },
        type: 'Pie'
      };
    }
  }

  public get currentStepPercentage(): number { return this.stepPercentage * this.currentIndex; }
  public get stepPercentage(): number { return Math.round(100 / (this.steps.length - 1)); }
  public get firstStep(): number { return 0; }
  public get lastStep(): number { return this.steps.length - 1; }
  public get unionLinesMargin(): number { return this.progress ? -100 / ((this.steps.length + 1) * 2) : -100 / (this.steps.length * 2); }
  public get currentLabel(): string { return this.steps[this.currentIndex]; }
  public isFirst(index: number): boolean { return index === this.firstStep; }
  public isLast(index: number): boolean { return index === this.lastStep; }
  public isCurrent(index: number): boolean { return index === this.currentIndex; }
  public isNextToCurrent(index: number): boolean { return index === this.currentIndex + 1; }
  public isBeforeCurrent(index: number): boolean { return index < this.currentIndex; }

  public nextStep(): void {
    if (this.currentIndex === this.lastStep) { return; }
    this.changeStep(this.currentIndex + 1);
  }

  public previousStep(): void {
    if (this.currentIndex === this.firstStep) { return; }
    this.changeStep(this.currentIndex - 1);
  }

  public canGoToStep(index: number): boolean {
    return !this.isLinear || (this.canGoBackwards && this.isBeforeCurrent(index));
  }

  public onStepClicked(index: number): void {
    if (!this.canGoToStep(index)) return;
    this.changeStep(index);
  }

  public changeStep(index: number): void {
    if (index < this.firstStep || index > this.lastStep) { return; }
    this.currentIndex = index;
    this.changedStep.emit(index);
  }

}
