import { RadioButtonInputListElement } from '@interfaces/radio-button-input-list-element.interface';

import {
  PESOS_CURRENCY_TYPE, POSITIVE_INTEGER_CURRENCY_TYPE, UF_CURRENCY_TYPE, UTM_CURRENCY_TYPE
} from './constants';

const pageKey = 'REQUESTS.SURPLUS_PAYMENT';

export const FREELY_DISPOSABLE_SURPLUS_PAYMENT_FORM_FILE_TYPE = 'solicitud-pago-excedente-libre-disposicion';

export const SURPLUS_CALCULATION_FORM_FILE_TYPE = 'solicitud-calculo-excedente-libre-disposicion';

const personalBackgroundKey = `${pageKey}.STEPS.PERSONAL_BACKGROUND`;
const withdrawConditionsKey = `${pageKey}.STEPS.WITHDRAW_CONDITIONS`;
const paymentMethodsKey = `${pageKey}.STEPS.PAYMENT_METHODS`;
const voucherKey = `${pageKey}.STEPS.VOUCHER`;
const savedModal = 'REQUESTS.MODALS.SAVED';
const toastKey = `${pageKey}.TOAST`;
const disclaimerKey = 'GENERIC_DISCLAIMER.PERSONAL_BACKGROUND';

export const SURPLUS_PAYMENT_AMOUNT_TYPES = {
  0: { id: UF_CURRENCY_TYPE, description: `${pageKey}.SURPLUS_PAYMENT_AMOUNT_TYPES.0.DESCRIPTION` },
  1: { id: PESOS_CURRENCY_TYPE, description: `${pageKey}.SURPLUS_PAYMENT_AMOUNT_TYPES.1.DESCRIPTION` },
  2: { id: UTM_CURRENCY_TYPE, description: `${pageKey}.SURPLUS_PAYMENT_AMOUNT_TYPES.2.DESCRIPTION` },
  3: { id: UF_CURRENCY_TYPE, description: `${pageKey}.SURPLUS_PAYMENT_AMOUNT_TYPES.3.DESCRIPTION` },
  4: { id: POSITIVE_INTEGER_CURRENCY_TYPE, description: `${pageKey}.SURPLUS_PAYMENT_AMOUNT_TYPES.4.DESCRIPTION` }
};

export const SURPLUS_PAYMENT_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved',
};

export const SURPLUS_PAYMENT_FORM_CONTENT = {
  breadcrumb: `${pageKey}.BREADCRUMB`,
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  folio: `${pageKey}.FOLIO`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`
  },
  steps: {
    personalBackground: {
      title: `${personalBackgroundKey}.TITLE`,
      surplusCalculation: `${personalBackgroundKey}.SURPLUS_CALCULATION`,
      label: `${personalBackgroundKey}.LABEL`,
      buttons: {
        next: `${personalBackgroundKey}.BUTTONS.NEXT`,
        surplusCalculation: `${personalBackgroundKey}.BUTTONS.SURPLUS_CALCULATION`,
        saveContinueLater: `${personalBackgroundKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      disclaimer: {
        message: `${disclaimerKey}.MESSAGE`,
        redirectionText: `${disclaimerKey}.REDIRECTION_TEXT`
      },
      form: {
        names: `${personalBackgroundKey}.FORM.NAMES`,
        lastNames: `${personalBackgroundKey}.FORM.LAST_NAMES`,
        rut: `${personalBackgroundKey}.FORM.RUT`,
        gender: {
          label: `${personalBackgroundKey}.FORM.GENDER.LABEL`,
          male: `${personalBackgroundKey}.FORM.GENDER.MALE`,
          female: `${personalBackgroundKey}.FORM.GENDER.FEMALE`
        },
        address: `${personalBackgroundKey}.FORM.ADDRESS`,
        birthdate: `${personalBackgroundKey}.FORM.BIRTHDATE`,
        email: `${personalBackgroundKey}.FORM.EMAIL`,
        phone: `${personalBackgroundKey}.FORM.PHONE`,
        maritalStatus: `${personalBackgroundKey}.FORM.MARITAL_STATUS`,
        jobTitle: `${personalBackgroundKey}.FORM.JOB_TITLE`,
        pensionType: {
          label: `${personalBackgroundKey}.FORM.PENSION_TYPE.LABEL`,
          options: [
            { code: 'normal', text: `${personalBackgroundKey}.FORM.PENSION_TYPE.OPTIONS.NORMAL.TEXT` },
            { code: 'anticipated', text: `${personalBackgroundKey}.FORM.PENSION_TYPE.OPTIONS.ANTICIPATED.TEXT` },
            { code: 'disability', text: `${personalBackgroundKey}.FORM.PENSION_TYPE.OPTIONS.DISABILITY.TEXT` },
          ] as Array<RadioButtonInputListElement>
        },
        hasSurplusCalculation: {
          label: `${personalBackgroundKey}.FORM.HAS_SURPLUS_CALCULATION.LABEL`,
          options: {
            yes: `${personalBackgroundKey}.FORM.HAS_SURPLUS_CALCULATION.OPTIONS.YES`,
            no: `${personalBackgroundKey}.FORM.HAS_SURPLUS_CALCULATION.OPTIONS.NO`
          }
        },
        disclaimer: `${personalBackgroundKey}.FORM.DISCLAIMER`,
        errors: {
          required: `${personalBackgroundKey}.FORM.ERRORS.REQUIRED`,
          invalidEmail: `${personalBackgroundKey}.FORM.ERRORS.INVALID_EMAIL`,
          invalidDomain: `${personalBackgroundKey}.FORM.ERRORS.INVALID_DOMAIN`,
          invalidRut: `${personalBackgroundKey}.FORM.ERRORS.INVALID_RUT`,
          invalidAddress: `${personalBackgroundKey}.FORM.ERRORS.INVALID_ADDRESS`
        }
      }
    },
    withdrawConditions: {
      title: `${withdrawConditionsKey}.TITLE`,
      description: `${withdrawConditionsKey}.DESCRIPTION`,
      label: `${withdrawConditionsKey}.LABEL`,
      buttons: {
        next: `${withdrawConditionsKey}.BUTTONS.NEXT`,
        back: `${withdrawConditionsKey}.BUTTONS.BACK`,
        saveContinueLater: `${withdrawConditionsKey}.BUTTONS.CONTINUE_LATER`,
        deleteRequest: `${personalBackgroundKey}.BUTTONS.DELETE_REQUEST`,
      },
      form: {
        option: `${withdrawConditionsKey}.FORM.OPTION`,
        amount: `${withdrawConditionsKey}.FORM.AMOUNT`,
        taxRegime: {
          label: `${withdrawConditionsKey}.FORM.TAX_REGIME.LABEL`,
          options: [
            {
              code: '0',
              text: `${withdrawConditionsKey}.FORM.TAX_REGIME.OPTIONS.0.TEXT`,
              definitions: [{ title: `FORM_DEFINITIONS.11.TITLE`, definition: `FORM_DEFINITIONS.11.TEXT` }]
            },
            {
              code: '1',
              text: `${withdrawConditionsKey}.FORM.TAX_REGIME.OPTIONS.1.TEXT`,
              definitions: [{ title: `FORM_DEFINITIONS.12.TITLE`, definition: `FORM_DEFINITIONS.12.TEXT` }]
            },
            {
              code: '2',
              text: `${withdrawConditionsKey}.FORM.TAX_REGIME.OPTIONS.2.TEXT`,
              definitions: [{ title: `FORM_DEFINITIONS.13.TITLE`, definition: `FORM_DEFINITIONS.13.TEXT` }]
            },
          ] as Array<RadioButtonInputListElement>
        },
        errors: {
          required: `${withdrawConditionsKey}.FORM.ERRORS.REQUIRED`,
          minAmount: `${withdrawConditionsKey}.FORM.ERRORS.MIN_AMOUNT`
        }
      }
    },
    paymentMethods: {
      label: `${paymentMethodsKey}.LABEL`,
      formSection: {
        title: `${paymentMethodsKey}.FORM_SECTION.TITLE`,
        transfer: `${paymentMethodsKey}.FORM_SECTION.TRANSFER`,
        cash: `${paymentMethodsKey}.FORM_SECTION.CASH`,
        bank: {
          field: `${paymentMethodsKey}.FORM_SECTION.BANK.FIELD`,
          options: `${paymentMethodsKey}.FORM_SECTION.BANK.OPTIONS`
        },
        account: {
          field: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.FIELD`,
          options: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.OPTIONS`
        },
        other: {
          options: `${paymentMethodsKey}.FORM_SECTION.OTHER.OPTIONS`
        }
      },
      disclaimer: `${paymentMethodsKey}.DISCLAIMER`,
      digitalAccountDisclaimer: `${paymentMethodsKey}.DIGITAL_ACCOUNT_DISCLAIMER`,
      buttons: {
        submit: `${paymentMethodsKey}.BUTTONS.SUBMIT`,
        back: `${paymentMethodsKey}.BUTTONS.BACK`
      },
      errors: {
        required: `${paymentMethodsKey}.ERRORS.REQUIRED`,
        digitalAccount: `${paymentMethodsKey}.ERRORS.DIGITAL_ACCOUNT`
      }
    }
  },
  voucher: {
    label: `${voucherKey}.LABEL`,
    content: {
      title: `${voucherKey}.CONTENT.TITLE`,
      subtitle: `${voucherKey}.CONTENT.SUBTITLE`,
      description: `${voucherKey}.CONTENT.DESCRIPTION`,
      homeButton: `${voucherKey}.CONTENT.HOME_BUTTON`,
      download: `${voucherKey}.CONTENT.DOWNLOAD_PDF`,
      customRouteButton: `${voucherKey}.CONTENT.CUSTOM_ROUTE_BUTTON`
    }
  }
};
