import { Mock } from '@interfaces/mock.interface';
import { UpdateEmployerRequest } from '@interfaces/update-employer.interface';

export const UPDATE_EMPLOYER_MOCK: Mock = {
  success: [
    {
      response: true
    },
  ],
  failures: [
    {
      response: {
        code: 50001,
        folioNumber: null,
        messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        serviceErrorMessage: '',
        message: 'Error',
      },
    },
  ],
};

export const UPDATE_EMPLOYER_PARAMS_MOCK: UpdateEmployerRequest = {
  economicActivityId: '841100',
  typeId: 'PN',
  communeId: '13104',
  address: 'Av. Independencia 123,456',
  phone: '123456789',
  cellphone: '123456789',
  email: 'Employer@gmail.cl',
};
