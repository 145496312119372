import { Mock } from '@interfaces/mock.interface';

export const LOG_MOCK: Mock = {
  success: [{
    response: {},
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
      planVitalPhoneNumber: '800 072 072',
    },
  }, {
    response: {
      message: 'Error',
      messageDescription: 'Error Description Bad Request',
      code: 40000,
      planVitalPhoneNumber: '800 072 072',
    },
  }],
};
