import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalStopScreenshareComponent } from '@components/modal-stop-screenshare/modal-stop-screenshare.component';
import { ModalComponent } from '@components/modal/modal.component';
import {
  CLIENT_USER_TYPE, CONTACT_CENTER, EMPLOYER_USER_TYPE, PHONE_NUMBER_LENGTH, SCREENSHARE_CODE_MAX_LENGTH
} from '@constants/constants';
import { Click2CallData, Click2CallRequest } from '@interfaces/genesys.interface';
import { HELP_BUTTON_CONTENT } from '@pages-content/help-button.constant';
import { FontService } from '@providers/font/font.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { CmsService } from '@services/cms/cms.service';
import { GenesysService } from '@services/genesys/genesys.service';
import { UserManagerService } from '@services/user-manager/user-manager.service';
import { Util } from '@util';

@Component({
  selector: 'app-modal-contact-center',
  templateUrl: './modal-contact-center.component.html',
  styleUrls: ['./modal-contact-center.component.scss'],
})
export class ModalContactCenterComponent implements OnInit {
  public pageContent = HELP_BUTTON_CONTENT.modals.callCenter;
  public click2CallContent = this.pageContent.click2Call;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public contactCenterNumber = CONTACT_CENTER.NUMBER;
  public screenshareCodeMaxLength = SCREENSHARE_CODE_MAX_LENGTH;
  public isSessionActive = this.genesysService.isSessionActive.value;
  public isWithinContactCenterHours = true;
  public isContactCenterAvailable = true;
  public callCenterHours: string;
  public click2callForm: UntypedFormGroup;
  public screenshareForm: UntypedFormGroup;
  public isClick2CallSelected = false;
  public isScreenshareSelected = false;
  public succesfullClick2Call = false;
  private isEmployer: boolean;
  private rut: string;
  private userData: { name: string; phone: string; };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private genesysService: GenesysService,
    private authService: AuthenticationService,
    private userManagerService: UserManagerService,
    private cmsService: CmsService,
    public router: Router,
    public dialogRef: MatDialogRef<ModalComponent>,
    public font: FontService,
    public util: Util,
    public dialog: MatDialog,
  ) {
    this.getCallCenterHours();
    this.checkContactCenterAvailable();
    this.genesysService.isSessionActive.subscribe(response => {
      this.isSessionActive = response;
    });
  }

  public get enableContactCenterButtons(): boolean {
   return this.isWithinContactCenterHours && this.isContactCenterAvailable;
   }
     public get isScreenshareAvailable(): boolean { return this.genesysService.isScreenshareAvailable; }

  public get noOptionSelected(): boolean { return !this.isClick2CallSelected && !this.isScreenshareSelected; }
  public get isShareScreenButtonDisabled(): boolean { return this.screenshareForm.invalid || this.isSessionActive; }
  public get shareScreenButtonText(): string {
    return this.isSessionActive ? this.pageContent?.cobrowsing.disabledScreenshareButton : this.pageContent?.cobrowsing.screenshareButton;
  }

  public get phone(): UntypedFormControl { return this.click2callForm.get('phone') as UntypedFormControl; }
  public get reason(): UntypedFormControl { return this.click2callForm.get('reason') as UntypedFormControl; }
  public get dateTime(): UntypedFormControl { return this.click2callForm.get('dateTime') as UntypedFormControl; }

  public get securityCode(): UntypedFormControl { return this.screenshareForm.get('securityCode') as UntypedFormControl; }

  public ngOnInit(): void {
    this.isEmployer = this.authService.userIsEmployer();
    this.rut = this.authService.getUserRut();
    const userType = this.isEmployer ? EMPLOYER_USER_TYPE : CLIENT_USER_TYPE;
    this.userManagerService.getUserData(userType)
      .subscribe((response) => this.setUserData(response));
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public submitClick2CallForm(): void {
    if (this.click2callForm.invalid) { return; }
    const callbackRequest = this.getClick2CallRequest(this.click2callForm.value);
    this.genesysService.sendClick2CallRequest(callbackRequest).subscribe(() => {
      this.succesfullClick2Call = true;
    });
  }

  public selectClick2Call(): void {
    this.setClick2CallForm();
    this.isClick2CallSelected = true;
  }

  public selectScreenshare(): void {
    this.setScreenshareForm();
    this.isScreenshareSelected = true;
  }

  public async startScreenshare(): Promise<void> {
    if (this.screenshareForm.invalid) { return; }
    try {
      await this.genesysService.initializeScreenshare(this.securityCode.value);
      this.dialog.open(ModalStopScreenshareComponent, {
        hasBackdrop: false,
        position: { top: '20px' },
        panelClass: 'stop-screenshare-modal',
      });
      this.closeModal();
    } catch (err) {
      this.securityCode.setErrors({ invalid: 'invalid' });
    }
  }

  public goBack(): void {
    this.isClick2CallSelected = false;
    this.isScreenshareSelected = false;
    this.succesfullClick2Call = false;
  }

  private getClick2CallRequest(callbackData: Click2CallData): Click2CallRequest {
    const { phone, reason } = callbackData;
    return {
      callbackNumbers: [phone],
      callbackUserName: this.userData.name,
      isEmployer: this.isEmployer,
      rut: this.rut,
      reason,
      url: this.router.url
    };
  }

  private setUserData(data): void {
    this.userData = {
      name: this.isEmployer ? data.user.name : `${data.name} ${data.lastName}`,
      phone: this.isEmployer ? data.user.cellphone : data.cellphoneNumber,
    };
  }

  private setClick2CallForm(): void {
    this.click2callForm = this.formBuilder.group({
      phone: [this.userData.phone, [
        Validators.required,
        Validators.minLength(PHONE_NUMBER_LENGTH),
        Validators.maxLength(PHONE_NUMBER_LENGTH),
      ]],
      reason: [''],
    });
  }

  private setScreenshareForm(): void {
    this.screenshareForm = this.formBuilder.group({
      securityCode: ['', [Validators.required]],
    });
  }

  private getCallCenterHours(): void {
    this.cmsService.getGeneralInfo()
      .subscribe((response) => {
        this.callCenterHours = response.callCenterHours;
        this.isContactCenterAvailable = response.callCenterAvailable;
      });
  }

  private checkContactCenterAvailable(): void {
    this.genesysService.isContactCenterAvailable()
      .subscribe((response) => this.isWithinContactCenterHours = response);
  }
}
