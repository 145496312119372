import { Mock } from '@interfaces/mock.interface';

export const REGIONS_MOCKUP: Mock = {
  success: [{
    response: [
      {
        communes: [
          {
            regionCode: '1',
            code: '1107',
            city: 'IQUIQUE',
            description: 'ALTO HOSPICIO'
          },
          {
            regionCode: '1',
            code: '1402',
            city: 'CAMINA',
            description: 'CAMINA'
          },
          {
            regionCode: '1',
            code: '1403',
            city: 'ARICA',
            description: 'COLCHANE'
          },
          {
            regionCode: '1',
            code: '1404',
            city: 'IQUIQUE',
            description: 'HUARA'
          },
          {
            regionCode: '1',
            code: '1101',
            city: 'IQUIQUE',
            description: 'IQUIQUE'
          },
          {
            regionCode: '1',
            code: '1405',
            city: 'IQUIQUE',
            description: 'PICA'
          },
          {
            regionCode: '1',
            code: '1401',
            city: 'IQUIQUE',
            description: 'POZO ALMONTE'
          },
        ],
        description: 'PRIMERA REGIÓN',
        code: '1'
      },
      {
        communes: [
          {
            regionCode: '2',
            code: '2101',
            city: 'ANTOFAGASTA',
            description: 'ANTOFAGASTA'
          },
          {
            regionCode: '2',
            code: '2201',
            city: 'CALAMA',
            description: 'CALAMA'
          },
          {
            regionCode: '2',
            code: '2302',
            city: 'MARIA ELENA',
            description: 'MARIA ELENA'
          },
          {
            regionCode: '2',
            code: '2102',
            city: 'MEJILLONES',
            description: 'MEJILLONES'
          },
          {
            regionCode: '2',
            code: '2202',
            city: 'OLLAGUE',
            description: 'OLLAGUE'
          },
          {
            regionCode: '2',
            code: '2203',
            city: 'SAN PEDRO DE ATACAMA',
            description: 'SAN PEDRO DE ATACAMA'
          },
          {
            regionCode: '2',
            code: '2103',
            city: 'SIERRA GORDA',
            description: 'SIERRA GORDA'
          },
          {
            regionCode: '2',
            code: '2104',
            city: 'TALTAL',
            description: 'TALTAL'
          },
          {
            regionCode: '2',
            code: '2301',
            city: 'TOCOPILLA',
            description: 'TOCOPILLA'
          },
        ],
        description: 'SEGUNDA REGIÓN',
        code: '2'
      },
      {
        communes: [
          {
            regionCode: '3',
            code: '3302',
            city: 'ALTO DEL CARMEN',
            description: 'ALTO DEL CARMEN'
          },
          {
            regionCode: '3',
            code: '3102',
            city: 'CALDERA',
            description: 'CALDERA'
          },
          {
            regionCode: '3',
            code: '3201',
            city: 'CHAÑARAL',
            description: 'CHAÑARAL'
          },
          {
            regionCode: '3',
            code: '3101',
            city: 'COPIAPO',
            description: 'COPIAPO'
          },
          {
            regionCode: '3',
            code: '3202',
            city: 'EL SALVADOR',
            description: 'DIEGO DE ALMAGRO'
          },
          {
            regionCode: '3',
            code: '3303',
            city: 'FREIRINA',
            description: 'FREIRINA'
          },
          {
            regionCode: '3',
            code: '3304',
            city: 'HUASCO',
            description: 'HUASCO'
          },
          {
            regionCode: '3',
            code: '3103',
            city: 'TIERRA AMARILLA',
            description: 'TIERRA AMARILLA'
          },
          {
            regionCode: '3',
            code: '3301',
            city: 'VALLENAR',
            description: 'VALLENAR'
          },
        ],
        description: 'TERCERA REGIÓN',
        code: '3'
      },
      {
        communes: [
          {
            regionCode: '4',
            code: '4103',
            city: 'ANDACOLLO',
            description: 'ANDACOLLO'
          },
          {
            regionCode: '4',
            code: '4202',
            city: 'CANELA',
            description: 'CANELA'
          },
          {
            regionCode: '4',
            code: '4302',
            city: 'COMBARBALA',
            description: 'COMBARBALA'
          },
          {
            regionCode: '4',
            code: '4102',
            city: 'COQUIMBO',
            description: 'COQUIMBO'
          },
          {
            regionCode: '4',
            code: '4201',
            city: 'ILLAPEL',
            description: 'ILLAPEL'
          },
          {
            regionCode: '4',
            code: '4104',
            city: 'LA HIGUERA',
            description: 'LA HIGUERA'
          },
          {
            regionCode: '4',
            code: '4101',
            city: 'LA SERENA',
            description: 'LA SERENA'
          },
          {
            regionCode: '4',
            code: '4203',
            city: 'LOS VILOS',
            description: 'LOS VILOS'
          },
          {
            regionCode: '4',
            code: '4303',
            city: 'MONTE PATRIA',
            description: 'MONTE PATRIA'
          },
          {
            regionCode: '4',
            code: '4301',
            city: 'OVALLE',
            description: 'OVALLE'
          },
          {
            regionCode: '4',
            code: '4105',
            city: 'PAIHUANO',
            description: 'PAIHUANO'
          },
          {
            regionCode: '4',
            code: '4304',
            city: 'PUNITAQUI',
            description: 'PUNITAQUI'
          },
          {
            regionCode: '4',
            code: '4305',
            city: 'RIO HURTADO',
            description: 'RIO HURTADO'
          },
          {
            regionCode: '4',
            code: '4204',
            city: 'SALAMANCA',
            description: 'SALAMANCA'
          },
          {
            regionCode: '4',
            code: '4106',
            city: 'VICUÑA',
            description: 'VICUÑA'
          },
        ],
        description: 'CUARTA REGIÓN',
        code: '4'
      },
      {
        communes: [
          {
            regionCode: '5',
            code: '5602',
            city: 'ALGARROBO',
            description: 'ALGARROBO'
          },
          {
            regionCode: '5',
            code: '5402',
            city: 'CABILDO',
            description: 'CABILDO'
          },
          {
            regionCode: '5',
            code: '5502',
            city: 'CALERA',
            description: 'CALERA'
          },
          {
            regionCode: '5',
            code: '5302',
            city: 'LOS ANDES',
            description: 'CALLE LARGA'
          },
          {
            regionCode: '5',
            code: '5603',
            city: 'CARTAGENA',
            description: 'CARTAGENA'
          },
          {
            regionCode: '5',
            code: '5102',
            city: 'CASABLANCA',
            description: 'CASABLANCA'
          },
          {
            regionCode: '5',
            code: '5702',
            city: 'CATEMU',
            description: 'CATEMU'
          },
          {
            regionCode: '5',
            code: '5103',
            city: 'CONCON',
            description: 'CONCON'
          },
          {
            regionCode: '5',
            code: '5604',
            city: 'EL QUISCO',
            description: 'EL QUISCO'
          },
          {
            regionCode: '5',
            code: '5605',
            city: 'EL TABO',
            description: 'EL TABO'
          },
          {
            regionCode: '5',
            code: '5503',
            city: 'HIJUELAS',
            description: 'HIJUELAS'
          },
          {
            regionCode: '5',
            code: '5201',
            city: 'ISLA DE PASCUA',
            description: 'ISLA DE PASCUA'
          },
          {
            regionCode: '5',
            code: '5104',
            city: 'JUAN FERNANDEZ',
            description: 'JUAN FERNANDEZ'
          },
          {
            regionCode: '5',
            code: '5504',
            city: 'LA CRUZ',
            description: 'LA CRUZ'
          },
          {
            regionCode: '5',
            code: '5401',
            city: 'LA LIGUA',
            description: 'LA LIGUA'
          },
          {
            regionCode: '5',
            code: '5802',
            city: 'LIMACHE',
            description: 'LIMACHE'
          },
          {
            regionCode: '5',
            code: '5703',
            city: 'LLAILLAY',
            description: 'LLAILLAY'
          },
          {
            regionCode: '5',
            code: '5301',
            city: 'LOS ANDES',
            description: 'LOS ANDES'
          },
          {
            regionCode: '5',
            code: '5108',
            city: 'VIÑA DEL MAR',
            description: 'MARGA MARGA'
          },
          {
            regionCode: '5',
            code: '5506',
            city: 'NOGALES',
            description: 'NOGALES'
          },
          {
            regionCode: '5',
            code: '5803',
            city: 'OLMUE',
            description: 'OLMUE'
          },
          {
            regionCode: '5',
            code: '5704',
            city: 'PANQUEHUE',
            description: 'PANQUEHUE'
          },
          {
            regionCode: '5',
            code: '5403',
            city: 'PAPUDO',
            description: 'PAPUDO'
          },
          {
            regionCode: '5',
            code: '5404',
            city: 'PETORCA',
            description: 'PETORCA'
          },
          {
            regionCode: '5',
            code: '5105',
            city: 'PUCHUNCAVI',
            description: 'PUCHUNCAVI'
          },
          {
            regionCode: '5',
            code: '5705',
            city: 'PUTAENDO',
            description: 'PUTAENDO'
          },
          {
            regionCode: '5',
            code: '5501',
            city: 'QUILLOTA',
            description: 'QUILLOTA'
          },
          {
            regionCode: '5',
            code: '5801',
            city: 'QUILPUE',
            description: 'QUILPUE'
          },
          {
            regionCode: '5',
            code: '5107',
            city: 'QUINTERO',
            description: 'QUINTERO'
          },
          {
            regionCode: '5',
            code: '5303',
            city: 'RINCONADA',
            description: 'RINCONADA'
          },
          {
            regionCode: '5',
            code: '5601',
            city: 'SAN ANTONIO',
            description: 'SAN ANTONIO'
          },
          {
            regionCode: '5',
            code: '5304',
            city: 'SAN ESTEBAN',
            description: 'SAN ESTEBAN'
          },
          {
            regionCode: '5',
            code: '5701',
            city: 'SAN FELIPE',
            description: 'SAN FELIPE'
          },
          {
            regionCode: '5',
            code: '5706',
            city: 'SANTA MARIA',
            description: 'SANTA MARIA'
          },
          {
            regionCode: '5',
            code: '5606',
            city: 'SANTO DOMINGO',
            description: 'SANTO DOMINGO'
          },
          {
            regionCode: '5',
            code: '5101',
            city: 'VALPARAISO',
            description: 'VALPARAISO'
          },
          {
            regionCode: '5',
            code: '5804',
            city: 'VILLA ALEMANA',
            description: 'VILLA ALEMANA'
          },
          {
            regionCode: '5',
            code: '5109',
            city: 'VIÑA DEL MAR',
            description: 'VIÑA DEL MAR'
          },
          {
            regionCode: '5',
            code: '5405',
            city: 'ZAPALLAR',
            description: 'ZAPALLAR'
          },
        ],
        description: 'QUINTA REGIÓN',
        code: '5'
      },
      {
        communes: [
          {
            regionCode: '6',
            code: '6302',
            city: 'CHEPICA',
            description: 'CHEPICA'
          },
          {
            regionCode: '6',
            code: '6303',
            city: 'CHIMBARONGO',
            description: 'CHIMBARONGO'
          },
          {
            regionCode: '6',
            code: '6102',
            city: 'CODEGUA',
            description: 'CODEGUA'
          },
          {
            regionCode: '6',
            code: '6103',
            city: 'COINCO',
            description: 'COINCO'
          },
          {
            regionCode: '6',
            code: '6104',
            city: 'COLTAUCO',
            description: 'COLTAUCO'
          },
          {
            regionCode: '6',
            code: '6105',
            city: 'DONIHUE',
            description: 'DONIHUE'
          },
          {
            regionCode: '6',
            code: '6106',
            city: 'GRANEROS',
            description: 'GRANEROS'
          },
          {
            regionCode: '6',
            code: '6202',
            city: 'LA ESTRELLA',
            description: 'LA ESTRELLA'
          },
          {
            regionCode: '6',
            code: '6107',
            city: 'LAS CABRAS',
            description: 'LAS CABRAS'
          },
          {
            regionCode: '6',
            code: '6203',
            city: 'LITUECHE',
            description: 'LITUECHE'
          },
          {
            regionCode: '6',
            code: '6304',
            city: 'LOLOL',
            description: 'LOLOL'
          },
          {
            regionCode: '6',
            code: '6108',
            city: 'RANCAGUA',
            description: 'MACHALI'
          },
          {
            regionCode: '6',
            code: '6109',
            city: 'MALLOA',
            description: 'MALLOA'
          },
          {
            regionCode: '6',
            code: '6204',
            city: 'MARCHIHUE',
            description: 'MARCHIHUE'
          },
          {
            regionCode: '6',
            code: '6110',
            city: 'RANCAGUA',
            description: 'MOSTAZAL'
          },
          {
            regionCode: '6',
            code: '6305',
            city: 'NANCAGUA',
            description: 'NANCAGUA'
          },
          {
            regionCode: '6',
            code: '6205',
            city: 'NAVIDAD',
            description: 'NAVIDAD'
          },
          {
            regionCode: '6',
            code: '6111',
            city: 'OLIVAR',
            description: 'OLIVAR'
          },
          {
            regionCode: '6',
            code: '6306',
            city: 'PALMILLA',
            description: 'PALMILLA'
          },
          {
            regionCode: '6',
            code: '6206',
            city: 'PAREDONES',
            description: 'PAREDONES'
          },
          {
            regionCode: '6',
            code: '6307',
            city: 'PERALILLO',
            description: 'PERALILLO'
          },
          {
            regionCode: '6',
            code: '6112',
            city: 'PEUMO',
            description: 'PEUMO'
          },
          {
            regionCode: '6',
            code: '6113',
            city: 'PICHIDEGUA',
            description: 'PICHIDEGUA'
          },
          {
            regionCode: '6',
            code: '6201',
            city: 'PICHILEMU',
            description: 'PICHILEMU'
          },
          {
            regionCode: '6',
            code: '6308',
            city: 'PLACILLA',
            description: 'PLACILLA'
          },
          {
            regionCode: '6',
            code: '6309',
            city: 'PUMANQUE',
            description: 'PUMANQUE'
          },
          {
            regionCode: '6',
            code: '6114',
            city: 'QUINTA DE TILCOCO',
            description: 'QUINTA DE TILCOCO'
          },
          {
            regionCode: '6',
            code: '6101',
            city: 'RANCAGUA',
            description: 'RANCAGUA'
          },
          {
            regionCode: '6',
            code: '6115',
            city: 'RENGO',
            description: 'RENGO'
          },
          {
            regionCode: '6',
            code: '6116',
            city: 'REQUINOA',
            description: 'REQUINOA'
          },
          {
            regionCode: '6',
            code: '6301',
            city: 'SAN FERNANDO',
            description: 'SAN FERNANDO'
          },
          {
            regionCode: '6',
            code: '6117',
            city: 'SAN VICENTE',
            description: 'SAN VICENTE'
          },
          {
            regionCode: '6',
            code: '6310',
            city: 'SANTA CRUZ',
            description: 'SANTA CRUZ'
          },
        ],
        description: 'SEXTA REGIÓN',
        code: '6'
      },
      {
        communes: [
          {
            regionCode: '7',
            code: '7201',
            city: 'CAUQUENES',
            description: 'CAUQUENES'
          },
          {
            regionCode: '7',
            code: '7202',
            city: 'CHANCO',
            description: 'CHANCO'
          },
          {
            regionCode: '7',
            code: '7402',
            city: 'COLBUN',
            description: 'COLBUN'
          },
          {
            regionCode: '7',
            code: '7102',
            city: 'CONSTITUCION',
            description: 'CONSTITUCION'
          },
          {
            regionCode: '7',
            code: '7103',
            city: 'CUREPTO',
            description: 'CUREPTO'
          },
          {
            regionCode: '7',
            code: '7301',
            city: 'CURICO',
            description: 'CURICO'
          },
          {
            regionCode: '7',
            code: '7104',
            city: 'EMPEDRADO',
            description: 'EMPEDRADO'
          },
          {
            regionCode: '7',
            code: '7302',
            city: 'HUALANE',
            description: 'HUALANE'
          },
          {
            regionCode: '7',
            code: '7303',
            city: 'LICANTEN',
            description: 'LICANTEN'
          },
          {
            regionCode: '7',
            code: '7401',
            city: 'LINARES',
            description: 'LINARES'
          },
          {
            regionCode: '7',
            code: '7403',
            city: 'LONGAVI',
            description: 'LONGAVI'
          },
          {
            regionCode: '7',
            code: '7105',
            city: 'MAULE',
            description: 'MAULE'
          },
          {
            regionCode: '7',
            code: '7304',
            city: 'MOLINA',
            description: 'MOLINA'
          },
          {
            regionCode: '7',
            code: '7404',
            city: 'PARRAL',
            description: 'PARRAL'
          },
          {
            regionCode: '7',
            code: '7106',
            city: 'PELARCO',
            description: 'PELARCO'
          },
          {
            regionCode: '7',
            code: '7203',
            city: 'PELLUHUE',
            description: 'PELLUHUE'
          },
          {
            regionCode: '7',
            code: '7107',
            city: 'PENCAHUE',
            description: 'PENCAHUE'
          },
          {
            regionCode: '7',
            code: '7305',
            city: 'RAUCO',
            description: 'RAUCO'
          },
          {
            regionCode: '7',
            code: '7405',
            city: 'RETIRO',
            description: 'RETIRO'
          },
          {
            regionCode: '7',
            code: '7108',
            city: 'RIO CLARO',
            description: 'RIO CLARO'
          },
          {
            regionCode: '7',
            code: '7306',
            city: 'ROMERAL',
            description: 'ROMERAL'
          },
          {
            regionCode: '7',
            code: '7307',
            city: 'SAGRADA FAMILIA',
            description: 'SAGRADA FAMILIA'
          },
          {
            regionCode: '7',
            code: '7109',
            city: 'SAN CLEMENTE',
            description: 'SAN CLEMENTE'
          },
          {
            regionCode: '7',
            code: '7406',
            city: 'SAN JAVIER',
            description: 'SAN JAVIER'
          },
          {
            regionCode: '7',
            code: '7110',
            city: 'SAN RAFAEL',
            description: 'SAN RAFAEL'
          },
          {
            regionCode: '7',
            code: '7101',
            city: 'TALCA',
            description: 'TALCA'
          },
          {
            regionCode: '7',
            code: '7308',
            city: 'TENO',
            description: 'TENO'
          },
          {
            regionCode: '7',
            code: '7309',
            city: 'VICHUQUEN',
            description: 'VICHUQUEN'
          },
          {
            regionCode: '7',
            code: '7407',
            city: 'VILLA ALEGRE',
            description: 'VILLA ALEGRE'
          },
          {
            regionCode: '7',
            code: '7408',
            city: 'YERBAS BUENAS',
            description: 'YERBAS BUENAS'
          },
        ],
        description: 'SEPTIMA REGIÓN',
        code: '7'
      },
      {
        communes: [
          {
            regionCode: '8',
            code: '8314',
            city: 'ALTO BIO BIO',
            description: 'ALTO BIO BIO'
          },
          {
            regionCode: '8',
            code: '8302',
            city: 'ANTUCO',
            description: 'ANTUCO'
          },
          {
            regionCode: '8',
            code: '8202',
            city: 'ARAUCO',
            description: 'ARAUCO'
          },
          {
            regionCode: '8',
            code: '8303',
            city: 'CABRERO',
            description: 'CABRERO'
          },
          {
            regionCode: '8',
            code: '8203',
            city: 'CANETE',
            description: 'CANETE'
          },
          {
            regionCode: '8',
            code: '8103',
            city: 'CONCEPCION',
            description: 'CHIGUAYANTE'
          },
          {
            regionCode: '8',
            code: '8101',
            city: 'CONCEPCION',
            description: 'CONCEPCION'
          },
          {
            regionCode: '8',
            code: '8204',
            city: 'CONTULMO',
            description: 'CONTULMO'
          },
          {
            regionCode: '8',
            code: '8102',
            city: 'CORONEL',
            description: 'CORONEL'
          },
          {
            regionCode: '8',
            code: '8205',
            city: 'CURANILAHUE',
            description: 'CURANILAHUE'
          },
          {
            regionCode: '8',
            code: '8104',
            city: 'FLORIDA',
            description: 'FLORIDA'
          },
          {
            regionCode: '8',
            code: '8112',
            city: 'HUALPEN',
            description: 'HUALPEN'
          },
          {
            regionCode: '8',
            code: '8105',
            city: 'CONCEPCION',
            description: 'HUALQUI'
          },
          {
            regionCode: '8',
            code: '8304',
            city: 'LAJA',
            description: 'LAJA'
          },
          {
            regionCode: '8',
            code: '8201',
            city: 'LEBU',
            description: 'LEBU'
          },
          {
            regionCode: '8',
            code: '8206',
            city: 'LOS ALAMOS',
            description: 'LOS ALAMOS'
          },
          {
            regionCode: '8',
            code: '8301',
            city: 'LOS ANGELES',
            description: 'LOS ANGELES'
          },
          {
            regionCode: '8',
            code: '8106',
            city: 'LOTA',
            description: 'LOTA'
          },
          {
            regionCode: '8',
            code: '8305',
            city: 'MULCHEN',
            description: 'MULCHEN'
          },
          {
            regionCode: '8',
            code: '8306',
            city: 'NACIMIENTO',
            description: 'NACIMIENTO'
          },
          {
            regionCode: '8',
            code: '8307',
            city: 'NEGRETE',
            description: 'NEGRETE'
          },
          {
            regionCode: '8',
            code: '8107',
            city: 'PENCO',
            description: 'PENCO'
          },
          {
            regionCode: '8',
            code: '8308',
            city: 'QUILACO',
            description: 'QUILACO'
          },
          {
            regionCode: '8',
            code: '8309',
            city: 'QUILLECO',
            description: 'QUILLECO'
          },
          {
            regionCode: '8',
            code: '8108',
            city: 'SAN PEDRO DE LA PAZ',
            description: 'SAN PEDRO DE LA PAZ'
          },
          {
            regionCode: '8',
            code: '8310',
            city: 'SAN ROSENDO',
            description: 'SAN ROSENDO'
          },
          {
            regionCode: '8',
            code: '8311',
            city: 'SANTA BARBARA',
            description: 'SANTA BARBARA'
          },
          {
            regionCode: '8',
            code: '8109',
            city: 'SANTA JUANA',
            description: 'SANTA JUANA'
          },
          {
            regionCode: '8',
            code: '8110',
            city: 'TALCAHUANO',
            description: 'TALCAHUANO'
          },
          {
            regionCode: '8',
            code: '8207',
            city: 'TIRUA',
            description: 'TIRUA'
          },
          {
            regionCode: '8',
            code: '8111',
            city: 'TOME',
            description: 'TOME'
          },
          {
            regionCode: '8',
            code: '8312',
            city: 'TUCAPEL',
            description: 'TUCAPEL'
          },
          {
            regionCode: '8',
            code: '8313',
            city: 'YUMBEL',
            description: 'YUMBEL'
          },
        ],
        description: 'OCTAVA REGIÓN',
        code: '8'
      },
      {
        communes: [
          {
            regionCode: '9',
            code: '9201',
            city: 'ANGOL',
            description: 'ANGOL'
          },
          {
            regionCode: '9',
            code: '9102',
            city: 'CARAHUE',
            description: 'CARAHUE'
          },
          {
            regionCode: '9',
            code: '9121',
            city: 'CHOL CHOL',
            description: 'CHOL CHOL'
          },
          {
            regionCode: '9',
            code: '9202',
            city: 'COLLIPULLI',
            description: 'COLLIPULLI'
          },
          {
            regionCode: '9',
            code: '9103',
            city: 'CUNCO',
            description: 'CUNCO'
          },
          {
            regionCode: '9',
            code: '9203',
            city: 'CURACAUTIN',
            description: 'CURACAUTIN'
          },
          {
            regionCode: '9',
            code: '9104',
            city: 'CURARREHUE',
            description: 'CURARREHUE'
          },
          {
            regionCode: '9',
            code: '9204',
            city: 'ERCILLA',
            description: 'ERCILLA'
          },
          {
            regionCode: '9',
            code: '9105',
            city: 'FREIRE',
            description: 'FREIRE'
          },
          {
            regionCode: '9',
            code: '9106',
            city: 'GALVARINO',
            description: 'GALVARINO'
          },
          {
            regionCode: '9',
            code: '9107',
            city: 'GORBEA',
            description: 'GORBEA'
          },
          {
            regionCode: '9',
            code: '9108',
            city: 'LAUTARO',
            description: 'LAUTARO'
          },
          {
            regionCode: '9',
            code: '9109',
            city: 'LONCOCHE',
            description: 'LONCOCHE'
          },
          {
            regionCode: '9',
            code: '9205',
            city: 'LONQUIMAY',
            description: 'LONQUIMAY'
          },
          {
            regionCode: '9',
            code: '9206',
            city: 'LOS SAUCES',
            description: 'LOS SAUCES'
          },
          {
            regionCode: '9',
            code: '9207',
            city: 'LUMACO',
            description: 'LUMACO'
          },
          {
            regionCode: '9',
            code: '9110',
            city: 'MELIPEUCO',
            description: 'MELIPEUCO'
          },
          {
            regionCode: '9',
            code: '9111',
            city: 'NUEVA IMPERIAL',
            description: 'NUEVA IMPERIAL'
          },
          {
            regionCode: '9',
            code: '9112',
            city: 'TEMUCO',
            description: 'PADRE LAS CASAS'
          },
          {
            regionCode: '9',
            code: '9113',
            city: 'PERQUENCO',
            description: 'PERQUENCO'
          },
          {
            regionCode: '9',
            code: '9114',
            city: 'PITRUFQUEN',
            description: 'PITRUFQUEN'
          },
          {
            regionCode: '9',
            code: '9115',
            city: 'PUCON',
            description: 'PUCON'
          },
          {
            regionCode: '9',
            code: '9208',
            city: 'PUREN',
            description: 'PUREN'
          },
          {
            regionCode: '9',
            code: '9209',
            city: 'RENAICO',
            description: 'RENAICO'
          },
          {
            regionCode: '9',
            code: '9116',
            city: 'SAAVEDRA',
            description: 'SAAVEDRA'
          },
          {
            regionCode: '9',
            code: '9101',
            city: 'TEMUCO',
            description: 'TEMUCO'
          },
          {
            regionCode: '9',
            code: '9117',
            city: 'TEODORO SCHMIDT',
            description: 'TEODORO SCHMIDT'
          },
          {
            regionCode: '9',
            code: '9118',
            city: 'TOLTEN',
            description: 'TOLTEN'
          },
          {
            regionCode: '9',
            code: '9210',
            city: 'TRAIGUEN',
            description: 'TRAIGUEN'
          },
          {
            regionCode: '9',
            code: '9211',
            city: 'VICTORIA',
            description: 'VICTORIA'
          },
          {
            regionCode: '9',
            code: '9119',
            city: 'VILCUN',
            description: 'VILCUN'
          },
          {
            regionCode: '9',
            code: '9120',
            city: 'VILLARRICA',
            description: 'VILLARRICA'
          },
        ],
        description: 'NOVENA REGIÓN',
        code: '9'
      },
      {
        communes: [
          {
            regionCode: '10',
            code: '10202',
            city: 'ANCUD',
            description: 'ANCUD'
          },
          {
            regionCode: '10',
            code: '10102',
            city: 'CALBUCO',
            description: 'CALBUCO'
          },
          {
            regionCode: '10',
            code: '10201',
            city: 'CASTRO',
            description: 'CASTRO'
          },
          {
            regionCode: '10',
            code: '10401',
            city: 'CHAITEN',
            description: 'CHAITEN'
          },
          {
            regionCode: '10',
            code: '10203',
            city: 'CHONCHI',
            description: 'CHONCHI'
          },
          {
            regionCode: '10',
            code: '10103',
            city: 'COCHAMO',
            description: 'COCHAMO'
          },
          {
            regionCode: '10',
            code: '10204',
            city: 'CURACO DE VELEZ',
            description: 'CURACO DE VELEZ'
          },
          {
            regionCode: '10',
            code: '10205',
            city: 'CASTRO',
            description: 'DALCAHUE'
          },
          {
            regionCode: '10',
            code: '10104',
            city: 'FRESIA',
            description: 'FRESIA'
          },
          {
            regionCode: '10',
            code: '10105',
            city: 'FRUTILLAR',
            description: 'FRUTILLAR'
          },
          {
            regionCode: '10',
            code: '10402',
            city: 'FUTALEUFU',
            description: 'FUTALEUFU'
          },
          {
            regionCode: '10',
            code: '10403',
            city: 'HUALAIHUE',
            description: 'HUALAIHUE'
          },
          {
            regionCode: '10',
            code: '10107',
            city: 'LLANQUIHUE',
            description: 'LLANQUIHUE'
          },
          {
            regionCode: '10',
            code: '10106',
            city: 'LOS MUERMOS',
            description: 'LOS MUERMOS'
          },
          {
            regionCode: '10',
            code: '10108',
            city: 'MAULLIN',
            description: 'MAULLIN'
          },
          {
            regionCode: '10',
            code: '10301',
            city: 'OSORNO',
            description: 'OSORNO'
          },
          {
            regionCode: '10',
            code: '10404',
            city: 'PALENA',
            description: 'PALENA'
          },
          {
            regionCode: '10',
            code: '10101',
            city: 'PUERTO MONTT',
            description: 'PUERTO MONTT'
          },
          {
            regionCode: '10',
            code: '10302',
            city: 'PUERTO OCTAY',
            description: 'PUERTO OCTAY'
          },
          {
            regionCode: '10',
            code: '10109',
            city: 'PUERTO VARAS',
            description: 'PUERTO VARAS'
          },
          {
            regionCode: '10',
            code: '10206',
            city: 'PUQUELDON',
            description: 'PUQUELDON'
          },
          {
            regionCode: '10',
            code: '10303',
            city: 'PURRANQUE',
            description: 'PURRANQUE'
          },
          {
            regionCode: '10',
            code: '10304',
            city: 'PUYEHUE',
            description: 'PUYEHUE'
          },
          {
            regionCode: '10',
            code: '10207',
            city: 'QUEILEN',
            description: 'QUEILEN'
          },
          {
            regionCode: '10',
            code: '10208',
            city: 'QUELLON',
            description: 'QUELLON'
          },
          {
            regionCode: '10',
            code: '10209',
            city: 'QUEMCHI',
            description: 'QUEMCHI'
          },
          {
            regionCode: '10',
            code: '10210',
            city: 'QUINCHAO',
            description: 'QUINCHAO'
          },
          {
            regionCode: '10',
            code: '10305',
            city: 'RIO NEGRO',
            description: 'RIO NEGRO'
          },
          {
            regionCode: '10',
            code: '10306',
            city: 'SAN JUAN DE LA COSTA',
            description: 'SAN JUAN DE LA COSTA'
          },
          {
            regionCode: '10',
            code: '10307',
            city: 'SAN PABLO',
            description: 'SAN PABLO'
          },
        ],
        description: 'DECIMA REGIÓN',
        code: '10'
      },
      {
        communes: [
          {
            regionCode: '11',
            code: '11201',
            city: 'AISEN',
            description: 'AISEN'
          },
          {
            regionCode: '11',
            code: '11401',
            city: 'CHILE CHICO',
            description: 'CHILE CHICO'
          },
          {
            regionCode: '11',
            code: '11202',
            city: 'CISNES',
            description: 'CISNES'
          },
          {
            regionCode: '11',
            code: '11301',
            city: 'COCHRANE',
            description: 'COCHRANE'
          },
          {
            regionCode: '11',
            code: '11101',
            city: 'COIHAIQUE',
            description: 'COIHAIQUE'
          },
          {
            regionCode: '11',
            code: '11203',
            city: 'GUAITECAS',
            description: 'GUAITECAS'
          },
          {
            regionCode: '11',
            code: '11102',
            city: 'LAGO VERDE',
            description: 'LAGO VERDE'
          },
          {
            regionCode: '11',
            code: '11302',
            city: 'O\'HIGGINS',
            description: 'O\'HIGGINS'
          },
          {
            regionCode: '11',
            code: '11402',
            city: 'RIO IBANEZ',
            description: 'RIO IBANEZ'
          },
          {
            regionCode: '11',
            code: '11303',
            city: 'TORTEL',
            description: 'TORTEL'
          },
        ],
        description: 'UNDECIMA REGIÓN',
        code: '11'
      },
      {
        communes: [
          {
            regionCode: '12',
            code: '12202',
            city: 'ANTARTICA',
            description: 'ANTARTICA'
          },
          {
            regionCode: '12',
            code: '12201',
            city: 'PUERTO WILLIAMS',
            description: 'CABO DE HORNOS'
          },
          {
            regionCode: '12',
            code: '12102',
            city: 'LAGUNA BLANCA',
            description: 'LAGUNA BLANCA'
          },
          {
            regionCode: '12',
            code: '12301',
            city: 'PORVENIR',
            description: 'PORVENIR'
          },
          {
            regionCode: '12',
            code: '12302',
            city: 'PRIMAVERA',
            description: 'PRIMAVERA'
          },
          {
            regionCode: '12',
            code: '12401',
            city: 'PUERTO NATALES',
            description: 'PUERTO NATALES'
          },
          {
            regionCode: '12',
            code: '12101',
            city: 'PUNTA ARENAS',
            description: 'PUNTA ARENAS'
          },
          {
            regionCode: '12',
            code: '12103',
            city: 'RIO VERDE',
            description: 'RIO VERDE'
          },
          {
            regionCode: '12',
            code: '12104',
            city: 'SAN GREGORIO',
            description: 'SAN GREGORIO'
          },
          {
            regionCode: '12',
            code: '12303',
            city: 'TIMAUKEL',
            description: 'TIMAUKEL'
          },
          {
            regionCode: '12',
            code: '12402',
            city: 'PUERTO NATALES',
            description: 'TORRES DEL PAINE'
          },
        ],
        description: 'DUODECIMA REGIÓN',
        code: '12'
      },
      {
        communes: [
          {
            regionCode: '13',
            code: '13502',
            city: 'SANTIAGO',
            description: 'ALHUE'
          },
          {
            regionCode: '13',
            code: '13402',
            city: 'SANTIAGO',
            description: 'BUIN'
          },
          {
            regionCode: '13',
            code: '13403',
            city: 'SANTIAGO',
            description: 'CALERA DE TANGO'
          },
          {
            regionCode: '13',
            code: '13102',
            city: 'SANTIAGO',
            description: 'CERRILLOS'
          },
          {
            regionCode: '13',
            code: '13103',
            city: 'SANTIAGO',
            description: 'CERRO NAVIA'
          },
          {
            regionCode: '13',
            code: '13301',
            city: 'SANTIAGO',
            description: 'COLINA'
          },
          {
            regionCode: '13',
            code: '13104',
            city: 'SANTIAGO',
            description: 'CONCHALI'
          },
          {
            regionCode: '13',
            code: '13503',
            city: 'SANTIAGO',
            description: 'CURACAVI'
          },
          {
            regionCode: '13',
            code: '13105',
            city: 'SANTIAGO',
            description: 'EL BOSQUE'
          },
          {
            regionCode: '13',
            code: '13602',
            city: 'SANTIAGO',
            description: 'EL MONTE'
          },
          {
            regionCode: '13',
            code: '13106',
            city: 'SANTIAGO',
            description: 'ESTACION CENTRAL'
          },
          {
            regionCode: '13',
            code: '13107',
            city: 'SANTIAGO',
            description: 'HUECHURABA'
          },
          {
            regionCode: '13',
            code: '13108',
            city: 'SANTIAGO',
            description: 'INDEPENDENCIA'
          },
          {
            regionCode: '13',
            code: '13603',
            city: 'SANTIAGO',
            description: 'ISLA DE MAIPO'
          },
          {
            regionCode: '13',
            code: '13109',
            city: 'SANTIAGO',
            description: 'LA CISTERNA'
          },
          {
            regionCode: '13',
            code: '13110',
            city: 'SANTIAGO',
            description: 'LA FLORIDA'
          },
          {
            regionCode: '13',
            code: '13111',
            city: 'SANTIAGO',
            description: 'LA GRANJA'
          },
          {
            regionCode: '13',
            code: '13112',
            city: 'SANTIAGO',
            description: 'LA PINTANA'
          },
          {
            regionCode: '13',
            code: '13113',
            city: 'SANTIAGO',
            description: 'LA REINA'
          },
          {
            regionCode: '13',
            code: '13302',
            city: 'SANTIAGO',
            description: 'LAMPA'
          },
          {
            regionCode: '13',
            code: '13114',
            city: 'SANTIAGO',
            description: 'LAS CONDES'
          },
          {
            regionCode: '13',
            code: '13115',
            city: 'SANTIAGO',
            description: 'LO BARNECHEA'
          },
          {
            regionCode: '13',
            code: '13116',
            city: 'SANTIAGO',
            description: 'LO ESPEJO'
          },
          {
            regionCode: '13',
            code: '13117',
            city: 'SANTIAGO',
            description: 'LO PRADO'
          },
          {
            regionCode: '13',
            code: '13118',
            city: 'SANTIAGO',
            description: 'MACUL'
          },
          {
            regionCode: '13',
            code: '13119',
            city: 'SANTIAGO',
            description: 'MAIPU'
          },
          {
            regionCode: '13',
            code: '13606',
            city: 'SANTIAGO',
            description: 'MANTENCION'
          },
          {
            regionCode: '13',
            code: '13504',
            city: 'SANTIAGO',
            description: 'MARIA PINTO'
          },
          {
            regionCode: '13',
            code: '13501',
            city: 'SANTIAGO',
            description: 'MELIPILLA'
          },
          {
            regionCode: '13',
            code: '13120',
            city: 'SANTIAGO',
            description: 'NUNOA'
          },
          {
            regionCode: '13',
            code: '13604',
            city: 'SANTIAGO',
            description: 'PADRE HURTADO'
          },
          {
            regionCode: '13',
            code: '13404',
            city: 'SANTIAGO',
            description: 'PAINE'
          },
          {
            regionCode: '13',
            code: '13121',
            city: 'SANTIAGO',
            description: 'PEDRO AGUIRRE CERDA'
          },
          {
            regionCode: '13',
            code: '13605',
            city: 'SANTIAGO',
            description: 'PENAFLOR'
          },
          {
            regionCode: '13',
            code: '13122',
            city: 'SANTIAGO',
            description: 'PENALOLEN'
          },
          {
            regionCode: '13',
            code: '13202',
            city: 'SANTIAGO',
            description: 'PIRQUE'
          },
          {
            regionCode: '13',
            code: '13123',
            city: 'SANTIAGO',
            description: 'PROVIDENCIA'
          },
          {
            regionCode: '13',
            code: '13124',
            city: 'SANTIAGO',
            description: 'PUDAHUEL'
          },
          {
            regionCode: '13',
            code: '13201',
            city: 'SANTIAGO',
            description: 'PUENTE ALTO'
          },
          {
            regionCode: '13',
            code: '13125',
            city: 'SANTIAGO',
            description: 'QUILICURA'
          },
          {
            regionCode: '13',
            code: '13126',
            city: 'SANTIAGO',
            description: 'QUINTA NORMAL'
          },
          {
            regionCode: '13',
            code: '13127',
            city: 'SANTIAGO',
            description: 'RECOLETA'
          },
          {
            regionCode: '13',
            code: '13128',
            city: 'SANTIAGO',
            description: 'RENCA'
          },
          {
            regionCode: '13',
            code: '13401',
            city: 'SANTIAGO',
            description: 'SAN BERNARDO'
          },
          {
            regionCode: '13',
            code: '13129',
            city: 'SANTIAGO',
            description: 'SAN JOAQUIN'
          },
          {
            regionCode: '13',
            code: '13203',
            city: 'SANTIAGO',
            description: 'SAN JOSE DE MAIPO'
          },
          {
            regionCode: '13',
            code: '13130',
            city: 'SANTIAGO',
            description: 'SAN MIGUEL'
          },
          {
            regionCode: '13',
            code: '13505',
            city: 'SAN PEDRO',
            description: 'SAN PEDRO'
          },
          {
            regionCode: '13',
            code: '13131',
            city: 'SANTIAGO',
            description: 'SAN RAMON'
          },
          {
            regionCode: '13',
            code: '13101',
            city: 'SANTIAGO',
            description: 'SANTIAGO'
          },
          {
            regionCode: '13',
            code: '13601',
            city: 'SANTIAGO',
            description: 'TALAGANTE'
          },
          {
            regionCode: '13',
            code: '13303',
            city: 'SANTIAGO',
            description: 'TILTIL'
          },
          {
            regionCode: '13',
            code: '13132',
            city: 'SANTIAGO',
            description: 'VITACURA'
          },
        ],
        description: 'REGION METROPOLITANA',
        code: '13'
      },
      {
        communes: [
          {
            regionCode: '14',
            code: '14102',
            city: 'CORRAL',
            description: 'CORRAL'
          },
          {
            regionCode: '14',
            code: '14202',
            city: 'VALDIVIA',
            description: 'FUTRONO'
          },
          {
            regionCode: '14',
            code: '14201',
            city: 'LA UNION',
            description: 'LA UNION'
          },
          {
            regionCode: '14',
            code: '14203',
            city: 'LAGO RANCO',
            description: 'LAGO RANCO'
          },
          {
            regionCode: '14',
            code: '14103',
            city: 'LANCO',
            description: 'LANCO'
          },
          {
            regionCode: '14',
            code: '14104',
            city: 'LOS LAGOS',
            description: 'LOS LAGOS'
          },
          {
            regionCode: '14',
            code: '14105',
            city: 'MAFIL',
            description: 'MAFIL'
          },
          {
            regionCode: '14',
            code: '14106',
            city: 'MARIQUINA',
            description: 'MARIQUINA'
          },
          {
            regionCode: '14',
            code: '14107',
            city: 'PAILLACO',
            description: 'PAILLACO'
          },
          {
            regionCode: '14',
            code: '14108',
            city: 'VALDIVIA',
            description: 'PANGUIPULLI'
          },
          {
            regionCode: '14',
            code: '14204',
            city: 'RIO BUENO',
            description: 'RIO BUENO'
          },
          {
            regionCode: '14',
            code: '14101',
            city: 'VALDIVIA',
            description: 'VALDIVIA'
          },
        ],
        description: 'DECIMOCUARTA REGIÓN',
        code: '14'
      },
      {
        communes: [
          {
            regionCode: '15',
            code: '15101',
            city: 'ARICA',
            description: 'ARICA'
          },
          {
            regionCode: '15',
            code: '15102',
            city: 'CAMARONES',
            description: 'CAMARONES'
          },
          {
            regionCode: '15',
            code: '15202',
            city: 'GENERAL LAGOS',
            description: 'GENERAL LAGOS'
          },
          {
            regionCode: '15',
            code: '15201',
            city: 'PUTRE',
            description: 'PUTRE'
          },
        ],
        description: 'DECIMOQUINTA REGIÓN',
        code: '15'
      },
      {
        communes: [
          {
            regionCode: '16',
            code: '16102',
            city: 'BULNES',
            description: 'BULNES'
          },
          {
            regionCode: '16',
            code: '16101',
            city: 'CHILLAN',
            description: 'CHILLAN'
          },
          {
            regionCode: '16',
            code: '16103',
            city: 'CHILLAN',
            description: 'CHILLAN VIEJO'
          },
          {
            regionCode: '16',
            code: '16202',
            city: 'COBQUECURA',
            description: 'COBQUECURA'
          },
          {
            regionCode: '16',
            code: '16203',
            city: 'COELEMU',
            description: 'COELEMU'
          },
          {
            regionCode: '16',
            code: '16302',
            city: 'COIHUECO',
            description: 'COIHUECO'
          },
          {
            regionCode: '16',
            code: '16104',
            city: 'EL CARMEN',
            description: 'EL CARMEN'
          },
          {
            regionCode: '16',
            code: '16204',
            city: 'NINHUE',
            description: 'NINHUE'
          },
          {
            regionCode: '16',
            code: '16303',
            city: 'NIQUEN',
            description: 'NIQUEN'
          },
          {
            regionCode: '16',
            code: '16105',
            city: 'PEMUCO',
            description: 'PEMUCO'
          },
          {
            regionCode: '16',
            code: '16106',
            city: 'PINTO',
            description: 'PINTO'
          },
          {
            regionCode: '16',
            code: '16205',
            city: 'PORTEZUELO',
            description: 'PORTEZUELO'
          },
          {
            regionCode: '16',
            code: '16107',
            city: 'QUILLON',
            description: 'QUILLON'
          },
          {
            regionCode: '16',
            code: '16201',
            city: 'QUIRIHUE',
            description: 'QUIRIHUE'
          },
          {
            regionCode: '16',
            code: '16206',
            city: 'RANQUIL',
            description: 'RANQUIL'
          },
          {
            regionCode: '16',
            code: '16301',
            city: 'SAN CARLOS',
            description: 'SAN CARLOS'
          },
          {
            regionCode: '16',
            code: '16304',
            city: 'SAN FABIAN',
            description: 'SAN FABIAN'
          },
          {
            regionCode: '16',
            code: '16108',
            city: 'SAN IGNACIO',
            description: 'SAN IGNACIO'
          },
          {
            regionCode: '16',
            code: '16305',
            city: 'SAN NICOLAS',
            description: 'SAN NICOLAS'
          },
          {
            regionCode: '16',
            code: '16207',
            city: 'TREGUACO',
            description: 'TREGUACO'
          },
          {
            regionCode: '16',
            code: '16109',
            city: 'YUNGAY',
            description: 'YUNGAY'
          },
        ],
        description: 'DECIMOSEXTA REGIÓN',
        code: '16'
      },
    ],
  }],
  failures: [{
    response: {}
  }]
};
