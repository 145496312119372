import { Subscription } from 'rxjs';

import { Component, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';
import { ReloginModalComponent } from '@components/relogin-modal/relogin-modal.component';
import {
  IDLE_TIMEOUT_IN_SECONDS, LANGUAGE_KEY, LOGOUT_MODES, REFRESH_TOKEN_TIME_IN_SECONDS,
  RELOAD_APP_TIME, VERSION_STORAGE_KEY
} from '@constants/constants';
import { DEFAULT_UNAUTHORIZED_MODAL_PROPS } from '@constants/modal-data.constants';
import { environment } from '@env';
import { AlertBanner } from '@interfaces/components.interface';
import { ModalData, ModalPropsInitial } from '@interfaces/modal-data.interface';
import { Platform } from '@ionic/angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TranslateService } from '@ngx-translate/core';
import packageInfo from '@package';
import { APP_COMPONENT_CONTENT } from '@pages-content/app-component.constant';
import { MAIN_CONTENT } from '@pages-content/main.constant';
import { CommunicationProvider } from '@providers/communication/communication';
import { GoogleAnalyticsService } from '@providers/google-analytics/google-analytics.service';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { FirebaseService } from '@services/firebase/firebase.abstract';
import { GoogleMapsLoaderService } from '@services/google-maps-loader/google-maps-loader.service';
import { UpdateAppService } from '@services/service-worker/service-worker';
import { Util } from '@util';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  public pageContent = MAIN_CONTENT;
  public updateContent = APP_COMPONENT_CONTENT;
  public showLoading = false;
  public showHelpButton = false;
  public showOutdatedToast = false;
  public subscription: Subscription;
  public alertBanner: AlertBanner;
  public showAlert: boolean;
  public loading = false;
  private modalState = false;
  private countSeconds: number;
  private dateState: Date;
  public vitaliaOptionsOpened = false;

  constructor(
    public communicationProvider: CommunicationProvider,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private authService: AuthenticationService,
    private firebaseService: FirebaseService,
    private googleMapsLoaderService: GoogleMapsLoaderService,
    private idle: Idle,
    private keepalive: Keepalive,
    private loadingProvider: LoadingProvider,
    private meta: Meta,
    private modalProvider: ModalProvider,
    private platform: Platform,
    private renderer: Renderer2,
    private router: Router,
    private serviceWorker: UpdateAppService,
    private translateService: TranslateService,
    private translate: TranslateService,
    private util: Util,
  ) {
    this.initializeLanguage();
    this.setupIdleBehavior();
    this.subscribeUnauthorizedEvent();
    this.addRecaptchaScript();
    this.meta.addTag({ name: 'Version', content: packageInfo.version });
    this.addWebChatScript();
  }

  public ngOnInit(): void {
    setTimeout(() => {
      if (this.util.isNative && packageInfo.version !== localStorage.getItem(VERSION_STORAGE_KEY)) {
        localStorage.clear();
        sessionStorage.clear();
        Preferences.clear();
        document.location.reload();
      }
      localStorage.setItem(VERSION_STORAGE_KEY, packageInfo.version);
    }, 8000);

    this.serviceWorker.getUpdateAppSubscription().subscribe(() => this.handleUpdateAppSubscription());
    this.serviceWorker.checkForUpdates();
    this.loadingProvider.getLoadingRequestIntercepted().subscribe((showLoading) => setTimeout(() => this.showLoading = showLoading, 0));
    if (this.util.isNative) this.firebaseService.pushNotificationsRegister();
    this.initializeApp();

    this.router.events
      .subscribe(async (event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          if (location.pathname === '/ingreso') {
            this.showHelpButton = false;
            this.authService.logout();
          }
        }
      });
    this.loadGoogleMapsScript();
  }

  public get iosClassName(): string {
    return this.util.isIosPlatform ? 'ios-margin' : '';
  }


  private initializeApp(): void {
    this.platform.ready().then(() => {
      if (this.util.isAndroidPlatform) this.util.styleStatusBar();
      if (this.util.isNative) {
        this.util.hideSplashScreen();
        localStorage.setItem(VERSION_STORAGE_KEY, packageInfo.version);
      }
      this.handlingBackButton();

      this.platform.pause.subscribe(() => {
        this.dateState = new Date();
      });

      this.platform.resume.subscribe(() => {
        const date = new Date();
        this.countSeconds = this.util.getSecondsBetweenDates(date, this.dateState);
        if (this.countSeconds >= IDLE_TIMEOUT_IN_SECONDS && this.authService.isUserLoggedIn()) {
          if (this.util.isNative) {
            this.modalProvider.openModalCenterCustom(ReloginModalComponent, {});
          }
        }
      });
    });
  }

  private handleUpdateAppSubscription(): void {
    this.showOutdatedToast = true;
    setTimeout(() => document.location.reload(), RELOAD_APP_TIME);
  }

  private subscribeUnauthorizedEvent(): void {
    this.subscription = this.communicationProvider.requestIntercepted
      .subscribe(() => {
        const params = this.util.setModalTexts(DEFAULT_UNAUTHORIZED_MODAL_PROPS);
        params.closeCallback = () => this.authService.logout(LOGOUT_MODES.logOutTemporalSession);
        this.modalProvider.openModal(params);
      });
  }

  private setupIdleBehavior(): void {
    if (this.authService.isTemporalSession) return;
    this.idle.setIdle(IDLE_TIMEOUT_IN_SECONDS);
    this.idle.setTimeout(IDLE_TIMEOUT_IN_SECONDS);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => this.idle.watch());
    this.idle.onTimeout.subscribe(() => {
      this.modalProvider.openModalCenterCustom(ReloginModalComponent, {});
    });
    this.keepalive.interval(REFRESH_TOKEN_TIME_IN_SECONDS);
    this.keepalive.onPing.subscribe(() => this.authService.refreshToken());
    this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) this.idle.watch();
      else this.idle.stop();
      this.showHelpButton = userLoggedIn;
    });
  }

  private addRecaptchaScript(): void {
    const recaptchaSrc = (document: any, s: string, id: string, obj: any) => {
      // eslint-disable-next-line prefer-const
      let js: any, fjs = document.getElementsByTagName(s)[0];
      if (document.getElementById(id)) { return; }
      // eslint-disable-next-line prefer-const
      js = document.createElement(s); js.id = id;
      js.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.recaptchaSiteKey}`;
      fjs.parentNode.insertBefore(js, fjs);
    };
    recaptchaSrc(document, 'script', 'recaptcha-jssdk', this);
  }

  private initializeLanguage() {
    if (localStorage.getItem(LANGUAGE_KEY) !== null) {
      return this.translate.use(localStorage.getItem(LANGUAGE_KEY));
    }
    localStorage.setItem(LANGUAGE_KEY, 'es');
    this.translate.use('es');
  }

  public handlingBackButton(): void {
    App.addListener('backButton', async () => {
      switch (location.pathname) {
        case '/ingreso':
          this.entryCase();
          break;
        case '/inicio':
          break;
        case '/inicio-empleador':
          if (!this.modalState) await this.logOutModal();
          break;
        default:
          history.back();
          break;
      }
    });
  }

  public async entryCase(): Promise<void> {
    this.showHelpButton = false;
    await this.authService.logout();
    App.exitApp();
  }

  public async logOutModal(): Promise<void> {
    const props: ModalPropsInitial = {} as ModalPropsInitial;
    const params = {
      title: this.translateService.instant(this.pageContent.logoutModal.title),
      iconName: 'i-alert',
      firstBtnText: this.translateService.instant(this.pageContent.logoutModal.firstButton),
      primaryCallback: () => {
        this.authService.logout();
        this.modalState = false;
        this.router.navigate(['/ingreso'], { replaceUrl: true });
      },
      secondBtnText: this.translateService.instant(this.pageContent.logoutModal.secondButton),
      secondaryCallback: () => {
        this.modalState = false;
        this.modalProvider.closeModals();
      },
      ...props
    } as ModalData;
    this.modalState = true;
    this.modalProvider.openModal(params);
  }

  private addWebChatScript() {

    const script = this.renderer.createElement('script');
    script.innerHTML = `
    (function (g, e, n, es, ys) {
      g['_genesysJs'] = e;
      g[e] = g[e] || function () {
        (g[e].q = g[e].q || []).push(arguments)
      };
      g[e].t = 1 * new Date();
      g[e].c = es;
      ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
    })(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
      environment: 'prod',
      deploymentId: '7d7e4112-4d7e-4c49-9cac-97cb0209044b'
    });
    `;
    this.renderer.appendChild(document.head, script);
  }

  private loadGoogleMapsScript(): void {
    this.googleMapsLoaderService.load();
  }
}
