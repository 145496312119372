import { Injectable } from '@angular/core';
import { LANGUAGE_KEY } from '@constants/constants';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class LanguageProvider {
  public language = new Subject<string>();
  public selectedLanguage: string;

  constructor(
    public translate: TranslateService,
  ) { }

  public setLanguage(language: string): void {
    if (this.selectedLanguage === language) return;
    localStorage.setItem(LANGUAGE_KEY, language);
    this.selectedLanguage = language;
    this.language.next(language);
    this.translate.use(language);
  }
}
