export class FormFiles {
  data: Array<string>;

  constructor(data: Array<string>) {
    this.data = data;
  }
}

export class DocumentFile {
  public file: File;
  public key: string;
  public personRut: string;
  public isEditable?: boolean;
}
