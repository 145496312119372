import { NgModule } from '@angular/core';
import { AutocompletePositionDirective } from './autocomplete-position/autocomplete-position.directive';

import { BlockPasteDirective } from './block-paste/block-paste.directive';
import { ConfirmExitDirective } from './confirm-exit/confirm-exit.directive';
import { CurrencyInputDirective } from './currency/currency.directive';
import { FormatRutDirective } from './format-rut/format-rut.directive';
import { MoneyInputDirective } from './money-directive/money.directive';
import { AppOnlyLettersDirective } from './only-letters/only-letters.directive';
import { PhoneNumberDirective } from './phone-number/phone-number.directive';
import { ScrollingInformantDirective } from './scrolling-informant/scrolling-informant.directive';

@NgModule({
  declarations: [
    BlockPasteDirective,
    MoneyInputDirective,
    FormatRutDirective,
    CurrencyInputDirective,
    ConfirmExitDirective,
    PhoneNumberDirective,
    AutocompletePositionDirective,
    ScrollingInformantDirective,
    AppOnlyLettersDirective,
  ],
  exports: [
    BlockPasteDirective,
    MoneyInputDirective,
    FormatRutDirective,
    CurrencyInputDirective,
    ConfirmExitDirective,
    PhoneNumberDirective,
    AutocompletePositionDirective,
    ScrollingInformantDirective,
    AppOnlyLettersDirective,
  ],
})
export class DirectivesModule {}
