<mat-dialog-content *ngIf="isTipStep && !isLoading">
  <app-id-capture-guidelines></app-id-capture-guidelines>
  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphIDProcess()">{{ pageContent?.step1.guidelines.buttons.continue |
      translate }}</button>
    <button class="btn secondary large" (click)="cancel()">{{ pageContent?.step1.guidelines.buttons.cancel | translate }}</button>
    <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isCaptureProcess && !isLoading">
  <div class="full-content capture">
    
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step1.actions.capture.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step1.actions.capture.subtitle | translate }}</h2>
  </div>

  <div class="buttons-container">
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isProcessSucceeded && !isLoading">
  
  <div class="full-content success">
    <div>
      <img src="../../../../../assets/imgs/check.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step1.actions.succeded.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step1.actions.succeded.subtitle | translate }}
    </h2>
  </div>

  <div class="buttons-container">
    <button class="btn primary large" (click)="continue()">{{ pageContent?.step1.actions.succeded.buttons.continue |
      translate }}</button>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isServiceError && !isLoading">
  
  <div class="full-content error">
    <div>
      <img src="../../../../../assets/imgs/warning.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step1.actions.error.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ message }}</h2>
  </div>

  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphIDProcess()">{{
      pageContent?.step1.actions.error.buttons.retry | translate }}</button>
    <button class="btn secondary large" (click)="cancel()">{{ pageContent?.step1.actions.error.buttons.finalize |
      translate }}</button>
    <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isCancelByUser && !isLoading">
  
  <div class="full-content cancel">
    <div>
      <img src="../../../../../assets/imgs/warning.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step1.actions.cancel.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step1.actions.cancel.subtitle | translate }}</h2>
  </div>

  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphIDProcess()">{{
      pageContent?.step1.actions.cancel.buttons.retry | translate }}</button>
    <button class="btn secondary large">{{ pageContent?.step1.actions.cancel.buttons.finalize | translate }}</button>
    <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isTimeOut && !isLoading">
  
  <div class="full-content cancel">
    <div>
      <img src="../../../../../assets/imgs/warning.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step1.actions.timeout.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step1.actions.timeout.subtitle | translate }}
    </h2>
  </div>

  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphIDProcess()">{{
      pageContent?.step1.actions.timeout.buttons.retry | translate }}</button>
    <button class="btn secondary large" (click)="cancel()">{{ pageContent?.step1.actions.timeout.buttons.finalize |
      translate }}</button>
    <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isLoading">
  
  <div class="full-content loading">
    <div>
      <img src="../../../../../assets/imgs/spinner.gif">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step1.actions.loading.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step1.actions.loading.subtitle | translate }}
    </h2>
  </div>

</mat-dialog-content>