import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionStatusProvider {
  private sessionHasEndedSubject =  new Subject<boolean>();
  public sessionHasEnded$: Observable<boolean> = this.sessionHasEndedSubject.asObservable();
  public setSessionAsEnded() { this.sessionHasEndedSubject.next(true); }
}
