import { Mock } from '@interfaces/mock.interface';

export const GET_EXECUTIVE_MOCK: Mock = {
  success: [{
    response: {
      name: 'CALVO GODOY LORENA PIA',
      rut: '000000134724714'
    },
    output: {
      name: 'CALVO GODOY LORENA PIA',
      rut: '000000134724714'
    }
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }],
};
