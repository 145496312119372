<div class="modal-close" *ngIf="closeBtn">
  <div class="i-cross-gray" (click)="dialogClose()"></div>
</div>
<div class="modal-container">
  <div *ngIf="iconName" class="modal-icon {{ iconName }}"></div>

  <h1 class="title-secondary" [ngClass]="font?.size" [innerHTML]="title | translate"></h1>

  <p class="description" [ngClass]="font?.size">{{message | translate}}<a class="personal-data-link" (click)="goToPersonalData()" target='blank'>{{personalData | translate}}</a></p>

  <div  class="buttons-container">
    <button class="btn small primary" [ngClass]="font?.size" (click)="primaryAction()" [innerHTML]="firstBtnText | translate"></button>
  </div>
</div>
