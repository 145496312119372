<div class="modal-close">
  <div (click)="onCloseDialog()" class="i-close-modal"></div>
</div>

<div class="relogin-main-container">
  <div class="icon i-relogin"></div>
  <div [ngClass]="font?.size" class="title">{{ modalContent?.title | translate }}</div>
  <div [ngClass]="font?.size" class="subtitle">{{ modalContent?.subtitle | translate }}</div>
  <div class="password">
    <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="onReloginHot()">
      <mat-form-field [ngClass]="font?.size">
        <mat-label [ngClass]="font?.size" [innerHTML]="pageContent?.form.controls.password.label | translate"></mat-label>
          <input [ngClass]="font?.size" matInput type="password" formControlName="password" placeholder="******" [maxLength]="maxLength" [minlength]="minLength" blockPaste>
          <span matSuffix class="i-validator"></span>
          <mat-error [ngClass]="font?.size" *ngIf="password.hasError('required')" [innerHTML]="pageContent?.form.controls.password.errors.required | translate"></mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="password.hasError('minlength') || password.hasError('maxlength')">{{ pageContent?.form.controls.password.errors.invalid.firstSegment | translate }} {{ minLength }} {{ pageContent?.form.controls.password.errors.invalid.secondSegment | translate }} {{ maxLength }} {{ pageContent?.form.controls.password.errors.invalid.thirdSegment| translate }}</mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="password.hasError('wrongPassword')">{{ wrongPasswordMessage }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <button [ngClass]="font?.size" class="btn primary large button-continue" (click)="onReloginHot()" [disabled]="loginForm.invalid || loading">{{ modalContent?.buttonRelogin | translate }}</button>
  <button [ngClass]="font?.size" class="button-recover" (click)="onRecoverPassword()">{{ modalContent?.buttonRecover | translate }}</button>
</div>
