const modalsKey = 'MODALS';

export const MODALS_CONTENT = {
  buttons: {
    ok: `${modalsKey}.BUTTONS.OK`,
    continue: `${modalsKey}.BUTTONS.CONTINUE`,
    retry: `${modalsKey}.BUTTONS.RETRY`,
    reapply: `${modalsKey}.BUTTONS.REAPPLY`,
    back: `${modalsKey}.BUTTONS.BACK`,
    negationBack: `${modalsKey}.BUTTONS.NEGATION_BACK`,
    contactCenter: `${modalsKey}.BUTTONS.CONTACT_CENTER`,
    cancelTransaction: `${modalsKey}.BUTTONS.CANCEL_TRANSACTION`,
    branchOffices: `${modalsKey}.BUTTONS.BRANCH_OFFICES`,
    edit: `${modalsKey}.BUTTONS.EDIT`,
    home: `${modalsKey}.BUTTONS.HOME`,
    login: `${modalsKey}.BUTTONS.LOGIN`,
    confirmWithdrawal: `${modalsKey}.BUTTONS.CONFIRM_WITHDRAWAL`,
    updateData: `${modalsKey}.BUTTONS.UPDATE_DATA`,
    notNow: `${modalsKey}.BUTTONS.NOT_NOW`,
    updateNow: `${modalsKey}.BUTTONS.UPDATE_NOW`,
    addSavings: `${modalsKey}.BUTTONS.ADD_SAVINGS`,
    previred: `${modalsKey}.BUTTONS.REDIRECT_PREVIRED`,
    removeSecurityKey: `${modalsKey}.BUTTONS.REMOVE_SECURITY_KEY`,
    download: `${modalsKey}.BUTTONS.DOWNLOAD`,
    declaine: `${modalsKey}.BUTTONS.DECLAINE`,
    sendKeyDynamic: `${modalsKey}.BUTTONS.SEND_KEY_DYNAMIC`,
    validateConfirm: `${modalsKey}.BUTTONS.VALIDATE_CONFIRM`,
    cancel: `${modalsKey}.BUTTONS.CANCEL`,
  },
  contactCenter: {
    title: `${modalsKey}.CONTACT_CENTER.TITLE`,
    message: `${modalsKey}.CONTACT_CENTER.MESSAGE`,
  },
  removeSecurityKey: {
    title: `${modalsKey}.REMOVE_SECURITY_KEY.TITLE`,
    message: `${modalsKey}.REMOVE_SECURITY_KEY.MESSAGE`,
  },
  unauthorized: {
    title: `${modalsKey}.UNAUTHORIZED.TITLE`,
    message: `${modalsKey}.UNAUTHORIZED.MESSAGE`,
  },
  updatingHistoricMovements: {
    title: `${modalsKey}.UPDATING_HISTORIC_MOVEMENTS.TITLE`,
    message: `${modalsKey}.UPDATING_HISTORIC_MOVEMENTS.MESSAGE`,
  },
  idleTimeout: {
    title: `${modalsKey}.IDLE_TIMEOUT.TITLE`,
    message: `${modalsKey}.IDLE_TIMEOUT.MESSAGE`,
    appMessage: `${modalsKey}.IDLE_TIMEOUT.APP_MESSAGE`,
  },
  error: {
    title: `${modalsKey}.ERROR.TITLE`,
    message: `${modalsKey}.ERROR.MESSAGE`,
  },
  errorFileSize: {
    title: `${modalsKey}.ERROR_FILE_SIZE.TITLE`,
    message: `${modalsKey}.ERROR_FILE_SIZE.MESSAGE`,
  },
  errorExpiredId: {
    title: `${modalsKey}.ERROR_EXPIRED_ID.TITLE`,
    message: `${modalsKey}.ERROR_EXPIRED_ID.MESSAGE`,
  },
  errorForeignerId: {
    title: `${modalsKey}.ERROR_FOREIGNER_ID.TITLE`,
    message: `${modalsKey}.ERROR_FOREIGNER_ID.MESSAGE`,
  },
  errorDniException: {
    title: `${modalsKey}.ERROR_DNI_EXCEPTION.TITLE`,
    message: `${modalsKey}.ERROR_DNI_EXCEPTION.MESSAGE`,
  },
  errorExternalToken: {
    title: `${modalsKey}.ERROR_EXTERNAL_TOKEN.TITLE`,
    message: `${modalsKey}.ERROR_EXTERNAL_TOKEN.MESSAGE`,
  },
  resume: {
    title: `${modalsKey}.RESUME.TITLE`,
    subtitle: `${modalsKey}.RESUME.SUBTITLE`,
    message: `${modalsKey}.RESUME.MESSAGE`,
    withdrawalAmount: `${modalsKey}.RESUME.WITHDRAWAL_AMOUNT`,
    withdrawalAccount: `${modalsKey}.RESUME.WITHDRAWAL_ACCOUNT`,
    withdrawalType: `${modalsKey}.RESUME.WITHDRAWAL_TYPE`,
    transfer: `${modalsKey}.RESUME.TRANSFER`,
    withdrawalCav10: `${modalsKey}.RESUME.WITHDRAWAL_CAV_10`,
    withdrawalCav2: `${modalsKey}.RESUME.WITHDRAWAL_CAV_2`,
    withdrawalApv: `${modalsKey}.RESUME.WITHDRAWAL_CAV_APV`,
    description60Min: `${modalsKey}.RESUME.DESCRIPTION_60_MIN`,
    description24Hrs: `${modalsKey}.RESUME.DESCRIPTION_24_HRS`,
    descriptionDeferred: `${modalsKey}.RESUME.DESCRIPTION_DEFERRED`,
    advice: `${modalsKey}.RESUME.ADVICE`,
  },
  accountValidation: {
    secondBtnText: `${modalsKey}.ACCOUNT_VALIDATION_ERROR.CALLME`,
    descriptionText: `${modalsKey}.ACCOUNT_VALIDATION_ERROR.HELP`,
    messageRut: `Lo sentimos el RUT no coincide con el titular de la cuenta bancaria`,
    messageDigital: `Lo sentimos, no puedes hacer retiros a una cuenta digital o bipersonal`,
  },
  employerNotFoundError: {
    title: `${modalsKey}.EMPLOYER_NOT_FOUND_ERROR.TITLE`,
    message: `${modalsKey}.EMPLOYER_NOT_FOUND_ERROR.MESSAGE`,
  },
  changePasswordSuccess: {
    title: `${modalsKey}.CHANGE_PASSWORD_SUCCESS.TITLE`,
    message: `${modalsKey}.CHANGE_PASSWORD_SUCCESS.MESSAGE`,
  },
  getQuestionsError: {
    title: `${modalsKey}.GET_QUESTIONS_ERROR.TITLE`,
    message: `${modalsKey}.GET_QUESTIONS_ERROR.MESSAGE`,
  },
  recoverPasswordEmailSuccess: {
    title: `${modalsKey}.RECOVER_PASSWORD_EMAIL_SUCCESS.TITLE`,
    message: `${modalsKey}.RECOVER_PASSWORD_EMAIL_SUCCESS.MESSAGE`,
  },
  recoverPasswordSmsSuccess: {
    title: `${modalsKey}.RECOVER_PASSWORD_SMS_SUCCESS.TITLE`,
    message: `${modalsKey}.RECOVER_PASSWORD_SMS_SUCCESS.MESSAGE`,
  },
  updateContactInfoSuccess: {
    title: `${modalsKey}.UPDATE_CONTACT_INFO_SUCCESS.TITLE`,
    message: `${modalsKey}.UPDATE_CONTACT_INFO_SUCCESS.MESSAGE`,
  },
  pensionaryFormIncompleteData: {
    title: `${modalsKey}.PENSIONARY_FORM_INCOMPLETE_DATA.TITLE`,
    message: `${modalsKey}.PENSIONARY_FORM_INCOMPLETE_DATA.MESSAGE`,
  },
  recoverAccessKeyValidationError: {
    title: `${modalsKey}.RECOVER_ACCESS_KEY_VALIDATION_ERROR.TITLE`,
    message: `${modalsKey}.RECOVER_ACCESS_KEY_VALIDATION_ERROR.MESSAGE`,
  },
  updateClientData: {
    title: `${modalsKey}.UPDATE_CLIENT_DATA.TITLE`,
    message: `${modalsKey}.UPDATE_CLIENT_DATA.MESSAGE`
  },
  accountIncreaseBalance: {
    title: `${modalsKey}.ACCOUNT_INCREASE_BALANCE.TITLE`,
    message: `${modalsKey}.ACCOUNT_INCREASE_BALANCE.MESSAGE`,
    disclaimer: `${modalsKey}.ACCOUNT_INCREASE_BALANCE.DISCLAIMER`,
    disclaimerNative: `${modalsKey}.ACCOUNT_INCREASE_BALANCE.DISCLAIMER_NATIVE`
  },
  accountRedirectPrevired: {
    title: `${modalsKey}.ACCOUNT_REDIRECT_PREVIRED.TITLE`,
    message: `${modalsKey}.ACCOUNT_REDIRECT_PREVIRED.MESSAGE`,
  },
  definitionModal: {
    title: `${modalsKey}.DEFINITIONS.TITLE`
  },
  serialNumberModal: {
    title: `${modalsKey}.SERIAL_NUMBER.TITLE`,
    description: `${modalsKey}.SERIAL_NUMBER.DESCRIPTION`,
  },
  acceptApplication: {
    conditions: `${modalsKey}.ACCEPT_APPLICATION.CONDITIONS`,
    mobileDescription: `${modalsKey}.ACCEPT_APPLICATION.MOBILE_DESCRIPTION`,
    mobileInfo: `${modalsKey}.ACCEPT_APPLICATION.MOBILE_INFO`,
    mobileInfoBold: `${modalsKey}.ACCEPT_APPLICATION.MOBILE_INFO_BOLD`,
    mobileTitle: `${modalsKey}.ACCEPT_APPLICATION.TITLE`,
  },
  nationalityModal: {
    title: '',
    description: `${modalsKey}.NATIONALITY_MODAL.DESCRIPTION`,
    button: `${modalsKey}.NATIONAL_BUTTON`
  },
  otpChannel: (module: string) => ({
    title: `${modalsKey}.OTP.CHANNEL.${module}.TITLE`,
    subtitle: `${modalsKey}.OTP.CHANNEL.${module}.SUBTITLE`,
    message: `${modalsKey}.OTP.CHANNEL.${module}.MESSAGE`,
    mailTitle: `${modalsKey}.OTP.CHANNEL.${module}.MAIL_TITLE`,
    smsTitle: `${modalsKey}.OTP.CHANNEL.${module}.SMS_TITLE`,
  }),
  otpValidate: {
    title: `${modalsKey}.OTP.VALIDATE.TITLE`,
    subtitle: `${modalsKey}.OTP.VALIDATE.SUBTITLE`,
    message: `${modalsKey}.OTP.VALIDATE.MESSAGE`,
    messageValidate: `${modalsKey}.OTP.VALIDATE.MESSAGE_VALIDATE`,
    description: `${modalsKey}.OTP.VALIDATE.DESCRIPTION`,
  },
  otpError: {
    message: `${modalsKey}.OTP.ERROR.MESSAGE`,
  },
  securityQuestions: {
    title: `${modalsKey}.SECURITY_QUESTIONS.TITLE`,
    description: `${modalsKey}.SECURITY_QUESTIONS.DESCRIPTION`,
  },
  validationDataRepeated: {
    title: `${modalsKey}.REPEATED_DATA_VALIDATION.TITLE`,
    description: `${modalsKey}.REPEATED_DATA_VALIDATION.DESCRIPTION`,
  },
  voluntaryAccountsDisclaimer: {
    title: `${modalsKey}.VOLUNTARY_ACCOUNTS_DISCLAIMER.TITLE`,
    description: `${modalsKey}.VOLUNTARY_ACCOUNTS_DISCLAIMER.DESCRIPTION`,
  }

};

export const SUBJECT_PROCEDURE = 'Asistencia trámite';
export type CallType = 'phoneCall' | 'videoCall';

