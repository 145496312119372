import { Mock } from '@interfaces/mock.interface';

export const GET_REASONS_MOCKUP: Mock = {
  success: [{
    response: [
      { code: '1', reason: 'Quiero traspasarme a AFP PlanVital' },
      { code: '2', reason: 'Mis trabajadores se quieren cambiar a  AFP PlanVital (visita a empresa)' },
      { code: '4', reason: 'Pago de Pensiones y Otros Beneficios' },
      { code: '5', reason: 'Cambio/Distribución de Fondos' },
      { code: '6', reason: 'Certificados' },
      { code: '7', reason: 'Rezagos' },
      { code: '8', reason: 'Cobranza' },
      { code: '9', reason: 'Quejas o sugerencias' },
      { code: '10', reason: 'Otros' },
      { code: '11', reason: 'Solicito visita para abrir cuenta 2' },
    ],
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};

export const CONTACT_MOCKUP: Mock = {
  success: [{
    response: {
      folio: '123456',
    }
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};
