<mat-dialog-content *ngIf="isTipPreview && !isLoading && !isCancelByUser && !isTimeout && !isGlasses">
  <app-facial-capture-guidelines></app-facial-capture-guidelines>
  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphiProcess()" *ngIf="!bestImageCropped">
      {{ pageContent?.step2.buttons.start | translate }}
    </button>
    <button class="btn primary large" (click)="continue()" *ngIf="processSucceeded">
      {{ pageContent?.step2.buttons.continue | translate }}
    </button>
    <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isProcessSucceeded && !isLoading && !isCancelByUser">
  <div class="full-content succeded">
    <div>
      <img src="../../../../../assets/imgs/check.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">
      {{ pageContent?.step2.actions.succeded.title | translate }}
    </h1>
    <h2 class="title-secondary" [ngClass]="font?.size">
      {{ pageContent?.step2.actions.succeded.subtitle | translate }}
    </h2>
  </div>
  <div class="buttons-container">
    <button class="btn primary large" (click)="continue()" *ngIf="processSucceeded">
      {{ pageContent?.step2.buttons.continue | translate }}
    </button>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isCancelByUser && !isLoading">
  <div class="full-content cancel">
    <div>
      <img src="../../../../../assets/imgs/warning.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">
      {{ pageContent?.step2.actions.cancel.title | translate }}
    </h1>
    <h2 class="title-secondary" [ngClass]="font?.size">
      {{ pageContent?.step2.actions.cancel.subtitle | translate }}
    </h2>
  </div>
  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphiProcess()">
      {{ pageContent?.step2.buttons.retry | translate }}
    </button>
    <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isServiceError && !isLoading">
  
  <div class="full-content error">
    <div>
      <img src="../../../../../assets/imgs/warning.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step2.actions.error.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step2.actions.error.subtitle | translate }}</h2>
  </div>

  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphiProcess()">{{ pageContent?.step2.buttons.retry | translate }}</button>
    <button class="btn secondary large" (click)="finalize()">{{ pageContent?.step2.buttons.finalize | translate
      }}</button>
      <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isLoading">
  
  <div class="full-content loading">
    <div>
      <img src="../../../../../assets/imgs/spinner.gif">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step2.actions.loading.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step2.actions.loading.subtitle | translate }}
    </h2>
  </div>

</mat-dialog-content>

<mat-dialog-content *ngIf="isTimeout && !isLoading">
  
  <div class="full-content cancel">
    <div>
      <img src="../../../../../assets/imgs/warning.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step2.actions.timeout.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step2.actions.timeout.subtitle | translate }}
    </h2>
  </div>

  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphiProcess()">{{ pageContent?.step2.buttons.retry | translate }}</button>
    <button class="btn secondary large" (click)="finalize()">{{ pageContent?.step2.buttons.finalize | translate
      }}</button>
    <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="isGlasses && !isLoading">
  
  <div class="full-content glasses">
    <div>
      <img src="../../../../../assets/imgs/warning.png">
    </div>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step2.actions.glasses.title | translate }}</h1>
    <h2 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.step2.actions.glasses.subtitle | translate }}
    </h2>
  </div>

  <div class="buttons-container">
    <button class="btn primary large" (click)="onLaunchSelphiProcess()">{{ pageContent?.step2.buttons.retry | translate }}</button>
    <button class="btn secondary large" (click)="finalize()">{{ pageContent?.step2.buttons.finalize | translate
      }}</button>
    <app-btn-see-tutorial [pageContent]="pageContent"></app-btn-see-tutorial>
  </div>
</mat-dialog-content>