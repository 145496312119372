import { Injectable } from '@angular/core';
import { BENEFICIARY_USER_TYPE, CLIENT_USER_TYPE, EMPLOYER_USER_TYPE, PENSIONER_USER_TYPE } from '@constants/constants';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { DefaultResponse } from '@interfaces/default-response.interface';
import { Employer } from '@interfaces/employer-info.interface';
import { EmployerUserData } from '@interfaces/employer-user-data.interface';
import { GetEmployerResponse } from '@interfaces/get-employer-response.interface';
import { UserType } from '@interfaces/segment.interface';
import { UpdateEmployerRequest } from '@interfaces/update-employer.interface';
import {
  BeneficiaryUserData,
  Executive, Income, UpdateAddressInfoRequest, UpdateContactInfoRequest, UpdatePersonalInfoRequest,
  UserData, ValidateContactInfoRequest
} from '@interfaces/user-data.interface';
import { Withdrawal } from '@interfaces/withdrawal.interface';
import { EMPTY_MOCK } from '@mocks/default-empty.mock';
import { GET_USER_DATA_MOCK } from '@mocks/getContactData.mock';
import {
  USER_DATA_UPDATE_DATE_MOCK, USER_DATA_UPDATED_MOCK, USER_TYPE_DATA_MOCK
} from '@mocks/user-data.mock';
import { CommunicationProvider } from '@providers/communication/communication';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { GET_EXECUTIVE_MOCK } from '@services/authentication/mocks/get-executive.mock';
import { Util } from '@util';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { BANKS_MOCKUP } from './mocks/banks.mock';
import { GET_CLIENT_EMPLOYERS_MOCK } from './mocks/get-employer.mock';
import { EMPLOYER_MOCK } from './mocks/getEmployerInfo.mock';
import { GET_INCOME_MOCK } from './mocks/income.mock';
import { PAYMENT_METHODS_MOCKUP } from './mocks/payment-methods.mock';
import { GET_RELATIONSHIPS } from './mocks/relationships.mock';
import { UPDATE_EMPLOYER_MOCK } from './mocks/update-employer.mock';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {
  private user: UserData | EmployerUserData;
  private keysToFilter = ['email', 'censoredEmail', 'securityKeyStatus', 'typeId',
    'regions', 'legalRepresentative', 'user', 'investmentOptions', 'generaliAuthorized', 'birthdate'];

  public get userIsPensioner(): boolean { return this.user.type === PENSIONER_USER_TYPE; }
  private get rut(): string { return this.authService.getUserRut(); }
  private get beneficiaryRut(): string { return this.authService.getBeneficiaryRut(); }

  constructor(
    public http: HttpInterceptor,
    public util: Util,
    public authService: AuthenticationService,
    public communicationProvider: CommunicationProvider,
  ) { }

  private capitalizeUserData(userData: any): any {
    Object.keys(userData)
      .filter((key) => !this.keysToFilter.includes(key))
      .forEach((key) => userData[key] = this.util.titleCase(userData[key]));
    return userData;
  }

  private getUserDataObject(user: any, userType: UserType): UserData | EmployerUserData {
    if (userType === CLIENT_USER_TYPE || userType === BENEFICIARY_USER_TYPE) {
      return this.capitalizeUserData({
        name: user.name,
        clientId: user.clientId,
        lastName: user.lastName,
        motherLastName: user.motherLastName,
        gender: user.sex,
        address: user.address,
        email: user.email,
        type: user.typeClass,
        securityKeyStatus: user.keyStatus,
        cellphoneNumber: user.cellphoneNumber,
        phoneNumber: user.phoneNumber,
        assignedExecutive: user.assignedExecutive,
        regionCode: user.regionCode,
        communeCode: user.communeCode,
        regions: user.regions,
        investmentOptions: user.investmentOptions,
        generaliAuthorized: user.generaliAuthorized,
        maritalStatus: user.maritalStatus,
        birthdate: user.birthdate,
        nationality: user.nationality
      } as UserData);
    }

    return this.capitalizeUserData(user);
  }

  public getUserData(
    userType: UserType,
    useSavedUser = true,
    rut?: string): Observable<UserData | EmployerUserData | BeneficiaryUserData> {
    const isBeneficiary = userType === BENEFICIARY_USER_TYPE;
    const userRut = rut ? rut : isBeneficiary ? this.beneficiaryRut : this.rut;
    if (this.user && useSavedUser) { return of(this.user); }

    let url = `${environment.baseUrl}${userType}/?rut=${userRut}`;
    if (userType === EMPLOYER_USER_TYPE) url = `${environment.employersUrl}employers/${userRut}`;
    else if (userType === BENEFICIARY_USER_TYPE) url = `${environment.clientsUrl}beneficiary/${userRut}/beneficiary-data`;

    return this.http.get(url, USER_TYPE_DATA_MOCK[userType])
      .pipe(map(user => {
        if (rut) return this.getUserDataObject(user, userType);
        this.setUserData(this.getUserDataObject(user, userType));
        return this.user;
      }));
  }

  public getEmployerInfo(employerRut: string): Observable<Employer> {
    employerRut = this.util.rutClean(employerRut);
    return this.http.get(`${environment.baseUrl}client/employer_information/?rut=${this.rut}&employer_rut=${employerRut}`, EMPLOYER_MOCK)
      .pipe(map(employer => this.capitalizeUserData(employer)));
  }

  public getLastUpdateDate(): Observable<string> {
    return this.http.get(`${environment.clientsUrl}clients/${this.rut}/last-update-date`, USER_DATA_UPDATE_DATE_MOCK);
  }

  public setUserData(user: UserData | EmployerUserData): void {
    this.user = user;
    this.communicationProvider.setUserData(user);
  }

  public getUserDataLoaded(): Observable<UserData | EmployerUserData> {
    if (this.user) return of(this.user);
    return this.communicationProvider.getUserData();
  }

  public getUserDataUpdated(): Observable<Withdrawal> {
    return this.http.get(`${environment.clientsUrl}clients`, USER_DATA_UPDATED_MOCK);
  }

  public markUserDataAsUpdated(): Observable<any> {
    return this.http.patch(`${environment.clientsUrl}clients/${this.rut}/last-update-date`, {}, EMPTY_MOCK);
  }

  public updateUserContactInfo(rut: string, data: UpdateContactInfoRequest): Observable<any> {
    this.setUserData(null);
    return this.http.patch(`${environment.clientsUrl}clients/${rut}/contact`, data, EMPTY_MOCK);
  }

  public updateEmployer(request: UpdateEmployerRequest): Observable<boolean> {
    return this.http.patch(`${environment.employersUrl}employers/${this.rut}`, request, UPDATE_EMPLOYER_MOCK)
      .pipe(map((response) => {
        this.setUserData(null);
        return response;
      }));
  }

  public updatePersonalInfo(rut: string, data: UpdatePersonalInfoRequest | UpdateAddressInfoRequest): Observable<any> {
    return this.http.patch(`${environment.clientsUrl}clients/${rut}`, data, EMPTY_MOCK)
      .pipe(map((response) => {
        this.setUserData(null);
        return response;
      }));
  }

  public updateBeneficiaryPersonalInfo(rut: string, data: UpdatePersonalInfoRequest | UpdateAddressInfoRequest): Observable<any> {
    return this.http.patch(`${environment.clientsUrl}beneficiary/${rut}`, data, EMPTY_MOCK)
      .pipe(map((response) => {
        this.setUserData(null);
        return response;
      }));
  }

  public validateUserContactInfo(rut: string, data: ValidateContactInfoRequest): Observable<any> {
    return this.http.post(`${environment.clientsUrl}clients/${rut}/contact/validate`, data, EMPTY_MOCK);
  }

  public getBeneficiaryContactInfo(rut: string): Promise<any> {
    return this.http.get(`${environment.clientsUrl}beneficiary/${rut}/masked-contact-data`, EMPTY_MOCK).pipe(take(1)).toPromise();
  }

  public getUserContactInfo(rut: string): Promise<any> {
    return this.http.get(`${environment.clientsUrl}clients/${rut}/contact/data`, GET_USER_DATA_MOCK).pipe(take(1)).toPromise();
  }

  public getExecutiveClient(): Observable<Executive> {
    return this.http.get(`${environment.clientsUrl}clients/${this.rut}/executives`, GET_EXECUTIVE_MOCK);
  }

  public getClientIncome(): Observable<number> {
    return this.http.get(`${environment.clientsUrl}clients/${this.rut}/incomes`, GET_INCOME_MOCK)
      .pipe(map((response: Income) => response.income))
      .pipe(catchError(() => of(0)));
  }

  public getRelationships(): Observable<Array<DefaultResponse>> {
    return this.http.get(`${environment.publicProxyUrl}relationships`, GET_RELATIONSHIPS);
  }

  public getClientEmployers(): Observable<Array<GetEmployerResponse>> {
    return this.http.get(`${environment.clientsUrl}clients/${this.rut}/employers`, GET_CLIENT_EMPLOYERS_MOCK);
  }

  public getBanks(): Observable<Array<DefaultResponse>> {
    return this.http.get(`${environment.publicProxyUrl}banks`, BANKS_MOCKUP, null);
  }

  public getPaymentMethods(functionality?: 'pensionary-forms'): Observable<Array<DefaultResponse>> {
    const query = functionality ? { functionality } : {};
    return this.http.get(`${environment.publicProxyUrl}payment-methods`, PAYMENT_METHODS_MOCKUP, null, query);
  }
}
