import { ChartistModule } from 'ng-chartist';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEsCL from '@angular/common/locales/es-CL';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ComponentsModule } from '@components/components.module';
import { ModalComponent } from '@components/modal/modal.component';
import { ResumeModalComponent } from '@components/resume-modal/resume-modal.component';
import { environment } from '@env';
import { AuthInterceptorProvider } from '@interceptors/auth-interceptor/auth-interceptor';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import {
  NoEmailNotificationInterceptorProvider
} from '@interceptors/noemail-notification-interceptor/noemail-notification-interceptor';
import { RecaptchaInterceptor } from '@interceptors/recaptcha/recaptcha.interceptor';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CommunicationProvider } from '@providers/communication/communication';
import {
  EmailDomainValidator
} from '@providers/email-domain-validator/email-domain-validator.service';
import { LanguageProvider } from '@providers/language/language.service';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { CustomTranslateLoader } from '@providers/translate-loader/translate-loader';
import { AccountStatusProvider } from '@services/account-status/account-status.service';
import { FirebaseService } from '@services/firebase/firebase.abstract';
import { FirebaseServiceImplemented } from '@services/firebase/firebase.service';
import { LogService } from '@services/log/log.service';
import { UpdateAppService } from '@services/service-worker/service-worker';
import { Util } from '@util';
import { FilesUtil } from '@utils/files/files.utils';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { DatePipe } from '@angular/common';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { IpInterceptor } from '@interceptors/http-ip-interceptor/ip-interceptor';
import { InvestorProfileProvider } from '@providers/investor-profile/investor-profile-provider.service';
import { OtpSecurityProvider } from '@providers/otp-security/otp-security.provider';
import { TraceabilityBgProvider } from '@providers/traceability-bg/traceability-bg.provider';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PipesModule } from './shared/pipes/pipes.module';
import { CmsUtils } from './shared/utils/cms/cms.utils';

registerLocaleData(localeEsCL, 'es-CL');

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    ResumeModalComponent,
  ],
  imports: [
    ComponentsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      hardwareBackButton: false
    }),
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorker }),
    BrowserAnimationsModule,
    PipesModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatBottomSheetModule,
    ChartistModule,
    NgxMatTimepickerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      }
    }),
  ],
  providers: [
    DatePipe,
    UpdateAppService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorProvider, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RecaptchaInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoEmailNotificationInterceptorProvider, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IpInterceptor, multi: true },
    ModalProvider,
    HttpInterceptor,
    AuthInterceptorProvider,
    NoEmailNotificationInterceptorProvider,
    AccountStatusProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirebaseService, useClass: FirebaseServiceImplemented },
    { provide: MatDialogRef, useValue: {} },
    LogService,
    LoadingProvider,
    LanguageProvider,
    InvestorProfileProvider,
    CommunicationProvider,
    EmailDomainValidator,
    Util,
    CmsUtils,
    FilesUtil,
    FileOpener,
    FingerprintAIO,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    {
      provide: APP_INITIALIZER,
      useFactory: (firebaseService: FirebaseServiceImplemented) => async () => {
        firebaseService.initializeFirebase();
        await firebaseService.initializeRemoteConfig();
      },
      deps: [FirebaseService],
      multi: true
    },
    OtpSecurityProvider,
    TraceabilityBgProvider,
  ],
  bootstrap: [AppComponent],
  exports: [
    TranslateModule,
    PipesModule,
  ]
})
export class AppModule { }
