const detailPageKey = 'PARTIAL_FORMS_DETAIL';
const partialFormModalDataRequestKey = 'MODAL_DATA.PARTIAL_FORMS';
const requestContentPageKey = `${detailPageKey}.REQUEST_CONTENT`;
const informationPageKey = `${requestContentPageKey}.INFORMATION`;
const statusPageKey = `${requestContentPageKey}.HISTORY_STATUS`;
const attachmentPageKey = `${requestContentPageKey}.ATTACHMENT`;
const rulesPageKey = `${requestContentPageKey}.ATTACHMENT.RULES`;
const modalsPageKey = `${detailPageKey}.MODALS`;
const commentsPageKey = `${requestContentPageKey}.COMMENTS`;
const buttonsPageKey = `${requestContentPageKey}.BUTTONS`;

export const PARTIAL_FORMS_DETAIL_CONTENT = {
  header: {
    breadcrumb: `${detailPageKey}.HEADER.BREADCRUMB`,
    title: `${detailPageKey}.HEADER.TITLE`,
    description: `${detailPageKey}.HEADER.DESCRIPTION`,
  },
  requestContent: {
    information: {
      title: `${informationPageKey}.TITLE`,
      request: {
        type: `${informationPageKey}.REQUEST.TYPE`,
        folio: `${informationPageKey}.REQUEST.FOLIO`,
        date: `${informationPageKey}.REQUEST.DATE`,
        origin: `${informationPageKey}.REQUEST.ORIGIN`,
      }
    },
    historyStatus: {
      title: `${statusPageKey}.TITLE`,
      disclaimer: `${statusPageKey}.DISCLAIMER`,
    },
    attachment: {
      title: `${attachmentPageKey}.TITLE`,
      emptyBox: `${attachmentPageKey}.EMPTY_BOX`,
      rules: {
        name: `${rulesPageKey}.NAME`,
        format: `${rulesPageKey}.FORMAT`,
        size: `${rulesPageKey}.SIZE`
      },
      button: `${attachmentPageKey}.BUTTON`,
      info: `${attachmentPageKey}.INFO`
    },
    error: {
      description: `${attachmentPageKey}.ERROR.DESCRIPTION`,
    },
    buttons: {
      goTo: `${buttonsPageKey}.GOTO`,
      back: `${buttonsPageKey}.BACK`,
      accept: `${buttonsPageKey}.ACCEPT`,
    },
    button: `${requestContentPageKey}.BUTTON`,
    buttonBack: `${requestContentPageKey}.BACK`
  },
  comments: {
    title: `${commentsPageKey}.TITLE`,
    sort: `${commentsPageKey}.SORT`,
    insertQuestion: `${commentsPageKey}.QUESTION`,
    button: {
      save: `${commentsPageKey}.BUTTON.SAVE`,
      cancel: `${commentsPageKey}.BUTTON.CANCEL`,
    },
    empty_comments: `${commentsPageKey}.EMPTY_COMMENTS`,
    showMore: `${commentsPageKey}.SHOW_MORE`,
    showLess: `${commentsPageKey}.SHOW_LESS`,
    requestDocument: `${commentsPageKey}.REQUEST_DOCUMENT`,
  },
  modals: {
    confirmation_delete_file: {
      title: `${modalsPageKey}.DELETE_FILE.TITLE`,
      subtitle: `${modalsPageKey}.DELETE_FILE.SUBTITLE`,
      confirmButton: `${modalsPageKey}.DELETE_FILE.CONFIRM_BUTTON`,
      dismissButton: `${modalsPageKey}.DELETE_FILE.DISMISS_BUTTON`
    },
    generic_error_modal: {
      title: `${modalsPageKey}.ERROR_MODAL.TITLE`,
      description: `${modalsPageKey}.ERROR_MODAL.DESCRIPTION`,
      firstBtnText: `${modalsPageKey}.ERROR_MODAL.FIRST_BTN_TEXT`,
    },
    application_accepted: {
      title: `${modalsPageKey}.APPLICATION_ACCEPTED.TITLE`,
      firstBtnText: `${modalsPageKey}.APPLICATION_ACCEPTED.TITLE`,
      defaultTitle: `${modalsPageKey}.APPLICATION_ACCEPTED.DEFAULT_TITLE`,
      message: `${modalsPageKey}.APPLICATION_ACCEPTED.MESSAGE`,
      iconName: 'i-successfully-saved-application',
    },
  }
};

export const PARTIAL_FORMS_DEFAULT_MODAL_DATA = {
  error: {
    title: `${partialFormModalDataRequestKey}.ERRORS.TITLE`,
    message: `${partialFormModalDataRequestKey}.ERRORS.MESSAGE`,
  },
  buttons: {
    goTo: `${partialFormModalDataRequestKey}.BUTTONS.GO_TO`,
    ok: `${partialFormModalDataRequestKey}.BUTTONS.OK`,
  }
};
