import { Observable } from 'rxjs/internal/Observable';

import { Injectable } from '@angular/core';
import { ACCOUNT_TYPE_APV, APV_ACCOUNT_TYPE } from '@constants/constants';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { AttentionCodeResponse } from '@interfaces/attention-code-response';
import { FirebaseService } from '@services/firebase/firebase.abstract';

import { ATTENTION_CODE_MOCK } from './mocks/attention-code.mock';
import { LOG_MOCK } from './mocks/log.mock';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    public http: HttpInterceptor,
    public firebaseService: FirebaseService,
  ) { }

  public change(accountType: string) {
    return this.log('fund_change', 1, accountType);
  }

  public distribution(accountType: string) {
    return this.log('fund_distribution', 1, accountType);
  }

  public openingDiscount(type: OpeningDiscountTypes, accountType: VoluntaryAccountTypes) {
    return this.log(type, 1, accountType);
  }

  public cavContactForm() {
    return this.log('cav_contact_form', 1, null);
  }

  public withdrawFund(accountId: string) {
    const step = accountId.toUpperCase() === ACCOUNT_TYPE_APV ? 'apv_withdraw'
                : accountId.toUpperCase() === APV_ACCOUNT_TYPE ? 'apv_withdraw' : 'cav_withdraw';
    return this.log(step, 1, null);
  }

  public contact() {
    return this.log('contact', 1, null);
  }

  public securityKeyActivation() {
    return this.log('key_activation', 1, null);
  }

  public securityKeyRequest() {
    return this.log('key_request', 1, null);
  }

  public logout(type: LogoutTypes, step: 1 | -1, rut: string) {
    return this.logWithoutCurrentUser(type, step, null, rut);
  }

  public sendAttentionCodeWithdraw10(rut: string, step: number, attentionCode: number): Observable<AttentionCodeResponse> {
    return this.http.post(`${environment.clientsUrl}withdrawal/attention-code/`, { rut, step, attentionCode }, ATTENTION_CODE_MOCK);
  }

  private async logWithoutCurrentUser(type: LogTypes, step: -1 | 1 | 2, product: string, rut: string) {
    const data = { rut, type, step, product };
    return this.http.post(`${environment.baseUrl}log/`, data, LOG_MOCK).toPromise().catch(() => null);
  }

  private async log(type: LogTypes, step: -1 | 1 | 2, product: string) {
    const { uid: rut } = environment.mockHttpCalls ? { uid: '111111111' } : await this.firebaseService.getCurrentUser();
    const data = { rut, type, step, product };
    return this.http.post(`${environment.baseUrl}log/`, data, LOG_MOCK).toPromise().catch(() => null);
  }
}

export type LogTypes = ContactTypes | DistributionTypes | OpeningDiscountTypes | CavContactFormTypes |
  WithdrawTypes | SecurityKeyTypes | LogoutTypes;
export type DistributionTypes = 'fund_change' | 'fund_distribution';
export type OpeningDiscountTypes = 'apv_opening_discount' |
  'remove_apv_opening_discount' | 'update_apv_opening_discount' | 'cav_opening_discount' |
  'remove_cav_opening_discount' | 'update_cav_opening_discount';
export type CavContactFormTypes = 'cav_contact_form';
export type ContactTypes = 'contact';
export type WithdrawTypes = 'apv_withdraw' | 'cav_withdraw';
export type SecurityKeyTypes = 'key_activation' | 'key_request';
export type LogoutTypes = 'logout_client' | 'logout_employer';
export type VoluntaryAccountTypes = 'apv'| 'cav';
