import { Voucher } from '@interfaces/voucher.interface';

const pageKey = 'ACCOUNT_WITHDRAWAL';

export const ACCOUNT_WITHDRAWAL_CONTENT = {
  breadcrumb: `${pageKey}.BREADCRUMB`,
  accountName: {
    apv: `${pageKey}.ACCOUNT_NAME.APV`,
    cav: `${pageKey}.ACCOUNT_NAME.CAV`,
  },
  title: {
    apv: `${pageKey}.TITLE.APV`,
    cav: `${pageKey}.TITLE.CAV`,
  },
  description: {
    apv: `${pageKey}.DESCRIPTION.APV`,
    cav: `${pageKey}.DESCRIPTION.CAV`,
  },
  loadDataError: {
    message: `${pageKey}.LOAD_DATA_ERROR.MESSAGE`,
    button: `${pageKey}.LOAD_DATA_ERROR.BUTTON`
  },
  steps: {
    securityKey: {
      label: `${pageKey}.STEPS.SECURITY_KEY.LABEL`,
      invalidBalanceTotalAmount: `${pageKey}.STEPS.SECURITY_KEY.INVALID_BALANCE_TOTAL_AMOUNT`,
      invalidBalanceTotalAmountWithdraw10: `${pageKey}.STEPS.SECURITY_KEY.INVALID_BALANCE_TOTAL_AMOUNT_WITHDRAW_10`,
      invalidWithdrawalAmount: `${pageKey}.STEPS.SECURITY_KEY.INVALID_WITHDRAWAL_AMOUNT`,
      defaultDisclaimer: `${pageKey}.STEPS.SECURITY_KEY.DEFAULT_DISCLAIMER`,
      buttons: {
        main: {
          apv: `${pageKey}.STEPS.SECURITY_KEY.BUTTONS.MAIN.APV`,
          cav: `${pageKey}.STEPS.SECURITY_KEY.BUTTONS.MAIN.CAV`,
        },
      },
    },
    selection: {
      label: `${pageKey}.STEPS.SELECTION.LABEL`,
      totalBalance: {
        title: `${pageKey}.STEPS.SELECTION.TOTAL_BALANCE.TITLE`,
        multipleFunds: `${pageKey}.STEPS.SELECTION.TOTAL_BALANCE.MULTIPLE_FUNDS`,
        totalWithdraw: `${pageKey}.STEPS.SELECTION.TOTAL_BALANCE.TOTAL_WITHDRAW`
      },
      fundDetails: {
        title: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.TITLE`,
        fund: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.FUND`,
        regime: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.REGIME`,
        inputAmount: {
          label: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.INPUT_AMOUNT.LABEL`,
          errors: {
            minAmount: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.INPUT_AMOUNT.ERRORS.MIN_AMOUNT`,
            maxAmount: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.INPUT_AMOUNT.ERRORS.MAX_AMOUNT`,
          },
        },
        taxBonus: {
          label: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.TAX_BONUS.LABEL`,
          description: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.TAX_BONUS.DESCRIPTION`,
          ok: `${pageKey}.STEPS.SELECTION.FUND_DETAILS.TAX_BONUS.OK`,
        },
      },
      buttons: {
        simulate: `${pageKey}.STEPS.SELECTION.BUTTONS.SIMULATE`,
      },
    },
    simulation: {
      label: `${pageKey}.STEPS.SIMULATION.LABEL`,
      cards: {
        actual: `${pageKey}.STEPS.SIMULATION.CARDS.ACTUAL`,
        net: `${pageKey}.STEPS.SIMULATION.CARDS.NET`,
        gross: `${pageKey}.STEPS.SIMULATION.CARDS.GROSS`,
        tax: `${pageKey}.STEPS.SIMULATION.CARDS.TAX`,
        commission: `${pageKey}.STEPS.SIMULATION.CARDS.COMMISSION`,
        fund: `${pageKey}.STEPS.SIMULATION.CARDS.FUND`,
        regime: `${pageKey}.STEPS.SIMULATION.CARDS.REGIME`
      },
      buttons: {
        continue: {
          apv: `${pageKey}.STEPS.SIMULATION.BUTTONS.CONTINUE.APV`,
          cav: `${pageKey}.STEPS.SIMULATION.BUTTONS.CONTINUE.CAV`,
        },
        back: `${pageKey}.STEPS.SIMULATION.BUTTONS.BACK`,
      },
    },
    confirmation: {
      label: `${pageKey}.STEPS.CONFIRMATION.LABEL`,
      bank: `${pageKey}.STEPS.CONFIRMATION.FIELDS.BANK_LABEL`,
      accountNumber: `${pageKey}.STEPS.CONFIRMATION.FIELDS.ACCOUNT_NUMBER_LABEL`,
      accountType: `${pageKey}.STEPS.CONFIRMATION.FIELDS.PAYMENT_METHOD_LABEL`,
      button: `${pageKey}.STEPS.CONFIRMATION.BUTTONS.MAIN`,
      errors: {
        virtualAccountError: `${pageKey}.STEPS.PAYMENT_METHOD.PAYMENT.FORM.ERRORS.VIRTUAL_ACCOUNT`,
        invalidNumberError: `${pageKey}.STEPS.PAYMENT_METHOD.PAYMENT.FORM.ERRORS.ACCOUNT_NUMBER`
      },
      toggle: {
        transfer: `${pageKey}.STEPS.CONFIRMATION.TOGGLE.TRANSFER`,
        others: `${pageKey}.STEPS.CONFIRMATION.TOGGLE.OTHERS`
      },
      buttons: {
        back: `${pageKey}.STEPS.CONFIRMATION.BUTTONS.BACK`
      },
      others: `${pageKey}.STEPS.CONFIRMATION.OTHER`,
      bankDisclaimer: {
        rutAccount: `${pageKey}.STEPS.CONFIRMATION.BANK_DISCLAIMER.RUT_ACCOUNT`,
        payCheck: `${pageKey}.STEPS.CONFIRMATION.BANK_DISCLAIMER.PAY_CHECK`,
        digitalAccount: `${pageKey}.STEPS.CONFIRMATION.BANK_DISCLAIMER.DIGITAL_ACCOUNT`
      },
      disclaimer: `${pageKey}.STEPS.CONFIRMATION.DISCLAIMER`
    },
    success: {
      apv: {
        label: `${pageKey}.STEPS.SUCCESS.APV.LABEL`,
        voucher: {
          title: `${pageKey}.STEPS.SUCCESS.APV.VOUCHER.TITLE`,
          folio: `${pageKey}.STEPS.SUCCESS.APV.VOUCHER.FOLIO`,
          description: `${pageKey}.STEPS.SUCCESS.APV.VOUCHER.DESCRIPTION`,
          homeButton: `${pageKey}.STEPS.SUCCESS.APV.VOUCHER.HOME_BUTTON`,
          download: `${pageKey}.STEPS.SUCCESS.APV.VOUCHER.DOWNLOAD_PDF`,
          advice: `${pageKey}.STEPS.SUCCESS.APV.VOUCHER.ADVICE`
        } as Voucher
      },
      cav: {
        label: `${pageKey}.STEPS.SUCCESS.CAV.LABEL`,
        voucher: {
          title: `${pageKey}.STEPS.SUCCESS.CAV.VOUCHER.TITLE`,
          folio: `${pageKey}.STEPS.SUCCESS.CAV.VOUCHER.FOLIO`,
          description: `${pageKey}.STEPS.SUCCESS.CAV.VOUCHER.DESCRIPTION`,
          homeButton: `${pageKey}.STEPS.SUCCESS.CAV.VOUCHER.HOME_BUTTON`,
          download: `${pageKey}.STEPS.SUCCESS.APV.VOUCHER.DOWNLOAD_PDF`,
           advice: `${pageKey}.STEPS.SUCCESS.CAV.VOUCHER.ADVICE`
        } as Voucher
      }
    }
  }
};

export const PREFIX_VIRTUAL_ACCOUNT_BCI = '7770';
export const PREFIX_VIRTUAL_ACCOUNT_SANTANDER = '710';
export const PREFIX_VIRTUAL_ACCOUNT_RIPLEY = '404';

export const BCI_CODE = '16';
export const RIPLEY_CODE = '53';
export const SANTANDER_CODE = '37';

export const VIRTUAL_ACCOUNTS_BANKS = {
  [BCI_CODE]: PREFIX_VIRTUAL_ACCOUNT_BCI,
  [RIPLEY_CODE]: PREFIX_VIRTUAL_ACCOUNT_RIPLEY,
  [SANTANDER_CODE]: PREFIX_VIRTUAL_ACCOUNT_SANTANDER
};
