import { Injectable } from '@angular/core';
import { EXISTING_APV_REQUEST_AND_NO_APV, PENDING_CAV_REQUEST_AND_NO_CAV_KEY } from '@constants/constants';
import { Subject } from 'rxjs';

@Injectable()
export class AccountStatusProvider {
  public pendingCavRequestAndNoCav = new Subject<boolean>();
  public existingApvRequestAndNoApv = new Subject<boolean>();


  constructor() { }

  public setPendingCavRequestAndNoCav(pendingCavRequestAndNoCav: boolean): void {
    sessionStorage.setItem(PENDING_CAV_REQUEST_AND_NO_CAV_KEY, String(pendingCavRequestAndNoCav));
    this.pendingCavRequestAndNoCav.next(pendingCavRequestAndNoCav);
  }

  public setExistingApvRequestAndNoApv(existingApvRequestAndNoApv: boolean): void {
    sessionStorage.setItem(EXISTING_APV_REQUEST_AND_NO_APV, String(existingApvRequestAndNoApv));
    this.existingApvRequestAndNoApv.next(existingApvRequestAndNoApv);
  }

}
