import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '@components/modal/modal.component';
import { DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS } from '@constants/modal-data.constants';
import { HELP_BUTTON_CONTENT } from '@pages-content/help-button.constant';
import { ModalProvider } from '@providers/modal/modal';
import { GenesysService } from '@services/genesys/genesys.service';
import { Util } from '@util';

@Component({
  selector: 'app-modal-stop-screenshare',
  templateUrl: './modal-stop-screenshare.component.html',
  styleUrls: ['./modal-stop-screenshare.component.scss'],
})
export class ModalStopScreenshareComponent {
  public buttonLabel = HELP_BUTTON_CONTENT.modals.callCenter.cobrowsing.stopScreenshareButton;

  constructor(
    private genesysService: GenesysService,
    private modalProvider: ModalProvider,
    private util: Util,
    public dialogRef: MatDialogRef<ModalComponent>,
  ) {
    this.genesysService.isSessionActive.subscribe(response => {
      if (response === false) { this.closeModal(); }
    });
  }

  private handleError(): void {
    const params = this.util.setModalTexts(DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS);
    this.modalProvider.openModal(params);
  }

  public async stopSharingScreen(): Promise<void> {
    try {
      await this.genesysService.stopScreenshare();
    } catch {
      this.handleError();
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
