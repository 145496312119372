
  <mat-dialog-content>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step1.guidelines.description | translate }}</h1>

    <div>
      <div class="guideline-content">
        <div class="icon">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none">
              <circle cx="55" cy="55" r="55" fill="#C21B17" />
              <path
                d="M75 75.01H35C32.24 75.01 30 72.77 30 70.01V66.01C30 65.46 30.45 65.01 31 65.01H46.07C46.35 65.01 46.62 65.13 46.81 65.34C48.9 67.67 51.88 69.01 54.99 69.01C58.1 69.01 61.08 67.67 63.17 65.34C63.36 65.13 63.63 65.01 63.91 65.01H78.98C79.53 65.01 79.98 65.46 79.98 66.01V70.01C79.98 72.77 77.74 75.01 74.98 75.01H75ZM32 67.01V70.01C32 71.66 33.35 73.01 35 73.01H75C76.65 73.01 78 71.66 78 70.01V67.01H64.36C61.91 69.56 58.53 71.01 55 71.01C51.47 71.01 48.09 69.56 45.64 67.01H32Z"
                fill="white" />
              <path
                d="M78.9998 51.01H63.9298C63.6498 51.01 63.3798 50.89 63.1798 50.68C61.0898 48.35 58.1098 47.01 54.9998 47.01C51.8898 47.01 48.9098 48.35 46.8198 50.68C46.6298 50.89 46.3598 51.01 46.0798 51.01H31.0098C30.4598 51.01 30.0098 50.56 30.0098 50.01V46.01C30.0098 43.25 32.2498 41.01 35.0098 41.01H75.0098C77.7698 41.01 80.0098 43.25 80.0098 46.01V50.01C80.0098 50.56 79.5598 51.01 79.0098 51.01H78.9998ZM64.3598 49.01H77.9998V46.01C77.9998 44.36 76.6498 43.01 74.9998 43.01H34.9998C33.3498 43.01 31.9998 44.36 31.9998 46.01V49.01H45.6398C48.0898 46.46 51.4798 45.01 54.9998 45.01C58.5198 45.01 61.9098 46.46 64.3598 49.01Z"
                fill="white" />
              <path
                d="M78.9998 67.01H63.9298C63.5398 67.01 63.1798 66.78 63.0198 66.42C62.8598 66.06 62.9298 65.64 63.1898 65.34C65.0098 63.31 66.0098 60.71 66.0098 58.01C66.0098 55.31 65.0098 52.7 63.1898 50.68C62.9298 50.39 62.8598 49.97 63.0198 49.6C63.1798 49.23 63.5398 49.01 63.9298 49.01H78.9998C79.5498 49.01 79.9998 49.46 79.9998 50.01V66.01C79.9998 66.56 79.5498 67.01 78.9998 67.01ZM65.9398 65.01H77.9998V51.01H65.9398C67.2798 53.09 67.9998 55.51 67.9998 58.01C67.9998 60.51 67.2798 62.93 65.9398 65.01Z"
                fill="white" />
              <path
                d="M46.0798 67.01H31.0098C30.4598 67.01 30.0098 66.56 30.0098 66.01V50.01C30.0098 49.46 30.4598 49.01 31.0098 49.01H46.0798C46.4698 49.01 46.8298 49.24 46.9898 49.6C47.1498 49.96 47.0798 50.38 46.8198 50.68C44.9998 52.71 43.9998 55.31 43.9998 58.01C43.9998 60.71 44.9998 63.32 46.8198 65.34C47.0798 65.63 47.1498 66.06 46.9898 66.42C46.8298 66.78 46.4698 67.01 46.0798 67.01ZM32.0098 65.01H44.0698C42.7298 62.93 42.0098 60.51 42.0098 58.01C42.0098 55.51 42.7298 53.09 44.0698 51.01H32.0098V65.01Z"
                fill="white" />
              <path
                d="M55 66.01C50.59 66.01 47 62.42 47 58.01C47 53.6 50.59 50.01 55 50.01C59.41 50.01 63 53.6 63 58.01C63 62.42 59.41 66.01 55 66.01ZM55 52.01C51.69 52.01 49 54.7 49 58.01C49 61.32 51.69 64.01 55 64.01C58.31 64.01 61 61.32 61 58.01C61 54.7 58.31 52.01 55 52.01Z"
                fill="white" />
              <path
                d="M53.0002 59.06C52.4502 59.06 51.9502 58.56 51.9502 58.01C51.9502 56.33 53.3202 54.96 55.0002 54.96C55.5502 54.96 56.0502 55.46 56.0502 56.01C56.0502 56.56 55.5502 57.06 55.0002 57.06C54.4502 57.06 54.0502 57.49 54.0502 58.01C54.0502 58.56 53.5502 59.06 53.0002 59.06Z"
                fill="white" />
              <path
                d="M67.0003 43.01H43.0003C42.6303 43.01 42.2903 42.81 42.1203 42.48C41.9503 42.15 41.9604 41.76 42.1704 41.45L44.2404 38.34L45.5703 36.34C46.1303 35.5 47.0603 35 48.0703 35H61.9304C62.9304 35 63.8704 35.5 64.4304 36.34L67.8403 41.45C68.0403 41.76 68.0604 42.15 67.8904 42.48C67.7204 42.81 67.3804 43.01 67.0103 43.01H67.0003ZM44.8703 41.01H65.1303L62.7603 37.46C62.5703 37.18 62.2604 37.01 61.9304 37.01H48.0703C47.7403 37.01 47.4204 37.18 47.2404 37.46L44.8703 41.01Z"
                fill="white" />
            </svg>  
          </span>
        </div>
        <div>
          <h3 class="guideline-title">{{ pageContent?.step1.guidelines.first.title | translate}}</h3>
        </div>
        <div class="guideline-description">
          <p class="description">
            {{pageContent?.step1.guidelines.first.description | translate}}
          </p>
        </div>
      </div>
      <div class="guideline-content">
        <div class="icon">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none">
              <circle cx="55" cy="55" r="55" fill="#C21B17" />
              <path
                d="M66 77C61.041 77 57 72.959 57 68C57 63.041 61.041 59 66 59C70.959 59 75 63.041 75 68C75 72.959 70.959 77 66 77ZM66 60.8C62.031 60.8 58.8 64.031 58.8 68C58.8 71.969 62.031 75.2 66 75.2C69.969 75.2 73.2 71.969 73.2 68C73.2 64.031 69.969 60.8 66 60.8Z"
                fill="white" />
              <path
                d="M64.992 71.06C64.758 71.06 64.533 70.97 64.353 70.799L62.076 68.522C61.905 68.351 61.806 68.117 61.815 67.874C61.815 67.631 61.923 67.397 62.103 67.235C62.454 66.902 63.021 66.902 63.372 67.235L65.001 68.864L68.655 65.21C69.006 64.859 69.573 64.859 69.924 65.21C70.275 65.561 70.293 66.146 69.933 66.497L65.631 70.799C65.451 70.979 65.226 71.06 64.992 71.06Z"
                fill="white" />
              <path
                d="M51 58.8901H37C36.4477 58.8901 36 59.3379 36 59.8901V60.0001C36 60.5524 36.4477 61.0001 37 61.0001H51C51.5523 61.0001 52 60.5524 52 60.0001V59.8901C52 59.3379 51.5523 58.8901 51 58.8901Z"
                fill="white" />
              <path
                d="M74.625 42H55.375C54.6156 42 54 42.4244 54 42.9479V43.0521C54 43.5756 54.6156 44 55.375 44H74.625C75.3844 44 76 43.5756 76 43.0521V42.9479C76 42.4244 75.3844 42 74.625 42Z"
                fill="white" />
              <path
                d="M69.9375 46H55.0625C54.4757 46 54 46.4244 54 46.9479V47.0521C54 47.5756 54.4757 48 55.0625 48H69.9375C70.5243 48 71 47.5756 71 47.0521V46.9479C71 46.4244 70.5243 46 69.9375 46Z"
                fill="white" />
              <path
                d="M65.25 50H54.75C54.3358 50 54 50.4244 54 50.9479V51.0521C54 51.5756 54.3358 52 54.75 52H65.25C65.6642 52 66 51.5756 66 51.0521V50.9479C66 50.4244 65.6642 50 65.25 50Z"
                fill="white" />
              <path
                d="M75 69C74.45 69 74 68.55 74 68C74 63.59 70.41 60 66 60C61.59 60 58 63.59 58 68C58 68.55 57.55 69 57 69H35C32.24 69 30 66.76 30 64V38C30 35.24 32.24 33 35 33H75C77.76 33 80 35.24 80 38V64C80 66.76 77.76 69 75 69ZM35 35C33.35 35 32 36.35 32 38V64C32 65.65 33.35 67 35 67H56.05C56.55 61.95 60.82 58 66 58C71.18 58 75.36 61.88 75.93 66.85C77.13 66.46 78 65.33 78 64V38C78 36.35 76.65 35 75 35H35Z"
                fill="white" />
              <rect x="36" y="40" width="15" height="14" rx="2" fill="white" />
            </svg>
          </span>
        </div>
        <div>
          <h3 class="guideline-title">{{ pageContent?.step1.guidelines.second.title | translate}}</h3>
        </div>
        <div class="guideline-description">
          <p class="description">
            {{pageContent?.step1.guidelines.second.description | translate}}
          </p>
        </div>
      </div>
      <div class="guideline-content">
        <div class="icon">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none">
              <circle cx="55" cy="55" r="55" fill="#C21B17" />
              <path
                d="M64.6004 78H45.4004C44.8504 78 44.4004 77.55 44.4004 77C44.4004 76.45 44.8504 76 45.4004 76C48.3804 76 50.8004 72.86 50.8004 69C50.8004 68.45 51.2504 68 51.8004 68H58.2004C58.7504 68 59.2004 68.45 59.2004 69C59.2004 72.86 61.6204 76 64.6004 76C65.1504 76 65.6004 76.45 65.6004 77C65.6004 77.55 65.1504 78 64.6004 78ZM50.0504 76H59.9604C58.4804 74.55 57.4704 72.42 57.2504 70H52.7604C52.5404 72.42 51.5304 74.55 50.0504 76Z"
                fill="white" />
              <path
                d="M75 70H35C32.24 70 30 67.76 30 65V61C30 60.45 30.45 60 31 60H79C79.55 60 80 60.45 80 61V65C80 67.76 77.76 70 75 70ZM32 62V65C32 66.65 33.35 68 35 68H75C76.65 68 78 66.65 78 65V62H32Z"
                fill="white" />
              <path
                d="M79 62H31C30.45 62 30 61.55 30 61V37C30 34.24 32.24 32 35 32H75C77.76 32 80 34.24 80 37V61C80 61.55 79.55 62 79 62ZM32 60H78V37C78 35.35 76.65 34 75 34H35C33.35 34 32 35.35 32 37V60Z"
                fill="white" />
              <path
                d="M57.0002 66.0499H53.0002C52.4502 66.0499 51.9502 65.55 51.9502 65C51.9502 64.45 52.4502 63.95 53.0002 63.95H57.0002C57.5502 63.95 58.0502 64.45 58.0502 65C58.0502 65.55 57.5502 66.0499 57.0002 66.0499Z"
                fill="white" />
              <rect x="46.8184" y="41.4683" width="17.1818" height="12.0638" rx="2" fill="white" />
              <path
                d="M53.3986 50.5671H48.2806C48.0787 50.5671 47.915 50.7308 47.915 50.9327V50.9729C47.915 51.1748 48.0787 51.3385 48.2806 51.3385H53.3986C53.6005 51.3385 53.7642 51.1748 53.7642 50.9729V50.9327C53.7642 50.7308 53.6005 50.5671 53.3986 50.5671Z"
                fill="#C21B17" />
              <path
                d="M62.036 44.3926H54.9988C54.7211 44.3926 54.4961 44.5477 54.4961 44.7391V44.7772C54.4961 44.9686 54.7211 45.1237 54.9988 45.1237H62.036C62.3136 45.1237 62.5386 44.9686 62.5386 44.7772V44.7391C62.5386 44.5477 62.3136 44.3926 62.036 44.3926Z"
                fill="#C21B17" />
              <path
                d="M60.3224 45.8547H54.8845C54.67 45.8547 54.4961 46.0099 54.4961 46.2013V46.2394C54.4961 46.4307 54.67 46.5859 54.8845 46.5859H60.3224C60.5369 46.5859 60.7108 46.4307 60.7108 46.2394V46.2013C60.7108 46.0099 60.5369 45.8547 60.3224 45.8547Z"
                fill="#C21B17" />
              <path
                d="M58.6088 47.3169H54.7703C54.6188 47.3169 54.4961 47.472 54.4961 47.6634V47.7015C54.4961 47.8929 54.6188 48.048 54.7703 48.048H58.6088C58.7602 48.048 58.8829 47.8929 58.8829 47.7015V47.6634C58.8829 47.472 58.7602 47.3169 58.6088 47.3169Z"
                fill="#C21B17" />
              <rect x="47.915" y="43.6616" width="5.48356" height="5.11799" rx="0.5" fill="#C21B17" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M42.0833 57.6328V54.3828H41V58.7161H45.3333V57.6328H42.0833Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M45.7159 54.766L41.9242 58.5577L41.1582 57.7917L44.9499 54L45.7159 54.766Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M68.6325 37.0833L68.6325 40.3333L69.7158 40.3333L69.7158 35.9999L65.3825 35.9999L65.3825 37.0833L68.6325 37.0833Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M64.9999 39.95L68.7916 36.1584L69.5576 36.9244L65.766 40.7161L64.9999 39.95Z" fill="white" />
            </svg>
          </span>
        </div>
        <div>
          <h3 class="guideline-title">{{ pageContent?.step1.guidelines.third.title | translate}}</h3>
        </div>
        <div class="guideline-description">
          <p class="description">
            {{pageContent?.step1.guidelines.third.description | translate}}
          </p>
        </div>
      </div>
      <div class="guideline-content">
        <div class="icon">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none">
              <circle cx="55" cy="55" r="55" fill="#C21B17" />
              <path
                d="M78 68H32C31.45 68 31 67.55 31 67V39C31 36.24 33.24 34 36 34H74C76.76 34 79 36.24 79 39V67C79 67.55 78.55 68 78 68ZM33 66H77V39C77 37.35 75.65 36 74 36H36C34.35 36 33 37.35 33 39V66Z"
                fill="white" />
              <path
                d="M77 74H33C30.24 74 28 71.76 28 69V67C28 66.45 28.45 66 29 66H49C49.55 66 50 66.45 50 67C50 68.1 50.9 69 52 69H58C59.1 69 60 68.1 60 67C60 66.45 60.45 66 61 66H81C81.55 66 82 66.45 82 67V69C82 71.76 79.76 74 77 74ZM30 68V69C30 70.65 31.35 72 33 72H77C78.65 72 80 70.65 80 69V68H61.87C61.43 69.72 59.86 71 58 71H52C50.14 71 48.57 69.72 48.13 68H30Z"
                fill="white" />
              <rect x="43" y="42" width="24.2121" height="17" rx="4" fill="white" />
              <path
                d="M52.2722 54.8223H45.0601C44.7756 54.8223 44.5449 55.0529 44.5449 55.3374V55.3941C44.5449 55.6786 44.7756 55.9092 45.0601 55.9092H52.2722C52.5567 55.9092 52.7873 55.6786 52.7873 55.3941V55.3374C52.7873 55.0529 52.5567 54.8223 52.2722 54.8223Z"
                fill="#C21B17" />
              <path
                d="M64.4434 46.1213H54.5267C54.1355 46.1213 53.8184 46.34 53.8184 46.6096V46.6633C53.8184 46.933 54.1355 47.1516 54.5267 47.1516H64.4434C64.8346 47.1516 65.1517 46.933 65.1517 46.6633V46.6096C65.1517 46.34 64.8346 46.1213 64.4434 46.1213Z"
                fill="#C21B17" />
              <path
                d="M62.0286 48.1816H54.3657C54.0634 48.1816 53.8184 48.4003 53.8184 48.6699V48.7236C53.8184 48.9933 54.0634 49.2119 54.3657 49.2119H62.0286C62.3309 49.2119 62.5759 48.9933 62.5759 48.7236V48.6699C62.5759 48.4003 62.3309 48.1816 62.0286 48.1816Z"
                fill="#C21B17" />
              <path
                d="M59.6138 50.2424H54.2047C53.9913 50.2424 53.8184 50.4611 53.8184 50.7307V50.7844C53.8184 51.0541 53.9913 51.2727 54.2047 51.2727H59.6138C59.8272 51.2727 60.0002 51.0541 60.0002 50.7844V50.7307C60.0002 50.4611 59.8272 50.2424 59.6138 50.2424Z"
                fill="#C21B17" />
              <rect x="44.5449" y="45.0911" width="7.72727" height="7.21212" rx="2" fill="#C21B17" />
            </svg>    
          </span>
        </div>
        <div>
          <h3 class="guideline-title">{{ pageContent?.step1.guidelines.fourth.title | translate}}</h3>
        </div>
        <div class="guideline-description">
          <p class="description">
            {{pageContent?.step1.guidelines.fourth.description | translate}}
          </p>
        </div>
      </div>
    </div>
  
  </mat-dialog-content>