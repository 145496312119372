const pageKey = 'HOME.COMPLAINS';

export const COMPLAINS_CONTENT = {
  cardText: `${pageKey}.CARD_TEXT`,
  cardButton: `${pageKey}.CARD_BUTTON`,
  ticket: {
    name: `${pageKey}.TICKET.NAME`,
    normativeDate: `${pageKey}.TICKET.NORMATIVE_DATE`,
    status: `${pageKey}.TICKET.STATUS`
  }
};

export const COMPLAINS_STATUS = {
  terminado: `${pageKey}.STATUS.TERMINADO`,
  pendiente: `${pageKey}.STATUS.PENDIENTE`,
  atrasado: `${pageKey}.STATUS.ATRASADO`,
};
