import { Mock } from '@interfaces/mock.interface';

export const CALCULATE_BUSINESS_DAY_MOCK: Mock = {
  success: [{
    response: {
      calculatedBusinessday: '2020-03-04',
    }
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};
