
  <mat-dialog-content>
    <h1 class="title" [ngClass]="font?.size">{{ pageContent?.step2.guidelines.description | translate }}</h1>

    <div>
      <div class="guideline-content">
        <div class="icon">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none">
              <circle cx="55" cy="55" r="55" fill="#C21B17" />
              <circle cx="55" cy="55" r="27" stroke="white" stroke-width="2" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M41.3635 70.6233V66.8997H40.1223V71.8646H45.0872V70.6233H41.3635Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M45.5257 67.3389L41.1814 71.6832L40.3037 70.8055L44.648 66.4612L45.5257 67.3389Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M67.1992 39.0585L67.1992 42.7822L68.4404 42.7822L68.4404 37.8173L63.4755 37.8173L63.4755 39.0585L67.1992 39.0585Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M63.0368 42.3433L67.3811 37.999L68.2588 38.8767L63.9145 43.2209L63.0368 42.3433Z" fill="white" />
              <path
                d="M65.0581 66.4724H47.5993C47.2674 66.4724 46.9958 66.2008 46.9958 65.8689V64.511C46.9958 61.3307 49.2046 60.7091 50.9728 60.2082C52.6626 59.7315 53.6161 59.3875 53.6161 57.8486V56.7684C53.6161 56.5511 53.7307 56.352 53.9178 56.2433C54.1049 56.1347 54.3342 56.1347 54.5213 56.2433C55.7162 56.9434 56.9292 56.9434 58.1241 56.2433C58.3112 56.1347 58.5405 56.1347 58.7276 56.2433C58.9146 56.352 59.0293 56.5511 59.0293 56.7684V57.8486C59.0293 59.3814 59.9828 59.7315 61.6726 60.2082C63.4468 60.7091 65.6495 61.3367 65.6495 64.511V65.8689C65.6495 66.2008 65.3779 66.4724 65.046 66.4724H65.0581ZM48.2028 65.2654H64.4546V64.511C64.4546 62.3566 63.2778 61.91 61.3527 61.3669C59.7836 60.9203 57.8284 60.3712 57.8284 57.8486V57.7219C56.8266 58.084 55.8248 58.084 54.823 57.7219V57.8486C54.823 60.3712 52.8677 60.9264 51.2987 61.3669C49.3736 61.91 48.1968 62.3566 48.1968 64.511V65.2654H48.2028Z"
                fill="white" />
              <path
                d="M56.3259 64.7888C53.8336 64.7888 51.6127 63.2982 50.6653 60.9869C50.5989 60.83 50.6049 60.6489 50.6834 60.492C50.7558 60.3351 50.8946 60.2205 51.0636 60.1782C52.6507 59.7256 53.6223 59.3756 53.6223 57.8427V56.7625C53.6223 56.5453 53.737 56.3461 53.9241 56.2375C54.1112 56.1288 54.3405 56.1288 54.5276 56.2375C55.7225 56.9375 56.9355 56.9375 58.1303 56.2375C58.3174 56.1288 58.5468 56.1288 58.7338 56.2375C58.9209 56.3461 59.0356 56.5453 59.0356 56.7625V57.8427C59.0356 59.3756 60.0072 59.7256 61.5943 60.1782C61.7573 60.2265 61.8961 60.3412 61.9745 60.492C62.047 60.6489 62.053 60.8239 61.9926 60.9869C61.0452 63.2982 58.8244 64.7888 56.332 64.7888H56.3259ZM52.0774 61.1378C52.9464 62.6404 54.5517 63.5819 56.3259 63.5819C58.1002 63.5819 59.6994 62.6404 60.5745 61.1378C59.2589 60.7153 57.8286 59.9851 57.8286 57.8488V57.722C56.8268 58.0841 55.825 58.0841 54.8233 57.722V57.8488C54.8233 59.9851 53.399 60.7153 52.0774 61.1378Z"
                fill="white" />
              <path
                d="M49.7178 61.7774C48.9755 61.7774 48.2513 61.6023 47.6116 61.2765C47.2194 61.0713 46.99 60.673 47.0142 60.2324C47.0383 59.7919 47.3159 59.4177 47.7323 59.2608C48.6617 58.9108 49.2893 58.0116 49.2893 57.0159V50.5827C49.2893 46.7024 52.4455 43.5461 56.3259 43.5461C60.2063 43.5461 63.3625 46.7024 63.3625 50.5827V57.0159C63.3625 58.0116 63.9902 58.9168 64.9195 59.2608C65.3359 59.4177 65.6135 59.7859 65.6377 60.2324C65.6618 60.673 65.4385 61.0773 65.0402 61.2765C64.3402 61.6386 63.5436 61.8075 62.7289 61.7774C62.6625 61.7774 62.578 61.7593 62.5177 61.7411C62.1495 61.6023 61.7392 61.4877 61.3469 61.373C59.7778 60.9264 57.8226 60.3773 57.8226 57.8547V57.728C56.8208 58.0901 55.819 58.0901 54.8172 57.728V57.8547C54.8172 60.3773 52.8619 60.9325 51.2929 61.373C50.9006 61.4877 50.4902 61.6023 50.1221 61.7411C50.0557 61.7653 49.9833 61.7774 49.9109 61.7774C49.8445 61.7774 49.7781 61.7774 49.7118 61.7774H49.7178ZM48.372 60.2988C48.8126 60.4859 49.3074 60.5885 49.8023 60.5704C50.1885 60.4316 50.5868 60.3169 50.973 60.2083C52.6628 59.7315 53.6163 59.3815 53.6163 57.8487V56.7684C53.6163 56.5512 53.7309 56.352 53.918 56.2434C54.1051 56.1348 54.3344 56.1348 54.5215 56.2434C55.7164 56.9435 56.9294 56.9435 58.1243 56.2434C58.3114 56.1348 58.5407 56.1348 58.7278 56.2434C58.9149 56.352 59.0295 56.5512 59.0295 56.7684V57.8487C59.0295 59.3815 59.983 59.7315 61.6728 60.2083C62.059 60.3169 62.4573 60.4316 62.8435 60.5704C63.3625 60.5825 63.8453 60.4859 64.2738 60.2988C62.9884 59.7195 62.1435 58.428 62.1435 57.0098V50.5767C62.1435 47.3602 59.5304 44.7471 56.3139 44.7471C53.0973 44.7471 50.4842 47.3602 50.4842 50.5767V57.0098C50.4842 58.428 49.6393 59.7255 48.3539 60.2988H48.372Z"
                fill="white" />
              <path
                d="M56.3255 57.9873C53.6521 57.9873 50.6709 55.2052 50.6709 51.4878C50.6709 49.7618 52.077 48.3557 53.803 48.3557C53.8995 48.3557 53.9961 48.3798 54.0866 48.4281C54.1349 48.4523 58.7757 50.8843 61.3888 50.8843C61.7207 50.8843 61.9922 51.1559 61.9922 51.4878C61.9922 55.2052 59.005 57.9873 56.3376 57.9873H56.3255ZM53.6581 49.5687C52.6624 49.6411 51.8718 50.4739 51.8718 51.4878C51.8718 54.469 54.2616 56.7803 56.3195 56.7803C58.2446 56.7803 60.4594 54.7587 60.743 52.0551C58.0937 51.7835 54.4366 49.967 53.6581 49.5687Z"
                fill="white" />
            </svg>
          </span>
        </div>
        <div>
          <h3 class="guideline-title">{{ pageContent?.step2.guidelines.first.title | translate}}</h3>
        </div>
        <div class="guideline-description">
          <p class="description">
            {{pageContent?.step2.guidelines.first.description | translate}}
          </p>
        </div>
      </div>
      <div class="guideline-content">
        <div class="icon">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="44" viewBox="0 0 76 50" fill="none">
              <path d="M68.8892 50H49.1291C48.5791 50 48.1291 49.55 48.1291 49V43.25C48.1291 42.7 47.6791 42.25 47.1291 42.25H43.0091C40.2491 42.25 38.0092 40.01 38.0092 37.25V31.72L35.0591 30.74C34.1891 30.45 33.5091 29.79 33.1891 28.93C32.8691 28.07 32.9591 27.13 33.4291 26.35L38.0092 18.72C38.1592 8.37 46.6191 0 57.0091 0C67.3992 0 76.0092 8.52 76.0092 19C76.0092 24.29 73.7891 29.34 69.8991 32.94V49C69.8991 49.27 69.7891 49.52 69.6091 49.71C69.4291 49.9 69.1691 50 68.8991 50H68.8892ZM50.1291 48H67.8892V32.49C67.8892 32.2 68.0092 31.93 68.2292 31.74C71.8992 28.5 74.0092 23.86 74.0092 19C74.0092 9.63 66.3792 2 57.0091 2C47.6391 2 40.0092 9.63 40.0092 19C40.0092 19.18 39.9591 19.36 39.8691 19.51L35.1491 27.37C34.9891 27.63 34.9591 27.95 35.0691 28.23C35.1791 28.52 35.3991 28.74 35.6891 28.83L39.3191 30.04C39.7291 30.18 39.9991 30.56 39.9991 30.99V37.24C39.9991 38.89 41.3491 40.24 42.9991 40.24H47.1191C48.7691 40.24 50.1191 41.59 50.1191 43.24V47.99L50.1291 48Z" fill="white"></path>
              <rect y="12" width="3" height="14" fill="white"></rect>
              <rect x="3" y="15" width="2" height="8" fill="white"></rect>
              <path d="M29.8536 19.3536C30.0488 19.1583 30.0488 18.8417 29.8536 18.6464L26.6716 15.4645C26.4763 15.2692 26.1597 15.2692 25.9645 15.4645C25.7692 15.6597 25.7692 15.9763 25.9645 16.1716L28.7929 19L25.9645 21.8284C25.7692 22.0237 25.7692 22.3403 25.9645 22.5355C26.1597 22.7308 26.4763 22.7308 26.6716 22.5355L29.8536 19.3536ZM8 19.5H9.075V18.5H8V19.5ZM11.225 19.5H13.375V18.5H11.225V19.5ZM15.525 19.5H17.675V18.5H15.525V19.5ZM19.825 19.5H21.975V18.5H19.825V19.5ZM24.125 19.5H26.275V18.5H24.125V19.5ZM28.425 19.5H29.5V18.5H28.425V19.5ZM29.8536 19.3536C30.0488 19.1583 30.0488 18.8417 29.8536 18.6464L26.6716 15.4645C26.4763 15.2692 26.1597 15.2692 25.9645 15.4645C25.7692 15.6597 25.7692 15.9763 25.9645 16.1716L28.7929 19L25.9645 21.8284C25.7692 22.0237 25.7692 22.3403 25.9645 22.5355C26.1597 22.7308 26.4763 22.7308 26.6716 22.5355L29.8536 19.3536ZM8 19.5H9.075V18.5H8V19.5ZM11.225 19.5H13.375V18.5H11.225V19.5ZM15.525 19.5H17.675V18.5H15.525V19.5ZM19.825 19.5H21.975V18.5H19.825V19.5ZM24.125 19.5H26.275V18.5H24.125V19.5ZM28.425 19.5H29.5V18.5H28.425V19.5Z" fill="white"></path>
            </svg>
          </span>
        </div>
        <div>
          <h3 class="guideline-title">{{ pageContent?.step2.guidelines.second.title | translate}}</h3>
        </div>
        <div class="guideline-description">
          <p class="description">
            {{pageContent?.step2.guidelines.second.description | translate}}
          </p>
        </div>
      </div>
      <div class="guideline-content">
        <div class="icon">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="38" viewBox="0 0 36 38" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.03292 3.45455C4.38889 3.45455 3.05613 5.0012 3.05613 6.90909L3.05613 24.1818H13.4749H13.475H22.4052H22.4054H32.8239V6.90909C32.8239 5.0012 31.4912 3.45455 29.8471 3.45455C28.2031 3.45455 26.8703 5.0012 26.8703 6.90909V8.63636C26.8703 9.59031 26.204 10.3636 25.3819 10.3636C24.5599 10.3636 23.8935 9.59031 23.8935 8.63636V6.90909C23.8935 3.09331 26.5591 0 29.8471 0C33.1352 0 35.8007 3.09331 35.8007 6.90909V27.6363V29.3636V31.0909C35.8007 34.9067 33.1352 38 29.8471 38H25.3819C22.0939 38 19.4284 34.9067 19.4284 31.0909V27.6363H16.4517V31.0909C16.4517 34.9067 13.7862 38 10.4981 38H6.03292C2.74485 38 0.0793457 34.9067 0.0793457 31.0909V29.3636V27.6363V6.90909C0.0793457 3.09331 2.74485 0 6.03292 0C9.32099 0 11.9865 3.09331 11.9865 6.90909V8.63636C11.9865 9.59031 11.3201 10.3636 10.4981 10.3636C9.67609 10.3636 9.00971 9.59031 9.00971 8.63636V6.90909C9.00971 5.0012 7.67696 3.45455 6.03292 3.45455ZM32.8239 29.3636V27.6363H22.4054H22.4052V31.0909C22.4052 32.9988 23.7379 34.5454 25.3819 34.5454H29.8471C31.4912 34.5454 32.8239 32.9988 32.8239 31.0909V29.3636ZM3.05613 29.3636L3.05613 27.6363H13.4749V31.0909C13.4749 32.9988 12.1421 34.5454 10.4981 34.5454H6.03292C4.38889 34.5454 3.05613 32.9988 3.05613 31.0909L3.05613 29.3636Z"
                fill="white" />
            </svg>
          </span>
        </div>
        <div>
          <h3 class="guideline-title">{{ pageContent?.step2.guidelines.third.title | translate}}</h3>
        </div>
        <div class="guideline-description">
          <p class="description">
            {{pageContent?.step2.guidelines.third.description | translate}}
          </p>
        </div>
      </div>
    </div>
  
  </mat-dialog-content>