<div class="modal-close" *ngIf="closeBtn">
  <div class="i-cross-gray" (click)="dialogClose()"></div>
</div>
<div class="modal-container">
  <div class="i-lock-stars">
    <!-- <img src="../../../../assets/icons/notify-locker-icon.svg" width="40px" height="40px"> -->
  </div>
  <p *ngIf="cardData.title" class="text-primary" [innerHTML]="cardData.title | translate"></p>

  <div class="form-key">
    <div class="input-code-container">
      <div *ngFor="let code of securityCode; let i = index">
        <input class="input-code-security" type="text" (change)="getValue(i, $event)" (keyup)="onKeyUp(i, $event)"
          (keypress)="validateNumber($event)" #inputRefs maxlength="1">
      </div>
      <div *ngIf="isSixLettersLong()" class="bullet"></div>
    </div>
    <p *ngIf="cardData.opcionalMessage && messageValidation" class="text-validate-error"
      [innerHTML]="cardData.opcionalMessage | translate">
    </p>
  </div>

  <div class="descriptions-container">
    <p *ngIf="cardData.subtitle" class="description" [innerHTML]="cardData.subtitle | translate"></p>
    <p class="description"><strong>{{remainingTime}}</strong></p>
    <p *ngIf="cardData.description" class="description" [innerHTML]="cardData.description | translate"></p>
  </div>

  <button *ngIf="cardData.message" class="btn large btn-retry" [disabled]="!buttonEnabled" [ngClass]="font?.size"
    (click)="secondaryAction('retry')" [innerHTML]="cardData.message | translate"></button>

  <div class="buttons-container">
    <button class="btn large" [ngClass]="font?.size" (click)="secondaryAction()">{{
      secondBtnText | translate }}</button>
    <button class="btn large primary" [disabled]="concatenatedCode.length < 6" [ngClass]="font?.size"
      (click)="primaryAction()" [innerHTML]="firstBtnText | translate"></button>
  </div>

</div>
<app-loading [subcomponent]="true" *ngIf="isLoading"></app-loading>