/* eslint-disable @typescript-eslint/ban-types */
import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalBioTutorialComponent } from '@components/modal-bio-tutorial/modal-bio-tutorial.component';
import { ModalComponent } from '@components/modal/modal.component';
import { MODAL_CONFIG } from '@constants/modal-data.constants';

@Component({
  selector: 'app-btn-see-tutorial',
  templateUrl: './btn-see-tutorial.component.html',
  styleUrls: ['./btn-see-tutorial.component.scss']
})
export class BtnSeeTutorialComponent {
  @Input() pageContent: any;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    private dialog: MatDialog,
  ) {}

  openTutorial(): void {
    this.dialog.open(ModalBioTutorialComponent, {
      ...MODAL_CONFIG,
      panelClass: 'custom-background',
    });
  }


}
