import { ModalData, ModalResumenData } from '@interfaces/modal-data.interface';
import { CERTIFICATES_CONTENT } from '@pages-content/certificates.constant';
import { EMPLOYER_CERTIFICATES_CONTENT } from '@pages-content/employer-certificates.constant';
import { HELP_BUTTON_CONTENT } from '@pages-content/help-button.constant';
import { LOGIN_CONTENT } from '@pages-content/login.constant';
import { MODALS_CONTENT } from '@pages-content/modals.constant';
import { PARTIAL_FORMS_DEFAULT_MODAL_DATA } from '@pages-content/partial-form.constant';
import { PASSWORDLESS_ACCESS_CONTENT } from '@pages-content/passwordless-access.constant';
import { PENSION_SETTLEMENT_CONTENT } from '@pages-content/pension-settlement.constant';
import { PENSION_STATEMENT_CONTENT } from '@pages-content/pension-statement.constant';
import { TAX_REDUCTION_SIMULATION_CONTENT } from '@pages-content/tax-reduction-simulation.constant';

const confirmExitModal = 'REQUESTS.MODALS.CONFIRMATION';
const deleteRequestModal = 'REQUESTS.MODALS.DELETE_MODAL';
const deleteBeneficiaryModal = 'REQUESTS.MODALS.DELETE_BENEFICIARY';
const confirmDeleteRequestModal = 'REQUESTS.MODALS.SUCCESS_DELETE_MODAL';
const facialValidationProcessModal = 'REQUESTS.MODALS.FACIAL_VALIDATION_MODAL';
export const MODAL_CONFIG = { autoFocus: false, disableClose: true, maxHeight: '100vh', maxWidth: '100vw', panelClass: 'newModal' };

export const REMOVE_SECURITY_KEY_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.removeSecurityKey.title,
  message: MODALS_CONTENT.removeSecurityKey.message,
  firstBtnText: MODALS_CONTENT.buttons.removeSecurityKey,
  primaryCallback: () => { },
};

export const SUCCESS_TAX_SIMULATION_SENT: ModalData = {
  iconName: 'i-checkmark',
  title: TAX_REDUCTION_SIMULATION_CONTENT.sendEmail.modals.successSentEmail.title,
  message: TAX_REDUCTION_SIMULATION_CONTENT.sendEmail.modals.successSentEmail.message,
  firstBtnText: '',
  primaryCallback: () => { },
};

export const SUCCESS_CERTIFICATE_EMAIL_SENT: ModalData = {
  iconName: 'i-checkmark',
  title: CERTIFICATES_CONTENT.modals.successSentEmail.title,
  message: CERTIFICATES_CONTENT.modals.successSentEmail.message,
  firstBtnText: MODALS_CONTENT.buttons.home,
  primaryCallback: () => { },
};

export const FAILURE_CERTIFICATE_EMAIL_SENT: ModalData = {
  iconName: 'i-alert',
  title: CERTIFICATES_CONTENT.modals.failureSentEmail.title,
  message: CERTIFICATES_CONTENT.modals.failureSentEmail.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const FAILURE_SETTLEMENT_EMAIL_SENT: ModalData = {
  iconName: 'i-alert',
  title: PENSION_SETTLEMENT_CONTENT.modals.failureSentEmail.title,
  message: PENSION_SETTLEMENT_CONTENT.modals.failureSentEmail.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const SUCCESS_SETTLEMENT_EMAIL_SENT: ModalData = {
  iconName: 'i-checkmark',
  title: PENSION_SETTLEMENT_CONTENT.modals.successSentEmail.title,
  message: PENSION_SETTLEMENT_CONTENT.modals.successSentEmail.message,
  firstBtnText: MODALS_CONTENT.buttons.home,
  primaryCallback: () => { },
};

export const DEFAULT_UNAUTHORIZED_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.unauthorized.title,
  message: MODALS_CONTENT.unauthorized.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_UPDATING_HISTORIC_MOVEMENTS_MODAL_PROPS: ModalData = {
  iconName: 'i-modal-clock',
  title: MODALS_CONTENT.updatingHistoricMovements.title,
  message: MODALS_CONTENT.updatingHistoricMovements.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_IDLE_TIMEOUT_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.idleTimeout.title,
  message: MODALS_CONTENT.idleTimeout.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_IDLE_TIMEOUT_MODAL_APP_PROPS: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.idleTimeout.title,
  message: MODALS_CONTENT.idleTimeout.appMessage,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_ERROR_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.error.title,
  message: MODALS_CONTENT.error.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_ERROR_MODAL_FILE_TOO_LARGE: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.errorFileSize.title,
  message: MODALS_CONTENT.errorFileSize.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_ERROR_MODAL_EXPIRED_ID: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.errorExpiredId.title,
  message: MODALS_CONTENT.errorExpiredId.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_DNI_ERROR_EXCEPTION: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.errorDniException.title,
  message: MODALS_CONTENT.errorDniException.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_ERROR_MODAL_FOREIGNER_RUT: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.errorForeignerId.title,
  message: MODALS_CONTENT.errorForeignerId.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_ERROR_MODAL_EXTERNAL_TOKEN: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.errorExternalToken.title,
  message: MODALS_CONTENT.errorExternalToken.message,
  firstBtnText: MODALS_CONTENT.buttons.login,
  primaryCallback: () => { },
};

export const DEFAULT_MODAL_RESUME: ModalResumenData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.resume.title,
  subtitle: MODALS_CONTENT.resume.subtitle,
  message: MODALS_CONTENT.resume.message,
  firstBtnText: MODALS_CONTENT.buttons.confirmWithdrawal,
  primaryCallback: () => { },
  secondBtnText: MODALS_CONTENT.buttons.negationBack,
};

export const DEFAULT_MODAL_ACCOUNT_VALIDATION = {
  iconName: 'i-alert',
  firstBtnText: MODALS_CONTENT.buttons.retry,
  secondBtnText: MODALS_CONTENT.accountValidation.secondBtnText,
  descriptionText: MODALS_CONTENT.accountValidation.descriptionText,
};

export const DEFAULT_EMPTY_CERTIFICATE_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: CERTIFICATES_CONTENT.modals.emptyCertificate.title,
  message: CERTIFICATES_CONTENT.modals.emptyCertificate.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS: ModalData = {
  ...DEFAULT_ERROR_MODAL_PROPS,
  firstBtnText: MODALS_CONTENT.buttons.retry,
};

export const EMPLOYER_NOT_FOUND_ERROR_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.employerNotFoundError.title,
  message: MODALS_CONTENT.employerNotFoundError.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_CERTIFICATES_ERROR_MODAL_WITH_RETRY_PROPS: ModalData = {
  ...DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS,
  secondBtnText: MODALS_CONTENT.buttons.home,
  closeBtn: false,
};

export const DEFAULT_PENSION_STATEMENT_ERROR_MODAL_WITH_RETRY_PROPS: ModalData = {
  ...DEFAULT_ERROR_MODAL_WITH_RETRY_PROPS,
  secondBtnText: MODALS_CONTENT.buttons.home,
  closeBtn: false,
};

export const CERTIFICATES_MODAL_PROPS: ModalData = {
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const DEFAULT_MANDATORY_ACCOUNT_REMUNERATIONS_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: CERTIFICATES_CONTENT.modals.noMandatoryAccount.title,
  message: CERTIFICATES_CONTENT.modals.noMandatoryAccount.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const EMAIL_DATA_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: CERTIFICATES_CONTENT.modals.emailData.title,
  message: CERTIFICATES_CONTENT.modals.emailData.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const EMAIL_DATA_MODAL_MOBILE_PROPS: ModalData = {
  iconName: 'i-alert',
  title: CERTIFICATES_CONTENT.modals.emailData.title,
  message: CERTIFICATES_CONTENT.modals.emailData.appMessage,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  personalData: CERTIFICATES_CONTENT.modals.emailData.appTargetMessage,
  primaryCallback: () => { },
};

export const CREATE_CAV_ERROR_MODAL: ModalData = {
  iconName: 'i-alert',
  title: CERTIFICATES_CONTENT.modals.duplicatedCavCreation.title,
  message: CERTIFICATES_CONTENT.modals.duplicatedCavCreation.message,
  firstBtnText: MODALS_CONTENT.buttons.home,
  primaryCallback: () => { },
};

export const CHANGE_PASSWORD_SUCCESS_MODAL: ModalData = {
  iconName: 'i-checkmark',
  title: MODALS_CONTENT.changePasswordSuccess.title,
  message: MODALS_CONTENT.changePasswordSuccess.message,
  firstBtnText: MODALS_CONTENT.buttons.continue,
  closeBtn: false,
  primaryCallback: () => { }
};

export const GET_QUESTIONS_ERROR_MODAL: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.getQuestionsError.title,
  message: MODALS_CONTENT.getQuestionsError.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { }
};

export const RECOVER_PASSWORD_EMAIL_SUCCESS_MODAL: ModalData = {
  iconName: 'i-checkmark',
  title: MODALS_CONTENT.recoverPasswordEmailSuccess.title,
  message: MODALS_CONTENT.recoverPasswordEmailSuccess.message,
  firstBtnText: MODALS_CONTENT.buttons.login,
  primaryCallback: () => { }
};

export const RECOVER_PASSWORD_SMS_SUCCESS_MODAL: ModalData = {
  iconName: 'i-checkmark',
  title: MODALS_CONTENT.recoverPasswordSmsSuccess.title,
  message: MODALS_CONTENT.recoverPasswordSmsSuccess.message,
  firstBtnText: MODALS_CONTENT.buttons.reapply,
  primaryCallback: () => { }
};

export const UPDATE_CONTACT_INFO_SUCCESS_MODAL: ModalData = {
  iconName: 'i-checkmark',
  title: MODALS_CONTENT.updateContactInfoSuccess.title,
  message: MODALS_CONTENT.updateContactInfoSuccess.message,
  firstBtnText: MODALS_CONTENT.buttons.reapply,
  primaryCallback: () => { }
};

export const DEFAULT_EMPTY_GENERAL_CERTIFICATE_MODAL_PROPS: ModalData = {
  ...DEFAULT_EMPTY_CERTIFICATE_MODAL_PROPS,
  message: CERTIFICATES_CONTENT.modals.emptyGeneralCertificate.message,
};

export const REQUEST_EMPLOYER_KEY_SUCCESS_MODAL: ModalData = {
  iconName: 'i-success-red',
  title: LOGIN_CONTENT.request.modals.requestEmployerKeySuccess.title,
  subtitle: LOGIN_CONTENT.request.modals.requestEmployerKeySuccess.subtitle,
  closeBtn: false,
  firstBtnText: LOGIN_CONTENT.request.modals.requestEmployerKeySuccess.button,
  primaryCallback: () => { }
};

export const SUCCESS_PENSION_STATEMENT_EMAIL_SENT: ModalData = {
  iconName: 'i-checkmark',
  title: PENSION_STATEMENT_CONTENT.modals.successSentEmail.title,
  message: PENSION_STATEMENT_CONTENT.modals.successSentEmail.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const NOT_FOUND_PENSION_STATEMENT_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: PENSION_STATEMENT_CONTENT.modals.notFoundElement.title,
  message: PENSION_STATEMENT_CONTENT.modals.notFoundElement.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const PENSION_SETTLEMENT_NO_DATA: ModalData = {
  iconName: 'i-alert',
  title: PENSION_SETTLEMENT_CONTENT.modals.notFound.title,
  message: PENSION_SETTLEMENT_CONTENT.modals.notFound.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const CERTIFICATE_SUCCESS_MODAL: ModalData = {
  iconName: 'i-checkmark',
  title: EMPLOYER_CERTIFICATES_CONTENT.form.successModal.title,
  message: EMPLOYER_CERTIFICATES_CONTENT.form.successModal.message,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { }
};

export const MODAL_CONTACT_DATA: ModalData = {
  iconName: 'i-send-outline',
  title: HELP_BUTTON_CONTENT.modals.contact.success.title,
  message: '',
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const PENSIONARY_FORM_INCOMPLETE_DATA: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.pensionaryFormIncompleteData.title,
  message: MODALS_CONTENT.pensionaryFormIncompleteData.message,
  firstBtnText: MODALS_CONTENT.buttons.updateData,
  primaryCallback: () => { }
};

export const PENSIONARY_FORM_INCOMPLETE_DATA_NATIONALITY: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.nationalityModal.title,
  message: MODALS_CONTENT.nationalityModal.description,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const RECOVER_DATA_VALIDATION_ERROR_MODAL: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.recoverAccessKeyValidationError.title,
  message: MODALS_CONTENT.recoverAccessKeyValidationError.message,
  firstBtnText: MODALS_CONTENT.buttons.updateData,
  primaryCallback: () => { }
};

export const RECOVER_EMAIL_PASSWORDLESS_ERROR_MODAL: ModalData = {
  iconName: 'i-alert',
  title: PASSWORDLESS_ACCESS_CONTENT.nullEmailModal.title,
  message: PASSWORDLESS_ACCESS_CONTENT.nullEmailModal.message,
  firstBtnText: PASSWORDLESS_ACCESS_CONTENT.nullEmailModal.button,
  primaryCallback: () => { }
};

export const BANNER_ALERT_DATA: ModalData = {
  iconName: 'icon i-bell',
  firstBtnText: MODALS_CONTENT.buttons.back,
  images: true,
  primaryCallback: () => { }
};

export const DEFAULT_PARTIAL_FORM_PROPS: ModalData = {
  iconName: 'i-alert',
  title: PARTIAL_FORMS_DEFAULT_MODAL_DATA.error.title,
  message: PARTIAL_FORMS_DEFAULT_MODAL_DATA.error.message,
  firstBtnText: PARTIAL_FORMS_DEFAULT_MODAL_DATA.buttons.goTo,
  secondBtnText: PARTIAL_FORMS_DEFAULT_MODAL_DATA.buttons.ok,
  closeBtn: false,
  primaryCallback: () => { },
  secondaryCallback: () => { },
};

export const MODAL_CONFIRM_EXIT = {
  title: `${confirmExitModal}.TITLE`,
  description: `${confirmExitModal}.DESCRIPTION`,
  disclaimer: `${confirmExitModal}.DISCLAIMER`,
  saveBtn: `${confirmExitModal}.SAVE`,
  deleteBtn: `${confirmExitModal}.DELETE`
};

export const MODAL_DELETE_REQUEST = {
  title: `${deleteRequestModal}.TITLE`,
  description: `${deleteRequestModal}.DESCRIPTION`,
  disclaimer: `${deleteRequestModal}.DISCLAIMER`,
  backBtn: `${deleteRequestModal}.BACK`,
  confirmBtn: `${deleteRequestModal}.CONFIRM`,
  cancelBtn: `${deleteRequestModal}.CANCEL`,
  deleteBtn: `${deleteRequestModal}.DELETE_CONFIRM`,
};
export const MODAL_DELETE_SUCCESS = {
  title: `${confirmDeleteRequestModal}.TITLE`,
  description: `${confirmDeleteRequestModal}.DESCRIPTION`,
  disclaimer: `${deleteRequestModal}.DISCLAIMER`,
  backBtn: `${confirmDeleteRequestModal}.BACK`,
  confirmBtn: `${confirmDeleteRequestModal}.CONFIRM`,
};

export const MODAL_DELETE_BENEFICIARY_REQUEST: ModalData = {
  iconName: 'i-alert',
  title: `${deleteBeneficiaryModal}.TITLE`,
  message: `${deleteBeneficiaryModal}.DESCRIPTION`,
  firstBtnText: `${deleteBeneficiaryModal}.CONFIRM`,
  secondBtnText: `${deleteBeneficiaryModal}.CANCEL`,
  primaryCallback: () => { }
};

export const MODAL_DELETE_BENEFICIARY_SUCCESS: ModalData = {
  iconName: 'i-checkmark',
  title: `${deleteBeneficiaryModal}.TITLE`,
  message: `${deleteBeneficiaryModal}.SUCCESS_MSG`,
  firstBtnText: `${deleteBeneficiaryModal}.CONFIRM`,
  primaryCallback: () => { }
};

export const MODAL_DELETE_BENEFICIARY_FAIL: ModalData = {
  iconName: 'i-alert',
  title: `${deleteBeneficiaryModal}.TITLE`,
  message: `${deleteBeneficiaryModal}.FAIL_MSG`,
  firstBtnText: `${deleteBeneficiaryModal}.CONFIRM`,
  primaryCallback: () => { }
};

export const MODAL_FACIAL_VALIDATE_OK: ModalData = {
  iconName: 'i-checkmark',
  title: `${facialValidationProcessModal}.TITLE`,
  message: `${facialValidationProcessModal}.VALIDATE_OK.MESSAGE`,
  firstBtnText: `${facialValidationProcessModal}.CONFIRM`,
  primaryCallback: () => { }
};

export const MODAL_FACIAL_VALIDATE_FAIL: ModalData = {
  iconName: 'i-alert',
  title: `${facialValidationProcessModal}.TITLE`,
  message: `${facialValidationProcessModal}.VALIDATE_FAIL.MESSAGE`,
  firstBtnText: `${facialValidationProcessModal}.CONFIRM`,
  primaryCallback: () => { }
};

export const MODAL_FACIAL_VALIDATE_CANCEL: ModalData = {
  iconName: 'i-alert',
  title: `${facialValidationProcessModal}.TITLE`,
  message: `${facialValidationProcessModal}.VALIDATE_CANCEL.MESSAGE`,
  firstBtnText: `${facialValidationProcessModal}.CONFIRM`,
  primaryCallback: () => { }
};

export const MODAL_FACIAL_PROCESS_BLOCKED: ModalData = {
  iconName: 'i-alert',
  title: `${facialValidationProcessModal}.TITLE`,
  message: `${facialValidationProcessModal}.VALIDATE_BLOCKED.MESSAGE`,
  firstBtnText: `${facialValidationProcessModal}.CONFIRM`,
  primaryCallback: () => { }
};
export const MODAL_OTP_CHANNEL = (module: string) => ({
  iconName: 'i-alert',
  title: MODALS_CONTENT.otpChannel(module).title,
  subtitle: MODALS_CONTENT.otpChannel(module).subtitle,
  message: MODALS_CONTENT.otpChannel(module).message,
  mailTitle: MODALS_CONTENT.otpChannel(module).mailTitle,
  smsTitle: MODALS_CONTENT.otpChannel(module).smsTitle,
  firstBtnText: MODALS_CONTENT.buttons.sendKeyDynamic,
  secondBtnText: MODALS_CONTENT.buttons.cancel,
  primaryCallback: () => { }
});

export const MODAL_OTP_VALIDATE = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.otpValidate.title,
  subtitle: MODALS_CONTENT.otpValidate.subtitle,
  message: MODALS_CONTENT.otpValidate.message,
  messageValidate: MODALS_CONTENT.otpValidate.messageValidate,
  description: MODALS_CONTENT.otpValidate.description,
  firstBtnText: MODALS_CONTENT.buttons.validateConfirm,
  secondBtnText: MODALS_CONTENT.buttons.cancel,
  primaryCallback: () => { }
};

export const SECURITY_QUESTION_ERROR_MODAL_PROPS: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.securityQuestions.title,
  message: MODALS_CONTENT.securityQuestions.description,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
};

export const VALIDATION_DATA_REPEATED: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.validationDataRepeated.title,
  message: MODALS_CONTENT.validationDataRepeated.description,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
  closeCallback: () => { },
};

export const VOLUNTARY_ACCOUNTS_DISCLAIMER: ModalData = {
  iconName: 'i-alert',
  title: MODALS_CONTENT.voluntaryAccountsDisclaimer.title,
  message: MODALS_CONTENT.voluntaryAccountsDisclaimer.description,
  firstBtnText: MODALS_CONTENT.buttons.ok,
  primaryCallback: () => { },
  closeCallback: () => { },
};

