/* eslint-disable @typescript-eslint/ban-types */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ACCOUNT_TYPE_CLASS, CAV_NAME_PATH } from '@constants/constants';
import { ModalResumenData } from '@interfaces/modal-data.interface';
import { MODALS_CONTENT } from '@pages-content/modals.constant';
import { PAYMENT_FORM } from '@pages-content/payments-account.constant';
import { FontService } from '@providers/font/font.service';
import { Util } from '@util';

@Component({
  selector: 'app-resume-modal',
  templateUrl: './resume-modal.component.html',
  styleUrls: ['./resume-modal.component.scss'],
})
export class ResumeModalComponent implements OnInit {
  public title: string;
  public subtitle: string;
  public message: string;
  public description = '';
  public advice: string;
  public firstBtnText: string;
  public secondBtnText: string;
  public showSecondaryButton = false;
  public iconName: string;
  public images: boolean;
  public dataResume: any;
  public paymentMethods: any;
  public loadedData: boolean;
  public bankName: string;
  public methodName: string;
  public content = MODALS_CONTENT.resume;
  private primaryCallback: Function;
  private secondaryCallback: Function;
  private closeCallback: Function;
  public withdrawalType: string;

  constructor(
    public font: FontService,
    public util: Util,
    public dialogRef: MatDialogRef<ResumeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalResumenData
  ) {
    this.loadDataResume(data);
  }

  loadDataResume(data: ModalResumenData) {
    Object.keys(data).forEach(key => this[key] = data[key]);
    if (data.secondaryCallback) {
      this.showSecondaryButton = true;
    }
    if (data.images) {
      this.images = data.images;
    }
    this.bankName = (data.data.bankName === 'ESTADO' ? 'Banco Estado' : data.data.bankName);
    this.methodName = data.data.methodName;
    this.dataResume = data.data;
    this.advice = this.content.advice;

    switch (data.data.withdrawalAccount) {
      case ACCOUNT_TYPE_CLASS.CCV:
        this.withdrawalType = this.content.withdrawalApv;
        break;
      case CAV_NAME_PATH:
        this.withdrawalType = this.content.withdrawalCav2;
        break;
      default:
        this.withdrawalType = this.content.withdrawalCav10;
        break;
    }

    this.messagePayment(data);
  }

  private messagePayment(modalResumenData: ModalResumenData) {
    switch (modalResumenData.data.paymentType) {
      case PAYMENT_FORM.inmediate:
        this.description = this.content.description60Min;
        break;
      case PAYMENT_FORM.a24hrs:
        this.description = this.content.description24Hrs;
        break;
      case PAYMENT_FORM.deferred:
        this.description = this.content.descriptionDeferred;
        break;
      default:
        this.description = '';
        break;
    }
  }

  public ngOnInit(): void {
    this.loadedData = true;
  }

  public primaryAction(): void {
    this.dialogClose();
    this.primaryCallback();
  }

  public secondaryAction(): void {
    this.dialogClose();
    this.secondaryCallback();
  }

  public dialogClose(): void {
    this.dialogRef.close();
    if (this.closeCallback) { this.closeCallback(); }
  }
}
