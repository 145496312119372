import { Certificate, Certificates } from '@interfaces/certificates.interface';
import { CERTIFICATES_CONTENT } from '@pages-content/certificates.constant';

export const TRANSACTIONAL_CERTIFICATES_TYPE = {
  remunerations: 'remunerations',
  quotations: 'quotations',
  unquoted: 'unquoted',
  hardworks: 'hardworks',
  movements: 'movements'
};

export const APV_REGIME_CERTIFICATE: Certificate = {
  name: CERTIFICATES_CONTENT.types.tax.apv.name,
  type: 'apv',
  description: CERTIFICATES_CONTENT.types.tax.apv.description,
};

export const GENERAL_REGIME_CERTIFICATE: Certificate = {
  name: CERTIFICATES_CONTENT.types.tax.rg.name,
  type: 'rg',
  description: CERTIFICATES_CONTENT.types.tax.rg.description,
};

export const TAX_CERTIFICATES: Array<Certificate> = [
  APV_REGIME_CERTIFICATE,
  {
    name: CERTIFICATES_CONTENT.types.tax.pen.name,
    type: 'pen',
    description: CERTIFICATES_CONTENT.types.tax.pen.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.tax.exc.name,
    type: 'exc',
    description: CERTIFICATES_CONTENT.types.tax.exc.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.tax.ind.name,
    type: 'ind',
    description: CERTIFICATES_CONTENT.types.tax.ind.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.tax.cdc.name,
    type: 'cdc',
    description: CERTIFICATES_CONTENT.types.tax.cdc.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.tax.ahoregop.name,
    type: 'ahoregop',
    description: CERTIFICATES_CONTENT.types.tax.ahoregop.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.tax.aho57bis.name,
    type: 'aho57bis',
    description: CERTIFICATES_CONTENT.types.tax.aho57bis.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.tax.aho54bis.name,
    type: 'aho54bis',
    description: CERTIFICATES_CONTENT.types.tax.aho54bis.description,
  },
  GENERAL_REGIME_CERTIFICATE,
];

export const QUOTATION_CERTIFICATE: Certificate = {
  name: CERTIFICATES_CONTENT.types.transactional.quotations.name,
  type: 'quotations',
  description: CERTIFICATES_CONTENT.types.transactional.quotations.description,
};

export const TRANSACTIONAL_CERTIFICATES: Array<Certificate> = [
  QUOTATION_CERTIFICATE,
  {
    name: CERTIFICATES_CONTENT.types.transactional.remunerations.name,
    type: 'remunerations',
    description: CERTIFICATES_CONTENT.types.transactional.remunerations.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.transactional.unquoted.name,
    type: 'unquoted',
    description: CERTIFICATES_CONTENT.types.transactional.unquoted.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.transactional.hardworks.name,
    type: 'hardworks',
    description: CERTIFICATES_CONTENT.types.transactional.hardworks.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.transactional.movements.name,
    type: 'movements',
    description: CERTIFICATES_CONTENT.types.transactional.movements.description,
  },
];

export const GENERAL_CERTIFICATES: Array<Certificate> = [
  {
    name: CERTIFICATES_CONTENT.types.general.affiliate.name,
    type: 'affiliate',
    description: CERTIFICATES_CONTENT.types.general.affiliate.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.general.contingencyRecords.name,
    type: 'contingency_records',
    description: CERTIFICATES_CONTENT.types.general.contingencyRecords.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.general.residenceRecords.name,
    type: 'residence_records',
    description: CERTIFICATES_CONTENT.types.general.residenceRecords.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.general.vacations.name,
    type: 'vacations',
    description: CERTIFICATES_CONTENT.types.general.vacations.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.general.notpensioned.name,
    type: 'not-pensioned',
    description: CERTIFICATES_CONTENT.types.general.notpensioned.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.general.pensionedquality.name,
    type: 'pensionedquality',
    description: CERTIFICATES_CONTENT.types.general.pensionedquality.description,
  },
  {
    name: CERTIFICATES_CONTENT.types.general.beneficiaryquality.name,
    type: 'beneficiaryquality',
    description: CERTIFICATES_CONTENT.types.general.beneficiaryquality.description,
  },
];

export const CES_CERTIFICATE_CARD: Certificate =
{
  name: CERTIFICATES_CONTENT.types.general.pensionCES.name,
  type: 'pensionCES',
  description: CERTIFICATES_CONTENT.types.general.pensionCES.description,
};

export const ALL_CERTIFICATES: Array<Certificates> = [
  {
    name: CERTIFICATES_CONTENT.categories.general.name,
    description: CERTIFICATES_CONTENT.categories.general.description,
    icon: 'i-general-certificate',
    certificatesList: GENERAL_CERTIFICATES,
  },
  {
    name: CERTIFICATES_CONTENT.categories.transactional.name,
    description: CERTIFICATES_CONTENT.categories.transactional.description,
    icon: 'i-transactional-certificate',
    certificatesList: TRANSACTIONAL_CERTIFICATES,
  },
  {
    name: CERTIFICATES_CONTENT.categories.tax.name,
    description: CERTIFICATES_CONTENT.categories.tax.description,
    icon: 'i-tax-certificate',
    certificatesList: TAX_CERTIFICATES,
  },
];

export const BENEFICIARY_CERTIFICATES: Array<Certificates> = [
  {
    name: CERTIFICATES_CONTENT.categories.beneficiary.name,
    description: CERTIFICATES_CONTENT.categories.beneficiary.description,
    icon: 'i-general-certificate',
    certificatesList: [
      {
        name: CERTIFICATES_CONTENT.types.general.contingencyRecords.name,
        type: 'contingency_records',
        description: CERTIFICATES_CONTENT.types.general.contingencyRecords.description,
      },
      // Commented until pension liquidation certificate service works
      // {
      //   name: CERTIFICATES_CONTENT.types.tax.penLiq.name,
      //   type: 'pen',
      //   description: CERTIFICATES_CONTENT.types.tax.penLiq.description,
      // },
    ],
  },
  {
    name: CERTIFICATES_CONTENT.categories.beneficiaries.name,
    description: CERTIFICATES_CONTENT.categories.beneficiaries.description,
    icon: 'i-general-certificate',
    certificatesList: [
      {
        name: CERTIFICATES_CONTENT.types.general.beneficiaryquality.name,
        type: 'beneficiaryquality',
        description: CERTIFICATES_CONTENT.types.general.beneficiaryquality.description,
      },
    ],
  },
];
