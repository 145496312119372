import { Mock } from '@interfaces/mock.interface';

export const GET_CLIENT_EMPLOYERS_MOCK: Mock = {
  success: [
    {
      response: [
        {
          rut: '9999999-9',
          businessName: 'Business B',
          address: 'business address 2',
          commune: 'commune 2',
          city: 'city 2',
          phone: '222222222',
          relationshipStartDate: '2010-01-01',
          relationshipEndDate: '2020-01-01',
          status: 'stat2',
          legalRepresentativeName: 'Juan',
          communeId: 'c2',
          quotations: [
            { period: '2015-01-01', income: 1 },
            { period: '2016-01-01', income: 2 },
          ]
        },
        {
          rut: '1-9',
          businessName: 'Business A',
          address: 'business address 1',
          commune: 'commune 1',
          city: 'city 1',
          phone: '111111111',
          relationshipStartDate: '2015-01-01',
          relationshipEndDate: '2015-01-01',
          status: 'stat1',
          legalRepresentativeName: 'Ana',
          communeId: 'c1',
          quotations: []
        },
      ]
    },
  ],
  failures: [{
    response: {
      code: 400,
      message: '400 BAD REQUEST',
      messageDescription: '400 BAD REQUEST',
    },
  }]
};
