import { Voucher } from '@interfaces/voucher.interface';

const pageKey = 'PENSION_SETTLEMENT';

export const PENSION_SETTLEMENT_CONTENT = {
  header: {
    breadcrumb: `${pageKey}.HEADER.BREADCRUMB`,
    title: `${pageKey}.HEADER.TITLE`,
    description: `${pageKey}.HEADER.DESCRIPTION`,
    beneficiaryDescription: `${pageKey}.HEADER.BENEFICIARY_DESCRIPTION`
  },
  form: {
    title: `${pageKey}.FORM.TITLE`,
    customDateLabel: `${pageKey}.FORM.CUSTOM_DATE_LABEL`,
    presetDateLabel: `${pageKey}.FORM.PRESET_DATE_LABEL`,
    presetOptionsLabel: `${pageKey}.FORM.PRESET_OPTIONS_LABEL`,
    button: `${pageKey}.FORM.BUTTON`
  },
  modals: {
    notFound: {
      title: `${pageKey}.MODALS.NOT_FOUND.TITLE`,
      message: `${pageKey}.MODALS.NOT_FOUND.MESSAGE`
    },
    successSentEmail: {
      title: `${pageKey}.MODALS.SUCCESSFUL_SENT_EMAIL.TITLE`,
      message: `${pageKey}.MODALS.SUCCESSFUL_SENT_EMAIL.MESSAGE`
    },
    failureSentEmail: {
      title: `${pageKey}.MODALS.FAILURE_SENT_EMAIL.TITLE`,
      message: `${pageKey}.MODALS.FAILURE_SENT_EMAIL.MESSAGE`
    }
  },
  voucher: {
    title: `${pageKey}.VOUCHER.TITLE`,
    subtitle: `${pageKey}.VOUCHER.SUBTITLE`,
    description: `${pageKey}.VOUCHER.DESCRIPTION`,
    homeButton: `${pageKey}.VOUCHER.HOME_BUTTON`,
    download: `${pageKey}.VOUCHER.DOWNLOAD_PDF`
  } as Voucher
};
