import { Mock } from '@interfaces/mock.interface';

export const GET_USER_DATA_MOCK: Mock = {
  success: [{
    response: {
      userEmail: 'tes****@t***.cl',
      userPhone: '****56789'
    },
  }],
  failures: [{
    response: {
      code: 40001,
      message: 'Al parecer no eres parte de la familia Planvital.',
      messageDescription: '¿Quieres integrarte a AFP PlanVital? ¡Estás a un click!',
    },
  }],
};
