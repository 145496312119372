import { Injectable } from '@angular/core';
import { ParametersService } from '@services/parameters/parameters.service';

@Injectable()
export class EmailDomainValidator {
  constructor(private parametersService: ParametersService) {
  }

  public async validateEmailDomain(email: string): Promise<boolean> {
    return this.parametersService.validateEmailDomain(email)
      .toPromise()
      .then((response) => response)
      .catch(() => true);
  }
}
