<div>
  <h1 class="title">{{title | translate}}</h1>
  <div class="stepper-header" [class.extra-margin]="progress">
    <ng-container *ngFor="let label of steps; let index = index">
      <div class="steps-union-line" [hidden]="isFirst(index)" [style.marginLeft.%]="unionLinesMargin"
        [style.marginRight.%]="unionLinesMargin" [class.done]="isBeforeCurrent(index) || isCurrent(index)"
        [class.pending]="isNextToCurrent(index)"></div>
      <div class="step" [class.clickable]="canGoToStep(index)"
        [ngClass]="{ 'final': isLast(index), 'current': isCurrent(index), 'done': isBeforeCurrent(index) }"
        (click)="onStepClicked(index)">
        <div class="icon-container">
          <div class="icon"></div>
        </div>
        <div class="description label" [ngClass]="font?.size">{{ label | translate }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="progress">
      <div class="steps-union-line" [style.marginLeft.%]="unionLinesMargin" [style.marginRight.%]="unionLinesMargin"
        [class.pending]="isNextToCurrent(steps.length)">
      </div>
      <div class="step">
        <div class="icon-container">
          <div class="progress-chart-container">
            <x-chartist class="progress-chart" [configuration]="progressChartConfiguration"></x-chartist>
          </div>
        </div>
        <div class="description label progress" [ngClass]="font?.size">{{ pageContent?.titles.progress | translate }}
        </div>
      </div>
    </ng-container>
  </div>
</div>