
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalFacialValidationComponent } from '@components/modal-facial-validation/modal-facial-validation.component';
import { CAPTURING_SELPHI, FACIAL_VALIDATION_TRACE_STATES } from '@constants/traceability.constants';
import { FacialValidation } from '@interfaces/facialvalidation.interface';
import { Trolley } from '@interfaces/trolley.interface';
import { CANCELED_PROCESS, MODAL_FACIAL_VALIDATION_CONTENT } from '@pages-content/modal-facial-validation.constant';
import { FontService } from '@providers/font/font.service';
import { SelphiService } from '@services/selphi/selphi.service';
import { TraceabilityBioService } from '@services/traceability-bio/traceability-bio.service';
import { SelphiFaceFinishStatus, SelphiFaceResult } from 'capacitor-selphi-plugin';

const URI_JPEG_HEADER = 'data:image/jpeg;base64,';

@Component({
  selector: 'app-facial-capture',
  templateUrl: './facial-capture.component.html',
  styleUrls: ['./facial-capture.component.scss'],
})
export class FacialCaptureComponent {
  @Output() facialCaptureProcess: EventEmitter<any> = new EventEmitter();
  @Input() trolley: Trolley;

  public pageContent = MODAL_FACIAL_VALIDATION_CONTENT;
  public facialValidationImage: FacialValidation = {
    imageTemplateRaw: '',
    bestCroppedImage: ''
  };
  message = '';
  bestImageCropped: string = null;
  showErrorClassStyle = false;
  processSucceeded = false;
  serviceError = false;
  cancelByUser = false;
  captureStarted = false;
  glassesDetected = false;
  timeOut = false;
  loading = false;

  get isCancelByUser(): boolean { return this.cancelByUser; }
  get isServiceError(): boolean { return this.serviceError && !this.processSucceeded; }
  get isTipPreview(): boolean { return (!this.captureStarted && !this.processSucceeded) && !this.serviceError; }
  get isCaptureProcess(): boolean { return (this.captureStarted && !this.processSucceeded) && !this.serviceError; }
  get isProcessSucceeded(): boolean { return (!this.captureStarted && this.processSucceeded) && !this.serviceError; }
  get isLoading(): boolean { return this.loading; }
  get isTimeout(): boolean { return this.timeOut; }
  get isGlasses(): boolean { return this.glassesDetected; }

  constructor(
    public selphiFaceService: SelphiService,
    public font: FontService,
    public dialogRef: MatDialogRef<ModalFacialValidationComponent>,
    private traceabilityBioService: TraceabilityBioService,
  ) { }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public continue(): void {
    this.facialCaptureProcess.emit(this.facialValidationImage);
    this.captureStarted = false;
    this.processSucceeded = false;
    this.serviceError = false;
  }

  public retry(): void {
    this.captureStarted = false;
    this.processSucceeded = false;
    this.loading = false;
    this.timeOut = false;
    this.cancelByUser = false;
    this.serviceError = false;
    this.glassesDetected = false;
  }

  async onLaunchSelphiProcess() {
    this.captureStarted = true;
    this.loading = true;
    this.cancelByUser = false;
    this.serviceError = false;
    this.timeOut = false;
    this.processSucceeded = false;
    this.glassesDetected = false;
    await this.selphiFaceService.launchSelphiAuthentication()
      .then((result: SelphiFaceResult) => {
        this.onSuccessSelphiExtraction(result);
      }, (e: string) => {
        this.onErrorSelphiExtraction(e);
      });
  }

  onSuccessSelphiExtraction(result) {
    if (result !== null && result) {
      if (result.eyeGlassesScore > 0) {
        this.glassesDetected = true;
        this.loading = false;
        return;
      }
      this.serviceError = false;
      this.cancelByUser = false;
      this.captureStarted = false;
      this.timeOut = false;
      this.processSucceeded = false;
      switch (result.finishStatus) {
        case SelphiFaceFinishStatus.Ok: // 1 - OK
          this.bestImageCropped = URI_JPEG_HEADER + result.bestImageCropped;
          this.facialValidationImage.imageTemplateRaw = result.bestImageTemplateRaw;
          this.facialValidationImage.bestCroppedImage = result.bestImageCropped;
          this.showErrorClassStyle = false;
          this.message = 'Vista previa de la selfie';
          this.processSucceeded = true;
          this.loading = false;
          this.traceabilityBioService.traceProcess(CAPTURING_SELPHI, { status: FACIAL_VALIDATION_TRACE_STATES.accomplish });
          this.continue();
          break;
        case SelphiFaceFinishStatus.CancelByUser: // 3 - CancelByUser
          this.showErrorClassStyle = true;
          this.message = 'El usuario a cancelado el proceso';
          this.cancelByUser = true;
          this.loading = false;
          this.traceabilityBioService.traceProcess(CAPTURING_SELPHI, { status: FACIAL_VALIDATION_TRACE_STATES.user_canceled });
          break;
        case SelphiFaceFinishStatus.StatusTimeout: // 4 - Timeout
          this.showErrorClassStyle = true;
          this.message = 'Se ha finalizado por tiempo de Espera';
          this.timeOut = true;
          this.loading = false;
          this.traceabilityBioService.traceProcess(CAPTURING_SELPHI, { status: FACIAL_VALIDATION_TRACE_STATES.extraction_timeout });
          break;
        default: // Undefined
          this.showErrorClassStyle = true;
          this.message = result.errorMessage ?? 'Error indefinido';
          this.serviceError = true;
          this.loading = false;
          break;
      }
    }
  }

  onErrorSelphiExtraction(result) {
    this.showErrorClassStyle = true;
    this.message = 'Se ha producido un error. Lea el registro para más información.';
    console.error('SELPHI_ERROR:' + result);
  }

  public finalize(): void {
    this.dialogRef.close(CANCELED_PROCESS);
  }

}
