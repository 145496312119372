<div class="modal-container">
  <!-- <div class="i-cross-gray" (click)="closeModal()"></div> -->
  <app-stepper-biometric class="stepper" [title]="pageContent?.title" [isLinear]="false" [canGoBackwards]="true"
    [steps]="steps" [currentIndex]="stepperStep"
  ></app-stepper-biometric>

  <app-id-capture [hidden]="step !== 0" (idCaptureProcess)="idCaptureProcess($event)" [trolley]="data"></app-id-capture>

  <app-facial-capture [hidden]="step !== 1" (facialCaptureProcess)="facialCaptureProcess($event)" [trolley]="data"></app-facial-capture>

  <app-process-validation [hidden]="step !== 2" [status]="status"></app-process-validation>
</div>