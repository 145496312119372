import { Injectable } from '@angular/core';
import { ACTION_TRACEABILITY, TRACEABILITY } from '@constants/traceability.constants';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { Session } from '@interfaces/session.interface';
import { TraceabilityBioRequest } from '@interfaces/traceability-bio.interface';

import { Trolley } from '@interfaces/trolley.interface';
import * as publicIp from 'public-ip';



@Injectable({
    providedIn: 'root'
})
export class TraceabilityBioService {
    private baseUrl = environment.facialValidationBaseUrl;

    private processId: string;
    private session: Session;
    private ip: string;

    constructor(private http: HttpInterceptor) { }

    private start(request: TraceabilityBioRequest): void {
        const url = `${this.baseUrl}traceability/start`;
        this.http.post(url, request, null).subscribe(
            (response: any) => { this.processId = response.processId; },
            () => { this.processId = 'empty-transaction-id'; },
        );
    }

    public traceProcess(action: string, data: any): void {
        // console.log(data);
        const request: TraceabilityBioRequest = {
            rut: this.session.rut,
            moduleId: TRACEABILITY.MODULE.REQUESTS.ID,
            functionalityId: this.session.process === 'G' ? TRACEABILITY.MODULE.REQUESTS.GENERAL_FLOW.functionalityId
                : TRACEABILITY.MODULE.REQUESTS.TRANSFER_FLOW.functionalityId,
            stepId: ACTION_TRACEABILITY[action],
            processId: this.processId,
            result: data.status.result ? data.status.result : 'empty',
            descriptionResult: data.status.code ? data.status.code : 'empty',
            stage: 'Verificacion_identidad',
            channel: 'app',
            ip: this.ip,
        };

        this.save(request);
    }

    private save(request: TraceabilityBioRequest): void {
        const url = `${this.baseUrl}traceability/save`;
        this.http.post(url, request, null).subscribe(() => { });
    }


    public setSession(trolley: Trolley) {

        publicIp.v4().then((ip: string) => {
            this.ip = ip;
            this.createSession(trolley);

        }, () => {
            this.ip = '1.1.1.1';
            this.createSession(trolley);
        });
    }

    private createSession(trolley: Trolley) {
        this.session = { ...trolley, status: 'logged', ip: this.ip };
        const dataRequest: TraceabilityBioRequest = {
            sessionId: 'empty-session-id',
            rut: this.session.rut,
            moduleId: TRACEABILITY.MODULE.REQUESTS.ID,
            functionalityId: TRACEABILITY.MODULE.REQUESTS.GENERAL_FLOW.functionalityId,
            stepId: TRACEABILITY.MODULE.REQUESTS.STEP_ID.facial_site_ingress,
            stage: 'Verificacion_identidad',
            result: 'ok',
            descriptionResult: 'ok',
            channel: 'app',
            ip: this.ip
        };
        // console.log(this.session);
        // console.log(dataRequest);
        this.start(dataRequest);
    }
}
