import { PESOS_CURRENCY_TYPE, UF_CURRENCY_TYPE } from '@constants/constants';

const pageKey = 'TAX_REDUCTION_SIMULATION';

export const TAX_REDUCTION_SIMULATION_CONTENT = {
  header: {
    title: `${pageKey}.HEADER.TITLE`,
    description: `${pageKey}.HEADER.DESCRIPTION`,
  },
  form: {
    title: `${pageKey}.FORM.TITLE`,
    controls: {
      currency: {
        label: `${pageKey}.FORM.CONTROLS.CURRENCY.LABEL`,
      },
      income: {
        label: `${pageKey}.FORM.CONTROLS.INCOME.LABEL`,
        errors: {
          nonZero: `${pageKey}.FORM.CONTROLS.INCOME.ERRORS.NON_ZERO`,
        }
      },
      quotationAmountType: {
        label: `${pageKey}.FORM.CONTROLS.QUOTATION_AMOUNT_TYPE.LABEL`,
      },
      quotation: {
        label: `${pageKey}.FORM.CONTROLS.QUOTATION.LABEL`,
        errors: {
          nonZero: `${pageKey}.FORM.CONTROLS.QUOTATION.ERRORS.NON_ZERO`,
        }
      },
      afpSelect: {
        label: `${pageKey}.FORM.CONTROLS.AFP_SELECT.LABEL`,
      }

    },
    toDate: `${pageKey}.FORM.TO_DATE`,
    values: {
      uf: `${pageKey}.FORM.VALUES.UF`,
      utm: `${pageKey}.FORM.VALUES.UTM`,
    },
    button: `${pageKey}.FORM.BUTTON`,
  },
  simulationTable: {
    headers: {
      title: `${pageKey}.SIMULATION_CARD.HEADERS.TITLE`,
    },
    fields: {
      grossIncome: `${pageKey}.SIMULATION_TABLE.FIELDS.GROSS_INCOME`,
      taxableCeiling: `${pageKey}.SIMULATION_TABLE.FIELDS.TAXABLE_CEILING`,
      quotations: `${pageKey}.SIMULATION_TABLE.FIELDS.QUOTATIONS`,
      afp: `${pageKey}.SIMULATION_TABLE.FIELDS.AFP`,
      health: `${pageKey}.SIMULATION_TABLE.FIELDS.HEALTH`,
      voluntary: `${pageKey}.SIMULATION_TABLE.FIELDS.VOLUNTARY`,
      taxableIncome: `${pageKey}.SIMULATION_TABLE.FIELDS.TAXABLE_INCOME`,
      tax: `${pageKey}.SIMULATION_TABLE.FIELDS.TAX`,
      net: `${pageKey}.SIMULATION_TABLE.FIELDS.NET`,
      taxSaving: `${pageKey}.SIMULATION_TABLE.FIELDS.TAX_SAVING`,
    }
  },
  simulationResults: {
    title: `${pageKey}.SIMULATION_RESULTS.TITLE`,
    regimeA: `${pageKey}.SIMULATION_RESULTS.REGIME_A`,
    regimeB: `${pageKey}.SIMULATION_RESULTS.REGIME_B`,
    recommendationText1: `${pageKey}.SIMULATION_RESULTS.RECOMMENDATION_TEXT1`,
    recommendationText2: `${pageKey}.SIMULATION_RESULTS.RECOMMENDATION_TEXT2`,
    recommendationText3: `${pageKey}.SIMULATION_RESULTS.RECOMMENDATION_TEXT3`,
    textRegimeA: `${pageKey}.SIMULATION_RESULTS.TEXT_REGIME_A`,
    textRegimeB: `${pageKey}.SIMULATION_RESULTS.TEXT_REGIME_B`,
  },
  box: {
    red: {
      title: `${pageKey}.BOX.RED.TITLE`,
      btn: `${pageKey}.BOX.RED.BTN`,
    },
    white: {
      title: `${pageKey}.BOX.WHITE.TITLE`,
      btn: `${pageKey}.BOX.WHITE.BTN`,
    }
  },
  btns: {
    title: `${pageKey}.BTNS.TITLE`,
    send: `${pageKey}.BTNS.SEND`,
    back: `${pageKey}.BTNS.BACK`,
  },

  disclaimer: `${pageKey}.DISCLAIMER`,
  sendEmail: {
    button: `${pageKey}.SEND_EMAIL.BUTTON`,
    modals: {
      successSentEmail: {
        title: `${pageKey}.SEND_EMAIL.MODALS.SUCCESS_SENT_EMAIL.TITLE`,
        message: `${pageKey}.SEND_EMAIL.MODALS.SUCCESS_SENT_EMAIL.MESSAGE`,
      }
    },
  },
  loadDataError: {
    message: `${pageKey}.LOAD_DATA_ERROR.MESSAGE`,
    button: `${pageKey}.LOAD_DATA_ERROR.BUTTON`,
  },

};

export const REGIME_A = 'A';
export const REGIME_B = 'B';

export const UF = 'UF';
export const CLP = 'CLP';

export const currencyTypes = [
  {
    id: PESOS_CURRENCY_TYPE,
    name: CLP,
    value: PESOS_CURRENCY_TYPE
  },
  {
    id: UF_CURRENCY_TYPE,
    name: UF,
    value: UF_CURRENCY_TYPE
  },
];

export const afps = [
  {
    name: 'AFP PlanVital',
    value: 'PLV'
  },
];
