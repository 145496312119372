import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Util } from '@util';

@Injectable()
export class PdfViewerProvider {
  public statementPdfUrl: string = null;
  public secureStatementPdfUrl: SafeResourceUrl = null;
  public link: HTMLAnchorElement;

  constructor(
    private util: Util,
    private sanitizer: DomSanitizer,
  ) { }

  public loadPDF(base64: string): void {
    let url = base64;
    if (!this.util.isNative) {
      const blob = this.util.base64toBlob(base64);
      url = URL.createObjectURL(blob);
    }
    this.statementPdfUrl = url;
  }

  public createSecureUrl(statementPdfUrl: string): void {
    this.secureStatementPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(statementPdfUrl);
  }

  public setDownloadPdfParams(url: string, name: string = ''): void {
    const safeName = name.replace(/[/\\?%*:|"<>]/g, '-');
    const fileName = `pv_${safeName}_${+new Date()}.pdf`;
    this.link = document.createElement('a');
    this.link.href = url;
    this.link.download = fileName;
    document.body.appendChild(this.link);
  }

}
