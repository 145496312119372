import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { onValue, off } from 'firebase/database';

import { ModalComponent } from '@components/modal/modal.component';
import { LIVE_FACIAL_IMG, POSITIVE_DNI_MORPH } from '@constants/constants';
import { BIOMETRIC_SPA_FLOW_ENDED, FACIAL_VALIDATION_TRACE_STATES, FLOW_ENDING_TRACE_STATES, SEND_CAPTURED_SELPHI } from '@constants/traceability.constants';
import { ValidationStatus } from '@custom-types/validation-status.type';
import { BiometricalData, FacialBiometricValidationData, FacialValidation } from '@interfaces/facialvalidation.interface';
import { IdValidationImages } from '@interfaces/idvalidation.interface';
import { Trolley } from '@interfaces/trolley.interface';
import { FacialValidationResponse } from '@interfaces/validation.interface';
import { APPROVED_PROCESS, MODAL_FACIAL_VALIDATION_CONTENT, REJECTED_PROCESS } from '@pages-content/modal-facial-validation.constant';
import { FontService } from '@providers/font/font.service';
import { FacialBiometricService } from '@services/facial-biometric/facial-biometric.service';
import { FirebaseService } from '@services/firebase/firebase.abstract';
import { TraceabilityBioService } from '@services/traceability-bio/traceability-bio.service';
import { Util } from '@util';

@Component({
  selector: 'app-modal-facial-validation',
  templateUrl: './modal-facial-validation.component.html',
  styleUrls: ['./modal-facial-validation.component.scss'],
})
export class ModalFacialValidationComponent implements OnInit {
  public status: ValidationStatus;

  public pageContent = MODAL_FACIAL_VALIDATION_CONTENT;
  public steps: Array<string> = [];
  public step: number;
  public stepperStep: number;

  private idImages: IdValidationImages = {
    frontImage: '',
    backImage: '',
    faceImage: '',
    scanReference: ''
  };

  private facialImage: FacialValidation = {
    imageTemplateRaw: '',
    bestCroppedImage: ''
  };

  constructor(
    public font: FontService,
    public dialogRef: MatDialogRef<ModalComponent>,
    private util: Util,
    private biometricService: FacialBiometricService,
    private firebaseService: FirebaseService,
    private traceabilityBioService: TraceabilityBioService,
    @Inject(MAT_DIALOG_DATA) public data: Trolley
  ) { }

  public ngOnInit(): void {
    this.step = 0;
    this.stepperStep = 0;
    this.status = 'verifying';
    this.setSteps();
    this.createSession(this.data);
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public nextStep(): void {
    this.step++;
    this.stepperStep++;
  }

  public idCaptureProcess(data: IdValidationImages): void {
    this.idImages = data;
    this.nextStep();
  }

  public facialCaptureProcess(data: FacialValidation): void {
    this.facialImage = data;

    const finalData: BiometricalData = {
      idImages: this.idImages,
      facialImage: this.facialImage
    };
    this.nextStep();
    this.validateFacial(finalData);
  }

  private validateFacial(dataRequest: BiometricalData): void {
    const { faceImage, scanReference } = dataRequest.idImages;
    const { imageTemplateRaw, bestCroppedImage } = dataRequest.facialImage;
    const { transferId, site, module, process } = this.data;
    const requestData: FacialBiometricValidationData = {
      faceImage: imageTemplateRaw,
      faceDNI: faceImage,
      face64Format: bestCroppedImage,
      scanReference,
      date: this.util.getISODate(),
      transferId,
      site,
      module,
      process
    };
    this.traceabilityBioService.traceProcess(SEND_CAPTURED_SELPHI, { status: FACIAL_VALIDATION_TRACE_STATES.finished });
    this.biometricService.facialValidation(this.data.rut, requestData)
      .subscribe((response: FacialValidationResponse) => {
        this.evaluateResponse(response);
      }, (error) => {
        const { statusCode, errorMessage } = error;
        let finalResult: any;
        if (statusCode === 409) {
          const msg = `Validación biométrica finalizada con error: statusCode: ${statusCode} - ${errorMessage}`;
          finalResult = { code: msg, result: 'error' };
          this.traceabilityBioService.traceProcess(BIOMETRIC_SPA_FLOW_ENDED, { status: finalResult });
        }
        this.status = 'error';
        this.stepperStep = 3;
      });
  }

  private evaluateResponse(response: FacialValidationResponse): void {
    const { statusDNI, statusFace } = response;

    if (statusDNI === POSITIVE_DNI_MORPH && statusFace === LIVE_FACIAL_IMG) {
      const databaseRef = this.firebaseService.readStatusData(this.data.transferId);
      onValue(databaseRef, (snapshot) => {
        const dataReceived = snapshot.val();
        const { status, rejectedReason } = dataReceived;
        if (dataReceived === null) { return; }
        switch (status) {
          case APPROVED_PROCESS:
            this.status = 'success';
            this.stepperStep = 3;
            this.traceabilityBioService.traceProcess(BIOMETRIC_SPA_FLOW_ENDED, { status: FLOW_ENDING_TRACE_STATES.success });
            off(databaseRef);
            break;
          case REJECTED_PROCESS:
            this.status = 'error';
            this.stepperStep = 3;
            this.traceabilityBioService.traceProcess(BIOMETRIC_SPA_FLOW_ENDED, { status: { code: rejectedReason, result: 'error' } });
            off(databaseRef);
            break;
        }
      });
    }
  }

  private setSteps(): void {
    const { documentCapture, photo, validation, confirmation } = this.pageContent.steps;
    this.steps = [documentCapture, photo, validation, confirmation];
  }

  private createSession(trolley: Trolley): void {
    this.traceabilityBioService.setSession(trolley);
  }

}
