import { Mock } from '@interfaces/mock.interface';


export const GET_BALANCE_EVOLUTION_INFO_MOCK: Mock = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            idioma: 'es',
            titulo_modal: '¿Porqué varian mis fondos?',
            titulo_contenido: 'Rentabilidad Marzo',
            contenido: `<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.&nbsp;</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>`,
            modal_grafica_evolucion: 1,
          },
          {
            idioma: 'ht',
            titulo_modal: '¿Porqué varian mis fondos? (ht)',
            titulo_contenido: 'Rentabilidad Marzo (ht)',
            contenido: `<p>(ht)<strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.&nbsp;</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>`,
            modal_grafica_evolucion: 1,
          },
        ]
      },
      public: true
    },
  }],
  failures: [{
    response: { }
  }]
};
