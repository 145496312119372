const fundsKey = 'FUNDS';

export const FUNDS = {
  types: {
    A: `${fundsKey}.TYPES.A`,
    B: `${fundsKey}.TYPES.B`,
    C: `${fundsKey}.TYPES.C`,
    D: `${fundsKey}.TYPES.D`,
    E: `${fundsKey}.TYPES.E`
  },
  selector: {
    fundFraction: `${fundsKey}.SELECTOR.FUND_FRACTION`,
    select: `${fundsKey}.SELECTOR.SELECT`,
    disabled: `${fundsKey}.SELECTOR.DISABLED`,
  }
};
