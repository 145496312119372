const pageKey = 'LOGIN';

export const LOGIN_CONTENT = {
  header: `${pageKey}.HEADER`,
  headerExpress: `${pageKey}.HEADER_EXPRESS`,
  title: `${pageKey}.TITLE`,
  titleExpress: `${pageKey}.TITLE_EXPRESS`,
  back: `${pageKey}.BACK`,
  backPublicSite: `${pageKey}.BACK_PUBLIC`,
  loginErrors: {
    blockedPassword: {
      header: `${pageKey}.LOGIN_ERRORS.BLOCKED_PASSWORD.HEADER`,
      subtext: `${pageKey}.LOGIN_ERRORS.BLOCKED_PASSWORD.SUBTEXT`,
      button: `${pageKey}.LOGIN_ERRORS.BLOCKED_PASSWORD.BUTTON`,
    },
    notAffiliate: {
      header: `${pageKey}.LOGIN_ERRORS.NOT_AFFILIATE.HEADER`,
      subtext: `${pageKey}.LOGIN_ERRORS.NOT_AFFILIATE.SUBTEXT`,
      button: `${pageKey}.LOGIN_ERRORS.NOT_AFFILIATE.BUTTON`,
    }

  },
  selectors: {
    client: `${pageKey}.SELECTORS.CLIENT`,
    employer: `${pageKey}.SELECTORS.EMPLOYER`,
    beneficiary: `${pageKey}.SELECTORS.BENEFICIARY`
  },
  languages: {
    spanish: `${pageKey}.LANGUAGES.SPANISH`,
    creole: `${pageKey}.LANGUAGES.CREOLE`,
  },
  welcomeBack: {
    greetings: `${pageKey}.WELCOME_BACK.GREETINGS`,
    message: `${pageKey}.WELCOME_BACK.MESSAGE`,
  },
  form: {
    controls: {
      rut: {
        clientLabel: `${pageKey}.FORM.CONTROLS.RUT.LABEL`,
        beneficiaryLabel: `${pageKey}.FORM.CONTROLS.RUT.LABEL_BENEFICIARY`,
        employerLabel: `${pageKey}.FORM.CONTROLS.RUT.LABEL_EMPLOYER`,
        errors: {
          required: `${pageKey}.FORM.CONTROLS.RUT.ERRORS.REQUIRED`,
          invalid: `${pageKey}.FORM.CONTROLS.RUT.ERRORS.INVALID`
        }
      },
      password: {
        label: `${pageKey}.FORM.CONTROLS.PASSWORD.LABEL`,
        errors: {
          required: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.REQUIRED`,
          invalid: {
            firstSegment: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.INVALID.FIRST_SEGMENT`,
            secondSegment: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.INVALID.SECOND_SEGMENT`,
            thirdSegment: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.INVALID.THIRD_SEGMENT`
          },
          wrong: {
            incorrect: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.WRONG.INCORRECT`,
            attempts: {
              singular: {
                firstSegment: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.WRONG.ATTEMPTS.SINGULAR.FIRST_SEGMENT`,
                secondSegment: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.WRONG.ATTEMPTS.SINGULAR.SECOND_SEGMENT`
              },
              plural: {
                firstSegment: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.WRONG.ATTEMPTS.PLURAL.FIRST_SEGMENT`,
                secondSegment: `${pageKey}.FORM.CONTROLS.PASSWORD.ERRORS.WRONG.ATTEMPTS.PLURAL.SECOND_SEGMENT`
              }
            }
          }
        }
      }
    },
    buttons: {
      recovery: `${pageKey}.FORM.BUTTONS.RECOVERY`,
      passwordless: `${pageKey}.FORM.BUTTONS.PASSWORDLESS`,
      login: `${pageKey}.FORM.BUTTONS.LOGIN`,
      loginExpress: `${pageKey}.FORM.BUTTONS.LOGIN_EXPRESS`,
      change: `${pageKey}.FORM.BUTTONS.CHANGE`,
      back: `${pageKey}.FORM.BUTTONS.BACK`,
      employer: {
        requestAccessKey: `${pageKey}.FORM.BUTTONS.EMPLOYER.REQUEST_ACCESS_KEY`,
        recoverAccessKey: `${pageKey}.FORM.BUTTONS.EMPLOYER.RECOVER_ACCESS_KEY`
      },
      cleanUser: `${pageKey}.FORM.BUTTONS.CLEAN_USER`,
      nativeRecovery: `${pageKey}.FORM.BUTTONS.NATIVE_RECOVERY`,
      fingerprintClaim: `${pageKey}.FORM.BUTTONS.FINGERPRINT_CLAIM`,
      withPassword: `${pageKey}.FORM.BUTTONS.WITH_PASSWORD`,
    },
    question: `${pageKey}.FORM.QUESTION`,
    parametrical: `${pageKey}.FORM.PARAMETRICAL`,
  },
  recover: {
    title: `${pageKey}.RECOVER_ACCESS_KEY.TITLE`,
    confirmText: `${pageKey}.RECOVER_ACCESS_KEY.CONFIRM_TEXT`,
    voucher: `${pageKey}.RECOVER_ACCESS_KEY.VOUCHER`,
    email: `${pageKey}.RECOVER_ACCESS_KEY.EMAIL`,
    steps: [
      `${pageKey}.RECOVER_ACCESS_KEY.STEPS.REQUEST_RUT`,
      `${pageKey}.RECOVER_ACCESS_KEY.STEPS.VALIDATE_EMAIL`,
      `${pageKey}.RECOVER_ACCESS_KEY.STEPS.VOUCHER`,
    ],
    controls: {
      rut: {
        label: `${pageKey}.RECOVER_ACCESS_KEY.CONTROLS.RUT.LABEL`,
        errors: {
          required: `${pageKey}.RECOVER_ACCESS_KEY.CONTROLS.RUT.ERRORS.REQUIRED`,
          invalid: `${pageKey}.RECOVER_ACCESS_KEY.CONTROLS.RUT.ERRORS.INVALID`
        }
      },
      email: {
        label: `${pageKey}.RECOVER_ACCESS_KEY.CONTROLS.EMAIL.LABEL`,
        errors: {
          required: `${pageKey}.RECOVER_ACCESS_KEY.CONTROLS.EMAIL.ERRORS.REQUIRED`,
          invalid: `${pageKey}.RECOVER_ACCESS_KEY.CONTROLS.EMAIL.ERRORS.INVALID_EMAIL`,
        }
      }
    },
    buttons: {
      recover: `${pageKey}.RECOVER_ACCESS_KEY.BUTTONS.RECOVER`,
      next: `${pageKey}.RECOVER_ACCESS_KEY.BUTTONS.NEXT`,
      previous: `${pageKey}.RECOVER_ACCESS_KEY.BUTTONS.PREVIOUS`,
      login: `${pageKey}.RECOVER_ACCESS_KEY.BUTTONS.LOGIN`
    }
  },
  request: {
    title: `${pageKey}.REQUEST_ACCESS_KEY.TITLE`,
    steps: [
      `${pageKey}.REQUEST_ACCESS_KEY.STEPS.BUSINESS_DATA`,
      // `${pageKey}.REQUEST_ACCESS_KEY.STEPS.BUSINESS_ADDRESS`,
      `${pageKey}.REQUEST_ACCESS_KEY.STEPS.LEGAL_ENTITY_STEP1`,
      // `${pageKey}.REQUEST_ACCESS_KEY.STEPS.LEGAL_ENTITY_STEP2`,
      `${pageKey}.REQUEST_ACCESS_KEY.STEPS.REQUEST_SUCCESS`,
    ],
    buttons: {
      next: `${pageKey}.REQUEST_ACCESS_KEY.BUTTONS.NEXT`,
      previous: `${pageKey}.REQUEST_ACCESS_KEY.BUTTONS.PREVIOUS`,
      requestKey: `${pageKey}.REQUEST_ACCESS_KEY.BUTTONS.REQUEST_KEY`,
    },
    forms: {
      titles: {
        businessData: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.TITLES.BUSINESS_DATA`,
        businessAddress: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.TITLES.BUSINESS_ADDRESS`,
        legalEntityStep1: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.TITLES.LEGAL_ENTITY_STEP1`,
        legalEntityStep2: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.TITLES.LEGAL_ENTITY_STEP2`,
      },
      descriptions: {
        rut: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.DESCRIPTIONS.RUT`,
      },
      controls: {
        business: {
          rut: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.RUT.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.RUT.ERRORS.REQUIRED`,
              invalid: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.RUT.ERRORS.INVALID`
            }
          },
          socialReason: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.SOCIAL_REASON.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.SOCIAL_REASON.ERRORS.REQUIRED`,
            }
          },
          email: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.EMAIL.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.EMAIL.ERRORS.REQUIRED`,
              invalid: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.EMAIL.ERRORS.INVALID`,
              invalidDomain: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.EMAIL.ERRORS.INVALID_DOMAIN`,
            }
          },
          phone: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.PHONE.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.PHONE.ERRORS.REQUIRED`,
              invalid: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.PHONE.ERRORS.INVALID`,
            }
          },
          address: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.ADDRESS.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.ADDRESS.ERRORS.REQUIRED`,
              invalid: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.ADDRESS.ERRORS.INVALID`,
            }
          },
          region: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.REGION.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.REGION.ERRORS.REQUIRED`,
              invalid: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.REGION.ERRORS.INVALID`,
            }
          },
          commune: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.COMMUNE.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.COMMUNE.ERRORS.REQUIRED`,
              invalid: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.COMMUNE.ERRORS.INVALID`,
            }
          },
        },
        legalEntity: {
          rut: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.RUT.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.RUT.ERRORS.REQUIRED`,
              invalid: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.RUT.ERRORS.INVALID`
            }
          },
          names: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.NAMES.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.NAMES.ERRORS.REQUIRED`,
            }
          },
          lastName: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.LAST_NAME.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.LAST_NAME.ERRORS.REQUIRED`,
            }
          },
          secondLastName: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.SECOND_LAST_NAME.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.SECOND_LAST_NAME.ERRORS.REQUIRED`,
            }
          },
          jobTitle: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.JOB_TITLE.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.JOB_TITLE.ERRORS.REQUIRED`,
            }
          },
          email: {
            label: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.BUSINESS.EMAIL.LABEL`,
            errors: {
              required: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.EMAIL.ERRORS.REQUIRED`,
              invalid: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.EMAIL.ERRORS.INVALID`,
              invalidDomain: `${pageKey}.REQUEST_ACCESS_KEY.FORMS.CONTROLS.LEGAL_ENTITY.EMAIL.ERRORS.INVALID_DOMAIN`,
            }
          },
        }
      }
    },
    modals: {
      requestEmployerKeySuccess: {
        title: `${pageKey}.REQUEST_ACCESS_KEY.MODALS.REQUEST_EMPLOYER_KEY_SUCCESS.TITLE`,
        subtitle: `${pageKey}.REQUEST_ACCESS_KEY.MODALS.REQUEST_EMPLOYER_KEY_SUCCESS.SUBTITLE` ,
        message: {
          introduction: `${pageKey}.REQUEST_ACCESS_KEY.MODALS.REQUEST_EMPLOYER_KEY_SUCCESS.MESSAGE.INTRODUCTION`,
          folio: `${pageKey}.REQUEST_ACCESS_KEY.MODALS.REQUEST_EMPLOYER_KEY_SUCCESS.MESSAGE.FOLIO`,
          description: `${pageKey}.REQUEST_ACCESS_KEY.MODALS.REQUEST_EMPLOYER_KEY_SUCCESS.MESSAGE.DESCRIPTION`,
        },
        button: `${pageKey}.REQUEST_ACCESS_KEY.MODALS.REQUEST_EMPLOYER_KEY_SUCCESS.BUTTON`,
      },
    }
  },
  recaptcha: {
    description: `${pageKey}.RECAPTCHA.DESCRIPTION`
  },
  biometricAuthentication: {
    title: `${pageKey}.BIOMETRIC_AUTHENTICATION.TITLE`,
    description: `${pageKey}.BIOMETRIC_AUTHENTICATION.DESCRIPTION`,
    fallButton: `${pageKey}.BIOMETRIC_AUTHENTICATION.FALLBUTTON`,
    cancelButton: `${pageKey}.BIOMETRIC_AUTHENTICATION.CANCELBUTTON`,
    fingerprintClaim: `${pageKey}.BIOMETRIC_AUTHENTICATION.FINGERPRINT_CLAIM`
  }
};
