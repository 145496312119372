const pageKey = 'MODALS.FACIAL_VALIDATION_MODAL';

export const MODAL_FACIAL_VALIDATION_CONTENT = {
  title: `${pageKey}.TITLE`,
  tutorialLegend: `${pageKey}.TUTORIAL_LEGEND`,
  steps: {
    documentCapture: `${pageKey}.STEPS.DOCUMENT_CAPTURE`,
    photo: `${pageKey}.STEPS.PHOTO`,
    validation: `${pageKey}.STEPS.VALIDATION`,
    confirmation: `${pageKey}.STEPS.CONFIRMATION`
  },
  step1: {
    description: `${pageKey}.STEP_1.DESCRIPTION`,
    guidelines: {
      first: {
        title: `${pageKey}.STEP_1.GUIDELINES.FIRST.TITLE`,
        description: `${pageKey}.STEP_1.GUIDELINES.FIRST.DESCRIPTION`
      },
      second: {
        title: `${pageKey}.STEP_1.GUIDELINES.SECOND.TITLE`,
        description: `${pageKey}.STEP_1.GUIDELINES.SECOND.DESCRIPTION`
      },
      third: {
        title: `${pageKey}.STEP_1.GUIDELINES.THIRD.TITLE`,
        description: `${pageKey}.STEP_1.GUIDELINES.THIRD.DESCRIPTION`
      },
      fourth: {
        title: `${pageKey}.STEP_1.GUIDELINES.FOURTH.TITLE`,
        description: `${pageKey}.STEP_1.GUIDELINES.FOURTH.DESCRIPTION`
      },
      buttons: {
        cancel: `${pageKey}.STEP_1.GUIDELINES.BUTTONS.CANCEL`,
        continue: `${pageKey}.STEP_1.GUIDELINES.BUTTONS.CONTINUE`
      }
    },
    actions: {
      timeout: {
        title: `${pageKey}.STEP_1.ACTIONS.TIMEOUT.TITLE`,
        subtitle: `${pageKey}.STEP_1.ACTIONS.TIMEOUT.SUBTITLE`,
        buttons: {
          finalize: `${pageKey}.STEP_1.ACTIONS.TIMEOUT.BUTTONS.FINALIZE`,
          retry: `${pageKey}.STEP_1.ACTIONS.TIMEOUT.BUTTONS.RETRY`
        }
      },
      capture: {
        title: `${pageKey}.STEP_1.ACTIONS.CAPTURE.TITLE`,
        subtitle: `${pageKey}.STEP_1.ACTIONS.CAPTURE.SUBTITLE`
      },
      loading: {
        title: `${pageKey}.STEP_1.ACTIONS.LOADING.TITLE`,
        subtitle: `${pageKey}.STEP_1.ACTIONS.LOADING.SUBTITLE`,
      },
      error: {
        title: `${pageKey}.STEP_1.ACTIONS.ERROR.TITLE`,
        buttons: {
          finalize: `${pageKey}.STEP_1.ACTIONS.ERROR.BUTTONS.FINALIZE`,
          retry: `${pageKey}.STEP_1.ACTIONS.ERROR.BUTTONS.RETRY`
        }
      },
      succeded: {
        title: `${pageKey}.STEP_1.ACTIONS.SUCCEDED.TITLE`,
        subtitle: `${pageKey}.STEP_1.ACTIONS.SUCCEDED.SUBTITLE`,
        buttons: {
          continue: `${pageKey}.STEP_1.ACTIONS.SUCCEDED.BUTTONS.CONTINUE`,
        }
      },
      cancel: {
        title: `${pageKey}.STEP_1.ACTIONS.CANCEL.TITLE`,
        subtitle: `${pageKey}.STEP_1.ACTIONS.CANCEL.SUBTITLE`,
        buttons: {
          finalize: `${pageKey}.STEP_1.ACTIONS.CANCEL.BUTTONS.FINALIZE`,
          retry: `${pageKey}.STEP_1.ACTIONS.CANCEL.BUTTONS.RETRY`
        }
      }
    }
  },
  step2: {
    description: `${pageKey}.STEP_2.DESCRIPTION`,
    guidelines: {
      first: {
        title: `${pageKey}.STEP_2.GUIDELINES.FIRST.TITLE`,
        description: `${pageKey}.STEP_2.GUIDELINES.FIRST.DESCRIPTION`
      },
      second: {
        title: `${pageKey}.STEP_2.GUIDELINES.SECOND.TITLE`,
        description: `${pageKey}.STEP_2.GUIDELINES.SECOND.DESCRIPTION`
      },
      third: {
        title: `${pageKey}.STEP_2.GUIDELINES.THIRD.TITLE`,
        description: `${pageKey}.STEP_2.GUIDELINES.THIRD.DESCRIPTION`
      },
      buttons: {
        cancel: `${pageKey}.STEP_2.GUIDELINES.BUTTONS.CANCEL`,
        continue: `${pageKey}.STEP_2.GUIDELINES.BUTTONS.CONTINUE`
      }
    },
    buttons: {
      start: `${pageKey}.STEP_2.BUTTONS.START`,
      cancel: `${pageKey}.STEP_2.BUTTONS.CANCEL`,
      continue: `${pageKey}.STEP_2.BUTTONS.CONTINUE`,
      retry: `${pageKey}.STEP_2.BUTTONS.RETRY`,
      finalize: `${pageKey}.STEP_2.BUTTONS.FINALIZE`
    },
    actions: {
      timeout: {
        title: `${pageKey}.STEP_2.ACTIONS.TIMEOUT.TITLE`,
        subtitle: `${pageKey}.STEP_2.ACTIONS.TIMEOUT.SUBTITLE`,
        buttons: {
          finalize: `${pageKey}.STEP_2.ACTIONS.TIMEOUT.BUTTONS.FINALIZE`,
          retry: `${pageKey}.STEP_2.ACTIONS.TIMEOUT.BUTTONS.RETRY`
        }
      },
      capture: {
        title: `${pageKey}.STEP_2.ACTIONS.CAPTURE.TITLE`,
        subtitle: `${pageKey}.STEP_2.ACTIONS.CAPTURE.SUBTITLE`
      },
      loading: {
        title: `${pageKey}.STEP_2.ACTIONS.LOADING.TITLE`,
        subtitle: `${pageKey}.STEP_2.ACTIONS.LOADING.SUBTITLE`,
      },
      error: {
        title: `${pageKey}.STEP_2.ACTIONS.ERROR.TITLE`,
        subtitle: `${pageKey}.STEP_2.ACTIONS.ERROR.SUBTITLE`,
        buttons: {
          finalize: `${pageKey}.STEP_2.ACTIONS.ERROR.BUTTONS.FINALIZE`,
          retry: `${pageKey}.STEP_2.ACTIONS.ERROR.BUTTONS.RETRY`
        }
      },
      glasses: {
        title: `${pageKey}.STEP_2.ACTIONS.GLASSES.TITLE`,
        subtitle: `${pageKey}.STEP_2.ACTIONS.GLASSES.SUBTITLE`,
      },
      succeded: {
        title: `${pageKey}.STEP_2.ACTIONS.SUCCEDED.TITLE`,
        subtitle: `${pageKey}.STEP_2.ACTIONS.SUCCEDED.SUBTITLE`,
        buttons: {
          continue: `${pageKey}.STEP_2.ACTIONS.SUCCEDED.BUTTONS.CONTINUE`,
        }
      },
      cancel: {
        title: `${pageKey}.STEP_2.ACTIONS.CANCEL.TITLE`,
        subtitle: `${pageKey}.STEP_2.ACTIONS.CANCEL.SUBTITLE`,
        buttons: {
          finalize: `${pageKey}.STEP_2.ACTIONS.CANCEL.BUTTONS.FINALIZE`,
          retry: `${pageKey}.STEP_2.ACTIONS.CANCEL.BUTTONS.RETRY`
        }
      }
    }
  },
  step3: {
    verifying: {
      title: `${pageKey}.STEP_3.VERIFYING.TITLE`,
      subtitle: `${pageKey}.STEP_3.VERIFYING.SUBTITLE`,
      subtitle2: `${pageKey}.STEP_3.VERIFYING.SUBTITLE2`,
    },
    success: {
      title: `${pageKey}.STEP_3.SUCCESS.TITLE`,
      subtitle: `${pageKey}.STEP_3.SUCCESS.SUBTITLE`,
      buttons: {
        finalize: `${pageKey}.STEP_3.SUCCESS.BUTTONS.FINALIZE`
      }
    },
    error: {
      title: `${pageKey}.STEP_3.ERROR.TITLE`,
      subtitle: `${pageKey}.STEP_3.ERROR.SUBTITLE`,
      buttons: {
        finalize: `${pageKey}.STEP_3.ERROR.BUTTONS.FINALIZE`
      }
    },

    title: `${pageKey}.STEP_3.TITLE`,
    subtitle: `${pageKey}.STEP_3.SUBTITLE`,
    buttons: {
      start: `${pageKey}.STEP_3.BUTTONS.START`,
      cancel: `${pageKey}.STEP_3.BUTTONS.CANCEL`,
      continue: `${pageKey}.STEP_3.BUTTONS.CONTINUE`,
      retry: `${pageKey}.STEP_3.BUTTONS.RETRY`,
      finalize: `${pageKey}.STEP_3.BUTTONS.FINALIZE`
    },
    actions: {
      capture: {
        title: `${pageKey}.STEP_3.ACTIONS.CAPTURE.TITLE`,
        subtitle: `${pageKey}.STEP_3.ACTIONS.CAPTURE.SUBTITLE`,
      },
      cancel: {
        title: `${pageKey}.STEP_3.ACTIONS.CANCEL.TITLE`,
        subtitle: `${pageKey}.STEP_3.ACTIONS.CANCEL.SUBTITLE`,
      },
      timeout: {
        title: `${pageKey}.STEP_3.ACTIONS.TIMEOUT.TITLE`,
        subtitle: `${pageKey}.STEP_3.ACTIONS.TIMEOUT.SUBTITLE`,
      },
      succeded: {
        title: `${pageKey}.STEP_3.ACTIONS.SUCCEDED.TITLE`,
        subtitle: `${pageKey}.STEP_3.ACTIONS.SUCCEDED.SUBTITLE`,
      },
      loading: {
        title: `${pageKey}.STEP_3.ACTIONS.LOADING.TITLE`,
        subtitle: `${pageKey}.STEP_3.ACTIONS.LOADING.SUBTITLE`,
      },
      error: {
        title: `${pageKey}.STEP_3.ACTIONS.ERROR.TITLE`
      }
    }
  },
  step4: {
    verifying: {
      title: `${pageKey}.STEP_4.VERIFYING.TITLE`,
      subtitle: `${pageKey}.STEP_4.VERIFYING.SUBTITLE`,
      subtitle2: `${pageKey}.STEP_4.VERIFYING.SUBTITLE2`,
    },
    success: {
      title: `${pageKey}.STEP_4.SUCCESS.TITLE`,
      subtitle: `${pageKey}.STEP_4.SUCCESS.SUBTITLE`,
      buttons: {
        finalize: `${pageKey}.STEP_4.SUCCESS.BUTTONS.FINALIZE`
      }
    },
    error: {
      title: `${pageKey}.STEP_4.ERROR.TITLE`,
      subtitle: `${pageKey}.STEP_4.ERROR.SUBTITLE`,
      buttons: {
        finalize: `${pageKey}.STEP_4.ERROR.BUTTONS.FINALIZE`
      }
    }
  }
};

export const APPROVED_PROCESS = 'aprobado';
export const REJECTED_PROCESS = 'rechazado';
export const PENDING_PROCESS = 'pendiente';
export const CANCELED_PROCESS = 'cancelado';
