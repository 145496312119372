import { Mock } from '@interfaces/mock.interface';

export const EMPTY_BODY_MOCK: Mock = {
  success: [
    {
      response: {}
    },
  ],
  failures: [
    {
      response: {
        code: 50001,
        messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        message: 'Error',
      },
    },
  ],
};
