const pageKey = 'MAIN';

export const MAIN_CONTENT = {
  lateralLayout: {
    greetings: `${pageKey}.LATERAL_LAYOUT.GREETINGS`,
    welcome: {
      male: `${pageKey}.LATERAL_LAYOUT.WELCOME.MALE`,
      female: `${pageKey}.LATERAL_LAYOUT.WELCOME.FEMALE`,
      message: `${pageKey}.LATERAL_LAYOUT.WELCOME.MESSAGE`,
      beneficiaryMessage: `${pageKey}.LATERAL_LAYOUT.WELCOME.BENEFICIARY_MESSAGE`,
      emailMessage: `${pageKey}.LATERAL_LAYOUT.WELCOME.EMAIL_MESSAGE`,
      phoneMessage: `${pageKey}.LATERAL_LAYOUT.WELCOME.PHONE_MESSAGE`
    },
  },
  topLayout: {
    lastVisit: `${pageKey}.TOP_LAYOUT.LAST_VISIT`,
    logout: `${pageKey}.TOP_LAYOUT.LOGOUT`,
  },
  navMenu: {
    back: `${pageKey}.NAV_MENU.BACK`,
    updatePersonalInfo: `${pageKey}.NAV_MENU.UPDATE_PERSONAL_INFO`,
    client: {
      home: `${pageKey}.NAV_MENU.CLIENT.HOME`,
      fundChange: `${pageKey}.NAV_MENU.CLIENT.FUND_CHANGE`,
      requests: `${pageKey}.NAV_MENU.CLIENT.REQUESTS`,
      enterRequest: `${pageKey}.NAV_MENU.CLIENT.ENTER_REQUEST`,
      checkRequest: `${pageKey}.NAV_MENU.CLIENT.CHECK_REQUEST`,
      claimRequest: `${pageKey}.NAV_MENU.CLIENT.CLAIM_REQUEST`,
      savings: `${pageKey}.NAV_MENU.CLIENT.SAVINGS`,
      apv: `${pageKey}.NAV_MENU.CLIENT.APV`,
      mandatoryAccount: `${pageKey}.NAV_MENU.CLIENT.MANDATORY_ACCOUNT`,
      othersAccounts: `${pageKey}.NAV_MENU.CLIENT.OTHERS_ACCOUNTS`,
      certificatesAndPension: `${pageKey}.NAV_MENU.CLIENT.CERTIFICATES_AND_PENSION`,
      certificates: `${pageKey}.NAV_MENU.CLIENT.CERTIFICATES`,
      pension: `${pageKey}.NAV_MENU.CLIENT.PENSION`,
      pensionSettlement: `${pageKey}.NAV_MENU.CLIENT.PENSION_SETTLEMENT`,
      personalInfo: `${pageKey}.NAV_MENU.CLIENT.PERSONAL_INFO`,
      infoAndPasswords: `${pageKey}.NAV_MENU.CLIENT.INFO_AND_PASSWORDS`,
      passwords: `${pageKey}.NAV_MENU.CLIENT.PASSWORDS`,
      changePassword: `${pageKey}.NAV_MENU.CLIENT.CHANGE_PASSWORD`,
      securityKey: `${pageKey}.NAV_MENU.CLIENT.SECURITY_KEY`,
      cavWithdrawal10: `${pageKey}.NAV_MENU.CLIENT.CAV_WITHDRAWAL_10`,
      maxWithdrawal10: `${pageKey}.NAV_MENU.CLIENT.MAX_WITHDRAWAL_10`,
      apvAccount: `${pageKey}.NAV_MENU.CLIENT.APV_ACCOUNT`,
      cavAccount: `${pageKey}.NAV_MENU.CLIENT.CAV_ACCOUNT`,
      cavAccount10: `${pageKey}.NAV_MENU.CLIENT.CAV_ACCOUNT_10`,
      pensionStatement: `${pageKey}.NAV_MENU.CLIENT.PENSION_STATEMENT`,
      applications: `${pageKey}.NAV_MENU.CLIENT.APPLICATIONS`,
      financialInformation: `${pageKey}.NAV_MENU.CLIENT.FINANCIAL_INFORMATION`,
      quotaValue: `${pageKey}.NAV_MENU.CLIENT.QUOTA_VALUE`,
      economicIndicators: `${pageKey}.NAV_MENU.CLIENT.ECONOMIC_INDICATORS`,
      profitabilityVariation: `${pageKey}.NAV_MENU.CLIENT.PROFITABILITY_VARIATION`,
      fundSimulator: `${pageKey}.NAV_MENU.CLIENT.FUND_SIMULATOR`,
      pensionSimulator: `${pageKey}.NAV_MENU.CLIENT.PENSION_SIMULATOR`,
      help: `${pageKey}.NAV_MENU.CLIENT.HELP`,
    },
    beneficiary: {
      savings: `${pageKey}.NAV_MENU.BENEFICIARY.SAVINGS`,
      securityKey: `${pageKey}.NAV_MENU.BENEFICIARY.SECURITY_KEY`,
      fundChange: `${pageKey}.NAV_MENU.CLIENT.FUND_CHANGE`,
      password: `${pageKey}.NAV_MENU.BENEFICIARY.PASSWORD`,
      personalInfo: `${pageKey}.NAV_MENU.BENEFICIARY.PERSONAL_INFO`
    },
    employer: {
      home: `${pageKey}.NAV_MENU.EMPLOYER.HOME`,
      payments: `${pageKey}.NAV_MENU.EMPLOYER.PAYMENTS`,
      certificates: `${pageKey}.NAV_MENU.EMPLOYER.CERTIFICATES`,
      keys: `${pageKey}.NAV_MENU.EMPLOYER.KEYS`,
      employerInfo: `${pageKey}.NAV_MENU.EMPLOYER.EMPLOYER_INFO`,
      contact: `${pageKey}.NAV_MENU.EMPLOYER.CONTACT`,
      personalInfo: `${pageKey}.NAV_MENU.EMPLOYER.PERSONAL_INFO`,
      changePassword: `${pageKey}.NAV_MENU.EMPLOYER.CHANGE_PASSWORD`,
      securityKey: `${pageKey}.NAV_MENU.CLIENT.SECURITY_KEY`,
      quotationsAggregates: `${pageKey}.NAV_MENU.EMPLOYER.QUOTATIONS_AGGREGATE`,
      debts: `${pageKey}.NAV_MENU.EMPLOYER.DEBTS`,
      requests: `${pageKey}.NAV_MENU.EMPLOYER.REQUESTS`,
    }
  },
  navMenuMobile: {
    client: {
      home: `${pageKey}.NAV_MENU_MOBILE.CLIENT.HOME`,
      savings: `${pageKey}.NAV_MENU_MOBILE.CLIENT.SAVINGS`,
      profileAndKeys: `${pageKey}.NAV_MENU_MOBILE.CLIENT.PROFILE_AND_KEYS`,
      helpCenter: `${pageKey}.NAV_MENU_MOBILE.CLIENT.HELP_CENTER`,
      enterRequest: `${pageKey}.NAV_MENU_MOBILE.CLIENT.ENTER_REQUEST`,
      checkRequest: `${pageKey}.NAV_MENU_MOBILE.CLIENT.CHECK_REQUEST`,
      changePassword: `${pageKey}.NAV_MENU_MOBILE.CLIENT.CHANGE_PASSWORD`,
    }
  },
  languages: {
    spanish: `${pageKey}.LANGUAGES.SPANISH`,
    creole: `${pageKey}.LANGUAGES.CREOLE`,
  },
  accessibility: {
    title: `${pageKey}.ACCESSIBILITY.TITLE`
  },
  toggleMenu: {
    configuration: `${pageKey}.TOGGLE_MENU.CONFIGURATION`,
    headerText: {
      welcome: `${pageKey}.TOGGLE_MENU.HEADER_TEXT.WELCOME`,
      littleMissing: `${pageKey}.TOGGLE_MENU.HEADER_TEXT.LITTLE_MISSING`,
      updateInfo: `${pageKey}.TOGGLE_MENU.HEADER_TEXT.UPDATE_INFO`,
    },
    menuToolbar: {
      logout: `${pageKey}.TOGGLE_MENU.MENU_TOOLBAR.LOGOUT`,
      logoutMobile: `${pageKey}.TOGGLE_MENU.MENU_TOOLBAR.LOGOUT_MOBILE`
    }
  },
  paymentReactivation: {
    title: `${pageKey}.PAYMENT_REACTIVATION.TITLE`,
    page: {
      reactivationPagePayments: {
        text: `${pageKey}.PAYMENT_REACTIVATION.PAGES.REACTIVATION_PAYMENTS.TEXT`,
        buttonClose: `${pageKey}.PAYMENT_REACTIVATION.PAGES.REACTIVATION_PAYMENTS.BUTTON_CLOSE`,
        buttonContinue: `${pageKey}.PAYMENT_REACTIVATION.PAGES.REACTIVATION_PAYMENTS.BUTTON_CONTINUE`
      },
      requestSent: {
        title: `${pageKey}.PAYMENT_REACTIVATION.PAGES.REQUEST_SENT.TITLE`,
        text: `${pageKey}.PAYMENT_REACTIVATION.PAGES.REQUEST_SENT.TEXT`,
        buttonAccept: `${pageKey}.PAYMENT_REACTIVATION.PAGES.REQUEST_SENT.BUTTON_ACCEPT`
      },
      noticeMessage: {
        title: `${pageKey}.PAYMENT_REACTIVATION.PAGES.NOTICE_MESSAGE.TITLE`,
        text: `${pageKey}.PAYMENT_REACTIVATION.PAGES.NOTICE_MESSAGE.TEXT`,
        buttonClose: `${pageKey}.PAYMENT_REACTIVATION.PAGES.NOTICE_MESSAGE.BUTTON_CLOSE`
      }
    }
  },
  general: {
    moreInfo: `${pageKey}.GENERAL.MORE_INFO`
  },
  logoutModal: {
    title: `${pageKey}.LOGOUT_MODAL.TITLE`,
    firstButton: `${pageKey}.LOGOUT_MODAL.FIRST_BUTTON`,
    secondButton: `${pageKey}.LOGOUT_MODAL.SECOND_BUTTON`
  }
};
