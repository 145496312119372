import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';


export interface WebchatUserInfo {
  name: string;
  rut: string;
  contactUrl: string;
}
// eslint-disable-next-line @typescript-eslint/ban-types
declare let Genesys: Function;

@Injectable({ providedIn: 'root' })
export class WebChatService {

  public user: WebchatUserInfo;
  private renderer: Renderer2;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.init();
  }

  public async initializeChat(): Promise<void> {
    this.user = await this.getUserData();
    this.setUserData(this.user);
  }

  public toggleWebChatWindow(): void {

    Genesys('command', 'Messenger.open', {},
      () => { },  // if resolved
      () => {    // if rejected
        Genesys('command', 'Messenger.close');
      }
    );
  }

  public async getUserData(): Promise<WebchatUserInfo> {
    const name = await this.authService.getUserFullName();
    const rut = this.authService.getUserRut();
    return {
      name: name.trim(),
      rut,
      contactUrl: this.router.url
    };
  }

  private setUserData(userData: WebchatUserInfo): void {
    Genesys('command', 'Database.set', {
      messaging: {
        customAttributes: {
          ...userData
        }
      },
    });

  }

  private init(): void {
    Genesys('subscribe', 'Messenger.ready', () => {
      this.displayMessenger();
    });
  }

  public displayVideocall(): void {
    const videocall = this.document.querySelector('app-auvious-widget');
    this.renderer.setAttribute(videocall, 'customer-display-name', this.user.name);
    this.renderer.setStyle(videocall, 'display', 'initial');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videocall.setCustomerMetadata({ 'name': this.user.name });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videocall.setCustomerMetadata({ 'rut': this.user.rut });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videocall.launch('video');
  }
  
  public displayMessenger(): void {
    const chat = this.document.querySelector('#genesys-messenger');
    this.renderer.setStyle(chat, 'visibility', 'visible');
  }

  public endVideocall(): void {
    const videocall = this.document.querySelector('app-auvious-widget');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videocall.terminate(false);
    this.renderer.setStyle(videocall, 'display', 'none');
  }
}
