import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { CalculateBusinessDayResponse } from '@interfaces/calculate-business-day.interface';
import { Country } from '@interfaces/country.interface';
import { DefaultResponse } from '@interfaces/default-response.interface';
import { PreviredQuestion } from '@interfaces/security-questions.interface';
import { ClientEconomicActivity, Region } from '@interfaces/user-data.interface';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { GET_PREVIRED_QUESTIONS_MOCK } from '@services/parameters/mocks/previred-questions.mock';
import { Util } from '@util';

import { APPLICATION_TYPES_MOCK } from './mocks/application-types.mock';
import { CALCULATE_BUSINESS_DAY_MOCK } from './mocks/calculate-business-day.mock';
import { COUNTRIES_MOCKUP } from './mocks/countries.mock';
import { VALIDATE_EMAIL_DOMAIN_MOCK } from './mocks/email-domain-validation.mock';
import { INCOME_SOURCE_MOCKUP } from './mocks/income-sources.mock';
import { REGIONS_MOCKUP } from './mocks/regions.mock';
import { ECONOMIC_ACTIVITIES_MOCKUP } from './mocks/economic-activities.mock';
import { PENSION_ADMINISTRATORS_MOCKUP } from './mocks/pensionAdministrators.mock';
import { JOB_TITLES_MOCKUP } from './mocks/job-titles.mock';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  private baseUrl = `${environment.clientsUrl}parameters`;
  private publicProxyBaseUrl = `${environment.publicProxyUrl}`;

  constructor(
    private http: HttpInterceptor,
    private authService: AuthenticationService,
    private util: Util,
  ) { }

  public getPreviredQuestions(): Observable<Array<PreviredQuestion>> {
    const rut = this.authService.getUserRut();
    const url = `${this.baseUrl}/previred-questions/?rut=${rut}`;
    return this.http.get(url, GET_PREVIRED_QUESTIONS_MOCK);
  }

  public getRegions(): Observable<Array<Region>> {
    const url = `${this.baseUrl}/public-regions`;
    return this.http.get(url, REGIONS_MOCKUP);
  }

  public getPensionAdministrators(): Observable<Array<DefaultResponse>> {
    const url = `${this.publicProxyBaseUrl}pension-administrators`;
    return this.http.get(url, PENSION_ADMINISTRATORS_MOCKUP);
  }

  public getClientEconomicActivities(): Observable<Array<ClientEconomicActivity>> {
    const url = `${this.publicProxyBaseUrl}clients/economic-activities`;
    return this.http.get(url, ECONOMIC_ACTIVITIES_MOCKUP);
  }

  public getJobTitles(): Observable<Array<ClientEconomicActivity>> {
    const url = `${this.publicProxyBaseUrl}job-titles`;
    return this.http.get(url, JOB_TITLES_MOCKUP);
  }

  public validateEmailDomain(email: string): Observable<boolean> {
    const url = `${this.baseUrl}/validate-email/?email=${email}`;
    return this.http.get(url, VALIDATE_EMAIL_DOMAIN_MOCK)
      .pipe(catchError(() => of(true)));
  }

  public getCountries(): Observable<Array<Country>> {
    return this.http.get(`${environment.clientsUrl}countries`, COUNTRIES_MOCKUP);
  }

  public getIncomeSources(): Observable<Array<DefaultResponse>> {
    return this.http.get(`${environment.clientsUrl}income-sources`, INCOME_SOURCE_MOCKUP);
  }

  public calculateBusinessDay(date: string, days: number): Observable<CalculateBusinessDayResponse> {
    const url = `${environment.clientsUrl}parameters/calculate-business-day/?date=${date}&days=${days}`;
    return this.http.get(url, CALCULATE_BUSINESS_DAY_MOCK);
  }

  public getApplicationTypes(): Observable<Array<DefaultResponse>> {
    const url = `${environment.clientsUrl}parameters/applications/types`;
    return this.http.get(url, APPLICATION_TYPES_MOCK)
      .pipe(map(response => {
        return response.map(type => ({ ...type, description: this.util.capitalize(type.description)}));
      }));
  }
}
