import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { DefaultCreateResponse, DefaultResponse } from '@interfaces/default-response.interface';
import { CONTACT_MOCKUP, GET_REASONS_MOCKUP } from '@services/contact/mocks/contact.mock';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(
    private http: HttpInterceptor,
  ) { }

  public getReasons(): Observable<Array<DefaultResponse>> {
    const url = `${environment.clientsUrl}contacts/reasons`;
    return this.http.get(url, GET_REASONS_MOCKUP);
  }

  public contact(form: FormData): Observable<DefaultCreateResponse> {
    const url = `${environment.clientsUrl}contacts`;
    return this.http.post(url, form, CONTACT_MOCKUP);
  }

}
