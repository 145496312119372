const accountsKey = 'ACCOUNTS';

export const ACCOUNT_TYPES = {
  CCO: `${accountsKey}.CCO`,
  CCICO: `${accountsKey}.CCO`,
  CAV: `${accountsKey}.CAV`,
  CAV_10: `${accountsKey}.CAV10`,
  CAI: `${accountsKey}.CAI`,
  CCV: `${accountsKey}.CCV`,
  APV: `${accountsKey}.CCV`,
  CCICV: `${accountsKey}.CCV`,
  CCIV: `${accountsKey}.CCV`,
  CDC: `${accountsKey}.CDC`,
  CCIDC: `${accountsKey}.CDC`,
  CAF: `${accountsKey}.CAF`,
  CCIAV: `${accountsKey}.CAF`,
  CVC: `${accountsKey}.CVC`,
  CAPVC: `${accountsKey}.CVC`,
  APVC: `${accountsKey}.CVC`,
  BR: `${accountsKey}.BR`,
};

export const FUNDS_ORIGINS = {
  R: 9,
  H: 6,
  P: 999,
  V: 8,
  O: 999,
};

export const ACCOUNT_ORDER = ['cco', 'caf', 'cai', 'ccv', 'cav', 'cdc', 'cvc'];

export const ACCOUNT_LAPSES = ['5M', '1A', '2A', '3A', '5A'];
