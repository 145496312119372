<div class="modal-close" *ngIf="closeBtn">
  <div class="i-cross-gray" (click)="dialogClose()"></div>
</div>
<div class="modal-container">
  <div *ngIf="iconName" class="modal-icon {{ iconName }}"></div>

  <h1 class="title-secondary" [ngClass]="font?.size" [innerHTML]="title | translate"></h1>
  <h2 *ngIf="subtitle" class="subtitle" [ngClass]="font?.size" [innerHTML]="subtitle | translate"></h2>

  <p class="description" [ngClass]="font?.size" [innerHTML]="message | translate"></p>

  <div *ngIf="alertImage?.url">
    <img src="{{ alertImage?.url | translate }}" alt="{{ alertImage?.alt }}">
  </div>

  <div  class="buttons-container">
    <button class="btn small secondary" [ngClass]="font?.size" *ngIf="showSecondaryButton" (click)="secondaryAction()">{{ secondBtnText | translate }}</button>
    <button class="btn small primary" [ngClass]="font?.size" (click)="primaryAction()" [innerHTML]="firstBtnText | translate"></button>
  </div>
</div>
