import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { HTTP_ERROR_CODES, HttpResponseTypes, ORIGIN_APP, ORIGIN_TYPE } from '@constants/constants';
import { environment } from '@env';
import { Environment } from '@interfaces/environment.interface';
import { HttpParams } from '@interfaces/http.interface';
import { Mock } from '@interfaces/mock.interface';

@Injectable()
export class HttpInterceptor {
  private httpSuccessCode = 200;
  private headers = new HttpHeaders();
  private environment: Environment;
  private MOCK_DELAY_MILISECONDS = 1000;

  constructor(private http: HttpClient) {
    this.environment = environment;
  }

  mockedResponse(mockup: Mock): Observable<any> {
    const responseStatus = (mockup.failures && Math.random() > this.environment.mockedResponseSuccessRate) ? 'failures' : 'success';
    const responseNumber = Math.floor(Math.random() * mockup[responseStatus].length);
    const responseOptionsParams = {
      status: responseStatus === 'success' ? this.httpSuccessCode : HTTP_ERROR_CODES.badRequest.code,
    };
    if (responseStatus === 'success') {
      responseOptionsParams['body'] = mockup[responseStatus][responseNumber].response;
    } else {
      responseOptionsParams['error'] = mockup[responseStatus][responseNumber].response;
    }
    const response = new HttpResponse(responseOptionsParams);
    const responseError = new HttpErrorResponse(responseOptionsParams);
    return responseStatus === 'success' ? of(response.body).pipe(delay(this.MOCK_DELAY_MILISECONDS)) : throwError(responseError);
  }

  private httpCall({ method, url, mockup, data, customHeaders, responseType }: HttpParams): Observable<any> {

    const header = customHeaders ? customHeaders : this.headers;
    const origin = Capacitor.isNativePlatform() ? ORIGIN_APP : ORIGIN_TYPE;

    if (data) data['origin'] = origin;
    else data = { origin };

    data['site'] = 'private';

    if (this.environment.mockHttpCalls) { return this.mockedResponse(mockup); }
    if (method === 'delete' || method === 'get') {
      const options = { headers: header, params: data } as any;
      if (responseType) { options.responseType = responseType; }
      return this.http[method](url, options);
    }

    return this.http[method]<any>(url, data, { headers: header });
  }

  delete(url: string, mockup: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'delete', url, mockup, data: {}, customHeaders });
  }

  deleteWithBody(url: string, mockup: any, data: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'delete', url, mockup, data, customHeaders });
  }

  get(url: string, mockup: any, customHeaders?: HttpHeaders, data?: any, responseType?: HttpResponseTypes): Observable<any> {
    return this.httpCall({ method: 'get', url, mockup, data, customHeaders, responseType });
  }

  post(url: string, data: any, mockup: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'post', url, mockup, data, customHeaders });
  }

  put(url: string, data: any, mockup: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'put', url, mockup, data, customHeaders });
  }

  patch(url: string, data: any, mockup: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'patch', url, mockup, data, customHeaders });
  }
}
