import { cloneDeep } from 'lodash';
import moment from 'moment';
import { clean, format } from 'rut.js';

import { formatDate, formatNumber } from '@angular/common';
import { Injectable, SimpleChange } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import {
  GENERAL_CERTIFICATES, TAX_CERTIFICATES, TRANSACTIONAL_CERTIFICATES, TRANSACTIONAL_CERTIFICATES_TYPE
} from '@constants/certificates.constants';
import { COMPLAINS_STATUS } from '@constants/complains.constants';
import {
  ACCOUNT_TYPE_CLASS, ACCOUNT_TYPE_ORDER, APP_ROUTES, APV_ACCOUNT_TYPE, CAV_10_NAME_PATH, CAV_ACCOUNT_TYPE, CAV_NAME_PATH, CONTACT_CENTER,
  CREOLE_KEY,
  EDITABLE_USER_DATA_ADDRESS, EDITABLE_USER_DATA_PERSONAL, HTTP_ERROR_CODES, LANGUAGE_KEY, MANDATORY_ACCOUNT_TYPE,
  MAX_RIM, MIN_PESOS, MIN_RIM, MIN_UF, MIN_UTM, PASSWORD_CONSECUTIVE_LENGTH, PASSWORD_LOWER_CHAR, PASSWORD_MAX_LENGTH, PASSWORD_MIN_DIGITS,
  PASSWORD_MIN_LENGTH, PASSWORD_MIN_LETTERS, PASSWORD_REPETITIVE_LENGTH, PASSWORD_SPECIAL_CHAR, PASSWORD_TYPES, PASSWORD_UPPER_CHAR,
  PESOS_CURRENCY_TYPE, RECOGNITION_BONUS_ACCOUNT_TYPE, RIM_CURRENCY_TYPE, SCREEN_BREAKPOINTS, SPANISH_KEY,
  UF_CURRENCY_TYPE,
  UTM_CURRENCY_TYPE
} from '@constants/constants';
import { FILE_EXTENSIONS } from '@constants/forms.constants';
import { DEFAULT_ERROR_MODAL_PROPS } from '@constants/modal-data.constants';
import { MONTHS_EN, MONTHS_ES, MONTHS_HT } from '@constants/months.constant';
import { MALE_GENDER, PENSION_AGE_MONTHS } from '@constants/pension-form.constant';
import {
  CLAIM_FILE_REG_NAME, FILE_REG_RENAME, NUMBERS_COMMAS_PATTERN, ONLY_NUMBERS_PATTERN,
  SLASH_PATTERN, WHITESPACE_PATTERN
} from '@constants/regex.constants';
import {
  CONSECUTIVE_LENGTH_SECURITY_KEY, MAX_LENGTH_SECURITY_KEY,
  MIN_LENGTH_SECURITY_KEY,
  MIN_LETTERS_SECURITY_KEY,
  REPETITIVE_LENGTH_SECURITY_KEY,
  SECURITY_KEY_LOWER_CHAR, SECURITY_KEY_UPPER_CHAR,
  SECURITY_KEY_UPPER_CHAR_NONE
} from '@constants/security-key.constants';
import { STATUS_BAR_STYLE } from '@constants/status-bar.constants';
import {
  BRANCH_OFFICES_URL,
  GO_BACK_TO_REQUEST,
  PENSIONS_URL, PREVIRED_URL, PUBLIC_SITE_URL, QUOTA_VALUE_URL, TRANSFER_APP_URL
} from '@constants/url.constants';
import { currencyInputType } from '@custom-types/currency-input.type';
import { environment } from '@env';
import { AccountFundChange } from '@interfaces/account-fund-change.interface';
import { Account, DetailedAccount, Fund, Regime } from '@interfaces/accounts.interface';
import { Campaign } from '@interfaces/alert-news.interface';
import { RegimeBalance } from '@interfaces/balance.interface';
import { ErrorResponse } from '@interfaces/error-response.model';
import { FormFiles } from '@interfaces/form-files';
import { Backgrounds, backgroundKeys } from '@interfaces/form-interface';
import { ModalData } from '@interfaces/modal-data.interface';
import { PartialFormsFilesResponse } from '@interfaces/partial-forms.interface';
import { TransferValues } from '@interfaces/request-values.interface';
import { UserData, UserStorage } from '@interfaces/user-data.interface';
import { ValidateSecurityResponse } from '@interfaces/validate-security-response.interface';
import { VIRTUAL_ACCOUNTS_BANKS } from '@pages-content/account-withdrawal.constant';
import { ACCOUNT_TYPES } from '@pages-content/accounts.constant';
import { FUNDS } from '@pages-content/funds.constant';
import { PUBLIC_SITE_AFFILIATE_PATH } from '@pages-content/general.constant';

const MIN_AMOUNT_BY_CURRENCY = {
  [UF_CURRENCY_TYPE]: MIN_UF,
  [UTM_CURRENCY_TYPE]: MIN_UTM,
  [PESOS_CURRENCY_TYPE]: MIN_PESOS,
  [RIM_CURRENCY_TYPE]: MIN_RIM,
};

@Injectable()
export class Util {
  private planvitalUrl = environment.planvitalUrl;
  private fingerprintAIO: FingerprintAIO = new FingerprintAIO();
  public contactCenterNumber = CONTACT_CENTER.NUMBER;

  private accentsMap = {
    a: 'á|à|ã|â|À|Á|Ã|Â',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
  };

  public amountValidator(type: currencyInputType, minAmount?: number): ValidatorFn {
    return (control) => {
      let amount = control.value as string;

      if ([UF_CURRENCY_TYPE, UTM_CURRENCY_TYPE].includes(type)) {
        amount = amount.replace(NUMBERS_COMMAS_PATTERN, '');
        amount = amount.replace(',', '.');
      } else {
        amount = amount.replace(ONLY_NUMBERS_PATTERN, '');
      }
      const error = {};

      minAmount ??= MIN_AMOUNT_BY_CURRENCY[type];

      const invalidAmount = Number(amount) < minAmount;
      const insufficientRimAmount = type === RIM_CURRENCY_TYPE && Number(amount) > MAX_RIM;

      error['insufficientAmount'] = invalidAmount || insufficientRimAmount;

      return error['insufficientAmount'] ? error : null;
    };
  }

  public amountLengthValidator(maxLength: number): ValidatorFn {
    return (control) => {
      let amount = control.value as string;
      if (!amount) return null;
      amount = amount.replace(ONLY_NUMBERS_PATTERN, '');
      const error = { exceededLength: amount.length > maxLength };
      return error.exceededLength ? error : null;
    };
  }

  public titleCase(text: string): string {
    if (!text) return '';
    return text.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  }

  public capitalize(text: string): string {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }

  public capitalizeEach(text: string): string {
    const firstCharReg = /(\b[a-z](?!\s))/g;
    return text.toLowerCase().replace(firstCharReg, firstChar => firstChar.toUpperCase());
  }

  public fundDescription(fundName: string): string {
    return FUNDS.types[fundName.toUpperCase()];
  }

  public accountDescription(type: string): string {
    return ACCOUNT_TYPES[type?.toUpperCase()];
  }

  // TODO: esto sobra?
  public accountShortName(type: string): string {
    return ACCOUNT_TYPES[type];
  }

  public complainStatus(status: string): string {
    if (!status) return '';
    const complainStatus = COMPLAINS_STATUS[status.toLowerCase()];
    return complainStatus ? complainStatus : status;
  }

  public rutFormat(unformatteRut: string): string {
    return format(unformatteRut);
  }

  public rutClean(formattedRut: string): string {
    return clean(formattedRut);
  }

  public get isTablet(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.TABLET;
  }

  public get isMediumMobile(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.MEDIUM_MOBILE;
  }

  public get isSmallMobile(): boolean {
    return window.innerWidth <= SCREEN_BREAKPOINTS.SMALL_MOBILE;
  }

  public get language(): string {
    const lang = localStorage.getItem(LANGUAGE_KEY);
    return lang || SPANISH_KEY;
  }

  public get isNative(): boolean {
    return Capacitor.isNativePlatform();
  }

  public goToTransfer(): void {
    this.goTo(TRANSFER_APP_URL);
  }

  public goToPrevired(): void {
    this.goTo(PREVIRED_URL);
  }

  public goToPensions(): void {
    this.goTo(PENSIONS_URL);
  }

  public goToBranchOffices(): void {
    this.goTo(BRANCH_OFFICES_URL);
  }

  public goToQuotaValue(): void {
    this.goTo(QUOTA_VALUE_URL);
  }

  public goToPublicSite(): void {
    this.goTo(PUBLIC_SITE_URL, '_self');
  }

  public goToBackRequest(): void {
    this.goTo(GO_BACK_TO_REQUEST);
  }

  public async goTo(url: string, target: string = '_blank'): Promise<void> {
    if (this.isNative) await Browser.open({ url });
    else window.open(url, target);
  }

  public redirectToPublicSite(): void {
    window.open(this.planvitalUrl, '_self');
  }

  public getPublicSiteRedirectUrl(route: string): string {
    return `${this.planvitalUrl}${PUBLIC_SITE_AFFILIATE_PATH}/${route}`;
  }

  public formatNumber(unformattedNumber: number, precision = 2): string {
    return formatNumber(unformattedNumber, 'es-CL', `1.${precision}-${precision}`);
  }

  public formatCurrency(amount: number, precision = 0): string {
    return '$' + this.formatNumber(amount, precision);
  }

  public base64toBlob(base64Data: string, contentType: string = 'application/pdf', sliceSize: number = 512): Blob {
    const byteCharacters = atob(base64Data);

    const byteArrays = this.range(byteCharacters.length / sliceSize + 1)
      .map((digit: number) => digit * sliceSize)
      .map((offset: number) => byteCharacters.slice(offset, offset + sliceSize))
      .map((selectedBytes: string) => Array.from(selectedBytes).map(char => char.charCodeAt(0)))
      .map((byteNumbers: Array<number>) => new Uint8Array(byteNumbers));

    return new Blob(byteArrays, { type: contentType });
  }

  public blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }
  public base64ToFile(attached: PartialFormsFilesResponse): File {
    if (!attached) { return null; }
    const blobFile = this.base64toBlob(attached.base64File, attached.contentType);
    return new File([blobFile], attached.fileName, { type: attached.contentType });
  }

  private range(length: number): Array<number> {
    return Array.from({ length }, (value, key) => key);
  }

  public getIcon(type: string): string {
    return `i-account-${ACCOUNT_TYPE_CLASS[type]}`;
  }

  public getClass(type: string): string {
    return `account-${ACCOUNT_TYPE_CLASS[type]}`;
  }

  public getMissingDataPercentage(userData: UserData): number {
    let totalData = EDITABLE_USER_DATA_PERSONAL.length;
    let missingData = EDITABLE_USER_DATA_PERSONAL.filter(field => !userData[field]).length;
    let progress = Math.round((missingData * 100) / totalData) * 0.5;

    totalData = EDITABLE_USER_DATA_ADDRESS.length;
    missingData = EDITABLE_USER_DATA_ADDRESS.filter(field => !userData[field]).length;
    progress = progress + Math.round((missingData * 100) / totalData) * 0.3;

    if (userData.shippingOptions && !userData.shippingOptions.hasEmailSubscription) progress += 20;

    return progress;
  }

  public isRecognitionBonus(accountType: string): boolean {
    return accountType === RECOGNITION_BONUS_ACCOUNT_TYPE;
  }

  public fundBalanceTotal(regimes: Array<Regime | RegimeBalance>): number {
    return regimes.reduce((sum, regime) => sum + regime.balance, 0);
  }

  public compareStringUsingWildcard(template: string, stringToCompare: string): boolean {
    return new RegExp('^' + template.replace(/\*/g, '.*') + '$').test(stringToCompare);
  }

  public orderAccounts(accounts: Array<DetailedAccount>): Array<DetailedAccount> {
    const accountOrdering = (previousAccount: DetailedAccount, nextAccount: DetailedAccount) =>
      ACCOUNT_TYPE_ORDER[previousAccount.type] > ACCOUNT_TYPE_ORDER[nextAccount.type] ? 1 : -1;
    return accounts.sort(accountOrdering);
  }

  public orderFundChangeAccounts(accounts: Array<AccountFundChange>): Array<AccountFundChange> {
    const accountOrdering = (previousAccount: AccountFundChange, nextAccount: AccountFundChange) =>
      ACCOUNT_TYPE_ORDER[previousAccount.product] > ACCOUNT_TYPE_ORDER[nextAccount.product] ? 1 : -1;
    return accounts.sort(accountOrdering);
  }

  public get appRoutes() { return APP_ROUTES; }

  public getLink(route: string): string {
    return `/${route}`;
  }

  public pesosToUf(amount: number, uf: number): number {
    return Math.round(amount / uf * 100) / 100;
  }

  public setModalTexts(modalData: ModalData = DEFAULT_ERROR_MODAL_PROPS, error?: ErrorResponse | ValidateSecurityResponse): ModalData {
    const data = cloneDeep(modalData);

    if (this.isUnauthorizedError(error)) { error.title = ''; }
    if (error && error.title) { data.title = error.title; }
    if (error && error.message) { data.message = error.message; }

    return data;
  }

  public cleanAmount(amount: number): string {
    return String(amount).replace('.', ',');
  }

  public isTaxCertificate(certificateType: string): boolean {
    return TAX_CERTIFICATES.some((certificate) => certificate.type === certificateType);
  }

  public isTransactionalCertificate(certificateType: string): boolean {
    return TRANSACTIONAL_CERTIFICATES.some((certificate) => certificate.type === certificateType);
  }

  public isGeneralCertificate(certificateType: string): boolean {
    return GENERAL_CERTIFICATES.some((certificate) => certificate.type === certificateType);
  }

  public isRemunerationsCertificate(certificateType: string): boolean {
    return (certificateType === TRANSACTIONAL_CERTIFICATES_TYPE.remunerations);
  }
  public isUnquotedCertificate(certificateType: string): boolean {
    return (certificateType === TRANSACTIONAL_CERTIFICATES_TYPE.unquoted);
  }
  public isHardworksCertificate(certificateType: string): boolean {
    return (certificateType === TRANSACTIONAL_CERTIFICATES_TYPE.hardworks);
  }
  public isMovementsCertificate(certificateType: string): boolean {
    return (certificateType === TRANSACTIONAL_CERTIFICATES_TYPE.movements);
  }

  public mandatoryAccount(accounts: Array<Account | DetailedAccount>): Account | DetailedAccount {
    return accounts.find((account: Account | DetailedAccount) => account.type === MANDATORY_ACCOUNT_TYPE);
  }

  public asDate(dateString: string, formatString = 'DD/MM/YYYY'): moment.Moment {
    return moment(dateString, formatString);
  }

  public isExpressVersion(): boolean {
    return environment.expressVersion;
  }

  public isControlDirtyOrTouched(control: AbstractControl): boolean {
    return control.dirty || control.touched;
  }

  public validatorClass(control: AbstractControl, error: string): string {
    if (!this.isControlDirtyOrTouched(control) || !control.value) { return ''; }
    return control.hasError(error) ? 'invalid' : 'valid';
  }

  public getValidatorsValue(passwordType: string) {
    if (passwordType === PASSWORD_TYPES.access) {
      return {
        minLetters: PASSWORD_MIN_LETTERS,
        minDigits: PASSWORD_MIN_DIGITS,
        repeatedCharacters: PASSWORD_REPETITIVE_LENGTH,
        consecutiveNumbers: PASSWORD_CONSECUTIVE_LENGTH,
        minLength: PASSWORD_MIN_LENGTH,
        maxLength: PASSWORD_MAX_LENGTH,
        minSpecialChar: PASSWORD_SPECIAL_CHAR,
        minLowerChar: PASSWORD_LOWER_CHAR,
        minUpperChar: PASSWORD_UPPER_CHAR
      };
    }
    return {
      minLowerChar: SECURITY_KEY_LOWER_CHAR,
      minUpperChar: SECURITY_KEY_UPPER_CHAR,
      minDigits: MIN_LETTERS_SECURITY_KEY,
      repeatedCharacters: REPETITIVE_LENGTH_SECURITY_KEY,
      consecutiveNumbers: CONSECUTIVE_LENGTH_SECURITY_KEY,
      minLength: MIN_LENGTH_SECURITY_KEY,
      maxLength: MAX_LENGTH_SECURITY_KEY,
      noUpperCaseOrSpecialChar: SECURITY_KEY_UPPER_CHAR_NONE,
    };
  }


  public accentsRemove(text: string): string {
    if (!text) return '';
    return Object.keys(this.accentsMap)
      .reduce((accumulate, current) => accumulate.replace(new RegExp(this.accentsMap[current], 'g'), current), text);
  }

  public generatePathWithAccount(account: DetailedAccount) {
    let accountName = account.description.toLowerCase();
    accountName = this.accentsRemove(accountName);
    return accountName.replace(WHITESPACE_PATTERN, '-');
  }

  public callContactCenter(): void {
    document.location.href = `tel:${this.contactCenterNumber}`;
  }

  public getAccountType(accountId: string): string {
    return accountId.toUpperCase() === APV_ACCOUNT_TYPE ? 'apv' : 'cav';
  }

  public inputAmountFormat(inputValue: number, inputType: string): string {
    inputValue = inputValue || 0;
    const precision = [UF_CURRENCY_TYPE, UTM_CURRENCY_TYPE].includes(inputType as currencyInputType) ? 2 : 0;
    const value = this.formatNumber(inputValue, precision) || 0;

    const formatted = {
      U: value + ' UF',
      P: '$' + value,
      R: value + '%',
      PI: value,
      T: value + ' UTM',
      '%': value + '%',
    };

    return formatted[inputType] || '';
  }

  public sumUpByProperty(items: any[], attr: string): number {
    return items.reduce((a, b) => a + b[attr], 0);
  }

  public cleanInvalidCharacter(value: string, currencyType: currencyInputType): number {
    if ([UF_CURRENCY_TYPE, UTM_CURRENCY_TYPE].includes(currencyType)) {
      const inputArray = value.replace(NUMBERS_COMMAS_PATTERN, '').split(',');
      value = inputArray.shift() + '.' + inputArray.join('');
    } else {
      value = value.replace(ONLY_NUMBERS_PATTERN, '');
    }
    return Number(value);
  }

  public addSymbols(value: string, currencyType: currencyInputType): string {
    const inputValue = this.cleanInvalidCharacter(value, currencyType);
    return this.inputAmountFormat(inputValue, currencyType);
  }

  public removeSymbols(value: string, currencyType: currencyInputType): string {
    const regex = [UF_CURRENCY_TYPE, UTM_CURRENCY_TYPE].includes(currencyType) ? NUMBERS_COMMAS_PATTERN : ONLY_NUMBERS_PATTERN;
    return value !== '0' ? value.replace(regex, '') : '';
  }

  public amountValue(value: string, currencyType: currencyInputType = PESOS_CURRENCY_TYPE): number {
    if (!value) return 0;
    value = this.removeSymbols(value, currencyType);
    return Number([UF_CURRENCY_TYPE, UTM_CURRENCY_TYPE].includes(currencyType) ?
      value.replace(',', '.') :
      value.replace(ONLY_NUMBERS_PATTERN, ''));
  }

  public onlyLettersAndNumbers(name: string) {
    return new RegExp(CLAIM_FILE_REG_NAME).test(name);
  }

  public renameFile(name: string) {
    name = name.replace(FILE_REG_RENAME, '');
    name = name.replace('_', '');
    return name;
  }

  public getFullName(name: string, lastName: string, secondSurname?: string): string {
    name = name.trim();
    lastName = lastName.trim();
    secondSurname = secondSurname ? secondSurname.trim() : '';

    let fullName = `${name} ${lastName}`.trim();
    fullName += ` ${secondSurname}`;

    return fullName.trim();
  }

  public showRoute(route: string): boolean {
    route = route.replace(SLASH_PATTERN, '').split('?')[0];
    const show = environment.routesConfig?.[route];
    return show === undefined ? true : show;
  }

  public createFormData(object: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();

    for (const property in object) {
      if (!object.hasOwnProperty(property) || this.isNullOrUndefined(object[property])) continue;

      const attribute = object[property];

      const formKey = namespace ? `${namespace}[${property}]` : property;

      if (this.isFormFiles(attribute)) attribute.data.forEach((file: string) => formData.append(formKey, file));
      else if (this.isObjectType(attribute) && !this.isFile(attribute)) this.createFormData(attribute, formData, formKey);
      else formData.append(formKey, attribute);
    }

    return formData;
  }

  public getLocaleMonthString(date: string): string {
    return (new Date(date)).toLocaleString('es-CL', { month: 'long' });
  }

  public getBackgrounds(form: UntypedFormGroup): Array<Backgrounds> {
    const backgrounds: Array<Backgrounds> = [];
    Object.entries(form.value).forEach(([key, value]) => {
      if (typeof value !== 'boolean' || !value) return;

      const option = form.value[`${key}Option`];
      const description = form.value[`${key}Text`];

      const background = { id: key as backgroundKeys } as Backgrounds;
      if (description) background['description'] = description;
      if (option) background['option'] = option;

      backgrounds.push(background);
    });
    return backgrounds;
  }

  private isFormFiles(object: any): boolean {
    return object instanceof FormFiles;
  }

  private isFile(object: any): boolean {
    return object instanceof File;
  }

  private isObjectType(object: any): boolean {
    return typeof object === 'object';
  }

  private isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  public updateValidators(control: AbstractControl, validator: Array<ValidatorFn>): void {
    control.setValidators(validator);
    control.updateValueAndValidity();
  }

  public isChecked(control: AbstractControl, code: string): boolean {
    return control.value === code;
  }

  public inputHasChanged(value: SimpleChange): boolean {
    if (!value) return false;
    return JSON.stringify(value.previousValue) !== JSON.stringify(value.currentValue);
  }

  public transferParamsBackgrounds(transferParams: TransferValues): Backgrounds[] {
    if (transferParams.accounts?.length) {
      const backgrounds = [{ id: 'accountTransfer' }] as Backgrounds[];

      if (transferParams.accounts.includes(APV_ACCOUNT_TYPE)) backgrounds.push({ id: 'voluntaryAccountsPlanVital' });
      if (transferParams.accounts.includes(CAV_ACCOUNT_TYPE)) backgrounds.push({ id: 'transferAccountsPlanVital' });

      return backgrounds;
    }
    return [];
  }

  private isUnauthorizedError(error?: ErrorResponse | ValidateSecurityResponse): boolean {
    return error instanceof ErrorResponse && error?.statusCode === HTTP_ERROR_CODES.unauthorized.code;
  }

  public isVirtualAccount(accountNumber: string, bankCode: string): boolean {
    const prefix: string = VIRTUAL_ACCOUNTS_BANKS[bankCode];
    if (!prefix) { return false; }
    const accountNumberWithoutZero = accountNumber.replace(/^0+/, '');
    return accountNumberWithoutZero.startsWith(prefix);
  }

  public styleStatusBar(): void {
    StatusBar.setBackgroundColor({
      color: STATUS_BAR_STYLE.backgroundColor
    });

    StatusBar.setStyle({
      style: Style.Light
    });
  }

  public get isIosPlatform(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }

  public get isAndroidPlatform(): boolean {
    return Capacitor.getPlatform() === 'android';
  }

  public hideSplashScreen(): void {
    SplashScreen.hide();
  }

  public async getPushNotificationsToken(): Promise<string> {
    const { value: token } = await Preferences.get({ key: 'pushNotToken' });
    return token;
  }

  public async getUserData(): Promise<UserStorage> {
    const data = await Preferences.get({ key: 'user' });
    return JSON.parse(data.value) as UserStorage;
  }

  public getPascalFirstName(name: string) {
    const firstName = name.split(' ')[0];
    return firstName.substr(0, 1).toUpperCase() + firstName.substr(1, firstName.length).toLowerCase();
  }

  public setUserData(user: UserStorage): void {
    Preferences.set({
      key: 'user',
      value: JSON.stringify(user)
    });
  }

  public async refreshUserPassword(userRut: string, newPassword: string): Promise<void> {
    const userData = await this.getUserData();
    if (userData && userData.rut === userRut) {
      this.setUserData({ ...userData, password: newPassword });
    }
  }

  public async setStorage(key: string, value: any): Promise<void> {
    return await Preferences.set({ key, value: JSON.stringify(value) });
  }

  public async getStorage(key: string): Promise<any> {
    const res = await Preferences.get({ key });
    return JSON.parse(res.value);
  }

  public async removeStorage(key: string): Promise<void> {
    return await Preferences.remove({ key });
  }

  public async clearStorage(): Promise<void> {
    return await Preferences.clear();
  }

  public isHalfDayOfMonth(date: Date): boolean {
    return date.getDate() === 15;
  }

  public isLastDayOfMonth(date: Date): boolean {
    return date.getDate() === this.getLastDayOfMonth(date);
  }

  public getLastDayOfMonth(date: Date): number {
    return (new Date(date.getFullYear(), date.getMonth() + 1, 0)).getDate();
  }

  public isDayNumberOfWeek(date: Date, dayNumber: number): boolean {
    return date.getDay() === dayNumber;
  }

  public getDate(date: string): Date {
    return new Date(date);
  }

  public getISODate(): string {
    return new Date().toISOString().substring(0, 10).toString();
  }

  public getMonthTranslate(monthNumber: string, language?: string): string {
    if (!monthNumber) { return ''; }
    switch (language) {
      case 'ht':
        return `${MONTHS_HT[monthNumber]}.`;
      case 'en':
        return `${MONTHS_EN[monthNumber]}.`;
      default:
        return `${MONTHS_ES[monthNumber]}.`;
    }
  }

  public getSecondsBetweenDates(firstDate: Date, secondDate: Date): number {
    return (firstDate.getTime() - secondDate.getTime()) / 1000;
  }

  // cambiar a true para mostrar el relogin
  public async isBiometricAvailable(): Promise<boolean> {
    const availableResult = this.isNative ? await this.fingerprintAIO.isAvailable().catch(() => '') : '';
    return availableResult === 'biometric' || availableResult === 'finger' || availableResult === 'face';
  }

  public stringToHslPastelColor(str: string): string {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    let num = 0;
    for (let i = 0; i < str.length; i++) {
      num += str.charCodeAt(i);
    }
    const hue = Math.floor(num % 360);
    return 'hsl(' + hue + ', 50%, 50%)';
  }

  public getFormattedDate(date: Date, dateFormat = 'yyyy-MM-dd'): string {
    return formatDate(date, dateFormat, 'es-CL');
  }

  public getTranslatedErrorMessage(error: ErrorResponse): string {
    if (error.messageHt && localStorage.getItem(LANGUAGE_KEY) !== null) {
      const language = localStorage.getItem(LANGUAGE_KEY);
      if (language === CREOLE_KEY) { return error.messageHt; }
    }
    return error.message;
  }

  public getRutAsNumber(rut: string): number {
    return Number(rut.slice(0, -1));
  }

  public getRutDV(rut: string): string {
    return rut.slice(-1);
  }

  public displayProperty(value: any) {
    return value?.description;
  }

  public getFundClassBackground(fund: string): string {
    return `fund-${fund?.toLowerCase()}-background`;
  }

  public get platformCompiledFor(): string {
    if (environment.platformCompiledFor) { return environment.platformCompiledFor; }
    return 'web';
  }

  public stringReplaceAll(str: string, find: string, replace: string): string {
    return str.replace(
      new RegExp(find?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'),
      replace
    );
  }

  public slugifyFileName(str: string): string {
    let extensionFile = '';
    const newStringWithoutDots = str
      .trim()
      .toLowerCase()
      .split('.')
      .reduce(
        (acc, item) => {
          if (!FILE_EXTENSIONS.includes(item)) {
            acc.push(item);
          } else {
            extensionFile = item;
          }
          return acc;
        },
        ['']
      )
      .join('');

    const newFileName = newStringWithoutDots
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
      .replace(/\d+/g, '')
      .split('')
      .reduce(
        (acc, item) => {
          if (acc[acc.length - 1] !== item) {
            acc.push(item);
          }
          return acc;
        },
        ['']
      )
      .join('')
      .replace(/_/g, '-');

    return `${newFileName}.${extensionFile}`;
  }

  public canAccessElderly(userData: UserData): boolean {
    const minimumAge = userData.gender === MALE_GENDER ? PENSION_AGE_MONTHS.men : PENSION_AGE_MONTHS.women;
    const birthDate = moment(userData.birthdate);

    return moment().diff(birthDate, 'months') >= minimumAge;
  }

  public getLastCampaing(campaigns: Campaign[]): number {
    campaigns.sort((firstCampaing, secondCampaing) => firstCampaing.id - secondCampaing.id);
    return campaigns.indexOf(campaigns[campaigns.length - 1]);
  }


  public filterCavRegimesData(funds, regimeType: string) {
    const accountFunds = funds.map(fund => {
      return {
        name: fund.name,
        regime: fund.regime.filter(fundRegime => {
          if (regimeType === 'GENERAL') {
            return fundRegime.regime !== 'RETIRO10';
          }
          return fundRegime.regime === 'RETIRO10';
        })
      };
    }).filter(fund => fund.regime.length > 0);
    return accountFunds;
  }

  public cavRegimesAsAccounts(funds: any) {
    if (funds.length < 1) return;
    if (funds.length === 1) {
      const fundWithMinimumLength = this.oneRegimeFilter(funds[0]);
      return this.regimeAsAccounts(fundWithMinimumLength);
    } else {
      return this.twoOrMoreRegimesFilter(funds);
    }
  }

  public oneRegimeFilter(funds: Fund) {

    if (funds.regime.length <= 0) {
      return;
    }
    let sum = 0;
    let description: string;
    let name;
    const filteredFunds = [];
    const returnedRegime = [];
    funds.regime.forEach(regime => {
      if (regime) {
        name = funds.name;

        sum += regime.balance;
        description = regime.regime;
        filteredFunds.push(regime);
      }
      returnedRegime.push({ name, regime: filteredFunds });
    });

    return {
      sum,
      description,
      funds: returnedRegime[0]
    };
  }

  public twoOrMoreRegimesFilter(funds) {
    const regimeFunds = [];
    funds.forEach((fund, idx) => {
      regimeFunds.push(this.oneRegimeFilter(fund));
    });

    return this.regimeAsAccounts(regimeFunds);
  }

  public regimeAsAccounts(fund) {

    if (!Array.isArray(fund)) {
      return {
        balanceTotal: fund.sum,
        type: fund.description === 'RETIRO10' ? `${CAV_ACCOUNT_TYPE}_10` : CAV_ACCOUNT_TYPE,
        description: fund.description,
        funds: [fund.funds],
        cavUrl: fund.description === 'RETIRO10' ? CAV_10_NAME_PATH : CAV_NAME_PATH,
      };
    } else {
      const funds = fund?.map(element => {
        return {
          name: element.funds.name,
          regime: [element.funds.regime[0]]
        };
      });
      let totalBalance = 0;
      for (const insideFund of funds) {
        for (const regimeFund of insideFund.regime) {
          totalBalance += regimeFund.balance;
        }
      }

      return {
        balanceTotal: totalBalance,
        type: funds[0].regime[0].regime === 'RETIRO10' ? `${CAV_ACCOUNT_TYPE}_10` : CAV_ACCOUNT_TYPE,
        description: funds[0].regime[0].regime,
        funds,
        cavUrl: funds[0].regime[0].regime === 'RETIRO10' ? CAV_10_NAME_PATH : CAV_NAME_PATH,
      };
    }
  }

  public processAccounts(accounts) {
    const allCavAccounts = accounts.filter(account => account.type === CAV_ACCOUNT_TYPE)[0];
    const cavAccountsFiltered = this.filterCavRegimesData(allCavAccounts.funds, 'GENERAL');
    const cavAccounts10Filtered = this.filterCavRegimesData(allCavAccounts.funds, 'RETIRO10');

    const accountsCav = this.cavRegimesAsAccounts(cavAccountsFiltered);
    const accountsCav10 = this.cavRegimesAsAccounts(cavAccounts10Filtered);
    const processedAccounts = accounts.filter(account => account.type !== CAV_ACCOUNT_TYPE);
    processedAccounts.push(accountsCav, accountsCav10);

    const finalAccounts = processedAccounts.filter(account => account !== undefined);
    return finalAccounts;
  }

  setLocalStorage(name, value) {
    localStorage.setItem(name, value);
  }

  getLocalStorage(name) {
    return localStorage.getItem(name);
  }

  extractAfterSlash(str) {
    const index = str.indexOf('/');
    return index === -1 ? str : str.substring(index + 1);
  }
}
