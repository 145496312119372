import { Certificate, EmployerCertificateType } from '@interfaces/certificates.interface';

const pageKey = 'EMPLOYER_CERTIFICATES';

export const DEBT_CERTIFICATE_TYPE: EmployerCertificateType = 'debt';
export const EMPLOYEE_AFFILIATION_CERTIFICATE_TYPE: EmployerCertificateType = 'employeeAffiliation';
export const AFFILIATE_DEBT_CERTIFICATE_TYPE: EmployerCertificateType = 'affiliateDebt';
export const CLARIFICATION_BULLETIN_CERTIFICATE_TYPE: EmployerCertificateType = 'clarificationBulletin';

export const AVAILABLE_CERTIFICATES = [
  DEBT_CERTIFICATE_TYPE,
  EMPLOYEE_AFFILIATION_CERTIFICATE_TYPE,
  AFFILIATE_DEBT_CERTIFICATE_TYPE,
  CLARIFICATION_BULLETIN_CERTIFICATE_TYPE,
];

export const EMPLOYER_CERTIFICATES_CONTENT = {
  breadcrumb: `${pageKey}.BREADCRUMB`,
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  sendEmail: `${pageKey}.SEND_EMAIL`,
  backButtons: {
    home: `${pageKey}.BACK_BUTTONS.HOME`
  },
  messages: {
    successful: {
      title: `${pageKey}.MESSAGES.SUCCESSFUL.TITLE`,
      description: `${pageKey}.MESSAGES.SUCCESSFUL.DESCRIPTION`
    },
    error: {
      title: `${pageKey}.MESSAGES.ERROR.TITLE`,
      description: `${pageKey}.MESSAGES.ERROR.DESCRIPTION`,
    },
  },
  types: [
    {
      type: 'paidQuotes',
      name: `${pageKey}.TYPES.PAID_QUOTES.NAME`,
      description: `${pageKey}.TYPES.PAID_QUOTES.DESCRIPTION`,
    },
    {
      type: DEBT_CERTIFICATE_TYPE,
      name: `${pageKey}.TYPES.DEBT.NAME`,
      description: `${pageKey}.TYPES.DEBT.DESCRIPTION`,
    },
    {
      type: EMPLOYEE_AFFILIATION_CERTIFICATE_TYPE,
      name: `${pageKey}.TYPES.EMPLOYEE_AFFILIATION.NAME`,
      description: `${pageKey}.TYPES.EMPLOYEE_AFFILIATION.DESCRIPTION`,
    },
    {
      type: AFFILIATE_DEBT_CERTIFICATE_TYPE,
      name: `${pageKey}.TYPES.AFFILIATE_DEBT.NAME`,
      description: `${pageKey}.TYPES.AFFILIATE_DEBT.DESCRIPTION`,
    },
    {
      type: CLARIFICATION_BULLETIN_CERTIFICATE_TYPE,
      name: `${pageKey}.TYPES.CLARIFICATION_BULLETIN.NAME`,
      description: `${pageKey}.TYPES.CLARIFICATION_BULLETIN.DESCRIPTION`,
    },
  ] as Array<Certificate>,
  download: `${pageKey}.DOWNLOAD`,
  form: {
    title: `${pageKey}.FORM.TITLE`,
    buttons: {
      showCertificate: `${pageKey}.FORM.BUTTONS.SHOW_CERTIFICATE`
    },
    fields: {
      email: `${pageKey}.FORM.FIELDS.EMAIL`,
      employeeRut: `${pageKey}.FORM.FIELDS.EMPLOYEE_RUT`,
      clientRut: `${pageKey}.FORM.FIELDS.EMPLOYEE_RUT`,
      fromDate: `${pageKey}.FORM.FIELDS.FROM_DATE`,
      toDate: `${pageKey}.FORM.FIELDS.TO_DATE`,
      fromDatePlaceHolder: `${pageKey}.FORM.FIELDS.FROM_DATE_PLACE_HOLDER`,
      toDatePlaceHolder: `${pageKey}.FORM.FIELDS.TO_DATE_PLACE_HOLDER`,
    },
    errors: {
      required: `${pageKey}.FORM.ERRORS.REQUIRED`,
      invalidEmail: `${pageKey}.FORM.ERRORS.INVALID_EMAIL`,
      invalidRut: `${pageKey}.FORM.ERRORS.INVALID_RUT`,
    },
    successModal: {
      title: `${pageKey}.FORM.SUCCESS_MODAL.TITLE`,
      message: `${pageKey}.FORM.SUCCESS_MODAL.MESSAGE`,
    }
  }
};
