<div class="modal-close">
  <div (click)="dialogClose()" class="i-cross-gray"></div>
</div>
<div class="modal-container">
  <div class="modal-icon {{ iconName }}"></div>

  <h2 class="title-secondary">{{ title | translate }}</h2>
  <h3 *ngIf="!description" class="subtitle-secondary">{{ subtitle | translate }}</h3>
  <h4 *ngIf="description" class="description-secondary" [innerHTML]="description | translate "></h4>

  <div *ngIf="loadedData" class="resume-container">
    <div class="label-section">
      <h3 [ngClass]="font?.size">RUT</h3>
      <h3 [ngClass]="font?.size">{{ content?.withdrawalAmount | translate }}</h3>
      <h3 [ngClass]="font?.size">{{ content?.withdrawalType | translate }}</h3>
      <h3 [ngClass]="font?.size">{{ content?.withdrawalAccount | translate }}</h3>
    </div>
    <div class="data-section">
      <h3 [ngClass]="font?.size">{{ util.rutFormat(dataResume.rut) }}</h3>
      <h3 [ngClass]="font?.size">{{ util.formatCurrency(dataResume.amount) }}</h3>
      <h3 [ngClass]="font?.size">{{ content?.transfer | translate }}</h3>
      <h3 [ngClass]="font?.size">{{ withdrawalType | translate }}<br>
        {{ bankName }} <br>
        {{ methodName }} <br>
        Nº {{ dataResume.accountNumber }}</h3>
    </div>
  </div>


    <div class="advice-container">
      <div class="i-advice"></div>
      <div class="advice" [ngClass]="font?.size" [innerHTML]="advice | translate"></div>
    </div>

  <div class="buttons-container">
    <button class="btn large primary" [ngClass]="font?.size" (click)="primaryAction()"
      [innerHTML]="firstBtnText | translate"></button>
    <button class="btn large" [ngClass]="font?.size" *ngIf="showSecondaryButton" (click)="secondaryAction()">{{
      secondBtnText | translate }}</button>
  </div>
</div>