import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalFacialValidationComponent } from '@components/modal-facial-validation/modal-facial-validation.component';
import { Trolley } from '@interfaces/trolley.interface';
import { CANCELED_PROCESS, MODAL_FACIAL_VALIDATION_CONTENT } from '@pages-content/modal-facial-validation.constant';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-id-capture-guidelines',
  templateUrl: './id-capture-guidelines.component.html',
  styleUrls: ['./id-capture-guidelines.component.scss'],
})
export class IdCaptureGuidelinesComponent {
  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Input() trolley: Trolley;

  public pageContent = MODAL_FACIAL_VALIDATION_CONTENT;

  constructor(
    public font: FontService,
    public dialogRef: MatDialogRef<ModalFacialValidationComponent>,
  ) { }

  public cancel(): void {
    this.dialogRef.close(CANCELED_PROCESS);
  }

  public continue(): void {
    this.nextStep.emit();
  }

}
