import { Mock } from '@interfaces/mock.interface';

export const GET_INCOME_MOCK: Mock = {
  success: [{
    response: {
      income: 1500000,
    }
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }],
};
