import { Mock } from '@interfaces/mock.interface';

export const PENSION_ADMINISTRATORS_MOCKUP: Mock = {
  success: [{
    response: [
      {
        description: 'AFP CUPRUM S.A.',
        id: '1003'
      },
      {
        description: 'AFP HABITAT',
        id: '1005'
      },
      {
        description: 'AFP PROVIDA',
        id: '1008'
      },
      {
        description: 'AFP PLANVITAL S.A.',
        id: '1032'
      },
      {
        description: 'AFP CAPITAL S.A.',
        id: '1033'
      },
      {
        description: 'AFP MODELO',
        id: '1034'
      },
      {
        description: 'AFP UNO',
        id: '1035'
      },
    ],
  }],
  failures: [{
    response: {}
  }]
};
