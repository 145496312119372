import { Mock } from '@interfaces/mock.interface';

export const GET_RELATIONSHIPS: Mock = {
  success: [{
    response: [
      {
        id: '1',
        description: 'Madre',
      },
      {
        id: '2',
        description: 'Padre',
      },
      {
        id: '3',
        description: 'Hijo',
      },
    ]
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }],
};
