import { Mock } from '@interfaces/mock.interface';

export const APPLICATION_TYPES_MOCK: Mock = {
  success: [{
    response: [
      {
        id: 'SAPV',
        description: 'SOLICITUD DE SUSCRIPCION AHORRO PREVISIONAL VOLUNTARIO'
      },
      {
        id: 'SCLVSEG',
        description: 'SOLICITUD DE CLAVE DE SEGURIDAD'
      },
      {
        id: 'SMODSA',
        description: 'SOLICITUD DE MODIFICACIÓN DE SUSCRIPCIÓN ALTERNATIVA DE AHORRO'
      },
      {
        id: 'SMVCFP',
        description: 'SOLICITUD DE CAMBIO DE FONDOS DE PENSIONES'
      },
      {
        id: 'SMVDFP',
        description: 'SOLICITUD DE DISTRIBUCIÓN DE FONDO DE PENSIONES'
      },
      {
        id: 'SRAPV',
        description: 'SOLICITUD DE RETIRO AHORRO PREVISIONAL VOLUNTARIO'
      },
      {
        id: 'SRCAI',
        description: 'SOLICITUD DE RETIRO CUENTA AHORRO INDEMNIZACION'
      },
      {
        id: 'SRCAV',
        description: 'SOLICITUD DE RETIRO CUENTA DE AHORRO VOLUNTARIO'
      },
      {
        id: 'SRECL',
        description: 'SOLICITUD DE RECLAMO'
      },
      {
        id: 'SRVAPV',
        description: 'SOLICITUD DE REVOCACIÓN AHORRO PREVISIONAL VOLUNTARIO'
      },
      {
        id: 'pension-vejez-normal',
        description: 'SOLICITUD DE PENSION DE VEJEZ'
      },
      {
        id: 'pension-vejez-anticipada',
        description: 'SOLICITUD DE PENSION DE VEJEZ ANTICIPADA'
      },
      {
        id: 'pension-invalidez',
        description: 'SOLICITUD DE PENSION DE INVALIDEZ'
      },
      {
        id: 'retiro-fondos-tecnicos-extranjeros',
        description: 'SOLICITUD DE RETIRO DE FONDOS TÉCNICOS EXTRANJERO'
      },
      {
        id: 'transferencia-cav',
        description: 'SOLICITUD DE TRANSFERENCIA DE FONDOS PARA DEPÓSITOS CONVENIDOS Y APV'
      },
      {
        id: 'transferencia-cuentas-pension',
        description: 'SOLICITUD DE TRANSFERENCIA DE CUENTAS'
      },
      {
        id: 'solicitud-calculo-excedente-libre-disposicion',
        description: 'SOLICITUD DE CALCULO DE EXCEDENTE DE LIBRE DISPOSICIÓN'
      },
      {
        id: 'solicitud-pago-excedente-libre-disposicion',
        description: 'SOLICITUD DE PAGO DE EXCEDENTE DE LIBRE DISPOSICIÓN'
      },
      {
        id: 'pension-sobrevivencia',
        description: 'SOLICITUD DE PENSIÓN DE SOBREVIVENCIA'
      },
      {
        id: 'reevaluacion-invalidez',
        description: 'SOLICITUD DE REEVALUACIÓN DE INVALIDEZ'
      },
      {
        id: 'devolucion-pago-exceso',
        description: 'SOLICITUD DE DEVOLUCIÓN DE PAGO EN EXCESO'
      },
      {
        id: 'asignacion-familiar',
        description: 'SOLICITUD DE ASIGNACIÓN FAMILIAR'
      },
      {
        id: 'garantia-estatal',
        description: 'SOLICITUD DE GARANTÍA ESTATAL'
      },
      {
        id: 'cuota-mortuoria',
        description: 'SOLICITUD DE CUOTA MORTUORIA'
      },
      {
        id: 'acreditacion-estudios',
        description: 'SOLICITUD DE ACREDITACIÓN DE ESTUDIOS'
      },
      {
        id: 'herencia',
        description: 'SOLICITUD DE HERENCIA'
      },
      {
        id: 'cobro-honorarios',
        description: 'SOLICITUD DE COBRO DE HONORARIOS'
      },
    ],
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};
