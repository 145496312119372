import { Mock } from '@interfaces/mock.interface';



export const DOCUMENT_VALIDATION_MOCKUP: Mock = {
  success: [{
    response: {
      scanReference: '1234-5678-90'
    }
  }],
  failures: []
};

export const FACIAL_VALIDATION_MOCKUP: Mock = {
  success: [{
    response: {
      statusFace: 'Live',
      statusDNI: 'Positive'
    }
  }],
  failures: []
};

export const FACIAL_SUCCESS_CONFIRMATION_MOCKUP: Mock = {
  success: [{
    response: {},
  }],
  failures: []
};

export const TROLLEY_AUTHORIZE_MOCKUP: Mock = {
  success: [{
    response: {
      token: '482bc25324de0d81dd7a266b58129c68b5c79e0b0e49927ab7a85141b7dc1e5c82bf8a96ed633e0446d0830deebcc77cadcbaf50e3534561e0e43e8dfcc6dd0a7ac38279754bc5d909d93c5e23fb6d60f876263de7a4500ff4abe574b9a7953ef23ceb35da63f4a016ef500ba881ca79d59c3398f515e9351098b6bcc53b1ff026d1bc94b35537c12189e733208dad1df97113eecdc2eb8820872c07e1ada193f8db3f3a7889fd53f4a6564ff315662bf7ec3ade1fee073b9c92404362a9fa86e843',
    }
  }],
  failures: []
};

export const FacialBiometricServiceMock = {
  documentValidation: () => { },
  facialValidation: () => { }
};
