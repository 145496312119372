// eslint-disable-next-line max-len
export const EMAIL_PATTERN = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
export const PHRASES_WITH_LETTERS_NUMBERS_REGEX = /^[a-zA-ZñÑ0-9 ]*$/;
export const ALPHANUMERIC_PATTERN = /^[a-zA-ZñÑ0-9]*$/;
export const NUMERIC_PATTERN = /^[0-9]*$/;
export const PHONE_PATTERN = /^[1-9][0-9]*$/;
export const ADDRESS_PATTERN = /^[A-Za-záéíóúüÁÉÍÓÚÜñÑ0-9.,\-\/ ]*$/;
export const ACCOUNT_NUMBER_PATTERN = /^[a-zA-Z0-9]*$/;
export const WHITESPACE_PATTERN = /\s/g;
export const ONLY_NUMBERS_PATTERN = /\D+/g;
export const NUMBERS_COMMAS_PATTERN = /[^0-9,]/gm;
export const SLASH_PATTERN = /^\/+|\/+$/g;
export const CLAIM_FILE_REG_NAME = '^[a-zA-Z0-9]+$';
export const FILE_REG_RENAME = /\W/g;
export const VALID_TEXT_REGEX = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ'\-\ ]+$/;
export const ALPHABETIC_PATTERN = /^[A-Za-záéíóúüÁÉÍÓÚÜñÑ ]*$/;
export const LOWERCASE_PATTERN = /^[a-záéíóúñü]*$/;
