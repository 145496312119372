import { Mock } from '@interfaces/mock.interface';

export const PAYMENT_METHODS_MOCKUP: Mock = {
  success: [{
    response: [
      {
        id: '3',
        description: 'Cuenta Corriente'
      },
      {
        id: '4',
        description: 'Vale Vista'
      },
      {
        id: '40',
        description: 'Efectivo Banco Estado'
      },
      {
        id: '41',
        description: 'Efectivo Servipag'
      },
      {
        id: '5',
        description: 'Cuenta Ahorro'
      },
      {
        id: '8',
        description: 'Cuenta Vista/Rut'
      },
    ]
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }],
};
