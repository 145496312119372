<div class="modal-close" *ngIf="closeBtn">
  <div class="i-cross-gray" (click)="dialogClose()"></div>
</div>
<div class="modal-container">
  <div class="container-text text-confirm">
    <p *ngIf="cardData.title" class="text-confirm title" [innerHTML]="cardData.title | translate"></p>
    <p *ngIf="cardData.subtitle" class="text-confirm subtitle" [innerHTML]="cardData.subtitle | translate"></p>
    <p *ngIf="cardData.message" class="text-confirm message" [innerHTML]="cardData.message | translate"></p>
  </div>


  <!-- Estructura base a replicar de asistencia en trámite con estilo de cards -->
  <div id="call" class="contact-option" (click)="selectOptionChannel('sms')" >
    <div class="icon-option i-smartphone-sms"></div>
    <div class="title">
      <h3>{{ cardData.data?.smsTitle | translate }}</h3>
      <!-- <p [innerHTML]="'pageContent.mainOptionsAssist.phoneCall.description | translate'"></p> -->
    </div>
    <div class="divider-container">
      <mat-divider class="divider" [vertical]="true"></mat-divider>
    </div>
    <div class="attention-hours">
      <p [innerHTML]="cardData.data?.smsValue"></p>
    </div>
    <div class="i-arrow-double-red"></div>
  </div>

  <!-- Estructura base a replicar de asistencia en trámite con estilo de cards -->
  <div id="call" class="contact-option" (click)="selectOptionChannel('email')" >
    <div class="icon-option i-notifications-email"></div>
    <div class="title">
      <h3>{{ cardData.data?.mailTitle | translate }}</h3>
      <!-- <p [innerHTML]="'pageContent.mainOptionsAssist.phoneCall.description | translate'"></p> -->
    </div>
    <div class="divider-container">
      <mat-divider class="divider" [vertical]="true"></mat-divider>
    </div>
    <div class="attention-hours">
      <p [innerHTML]="cardData.data?.mailValue"></p>
    </div>
    <div class="i-arrow-double-red"></div>
  </div>

  <!-- <div [ngClass]="{ 'card-activated': selectedOption === 'email', 'card-disabled': selectedOption !== 'email' }"
    (click)="selectOptionChannel('email')">
    <div class="content">
      <img *ngIf="selectedOption === 'email'" class="icon-image"
        src="../../../../assets/icons/notify-mail-icon-white.svg" alt="">
      <img *ngIf="selectedOption !== 'email'" class="icon-image" src="../../../../assets/icons/notify-mail-icon-red.svg"
        alt="">
      <div class="text">
        <p [innerHTML]="cardData.data?.mailTitle | translate"></p>
        <p>{{cardData.data?.mailValue}}</p>
      </div>
    </div>
  </div>

  <div [ngClass]="{ 'card-activated': selectedOption === 'sms', 'card-disabled': selectedOption !== 'sms' }"
    (click)="selectOptionChannel('sms')" *ngIf="cardData.data?.smsValue !== ''">
    <div class="content">
      <img *ngIf="selectedOption === 'sms'" class="icon-image"
        src="../../../../assets/icons/notify-phone-icon-white.svg" alt="">
      <img *ngIf="selectedOption !== 'sms'" class="icon-image" src="../../../../assets/icons/notify-phone-icon-red.svg"
        alt="">
      <div class="text">
        <p [innerHTML]="cardData.data?.smsTitle | translate"></p>
        <p>{{cardData.data?.smsValue}}</p>
      </div>
    </div>
  </div> -->

  <!-- <div class="buttons-container">
    <button class="btn large" [ngClass]="font?.size" (click)="secondaryAction()">{{
      secondBtnText | translate }}</button>
    <button class="btn large primary" [disabled]="!selectedOption" [ngClass]="font?.size" (click)="primaryAction()"
      [innerHTML]="firstBtnText | translate"></button>
  </div> -->
</div>
<app-loading [subcomponent]="true" *ngIf="isLoading"></app-loading>