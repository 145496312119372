import { Mock } from '@interfaces/mock.interface';
import { PreviredQuestion } from '@interfaces/security-questions.interface';

export const GET_PREVIRED_QUESTIONS_MOCK: Mock = {
  success: [{
    response: [
      {
        id: 432,
        description: '¿En qué ciudad naciste?',
      },
      {
        id: 433,
        description: '¿Cuál es el segundo nombre de tu madre?',
      },
    ] as Array<PreviredQuestion>
  }],
  failures: [{
    response: {
      statusCode: 400,
      message: 'La solicitud no se ha podido realizar',
      messageDescription: '',
    },
  }]
};
