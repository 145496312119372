/* eslint-disable @typescript-eslint/ban-types */
import { Component, ElementRef, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OTP_ACCOUNT2, OTP_APV, TRACEABILITY } from '@constants/constants';
import { DEFAULT_ERROR_MODAL_PROPS } from '@constants/modal-data.constants';
import { ErrorResponse } from '@interfaces/error-response.model';
import { ModalData } from '@interfaces/modal-data.interface';
import { OtpValidateKey } from '@interfaces/otp-dynamic-key.interface';
import { FontService } from '@providers/font/font.service';
import { ModalProvider } from '@providers/modal/modal';
import { TraceabilityBgProvider } from '@providers/traceability-bg/traceability-bg.provider';
import { OtpDynamicKeyService } from '@services/otp-dynamic-key/otp-dynamic-key.service';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-modal-dynamic-key-validate',
    templateUrl: './modal-dynamic-key-validate.component.html',
    styleUrls: ['./modal-dynamic-key-validate.component.scss'],
})
export class ModalDynamicKeyValidateComponent {
    // Only to line break
    @ViewChildren('inputRefs') inputRefs: QueryList<ElementRef>;

    public firstBtnText: string;
    public secondBtnText: string;

    private primaryCallback: (res: any) => {};
    private secondaryCallback: (res: any) => {};
    private closeCallback: () => {};

    public cardData: any;
    public closeBtn = true;

    public securityCode: string[] = ['', '', '', '', '', ''];
    public securityValues: string[] = ['', '', '', '', '', ''];
    public concatenatedCode = '';

    public isLoading = false;

    remainingTime = 300;
    buttonEnabled = false;

    subscription: Subscription;

    messageValidation = false;

    constructor(
        public dialogRef: MatDialogRef<ModalDynamicKeyValidateComponent>,
        public font: FontService,
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
        private otpDynamicKeyService: OtpDynamicKeyService,
        private readonly modalProvider: ModalProvider,
        private traceabilityBgProvider: TraceabilityBgProvider
    ) {
        Object.keys(data).forEach(key => this[key] = data[key]);
        this.cardData = data;
        this.startCounter();
        setTimeout(() => this.inputRefs.first.nativeElement.focus(), 0);
    }

    public primaryAction(): void {
        this.validateCode();
    }

    public secondaryAction(param?: string): void {
        this.dialogClose();
        this.dialogRef.afterClosed().subscribe(() => this.secondaryCallback(param));
    }

    public dialogClose(): void {
        this.dialogRef.close();
        if (this.closeCallback) this.closeCallback();
    }

    validateCode() {
        this.isLoading = true;
        const otpValidateKey = {
            site: 'privateSite',
            module: this.cardData.data?.module,
            rut: this.cardData.data?.rut,
            id: this.cardData.data?.id,
            code: this.concatenatedCode
        } as OtpValidateKey;

      let customHeaders;
      if ([OTP_ACCOUNT2, OTP_APV, OTP_ACCOUNT2].includes(this.cardData.data?.module)) {
        const functionalityId = this.traceabilityBgProvider.getFunctionalityId();
        customHeaders = this.traceabilityBgProvider.setTraceHeaderToBg(TRACEABILITY.MODULE.REQUESTS.ID, functionalityId);
      }
        this.otpDynamicKeyService.validaOtp(otpValidateKey, customHeaders).subscribe(res => {
            this.isLoading = false;
            if (res.success) {
                this.dialogClose();
                this.dialogRef.afterClosed().subscribe(() => this.primaryCallback(res.token));
            } else {
                this.messageValidation = true;
            }
        }, error => {
            this.messageValidation = true;
            this.isLoading = false;
            this.handleError(error);
        });
    }

  private handleError(error?: ErrorResponse): void {
    if (error.statusCode !== 400) {
      const modal = DEFAULT_ERROR_MODAL_PROPS;
      this.modalProvider.openModal(modal);
    }

    }

    getValue(index: string, value: any) {
        const inputElement = value.target as HTMLInputElement;
        this.securityValues[index] = inputElement.value;
        this.updateConcatenatedCode();
    }

    private updateConcatenatedCode(): void {
        this.concatenatedCode = this.securityValues.join('');
    }

    isSixLettersLong(): boolean {
        return this.concatenatedCode.length === 6;
    }

    startCounter() {
        const counter$ = new Observable(observer => {
            let counter = this.remainingTime;
            const intervalId = setInterval(() => {
                counter -= 1;
                observer.next(counter);
                if (counter === 0) {
                    observer.complete();
                    clearInterval(intervalId);
                }
            }, 1000);
            return {
                unsubscribe() {
                    clearInterval(intervalId);
                }
            };
        });

        this.subscription = counter$.pipe(
            take(this.remainingTime)
        ).subscribe({
            next: (value: number) => {
                this.remainingTime = value;
            },
            complete: () => {
                this.buttonEnabled = true;
            }
        });
    }

    onKeyUp(index: number, event: KeyboardEvent): void {
        const isNumber = /[0-9]/.test(event.key);
        if (isNumber && index < this.securityCode.length - 1) {
            this.inputRefs.toArray()[index + 1].nativeElement.focus();
        } else if (event.key === 'Backspace' && index > 0) {
            this.inputRefs.toArray()[index - 1].nativeElement.focus();
        } else if (isNumber && index === this.securityCode.length - 1) {
            this.inputRefs.toArray()[index].nativeElement.blur();
        }
    }

    validateNumber(event: KeyboardEvent): void {
        const pattern = /[0-9]/;
        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }

}
