const pageKey = 'CERTIFICATES';

export const CERTIFICATES_CONTENT = {
  breadcrumb: `${pageKey}.BREADCRUMB`,
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  sendEmail: `${pageKey}.SEND_EMAIL`,
  backButtons: {
    certificates: `${pageKey}.BACK_BUTTONS.CERTIFICATES`,
    home: `${pageKey}.BACK_BUTTONS.HOME`
  },
  messages: {
    successful: {
      title: `${pageKey}.MESSAGES.SUCCESSFUL.TITLE`,
      description: `${pageKey}.MESSAGES.SUCCESSFUL.DESCRIPTION`
    },
    loading: `${pageKey}.MESSAGES.LOADING`,
    error: {
      title: `${pageKey}.MESSAGES.ERROR.TITLE`,
      description: `${pageKey}.MESSAGES.ERROR.DESCRIPTION`,
    },
  },
  categories: {
    general: {
      name: `${pageKey}.CATEGORIES.GENERAL.NAME`,
      description: `${pageKey}.CATEGORIES.GENERAL.DESCRIPTION`,
    },
    transactional: {
      name: `${pageKey}.CATEGORIES.TRANSACTIONAL.NAME`,
      description: `${pageKey}.CATEGORIES.TRANSACTIONAL.DESCRIPTION`,
    },
    tax: {
      name: `${pageKey}.CATEGORIES.TAX.NAME`,
      description: `${pageKey}.CATEGORIES.TAX.DESCRIPTION`,
    },
    beneficiary: {
      name: `${pageKey}.CATEGORIES.BENEFICIARY.NAME`,
      description: `${pageKey}.CATEGORIES.BENEFICIARY.DESCRIPTION`,
    },
    beneficiaries: {
      name: `${pageKey}.CATEGORIES.BENEFICIARIES.NAME`,
      description: `${pageKey}.CATEGORIES.BENEFICIARIES.DESCRIPTION`,
    }
  },
  types: {
    general: {
      affiliate: {
        name: `${pageKey}.TYPES.GENERAL.AFFILIATE.NAME`,
        description: `${pageKey}.TYPES.GENERAL.AFFILIATE.DESCRIPTION`,
      },
      contingencyRecords: {
        name: `${pageKey}.TYPES.GENERAL.CONTINGENCY_RECORDS.NAME`,
        description: `${pageKey}.TYPES.GENERAL.CONTINGENCY_RECORDS.DESCRIPTION`,
      },
      residenceRecords: {
        name: `${pageKey}.TYPES.GENERAL.RESIDENCE_RECORDS.NAME`,
        description: `${pageKey}.TYPES.GENERAL.RESIDENCE_RECORDS.DESCRIPTION`,
      },
      vacations: {
        name: `${pageKey}.TYPES.GENERAL.VACATIONS.NAME`,
        description: `${pageKey}.TYPES.GENERAL.VACATIONS.DESCRIPTION`,
      },
      pensionCES: {
        name: `${pageKey}.TYPES.GENERAL.PENSION_CES.NAME`,
        description: `${pageKey}.TYPES.GENERAL.PENSION_CES.DESCRIPTION`,
      },
      notpensioned: {
        name: `${pageKey}.TYPES.GENERAL.NOTPENSIONED.NAME`,
        description: `${pageKey}.TYPES.GENERAL.NOTPENSIONED.DESCRIPTION`,
      },
      beneficiaryquality: {
        name: `${pageKey}.TYPES.GENERAL.BENEFICIARYQUALITY.NAME`,
        description: `${pageKey}.TYPES.GENERAL.BENEFICIARYQUALITY.DESCRIPTION`,
      },
      pensionedquality: {
        name: `${pageKey}.TYPES.GENERAL.PENSIONEDQUALITY.NAME`,
        description: `${pageKey}.TYPES.GENERAL.PENSIONEDQUALITY.DESCRIPTION`,
      },
    },
    transactional: {
      remunerations: {
        name: `${pageKey}.TYPES.TRANSACTIONAL.REMUNERATIONS.NAME`,
        description: `${pageKey}.TYPES.TRANSACTIONAL.REMUNERATIONS.DESCRIPTION`,
      },
      quotations: {
        name: `${pageKey}.TYPES.TRANSACTIONAL.QUOTATIONS.NAME`,
        description: `${pageKey}.TYPES.TRANSACTIONAL.QUOTATIONS.DESCRIPTION`,
      },
      unquoted: {
        name: `${pageKey}.TYPES.TRANSACTIONAL.UNQUOTED.NAME`,
        description: `${pageKey}.TYPES.TRANSACTIONAL.UNQUOTED.DESCRIPTION`,
      },
      hardworks: {
        name: `${pageKey}.TYPES.TRANSACTIONAL.HARDWORKS.NAME`,
        description: `${pageKey}.TYPES.TRANSACTIONAL.HARDWORKS.DESCRIPTION`,
      },
      movements: {
        name: `${pageKey}.TYPES.TRANSACTIONAL.MOVEMENTS.NAME`,
        description: `${pageKey}.TYPES.TRANSACTIONAL.MOVEMENTS.DESCRIPTION`,
      }
    },
    tax: {
      apv: {
        name: `${pageKey}.TYPES.TAX.APB.NAME`,
        description: `${pageKey}.TYPES.TAX.APB.DESCRIPTION`,
      },
      pen: {
        name: `${pageKey}.TYPES.TAX.PEN.NAME`,
        description: `${pageKey}.TYPES.TAX.PEN.DESCRIPTION`,
      },
      exc: {
        name: `${pageKey}.TYPES.TAX.EXC.NAME`,
        description: `${pageKey}.TYPES.TAX.EXC.DESCRIPTION`,
      },
      ind: {
        name: `${pageKey}.TYPES.TAX.IND.NAME`,
        description: `${pageKey}.TYPES.TAX.IND.DESCRIPTION`,
      },
      cdc: {
        name: `${pageKey}.TYPES.TAX.CDC.NAME`,
        description: `${pageKey}.TYPES.TAX.CDC.DESCRIPTION`,
      },
      ahoregop: {
        name: `${pageKey}.TYPES.TAX.AHOREGOP.NAME`,
        description: `${pageKey}.TYPES.TAX.AHOREGOP.DESCRIPTION`,
      },
      aho57bis:  {
        name: `${pageKey}.TYPES.TAX.AHO57BIS.NAME`,
        description: `${pageKey}.TYPES.TAX.AHO57BIS.DESCRIPTION`,
      },
      aho54bis: {
        name: `${pageKey}.TYPES.TAX.AHO54BIS.NAME`,
        description: `${pageKey}.TYPES.TAX.AHO54BIS.DESCRIPTION`,
      },
      rg: {
        name: `${pageKey}.TYPES.TAX.RG.NAME`,
        description: `${pageKey}.TYPES.TAX.RG.DESCRIPTION`,
      },
      penLiq: {
        name: `${pageKey}.TYPES.TAX.PENLIQ.NAME`,
        description: `${pageKey}.TYPES.TAX.PENLIQ.DESCRIPTION`,
      }
    }
  },
  download: `${pageKey}.DOWNLOAD`,
  viewDoc: `${pageKey}.VIEW_DOC`,
  form: {
    buttons: {
      showCertificate: `${pageKey}.FORM.BUTTONS.SHOW_CERTIFICATE`
    },
    controls: {
      year: {
        label: `${pageKey}.FORM.CONTROLS.YEAR.LABEL`
      },
      account: {
        label: `${pageKey}.FORM.CONTROLS.ACCOUNT.LABEL`
      },
      period: {
        label: `${pageKey}.FORM.CONTROLS.PERIOD.LABEL`,
        options: {
          twelve: `${pageKey}.FORM.CONTROLS.PERIOD.OPTIONS.TWELVE`,
          twentyFour: `${pageKey}.FORM.CONTROLS.PERIOD.OPTIONS.TWENTY_FOUR`,
          thirtySix: `${pageKey}.FORM.CONTROLS.PERIOD.OPTIONS.THIRTY_SIX`,
          fortyEight: `${pageKey}.FORM.CONTROLS.PERIOD.OPTIONS.FORTY_EIGHT`,
          sixty: `${pageKey}.FORM.CONTROLS.PERIOD.OPTIONS.SIXTY`,
          other: `${pageKey}.FORM.CONTROLS.PERIOD.OPTIONS.OTHER`
        }
      },
      fromDate: {
        label: `${pageKey}.FORM.CONTROLS.FROM_DATE.LABEL`
      },
      toDate: {
        label: `${pageKey}.FORM.CONTROLS.TO_DATE.LABEL`
      },
    }
  },
  modals: {
    emptyCertificate : {
      title: `${pageKey}.MODALS.EMPTY_CERTIFICATE.TITLE`,
      message: `${pageKey}.MODALS.EMPTY_CERTIFICATE.MESSAGE`,
    },
    emptyGeneralCertificate : {
      message: `${pageKey}.MODALS.EMPTY_GENERAL_CERTIFICATE.MESSAGE`,
    },
    noMandatoryAccount: {
      title: `${pageKey}.MODALS.NO_MANDATORY_ACCOUNT.TITLE`,
      message: `${pageKey}.MODALS.NO_MANDATORY_ACCOUNT.MESSAGE`,
    },
    certificates : {
      transactionalMessage: `${pageKey}.MODALS.CERTIFICATES.TRANSACTIONAL_MESSAGE`,
      taxMessage: `${pageKey}.MODALS.CERTIFICATES.TAX_MESSAGE`
    },
    duplicatedCavCreation : {
      title: `${pageKey}.MODALS.DUPLICATED_CAV_CREATION.TITLE`,
      message: `${pageKey}.MODALS.DUPLICATED_CAV_CREATION.MESSAGE`
    },
    emailData : {
      title: `${pageKey}.MODALS.EMAIL_DATA.TITLE`,
      message: `${pageKey}.MODALS.EMAIL_DATA.MESSAGE`,
      appMessage: `${pageKey}.MODALS.EMAIL_DATA.APP_MESSAGE`,
      appTargetMessage: `${pageKey}.MODALS.EMAIL_DATA.APP_TARGET_MESSAGE`,
    },
    successSentEmail : {
      title: `${pageKey}.MODALS.SUCCESS_SENT_EMAIL.TITLE`,
      message: `${pageKey}.MODALS.SUCCESS_SENT_EMAIL.MESSAGE`
    },
    failureSentEmail : {
      title: `${pageKey}.MODALS.FAILURE_SENT_EMAIL.TITLE`,
      message: `${pageKey}.MODALS.FAILURE_SENT_EMAIL.MESSAGE`
    }
  }
};
