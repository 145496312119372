import { Mock } from '@interfaces/mock.interface';

export const RECOVER_EMPLOYER_ACCESS_KEY: Mock = {
  success: [{
    response: {}
  }],
  failures: [{
    response: {
      code: 50001,
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      message: 'Error',
    },
  }, {
    response: {
      code: 40155,
      messageDescription: 'El correo no coincide con el registrado en AFP PlanVital',
      message: 'Error',
    },
  }],
};
