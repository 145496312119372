import { Mock } from '@interfaces/mock.interface';

export const INCOME_SOURCE_MOCKUP: Mock = {
  success: [{
    response: [
      { id: 'R', description: 'Ingresos Trabajador' },
      { id: 'H', description: 'Herencias' },
      { id: 'P', description: 'Premios' },
      { id: 'V', description: 'Venta de activos' },
      { id: 'O', description: 'Otros (Detallar)' },
    ],
  }],
  failures: [{
    response: {}
  }]
};
