import { Mock } from '@interfaces/mock.interface';

export const COUNTRIES_MOCKUP: Mock = {
  success: [{
    response: [
      { id: 'AD', name: 'ANDORRA' },
      { id: 'AE', name: 'EMIRATOS ARABES UNIDOS' },
      { id: 'CL', name: 'CHILE' },
    ],
  }],
  failures: [{
    response: {}
  }]
};
