<div class="modal-container">
  <div class="i-cross-gray" (click)="closeModal()"></div>
  <button class="btn back i-arrow-left" (click)="goBack()" *ngIf="!noOptionSelected">
    {{ pageContent?.buttons.back | translate }}
  </button>

  <div class="call-center-container">
    <div class="icon" *ngIf="!succesfullClick2Call"
      [ngClass]="isScreenshareSelected ? 'i-screen-share' : 'i-phone-red'"></div>
    <ng-container *ngIf="noOptionSelected">
      <p class="title" [ngClass]="font?.size" [innerHTML]="pageContent?.title | translate"></p>
      <p class="description dynamic-text" [ngClass]="font?.size" [innerHTML]="callCenterHours"></p>
      <div class="menu-buttons">
        <button class="btn large primary" [ngClass]="font?.size" (click)="util.callContactCenter()" >
          {{ pageContent?.buttons.normalCall | translate }}
        </button>
        <!-- Send call to genesys -->
        <button class="btn large primary" [ngClass]="font?.size" (click)="selectClick2Call()"
          [disabled]="!enableContactCenterButtons">
          {{ pageContent?.buttons.click2Call | translate }}
        </button>
        <button *ngIf="isScreenshareAvailable" class="btn large primary" [ngClass]="font?.size"
          (click)="selectScreenshare()" [disabled]="!enableContactCenterButtons">
          {{ pageContent?.buttons.cobrowsing | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="isClick2CallSelected">
      <ng-container *ngIf="!succesfullClick2Call">
        <h1 class="title-secondary" [ngClass]="font?.size">{{ click2CallContent?.title | translate }}</h1>
        <p class="description" [ngClass]="font?.size">{{ click2CallContent?.description | translate }}</p>

        <form [formGroup]="click2callForm">
          <div class="fields-group">
            <mat-form-field class="field" [ngClass]="font?.size">
              <mat-label [innerHTML]="click2CallContent?.form.phone | translate"></mat-label>
              <input [ngClass]="font?.size" matInput [maxlength]="phoneNumberLength" type="text" placeholder="223456789"
                formControlName="phone">
              <span matSuffix class="i-validator"></span>
              <mat-error [ngClass]="font?.size" *ngIf="phone?.hasError('required')">
                {{ click2CallContent?.errors.required | translate }}
              </mat-error>
              <mat-error [ngClass]="font?.size" *ngIf="phone?.hasError('minlength') || phone?.hasError('maxlength')">
                {{ click2CallContent?.errors.invalid | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="field" [ngClass]="font?.size">
              <mat-label [innerHTML]="click2CallContent?.form.reason | translate"></mat-label>
              <input [ngClass]="font?.size" matInput type="text" formControlName="reason">
              <span matSuffix class="i-validator"></span>
            </mat-form-field>
          </div>
        </form>

        <button class="btn large primary" [ngClass]="font?.size" [disabled]="click2callForm.invalid"
          (click)="submitClick2CallForm()">
          {{ pageContent?.buttons.send | translate }}
        </button>
        <div class="description" [ngClass]="font?.size" [innerHTML]="click2CallContent?.disclaimer | translate"></div>
      </ng-container>
      <ng-container *ngIf="succesfullClick2Call">
        <div class="icon i-check-outline"></div>
        <p class="title success-message" [ngClass]="font?.size"
          [innerHTML]="click2CallContent?.successMessage | translate"></p>
      </ng-container>
    </ng-container>

    <div class="cobrowsing-container" *ngIf="isScreenshareSelected">
      <p class="title" [ngClass]="font?.size" [innerHTML]="pageContent?.cobrowsing.title | translate"></p>
      <p class="description" [ngClass]="font?.size">{{ pageContent?.cobrowsing.description | translate }}</p>
      <p class="description enter-code-instruction" [ngClass]="font?.size">
        {{ pageContent?.cobrowsing.enterCodeInstruction | translate }}
      </p>
      <form [formGroup]="screenshareForm">
        <div class="fields-group screenshare-group">
          <mat-form-field class="field screenshare-input" [ngClass]="font?.size">
            <input [maxlength]="screenshareCodeMaxLength" [ngClass]="font?.size" matInput type="text"
              formControlName="securityCode">
            <mat-error *ngIf="securityCode?.hasError('invalid')" [ngClass]="font?.size">
              {{ pageContent?.cobrowsing.errors.invalid | translate }}
            </mat-error>
          </mat-form-field>
          <button class="btn large primary" [ngClass]="font?.size" [disabled]="isShareScreenButtonDisabled"
            (click)="startScreenshare()">
            {{ shareScreenButtonText | translate }}
          </button>
        </div>
      </form>
      <p class="description" [ngClass]="font?.size">{{ pageContent?.cobrowsing.recomendation | translate }}</p>
    </div>
  </div>
</div>