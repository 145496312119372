import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  APP_ROUTES, BENEFICIARY_USER_TYPE, CHANGE_PASSWORD_LOGOUT_MODES_DICT, CLIENT_USER_TYPE,
  EMPLOYER_USER_TYPE, LOGIN_SUCCESS_CODES, LOGOUT_MODES, SESSION_LOGOUT_MODES_DICT
} from '@constants/constants';
import { environment } from '@env';
import { HttpInterceptor } from '@interceptors/http-interceptor/http-interceptor';
import { RecoverAccessKey } from '@interfaces/authentication.interface';
import { EmployerRequestKey } from '@interfaces/employer-request-key.interface';
import { GetFirebaseTokenAuthResponse } from '@interfaces/get-firebase-token-auth';
import { LoginData } from '@interfaces/login.interface';
import { CodeRequest } from '@interfaces/security-key.interface';
import { UserType } from '@interfaces/segment.interface';
import { NavController } from '@ionic/angular';
import { LOGIN_MOCK } from '@mocks/login.mock';
import { FirebaseService } from '@services/firebase/firebase.abstract';
import { LogService, LogoutTypes } from '@services/log/log.service';
import { Util } from '@util';

import { ChangeBeneficiarySelectedAffiliate } from '@interfaces/beneficiary.interface';
import { ModalProvider } from '@providers/modal/modal';
import { SessionStatusProvider } from '@providers/session-status/session-status.provider';
import { EMPTY_BODY_MOCK } from './mocks/empty-body.mock';
import { RECOVER_EMPLOYER_ACCESS_KEY } from './mocks/recover-employer-access-key.mock';
import { REQUEST_EMPLOYER_ACCESS_KEY } from './mocks/request-employer-access-key.mock';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserType: Observable<UserType>;
  public sessionRut: string;
  public sessionBeneficiaryRut: string;
  public sessionToken: string;
  public isTemporalSession: boolean;
  private currentUserTypeSubject: BehaviorSubject<UserType> = new BehaviorSubject(null);
  private userLoggedIn$ = new BehaviorSubject(false);
  private selectedAffiliateRutSource: Subject<string> = new Subject<string>();
  public selectedAffiliateRut$: Observable<string> = this.selectedAffiliateRutSource.asObservable();
  public normalFlowMode = LOGOUT_MODES.normalFlow;
  public changePasswordMode = LOGOUT_MODES.changePassword;
  public logOutTemporalSessionMode = LOGOUT_MODES.logOutTemporalSession;
  public fromLoginMode = LOGOUT_MODES.fromLogin;
  public publicLogoutMode = LOGOUT_MODES.publicLogout;
  private currentUserStorageKey = 'currentUser';

  constructor(
    private util: Util,
    private http: HttpInterceptor,
    private httpClient: HttpClient,
    private firebaseService: FirebaseService,
    private navCtrl: NavController,
    private logService: LogService,
    private modalProvider: ModalProvider,
    private sessionStatusProvider: SessionStatusProvider,
  ) {
    this.setCurrentUserType();
  }

  get token() {
    return this.sessionToken;
  }

  public get currentUserTypeValue(): UserType {
    return this.currentUserTypeSubject.value;
  }

  public validateToken(token: string): Observable<GetFirebaseTokenAuthResponse> {
    return this.httpClient.post<GetFirebaseTokenAuthResponse>(
      `${environment.baseUrl}auth/authentication/get_firebase_token/`, { cipher: token, ingeniaToken: true });
  }

  public login(loginData: LoginData, userType: UserType): Observable<any> {
    loginData.rut = this.util.rutClean(loginData.rut);
    sessionStorage.setItem('rut', loginData.rut);
    let apiUrl: string;

    switch (userType) {
      case CLIENT_USER_TYPE:
        apiUrl = `${environment.authenticationUrl}clients/login`;
        break;
      case EMPLOYER_USER_TYPE:
        apiUrl = `${environment.employersUrl}authentication/login/`;
        break;
      case BENEFICIARY_USER_TYPE:
        apiUrl = `${environment.authenticationUrl}beneficiary/login`;
        break;
    }

    return this.handleLoginResponse(apiUrl, loginData, userType);
  }

  public changeDeceasedAffiliateToken(sessionData: ChangeBeneficiarySelectedAffiliate): Observable<any> {
    sessionData.rut = this.util.rutClean(sessionData.rut);
    sessionData.deceasedAffiliateRut = this.util.rutClean(sessionData.deceasedAffiliateRut);
    const url = `${environment.authenticationUrl}beneficiary/change-affiliate`;

    return this.handleLoginResponse(url, sessionData, BENEFICIARY_USER_TYPE);
  }

  public handleLoginResponse(
    apiUrl: string,
    loginData: LoginData | ChangeBeneficiarySelectedAffiliate,
    userType: UserType
  ): Observable<any> {
    return this.http.post(apiUrl, loginData, LOGIN_MOCK)
      .pipe(
        flatMap(async response => {
          const { password, temporaryPassword } = LOGIN_SUCCESS_CODES;
          const { code, token, temporalToken, lastAccess } = response;
          await this.setCurrentUserData(token, userType, temporalToken, lastAccess);
          if (code === password || code === temporaryPassword) {
            this.setUserLoggedIn(true);
          }
          return response;
        })
      );
  }

  public async passwordlessLogin(passwordlessResponse: any): Promise<void> {
    const { token, lastAccess } = passwordlessResponse;
    await this.setCurrentUserData(token, CLIENT_USER_TYPE, false, lastAccess);
    this.setUserLoggedIn(true);
  }

  public async setCurrentUserData(token: string, userType: UserType, isTemporalSession: boolean, lastAccess: string) {
    if (environment.mockHttpCalls) {
      await this.util.setStorage(this.currentUserStorageKey,
        { displayName: 'Valentina Torres', uid: '17750775', userType, isTemporalSession, lastAccess });
      return this.currentUserTypeSubject.next(userType);
    }
    const credential = await this.firebaseService.signInWithCustomToken(token);
    const fireBaseToken = await credential.user.getIdToken();
    const { claims } = await credential.user.getIdTokenResult();
    const user = await this.firebaseService.getCurrentUser();
    if (user) {
      const { displayName, uid } = user;
      this.sessionRut = uid;
      this.sessionToken = fireBaseToken;
      this.isTemporalSession = isTemporalSession;
      await this.util.setStorage(this.currentUserStorageKey, { displayName, uid, userType, isTemporalSession, lastAccess });
      this.currentUserTypeSubject.next(userType);
      if (claims.beneficiaryInformation?.rut) {
        this.setSelectedAffiliateRut(uid);
        this.sessionBeneficiaryRut = claims.beneficiaryInformation.rut;
      }
    }
  }

  public async setExternalLogin(token: string) {
    this.setUserLoggedIn(true);
    await this.setCurrentUserData(token, 'client', false, '');
  }

  public userIsClient(): boolean {
    return this.currentUserTypeValue === CLIENT_USER_TYPE;
  }

  public userIsBeneficiary(): boolean {
    return this.currentUserTypeValue === BENEFICIARY_USER_TYPE;
  }

  public async setTemporalSession(): Promise<boolean> {
    return await this.util.getStorage(this.currentUserStorageKey)
      .then((user) => this.isTemporalSession = JSON.parse(user.isTemporalSession))
      .catch(() => this.isTemporalSession = false);
  }

  public userIsEmployer(): boolean {
    return this.currentUserTypeValue === EMPLOYER_USER_TYPE;
  }

  public getUserRut() {
    if (environment.mockHttpCalls) return '129245905';
    return this.sessionRut;
  }

  public getBeneficiaryRut() {
    if (environment.mockHttpCalls) return '191234567';
    return this.sessionBeneficiaryRut;
  }

  public async getUserName(): Promise<string> {
    if (environment.mockHttpCalls) return 'Valentina';
    return await this.util.getStorage(this.currentUserStorageKey).then((user) => {
      const client = JSON.parse(user.displayName);
      const firstName = this.util.titleCase(client.firstName);
      return firstName;
    });
  }

  public async getUserFullName(): Promise<string> {
    if (environment.mockHttpCalls) return 'Valentina Torres Rivera';
    try {
      const client = await this.getCurrentUser();
      const { firstName, lastName, motherLastName } = client;
      const fullName = this.util.titleCase(`${firstName} ${lastName} ${motherLastName}`);
      return fullName;
    } catch { return ''; }
  }

  public async getCurrentUser() {
    return await this.util.getStorage(this.currentUserStorageKey).then((user) => {
      return JSON.parse(user.displayName);
    });
  }

  public async getUserLastAccess() {
    return await this.util.getStorage(this.currentUserStorageKey).then((user) => user.lastAccess).catch(() => '');
  }

  public async logout(origin: string = this.normalFlowMode, logEnabled: boolean = true) {
    this.modalProvider.closeModals();
    this.killSession(origin, logEnabled);

    if (this.util.isNative) {
      window.location.href = APP_ROUTES.login;
    } else {
      this.redirect(origin);
    }
  }

  public async killSession(origin: string = this.normalFlowMode, logEnabled: boolean = true) {
    const activelogService = this.activeLogService(origin);
    const logoutType = `logout_${this.currentUserTypeValue}` as LogoutTypes;
    if (logEnabled && this.isUserLoggedIn() && activelogService) { this.logService.logout(logoutType, 1, this.sessionRut); }
    await this.firebaseService.signOut();
    this.sessionStatusProvider.setSessionAsEnded();
    this.removeSessionData();
  }

  public refreshToken() {
    if (!this.isUserLoggedIn()) return EMPTY;
    const rut = this.getUserRut();
    this.http.post(`${environment.authenticationUrl}clients/refresh-token`, { rut }, EMPTY_BODY_MOCK)
      .subscribe(
        () => { },
        () => this.logout(LOGOUT_MODES.logOutTemporalSession)
      );
  }

  public setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn$.next(userLoggedIn);
  }

  public getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn$.asObservable();
  }

  public isUserLoggedIn(): boolean {
    return this.userLoggedIn$.getValue();
  }

  public setSelectedAffiliateRut(rut: string) {
    this.selectedAffiliateRutSource.next(rut);
  }

  public getSelectedAffiliateRutSource(): Subject<string> {
    return this.selectedAffiliateRutSource;
  }

  private async setCurrentUserType() {
    const currentUser = await this.util.getStorage(this.currentUserStorageKey);
    const userType = currentUser ? currentUser.userType : null;
    this.currentUserTypeSubject.next(userType);
    this.currentUserType = this.currentUserTypeSubject.asObservable();
  }

  public sendCode(data: CodeRequest): Observable<any> {
    const rut = this.getUserRut();
    return this.http.post(`${environment.clientsUrl}authentication/secondary-key/send`, { ...data, rut }, EMPTY_BODY_MOCK);
  }

  public sendBeneficiaryCode(data: CodeRequest): Observable<any> {
    const rut = this.getBeneficiaryRut();
    return this.http.post(`${environment.clientsUrl}authentication/secondary-key/beneficiary/send`, { ...data, rut }, EMPTY_BODY_MOCK);
  }

  public validateCode(pin: string): Observable<any> {
    const rut = this.getUserRut();
    return this.http.post(`${environment.clientsUrl}authentication/secondary-key/validate`, { rut, pin }, EMPTY_BODY_MOCK);
  }

  public validateBeneficiaryCode(pin: string): Observable<any> {
    const rut = this.getBeneficiaryRut();
    return this.http.post(`${environment.clientsUrl}authentication/secondary-key/beneficiary/validate`, { rut, pin }, EMPTY_BODY_MOCK);
  }

  public validateSerialNumber(serialNumber: string): Observable<any> {
    const rut = this.getUserRut();
    return this.http.post(`${environment.clientsUrl}authentication/serial-number/validate`, { rut, serialNumber }, EMPTY_BODY_MOCK);
  }

  public validateBeneficiarySerialNumber(serialNumber: string): Observable<any> {
    const rut = this.getBeneficiaryRut();
    return this.http
      .post(`${environment.clientsUrl}authentication/serial-number/beneficiary/validate`, { rut, serialNumber }, EMPTY_BODY_MOCK);
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const data = {
      rut: this.userIsBeneficiary() ? this.getBeneficiaryRut() : this.getUserRut(),
      oldPassword,
      newPassword
    };

    const apiUrl = this.userIsEmployer() ? environment.employersUrl : environment.clientsUrl;
    const url = this.userIsBeneficiary()
      ? `${apiUrl}authentication/password/beneficiary/`
      : `${apiUrl}authentication/password/`;
    return this.http.patch(url, data, EMPTY_BODY_MOCK);
  }

  public recoverClientAccessKey(data: RecoverAccessKey): Observable<any> {
    return this.http.post(`${environment.clientsUrl}authentication/password/recover`, data, EMPTY_BODY_MOCK);
  }

  public recoverBeneficiaryAccessKey(data: RecoverAccessKey): Observable<any> {
    return this.http.post(`${environment.clientsUrl}authentication/password/beneficiary/recover`, data, EMPTY_BODY_MOCK);
  }

  public getPasswordlessAccess(rut: string): Observable<any> {
    return this.http.post(`${environment.clientsUrl}authentication/passwordless-generate`, { rut }, EMPTY_BODY_MOCK);
  }

  public passwordlessValidate(rut: string, OTP: string): Observable<any> {
    return this.http.post(`${environment.clientsUrl}authentication/passwordless-login`, { rut, OTP }, EMPTY_BODY_MOCK);
  }

  public getClientContactData(rut: string): Observable<any> {
    return this.http.get(`${environment.clientsUrl}´${rut}´`, EMPTY_BODY_MOCK);
  }

  public recoverEmployerAccessKey(rut: string, email: string) {
    return this.http.post(`${environment.employersUrl}authentication/password/recover`, { rut, email }, RECOVER_EMPLOYER_ACCESS_KEY);
  }

  public requestEmployerAccessKey(data: EmployerRequestKey) {
    return this.http.post(`${environment.employersUrl}authentication/password`, data, REQUEST_EMPLOYER_ACCESS_KEY);
  }

  private removeSessionData() {
    this.util.removeStorage(this.currentUserStorageKey);
    this.util.removeStorage('trace_processid');
    this.util.removeStorage('trace_flow');
    this.currentUserTypeSubject.next(null);
    this.sessionRut = null;
    this.sessionToken = null;
    this.setUserLoggedIn(false);
  }

  private redirect(origin: string) {
    if (this.isChangePasswordLogOutMode(origin)) return;
    if (this.isSessionLogOutMode(origin)) { this.navCtrl.navigateRoot(APP_ROUTES.login); }
    if (origin === this.publicLogoutMode) { this.util.redirectToPublicSite(); }
  }

  private activeLogService(origin: string): boolean {
    return !this.isChangePasswordLogOutMode(origin) && origin !== this.logOutTemporalSessionMode;
  }

  private isChangePasswordLogOutMode(origin: string): boolean {
    return CHANGE_PASSWORD_LOGOUT_MODES_DICT.some((logoutMode) => logoutMode === origin);
  }

  private isSessionLogOutMode(origin: string): boolean {
    return SESSION_LOGOUT_MODES_DICT.some((logoutMode) => logoutMode === origin);
  }

}
